import api from './api'

export const get = async (): Promise<[APIError, Permission[] | null]> => {
  const response = await api.get('/v1/auth/permissions')

  if (response.status === 200) {
    const data: Permission[] = response.data.data
    return [null, data]
  }

  const data: ErrorSchema = response.data
  return [data.message, null]
}
