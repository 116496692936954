import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { InputCity } from 'components/InputCity/InputCity'
import { MultipleSelect } from 'components/Select/lugarh/MultipleSelect'
import { Select } from 'components/Select/Select'
import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'
import useStore from 'store'

import styles from './GeneralSection.module.scss'
import { FilterForm } from '../CandidateFilterContainer'

export interface GeneralFilter {
  location: string
  age: {
    min: number
    max: number
  }
  gender: string
  civilStatus: string
  salary: {
    min: number
    max: number
  }
  availableToMove: string
  workspaces: string[]
}

type Props = {
  onChange: (generalFilter: GeneralFilter) => void
  companySettings: CompanySettingsAPI | null
  clearFilters: (emptyFilter: any) => void
  filter: FilterForm
}

export const GeneralSection: React.FC<Props> = ({
  onChange,
  clearFilters,
  companySettings,
  filter
}) => {
  const { t } = useTranslation('candidateFilter')
  const { selectors } = useStore()
  const userData = selectors.auth.user()
  // General
  const [location, setLocation] = useState(filter.general?.location || '')
  const [age, setAge] = useState(filter.general?.age || { min: '', max: '' })
  const [salary, setSalary] = useState(
    filter.general?.salary || { min: '', max: '' }
  )
  const [gender, setGender] = useState(filter.general?.gender || '')
  const [civilStatus, setCivilStatus] = useState(
    filter.general?.civilStatus || ''
  )
  const [availableToMove, setAvailableToMove] = useState(
    filter.general?.availableToMove || ''
  )
  const [workspaces, setWorkspaces] = useState<string[]>(
    filter.general?.workspaces ? filter.general.workspaces.split(',') : []
  )
  const [completeWorlspaces, setCompleteWorkspaces] = useState<
    WorkspaceSchema[]
  >([])

  const genderOptions = ['other', 'male', 'female']
  const civilStatusOptions = [
    'single',
    'married',
    'divorced',
    'widowed',
    'other'
  ]
  const availableToMoveOptions = ['true', 'false']

  useEffect(() => {
    if (userData) {
      const noGeneralWorkspace = userData.workspaces.filter(
        (workspace: WorkspaceSchema) => workspace.name !== 'Geral'
      )
      setCompleteWorkspaces(noGeneralWorkspace)
    }
  }, [])

  useEffect(() => {
    onChange({
      location: location || '',
      age: {
        min: Number(age.min) || '',
        max: Number(age.max) || ''
      },
      salary: {
        min: Number(salary.min) || '',
        max: Number(salary.max) || ''
      },
      gender,
      civilStatus,
      availableToMove,
      workspaces
    } as GeneralFilter)
  }, [location, age, salary, gender, civilStatus, availableToMove, workspaces])

  useEffect(() => {
    if (!Object.keys(filter).length) {
      setLocation('')
      setGender('')
      setAge({ min: '', max: '' })
      setSalary({ min: '', max: '' })
      setCivilStatus('')
      setAvailableToMove('')
      setWorkspaces([])
    }
  }, [filter])

  const {
    ask_birthdate,
    ask_city_change_availability,
    ask_sex,
    ask_marital_status,
    ask_wage_claim
  } = companySettings || {}

  const handleSelectWorkspaces = (selected: string[]) => {
    setWorkspaces(selected)
  }

  return (
    <>
      <header className={styles.header}>
        <p className='candidate-filter-section-title'>{t('general.title')}</p>
        <button
          onClick={() => clearFilters({})}
          className={styles.clearFilterButton}
        >
          {t('general.clearFilters')}
        </button>
      </header>
      <FormRow>
        <FormItem>
          <InputCity
            type='text'
            label={t('general.location')}
            name='location'
            value={location}
            onChange={e => setLocation(e[0])}
            small
          />
        </FormItem>
      </FormRow>
      {(ask_birthdate || ask_sex) && (
        <FormRow>
          {ask_birthdate && (
            <>
              <FormItem formItemClasses={ask_sex ? 'col-md-4' : 'col-md-6'}>
                <Input
                  type='number'
                  label={t('general.age.min')}
                  name='min-age'
                  value={age.min}
                  onChange={e => setAge({ ...age, min: e })}
                  small
                ></Input>
              </FormItem>

              <FormItem
                formItemClasses={`${ask_sex ? 'col-md-4' : 'col-md-6'} spaceUp`}
              >
                <Input
                  type='number'
                  label={t('general.age.max')}
                  name='max-age'
                  value={age.max}
                  onChange={e => setAge({ ...age, max: e })}
                  small
                ></Input>
              </FormItem>
            </>
          )}

          {ask_sex && (
            <FormItem
              formItemClasses={`${
                ask_birthdate ? 'col-md-4' : 'col-md-12'
              } spaceUp`}
            >
              <Select
                id='gender-select'
                label={t('general.gender')}
                value={gender}
                onChange={e => setGender(e.target.value)}
              >
                <option value=''></option>

                {genderOptions.map((el, index) => {
                  return (
                    <option value={el} key={index}>
                      {t(`genderOptions.${el}`)}
                    </option>
                  )
                })}
              </Select>
            </FormItem>
          )}
        </FormRow>
      )}
      {(ask_marital_status || ask_wage_claim) && (
        <FormRow>
          {ask_marital_status && (
            <FormItem
              formItemClasses={ask_wage_claim ? 'col-md-4' : 'col-md-12'}
            >
              <Select
                id='civilStatus-select'
                label={t('general.civilStatus')}
                value={civilStatus}
                onChange={e => setCivilStatus(e.target.value)}
              >
                <option value=''></option>

                {civilStatusOptions.map((el, index) => {
                  return (
                    <option value={el} key={index}>
                      {t(`civilStatusOptions.${el}`)}
                    </option>
                  )
                })}
              </Select>
            </FormItem>
          )}
          {ask_wage_claim && (
            <>
              <FormItem
                formItemClasses={`${
                  ask_marital_status ? 'col-md-4' : 'col-md-6'
                } spaceUp`}
              >
                <Input
                  type='number'
                  label={t('general.salary.min')}
                  name='min-salary'
                  value={salary.min}
                  onChange={e => setSalary({ ...salary, min: e })}
                  small
                ></Input>
              </FormItem>

              <FormItem
                formItemClasses={`${
                  ask_marital_status ? 'col-md-4' : 'col-md-6'
                } spaceUp`}
              >
                <Input
                  type='number'
                  label={t('general.salary.max')}
                  name='max-salary'
                  value={salary.max}
                  onChange={e => setSalary({ ...salary, max: e })}
                  small
                ></Input>
              </FormItem>
            </>
          )}
        </FormRow>
      )}
      {ask_city_change_availability && (
        <FormRow>
          <FormItem>
            <Select
              id='availableToMove-select'
              label={t('general.availableToMove')}
              value={availableToMove}
              onChange={e => setAvailableToMove(e.target.value)}
            >
              <option value=''></option>
              {availableToMoveOptions.map((el, index) => {
                return (
                  <option value={el} key={index}>
                    {t(`availableToMoveOptions.${el}`)}
                  </option>
                )
              })}
            </Select>
          </FormItem>
        </FormRow>
      )}
      <FormRow>
        <FormItem>
          <MultipleSelect
            items={completeWorlspaces}
            onChange={handleSelectWorkspaces}
            defaultValue={workspaces}
          />
        </FormItem>
      </FormRow>
    </>
  )
}
