import { Questions } from 'components/CustomQuestions/CustomQuestions'
import ReactGA from 'utils/analytics'

import { FormData } from './CreateContainer'
interface Props extends FormData {
  questions: Array<Questions>
}

const createContainerAnalytics = (jobData: Props): void => {
  if (!jobData) return

  const {
    name,
    city,
    deadline,
    hiring_workflow,
    budget,
    job_group,
    quantity,
    description,
    tags,
    recruiters_team,
    managers_team,
    questions
  } = jobData

  if (
    name &&
    city &&
    deadline &&
    budget &&
    quantity &&
    job_group &&
    tags.length
  ) {
    ReactGA.event('mandatory_info', {
      event_category: 'create_job_listing',
      event_label: 'mandatory_info'
    })
  }

  if (description) {
    ReactGA.event('description', {
      event_category: 'create_job_listing',
      event_label: 'description'
    })
  }

  if (hiring_workflow) {
    ReactGA.event('hiring_workflow', {
      event_category: 'create_job_listing',
      event_label: 'hiring_workflow'
    })
  }

  if (recruiters_team.length) {
    ReactGA.event('recruiters_team', {
      event_category: 'create_job_listing',
      event_label: 'recruiters_team'
    })
  }

  if (managers_team.length) {
    ReactGA.event('managers_team', {
      event_category: 'create_job_listing',
      event_label: 'managers_team'
    })
  }

  if (questions?.length) {
    ReactGA.event('create_questions', {
      event_category: 'create_job_listing',
      event_label: 'create_questions'
    })

    questions.forEach((question: Questions): void => {
      if (question.type === 'multiple_choice') {
        ReactGA.event('create_question_single_answer', {
          event_category: 'create_job_listing',
          event_label: 'create_question_single_answer'
        })
      }

      if (question.type === 'selectable_multiple_choice') {
        ReactGA.event('create_question_multiple_choice', {
          event_category: 'create_job_listing',
          event_label: 'create_question_multiple_choice'
        })
      }

      if (question.type === 'open_ended') {
        ReactGA.event('create_question_single_text', {
          event_category: 'create_job_listing',
          event_label: 'create_question_single_text'
        })
      }
    })
  }

  ReactGA.event('create_job', {
    event_category: 'create_job_listing',
    event_label: 'create_new_job_finish'
  })
}

export default createContainerAnalytics
