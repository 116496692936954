import api from './api'

export const get = async (): Promise<
  [APIError, SubscriptionSettings | null]
> => {
  const response = await api.get(`/v1/rocketmat/company/subscription`)

  if (response.status === 200) {
    const data: SubscriptionSettings = response.data.data
    return [
      null,
      {
        subscription_iugu_id: data.subscription_iugu_id,
        plan_identifier: data.plan_identifier,
        price: data.price,
        currency: data.currency,
        expires_at: data.expires_at,
        recent_invoices: data.recent_invoices,
        plan_recrud: data.plan_recrud,
        payment_method:
          data.payment_method !== undefined ? data.payment_method : null
      }
    ]
  }

  if (
    response.status === 401 ||
    response.status === 404 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}
