import axios from 'axios'

type APIResponseData = {
  cep: string
  logradouro: string
  bairro: string
  localidade: string
  uf: string
}

export const getCitiesViacep = async (
  zipcode: string
): Promise<APIResponseData | null> => {
  try {
    if (zipcode !== '' && zipcode.length === 10) {
      const code = zipcode.replace(/\D/g, '')
      const data: APIResponseData = (
        await axios.get(`https://viacep.com.br/ws/${code}/json/`)
      ).data

      return data
    } else return null
  } catch (err) {
    throw new Error(err)
  }
}
