import React, { useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as GridIcon } from 'assets/images/icons/grid-icon.svg'
import { ReactComponent as ListIcon } from 'assets/images/icons/list-icon.svg'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { Select } from 'components/Select/Select'
import ReactGA from 'utils/analytics'
import { returnDefaultStages } from 'utils/defaultStage'

import './CandidateActions.scss'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

export type CandidateActionsRef = {
  orderByRef: React.RefObject<HTMLDivElement>
}

type Props = {
  moveStepId: string
  stageId: string
  stages: Array<WorkflowStageSchema>
  onOrderBy: (order: string) => void
  onMoveToStep: (stepId: string) => void
  onChangeViewType: (viewType: 'list' | 'grid') => void
  getRefs?: (value: CandidateActionsRef) => void
}

export const CandidateActions: React.FC<Props> = ({
  moveStepId,
  stageId,
  stages,
  onOrderBy,
  onMoveToStep,
  onChangeViewType,
  getRefs
}) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const [orderBy, setOrderBy] = useState('')
  const [moveStep, setMoveStep] = useState('')
  const [viewType, setViewType] = useState<'list' | 'grid'>('list')
  const { t } = useTranslation('candidateHeader')
  const [tworkflows] = useTranslation('defaultWorkflowStages')
  const orderByRef = useRef(null)

  const orderByOptions = ['applicationDate', 'review', 'name']

  useEffect(() => {
    if (getRefs !== undefined) getRefs({ orderByRef })
  }, [])

  const handleOrderBy = (e: { target: { value: any } }) => {
    const value = e.target.value
    setOrderBy(value)
    onOrderBy(value)

    ReactGA.event(`order_candidates_by_${value.toLowerCase()}`, {
      event_category: 'job_listing_candidates',
      event_label: `order_candidates_by_${value.toLowerCase()}`
    })
  }

  const handleOnMoveToStep = (e: { target: { value: any } }) => {
    const value = e.target.value
    onMoveToStep(value)
    setMoveStep(value)
    const currentStage = returnDefaultStages(stages).find(
      defaultStage => defaultStage.uuid === value
    )
    if (currentStage) {
      ReactGA.event(`move_candidates_to_${currentStage.name.toLowerCase()}`, {
        event_category: 'job_listing_candidates',
        event_label: `move_candidates_to_${currentStage.name.toLowerCase()}`
      })
    }
  }

  return (
    <div className='candidate-actions-wrapper'>
      <div className='view-actions' ref={orderByRef}>
        <Select label={t('orderBy')} value={orderBy} onChange={handleOrderBy}>
          <option value=''></option>

          {orderByOptions.map((el, index) => {
            const optionValue = el !== 'name' ? '-' + el : el
            return (
              <option value={optionValue} key={index}>
                {t(`orderByOptions.${el}`)}
              </option>
            )
          })}
        </Select>
        {dontShowOnGeralWorkspace && (
          <PermissionAgent
            module='job-application'
            actions={['edit']}
            onFail='hidden'
            Component={({ hidden }) =>
              !hidden && (
                <Select
                  label={t('moveSelected')}
                  value={moveStepId || moveStep || stageId}
                  onChange={handleOnMoveToStep}
                >
                  <option value=''></option>

                  {stages.map(stage => {
                    return (
                      <option value={stage.uuid} key={stage.uuid}>
                        {tworkflows(stage.name)}
                      </option>
                    )
                  })}
                </Select>
              )
            }
          />
        )}
      </div>
    </div>
  )
}
