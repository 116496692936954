import useStore from 'store'

interface UserCheckPermissionProps {
  resource: PermissionModule
  scope: PermissionAction[]
}

export const UserCheckPermission = (obj: UserCheckPermissionProps): boolean => {
  const { selectors } = useStore()
  const permissions = selectors.permissions.permissions()
  const permission = permissions[obj.resource]

  if (!permission) return false

  const anyDenied = obj.scope
    .map(action => permission.scopes.includes(`${obj.resource}:${action}`))
    .filter(action => !action)
  return !(anyDenied.length > 0)
}

export default UserCheckPermission
