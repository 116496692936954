import { useState } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { getDateFormat } from 'utils/date'

export interface DateValidationErrors {
  startDate: string | null
  endDate: string | null
  invalidDate: string | null
}

export enum ValidatewDateOptions {
  BIRTHDATE = 'BIRTHDATE',
  DEADLINE = 'DEADLINE'
}

type UseDateValidationType = {
  validateStartEndDate: (
    startDate: string,
    endDate: string,
    initialLimitDate: string
  ) => boolean
  validateDate: (
    dateToValidate: string,
    option: ValidatewDateOptions,
    canBeEmpty?: boolean
  ) => boolean
  errors: DateValidationErrors
}

/**
 * Hook responsável por realizar a validação de datas.
 *
 * @returns Um objeto contendo a função de validação de início e fim de datas, e os erros encontrados.
 */
export const useDateValidation = (): UseDateValidationType => {
  const [errors, setErrors] = useState<DateValidationErrors>({
    startDate: null,
    endDate: null,
    invalidDate: null
  })
  const { t: errorsTranslations } = useTranslation('errors')
  const resetErrors = () => {
    setErrors({
      startDate: null,
      endDate: null,
      invalidDate: null
    })
  }

  /**
   * Validates a date based on the provided options.
   * @param dateToValidate - The date to validate as a string.
   * @param option - The validation option to determine the validation rules.
   * @returns A boolean indicating whether the date is valid or not.
   * @example validateDate('28/02/2021', ValidatewDateOptions.BIRTHDATE) -> true
   */
  const validateDate = (
    dateToValidate: string,
    option: ValidatewDateOptions,
    canBeEmpty = false
  ): boolean => {
    const theDate = moment(dateToValidate, getDateFormat())
    // early return to validate if date is empty and canBeEmpty
    if (canBeEmpty && !theDate.isValid()) {
      resetErrors()
      return true
    }

    const today = moment()
    const minAge = moment().subtract(14, 'years')

    let beforeDate = today
    let afterDate = moment().add(100, 'years')
    let errorMessage = errorsTranslations('deadlineInvalidFormat')
    let isBirthInvalid = false

    if (option === ValidatewDateOptions.BIRTHDATE) {
      beforeDate = moment().subtract(100, 'years')
      isBirthInvalid = theDate.isAfter(minAge)
      afterDate = today
      if (theDate.isAfter(minAge)) {
        errorMessage = errorsTranslations('invalidBirthdate')
      } else {
        errorMessage = errorsTranslations('dateInvalidFormat')
      }
    }

    if (
      !theDate.isValid() ||
      theDate.isBefore(beforeDate) ||
      theDate.isAfter(afterDate) ||
      isBirthInvalid
    ) {
      setErrors(errors => ({
        ...errors,
        invalidDate: errorMessage
      }))
      return false
    }
    resetErrors()
    return true
  }

  /**
   * Valida a data de início e fim em relação a uma data limite inicial.
   *
   * @param startDate - A data de início a ser validada.
   * @param endDate - A data de fim a ser validada.
   * @param initialLimitDate - A data limite inicial.
   * @returns Retorna true se as datas forem válidas, caso contrário, retorna false e seta os erros.
   */
  const validateStartEndDate = (
    startDate: string,
    endDate: string,
    initialLimitDate: string
  ): boolean => {
    const startD = moment(startDate, 'DD/MM/YYYY')
    const endD = moment(endDate, 'DD/MM/YYYY')
    const initD =
      initialLimitDate === ''
        ? moment().subtract(100, 'years')
        : moment(initialLimitDate, 'DD/MM/YYYY')
    const today = moment().format('DD/MM/YYYY')

    if (!startD.isValid() || startD.isBefore(initD) || startD.isAfter(today)) {
      setErrors(errors => ({
        ...errors,
        startDate: errorsTranslations('invalidStartDate')
      }))
      return false
    }

    if (!endD.isValid() || endD.isBefore(startD) || endD.isAfter(today)) {
      setErrors(errors => ({
        ...errors,
        endDate: errorsTranslations('invalidEndDate')
      }))
      return false
    }

    return true
  }

  return {
    validateDate,
    validateStartEndDate,
    errors
  }
}
