/**
 * Trata os jobs remotos definindo city como 'Remote' se o valor for null or undefined.
 * @param jobs - Um array de Job objects.
 * @returns Um array de Job objects com a propriedade city tratada.
 */
export const treatRemoteJobs = (jobs: Job[]): Job[] => {
  return jobs.map(job => {
    if (!job.city) return { ...job, city: 'Remoto' }
    return job
  })
}
