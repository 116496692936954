import React, { useState, useEffect } from 'react'
import './Switch.scss'

type Props = {
  initialValue: boolean
  onChange: (value: boolean) => void
  label?: string
  disabled?: boolean
}

export const Switch: React.FC<Props> = ({
  initialValue,
  label,
  onChange,
  disabled = false
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div
      className={`switch ${value ? 'active' : ''}`}
      data-testid='switch_container'
      id='switch_container'
      onClick={() => {
        if (!disabled) {
          onChange(!value)
          setValue(!value)
        }
      }}
    >
      <div data-testid='switch_button' className='switch-button' />
      {label && <label>{label}</label>}
    </div>
  )
}
