import React from 'react'

import { useTranslation, Trans } from 'react-i18next'

import FacebookIcon from 'assets/images/social/facebook.png'
import LinkedInIcon from 'assets/images/social/linkedin.png'
import TwitterIcon from 'assets/images/social/twitter.png'
import WhatsAppIcon from 'assets/images/social/whatsapp.png'
import { ReactComponent as CopyIcon } from 'assets/svg/copy.svg'
import useStore from 'store'
import { NotificationActions } from 'store/notification'
import ReactGA from 'utils/analytics'

import './ShareJob.scss'

type ShareJobProps = {
  site: string
  jobUuid: string
}

type ItemProps = {
  title: string
  urlMessage: string
  url: string
  icon: React.ReactNode
  color: string
  copy?: boolean
  type: string
  onCopyingSuccessfully: () => void
}

const Item: React.FC<ItemProps> = ({
  title,
  urlMessage,
  url,
  icon,
  color,
  type,
  onCopyingSuccessfully
}) => {
  const copyLink = () => {
    const inputElement = document.createElement('input')
    inputElement.type = 'text'
    inputElement.value = url
    document.body.appendChild(inputElement)

    inputElement.select()
    inputElement.setSelectionRange(0, url.length)
    document.execCommand('copy')

    document.body.removeChild(inputElement)
    onCopyingSuccessfully()

    ReactGA.event(`share_job_via_${type}`, {
      event_category: 'edit_job_listing',
      event_label: `share_job_via_${type}`
    })
  }

  return (
    <div className='share-item' style={{ backgroundColor: color }}>
      <div className='icon'>{icon}</div>

      <div className='content'>
        <strong>{title}</strong>
        <Trans
          i18nKey='jobShare:message'
          values={{ url }}
          components={{
            copy: <a onClick={copyLink} className='share-job-link' />,
            p: <p />
          }}
        />
        <p>
          {urlMessage}{' '}
          <a target='_blank' rel='noreferrer' href={url}>
            {url}
          </a>
        </p>
      </div>
    </div>
  )
}

export const ShareJob: React.FC<ShareJobProps> = ({ jobUuid, site }) => {
  const { t } = useTranslation('jobShare')

  const url = `${site}/${jobUuid}?from=`

  const { dispatch } = useStore()

  const onCopyingSuccessfully = () => {
    dispatch(
      NotificationActions.notify({
        type: 'success',
        message: t('onCopyingSuccessfully')
      })
    )
  }

  return (
    <div className='p-4'>
      <Item
        type='manually'
        color='#00C7D9'
        title={t('shareManually')}
        urlMessage={t('url')}
        url={url + 'organic'}
        icon={<CopyIcon className='image' />}
        onCopyingSuccessfully={onCopyingSuccessfully}
      />

      <Item
        type='whatsApp'
        color='#25D366'
        title={t('shareWith', { socialMedia: 'WhatsApp' })}
        urlMessage={t('url')}
        url={url + 'whatsapp'}
        icon={<img src={WhatsAppIcon} className='image' />}
        onCopyingSuccessfully={onCopyingSuccessfully}
      />

      <Item
        type='twitter'
        color='#50ABF1'
        title={t('shareWith', { socialMedia: 'Twitter' })}
        urlMessage={t('url')}
        url={url + 'twitter'}
        icon={<img src={TwitterIcon} className='image' />}
        onCopyingSuccessfully={onCopyingSuccessfully}
      />

      <Item
        type='linkedin'
        color='#2C74B2'
        title={t('shareWith', { socialMedia: 'LinkedIn' })}
        urlMessage={t('url')}
        url={url + 'linkedin'}
        icon={<img src={LinkedInIcon} className='image' />}
        onCopyingSuccessfully={onCopyingSuccessfully}
      />

      <Item
        type='facebook'
        color='#38539A'
        title={t('shareWith', { socialMedia: 'Facebook' })}
        urlMessage={t('url')}
        url={url + 'facebook'}
        icon={<img src={FacebookIcon} className='image' />}
        onCopyingSuccessfully={onCopyingSuccessfully}
      />
    </div>
  )
}
