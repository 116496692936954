import React from 'react'

import '../DashboardContainer.scss'

import DashboardHeader from './components/DashboardHeader'
import {
  DashboardProvider,
  useDashboardContext
} from './DashboardContainerContext'
import AverageClosingTime from './sections/AverageClosingTime'
import AverageFinalizeTime from './sections/AverageFinalizeTime'
import CandidatesAcquisition from './sections/CandidatesAcquisition'
import CandidatesApplications from './sections/CandidatesApplications'
import CandidatesByGenderAndAge from './sections/CandidatesByGenderAndAge'
import CandidatesByLastSixMonths from './sections/CandidatesByLastSixMonths'
import CandidatesByLevel from './sections/CandidatesByLevel'
import CandidatesByMaritalStatus from './sections/CandidatesByMaritalStatus'
import CandidatesByState from './sections/CandidatesByState'
import JobPositions from './sections/JobPositions'
import JobPositionsFinalizedByRecruiterOffSchedule from './sections/JobPositionsFinalizedByRecruiterOffSchedule'

export interface DashboardFiltersPayload {
  user?: string
  workspaces?: string
  hiring_workflow?: string
}

const DashboardContent = () => {
  const { t, loading, dataPrivacySettings, expandedMenu } =
    useDashboardContext()

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      <DashboardHeader t={t} />

      <div className='content'>
        <div>
          <div className='container'>
            <JobPositions t={t} loading={loading} />
            <AverageClosingTime t={t} loading={loading} />
            <AverageFinalizeTime t={t} loading={loading} />
            <JobPositionsFinalizedByRecruiterOffSchedule
              t={t}
              loading={loading}
            />
            <CandidatesAcquisition t={t} loading={loading} />
            <CandidatesApplications t={t} loading={loading} />
            <CandidatesByLastSixMonths t={t} loading={loading} />
            <CandidatesByGenderAndAge
              t={t}
              loading={loading}
              dataPrivacySettings={dataPrivacySettings}
            />
            <CandidatesByLevel t={t} loading={loading} />
            <CandidatesByMaritalStatus
              t={t}
              loading={loading}
              dataPrivacySettings={dataPrivacySettings}
            />
            <CandidatesByState t={t} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  )
}

export function DashboardContainer(): JSX.Element {
  return (
    <DashboardProvider>
      <DashboardContent />
    </DashboardProvider>
  )
}
