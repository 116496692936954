import React from 'react'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import * as Pages from 'pages'
import { DynamicPageLoader } from 'pages/DynamicPageLoader'

import { PrivateRoute } from './private.routing'
import { RoutePaths, routesConfig } from './Router.config'

export default function Router(): JSX.Element {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path='/' element={<Navigate to={RoutePaths.login} replace />} />
        {/*
          // important: When using Theme.RECRUD on loin page the index must be like this
          <Route path='/' element={<Pages.LoginPage />} />
        */}
        {routesConfig.map(({ path, private: isPrivate }) =>
          isPrivate ? (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute path={path} component={DynamicPageLoader} />
              }
            />
          ) : (
            <Route key={path} path={path} element={<DynamicPageLoader />} />
          )
        )}
        <Route path='/loaderio-e0ffbeadd60e11efde72d45a4122a76c.txt' />
        <Route element={<Pages.NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
