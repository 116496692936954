import React from 'react'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme, useTheme } from '@mui/material/styles'

import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'
import './MultipleSelect.style.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  disablePortal: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

interface MultipleSelectProps {
  items: WorkspaceSchema[]
  onChange: (items: string[]) => void
  defaultValue: string[]
  label?: string
}

export const MultipleSelect: React.FC<MultipleSelectProps> = ({
  items,
  onChange,
  defaultValue,
  label
}) => {
  const theme = useTheme()
  const [personName, setPersonName] = React.useState<string[]>(
    defaultValue || []
  )

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
    onChange(value as string[])
  }

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <InputLabel id='worskpace-multiple-select-label'>
        {label || 'Workspaces'}
      </InputLabel>
      <Select
        labelId='worskpace-multiple-select-label'
        id='worskpace-multiple-select'
        multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput label={label || 'Workspaces'} />}
        MenuProps={MenuProps}
      >
        {items.map((item: WorkspaceSchema) => (
          <MenuItem
            key={item.uuid}
            value={item.uuid}
            style={getStyles(item.name, personName, theme)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
