import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import people from 'assets/images/background/people.png'
import splashBackground from 'assets/images/background/splash-background.png'
import recrudLogo from 'assets/images/logo/recrud-title-logo.png'
import { LoginSwitch } from 'components/LoginSwitch/LoginSwitch'
import { ForgotPassword } from 'containers/ForgotPassword/ForgotPassword'
import '../login/login.scss'
import ReactGA from 'utils/analytics'

export function ForgotPasswordPage(): JSX.Element {
  const { t } = useTranslation('login')

  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('metatags.title')}</title>
        <meta name='description' content={t('metatags.description')} />
        <meta name='url' content={t('metatags.url')} />
        <meta property='og:title' content={t('metatags.title')} />
        <meta property='og:description' content={t('metatags.description')} />
        <meta property='og:url' content={t('metatags.url')} />
      </Helmet>

      <main>
        <div className='container h-100'>
          <div className='row h-100'>
            <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
              <div className='splash-background-area'>
                <img
                  className='splash-background'
                  src={splashBackground}
                  alt='splash-background'
                />
                <img
                  className='splash-background-people'
                  src={people}
                  alt='people'
                />

                <div className='landing-info'>
                  <h2>{t('welcome')}</h2>
                  <div className='d-flex justify-content-center mb-4'>
                    <img
                      className='recrud-logo'
                      src={recrudLogo}
                      alt='Recrud'
                    />
                  </div>
                  <div className='paragraph-area'>
                    <p className='first-paragraph'>{t('firstParagraph')}</p>
                    <p className='second-paragraph'>{t('secondParagraph')}</p>
                  </div>
                  <div className='mb-5'>
                    <LoginSwitch
                      kind={'forgotPassword'}
                      onChange={value => {
                        if (value === 'login') navigate('/')
                        else if (value === 'register') navigate('/signup')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
              <ForgotPassword />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
