import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import accounthub from 'assets/images/general/accounthub.png'
import { SectionBanner } from 'components/SectionBanner/SectionBanner'
import { SubscriptionSettingsContainer } from 'containers/SubscriptionSettings/SubscriptionSettingsContainer'
import useStore from 'store'
import ReactGA from 'utils/analytics'

export function SubscriptionSettingsPage(): JSX.Element {
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const { t } = useTranslation('subscriptionSettings')

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      <div className='content-grid medium-space'>
        <div className='account-hub-content'>
          <div className='grid-column'>
            <SubscriptionSettingsContainer />
          </div>
        </div>
      </div>
    </div>
  )
}
