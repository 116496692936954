/* eslint-disable indent */
import React from 'react'

import { ReactComponent as Hexagon } from 'assets/images/icons/hexagon.svg'
import './Avatar.scss'

type Props = {
  user: string
  showTooltip?: boolean
  tooltip?: string
}

export const Avatar: React.FC<Props> = ({ user, showTooltip, tooltip }) => {
  const profileImage = () => {
    const firstLetter = [...user][0].toUpperCase()
    return firstLetter
  }

  return (
    <div
      className='user-status-avatar'
      {...(showTooltip && tooltip ? { 'data-tooltip': tooltip } : '')}
    >
      <div className='user-avatar small no-outline '>
        <div className='user-avatar-content'>
          <Hexagon />
          <div className='xm-tooltip-text'>{profileImage()}</div>
        </div>
      </div>
    </div>
  )
}
