import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput'
import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { InputCity } from 'components/InputCity/InputCity'
import { InputTag } from 'components/InputTag/InputTag'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'

export interface JobOpportunityBaseFormProps {
  jobName?: string
  roleFamily?: string
  localization?: string
  amount?: number
  deadline?: Date
  budget?: string
  tags?: Array<string>
  description?: string
}
// TODO: Push those states to the upper component and handle with formik
export const JobOpportunityBaseForm: React.FC<
  JobOpportunityBaseFormProps
> = () => {
  const { t } = useTranslation('jobOpportunity')
  const [jobName, setJobName] = useState('')
  const [roleFamily, setSetRoleFamily] = useState('')
  const [localization, setLocalization] = useState('')
  const [amount, setAmount] = useState('')
  const [deadline, setDeadline] = useState<Date>()
  const [budget, setBudget] = useState('')
  const [tags, setTags] = useState<Array<string>>()
  const [description, setDescription] = useState('')

  const handleDeadlineDateChange = (date: any) => {
    setDeadline(date)
  }

  return (
    <>
      <FormRow>
        <FormItem>
          <Input
            type='text'
            label={t('name')}
            name='job-name'
            value={jobName}
            onChange={e => setJobName(e)}
            small
          ></Input>
        </FormItem>

        <FormItem formItemClasses='spaceUp'>
          <Input
            type='text'
            label={t('roleFamilyPlaceholder')}
            name='role-family'
            value={roleFamily}
            onChange={e => setSetRoleFamily(e)}
            small
          ></Input>
        </FormItem>
      </FormRow>

      <FormRow>
        <FormItem>
          <InputCity
            type='text'
            label={t('localization')}
            name='job-opportunity-localization'
            value={localization}
            onChange={e => setLocalization(e[0])}
            small
          ></InputCity>
        </FormItem>

        <FormItem formItemClasses='spaceUp'>
          <Input
            type='text'
            label={t('amount')}
            name='job-opportunity-amount'
            value={amount}
            onChange={e => setAmount(e)}
            small
          ></Input>
        </FormItem>
      </FormRow>

      <FormRow>
        <FormItem>
          <DatePickerInput
            label={t('deadline')}
            name='job-opportunity-deadline'
            value={deadline}
            onChange={handleDeadlineDateChange}
            small
          />
        </FormItem>

        <FormItem formItemClasses='spaceUp'>
          <Input
            type='text'
            label={t('budget')}
            name='job-opportunity-budget'
            value={budget}
            onChange={e => setBudget(e)}
            small
          ></Input>
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem>
          <InputTag
            label={t('tags')}
            name='job-opportunity-tags'
            initialTags={tags}
            onChange={e => setTags(e)}
            small
          ></InputTag>
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem>
          <RichTextEditor
            placeholder={t('description')}
            value={description}
            height={180}
            onChange={e => setDescription(e)}
          />
        </FormItem>
      </FormRow>
    </>
  )
}
