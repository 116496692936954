import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface AverageClosingTimeProps {
  t: any
  loading: boolean
}

const AverageClosingTime = ({ t, loading }: AverageClosingTimeProps) => {
  const { lastSixMonths } = useDashboardContext()

  return (
    <div className='row'>
      <div className='col-12'>
        {loading && !hasContent(lastSixMonths) ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard title={t('chart1')}>
            <DashboardChart
              id='avg-closing-chart'
              visible={hasContent(lastSixMonths)}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default AverageClosingTime
