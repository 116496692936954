import React from 'react'

import { Button } from 'components/Button/Button'
import './CardPlan.scss'

type CardPlanProps = {
  title: string
  subTitle: string
  planValue: string
  planPeriod: string
  features: Array<string>
  selectButton: string
  withoutButton?: boolean
  onChangeKind: (value: 'login' | 'register') => void
}

export const CardPlan: React.FC<CardPlanProps> = ({
  title,
  subTitle,
  planValue,
  planPeriod,
  features,
  selectButton,
  withoutButton,
  onChangeKind
}) => {
  return (
    <div className='card-plan'>
      <div className='card-plan-title'>
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
      </div>
      <div className='card-plan-price'>
        <p className='card-plan-value'>{planValue}</p>
        <p className='card-plan-period'>{planPeriod}</p>
      </div>
      <div className='list-items'>
        <ul>
          {features.map((feature, i) => {
            return <li key={`feat_${i}`}>{feature}</li>
          })}
        </ul>
      </div>
      {!withoutButton ? (
        <div className='card-plan-button'>
          <Button
            className='secondary'
            type='submit'
            onClick={() => onChangeKind('register')}
          >
            {selectButton}
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
