import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  CandidateActions,
  CandidateActionsRef
} from 'components/CandidateActions/CandidateActions'
import { CandidateCardRef } from 'components/CandidateCard/CandidateCard'
import { CandidateHeader } from 'components/CandidateHeader/CandidateHeader'
import { GoHomeButton } from 'components/GoHomeButton/GoHomeButton'
import {
  HiringStages,
  HiringStagesRef
} from 'components/HiringStages/HiringStages'
import { Loading } from 'components/Loading/Loading'
import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import {
  CandidateFilterContainer,
  CandidateFilterRef,
  FilterForm
} from 'containers/CandidateFilter/CandidateFilterContainer'
import { CandidatesListContainer } from 'containers/CandidatesList/CandidatesListContainer'
import { usePermission } from 'hooks/usePermission'
import { useStoreNotification } from 'hooks/useStoreNotification'
import { jobs as jobsAPI, workflow as workflowAPI } from 'services/api'
import useStore from 'store'
import { snakeKeyToCamelCase } from 'utils'
import ReactGA from 'utils/analytics'
import { convertStagesSchemaToWorkflowStageSchema } from 'utils/defaultStage'

export function CandidatesListPage(): JSX.Element {
  // usePermission({ moduleName: 'candidate', action: 'view' })
  usePermission({ moduleName: 'candidate', action: 'list' })

  const { uuid: jobUuid } = useParams<{ uuid: string }>()
  const [job, setJob] = React.useState<Job>({} as Job)
  const [stages, setStages] = React.useState<WorkflowStageSchema[]>([])
  const [selectedStage, setSelectedStage] = React.useState<WorkflowStageSchema>(
    {} as WorkflowStageSchema
  )
  const [viewType, setViewType] = React.useState<'grid' | 'list'>('list')
  const [refList, setRefList] = React.useState({})
  const [filterRefList, setFilterRefList] = React.useState({})
  const [workflowRefList, setWorkflowRefList] = React.useState({})
  const [candidateActionsRefList, setCandidateActionsRefList] = React.useState(
    {}
  )
  const [cardRefList, setCardRefList] = React.useState({})
  const [moveStepId, setMoveStepId] = React.useState('')
  const [orderBy, setOrderBy] = React.useState('')
  const [filter, setFilter] = React.useState<FilterForm>({} as FilterForm)
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const { t } = useTranslation('defaultWorkflowStages')
  const { t: tNotification } = useTranslation('notifications')
  const [loading, setLoading] = useState(true)
  const [profiles, setProfiles] = useState<any>([])
  const { showNotification } = useStoreNotification()
  const [notAuthorized, setNotAuthorized] = useState(false)

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  useEffect(() => {
    const isJobAndStagesLoaded =
      stages.length > 0 && Object.keys(job).length > 0
    isJobAndStagesLoaded && setLoading(false)
  }, [stages, job])

  const getJob = async (uuid: string) => {
    try {
      const [error, data, profile, stages] = await jobsAPI.read(uuid)

      if (error) throw new Error(error.toString())
      if (data) setJob(snakeKeyToCamelCase(data))
      if (profile) setProfiles(profile)
      if (stages) {
        const convertedStages = convertStagesSchemaToWorkflowStageSchema(stages)
        setStages(convertedStages)
        !selectedStage?.uuid &&
          setSelectedStage(
            convertedStages.filter(el => el.name === `Available`)[0]
          )
      }
    } catch (error: unknown) {
      let message = (error as Error).message
      if (
        (error as Error).message ===
        'Not authorized, please check the workspace'
      ) {
        setNotAuthorized(true)
        message = tNotification(`lugarh.error.${(error as Error).message}`)
      }
      showNotification({ message, type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (jobUuid) {
      getJob(jobUuid)
    }
  }, [jobUuid])

  useEffect(() => {
    setRefList({
      ...cardRefList,
      ...filterRefList,
      ...workflowRefList,
      ...candidateActionsRefList
    })
  }, [filterRefList, cardRefList, workflowRefList, candidateActionsRefList])

  const setWorkflowTutorial = (value: HiringStagesRef) => {
    const obj: RefList = {
      1: { ref: value.workflowRef as React.MutableRefObject<HTMLElement> }
    }
    setWorkflowRefList(obj)
  }

  const setCandidateActionsTutorial = (value: CandidateActionsRef) => {
    const obj: RefList = {
      2: { ref: value.orderByRef as React.MutableRefObject<HTMLElement> }
    }
    setCandidateActionsRefList(obj)
  }

  const setFilterTutorial = (value: CandidateFilterRef) => {
    const obj: RefList = {
      3: {
        ref: value.filterRef as React.MutableRefObject<HTMLElement>,
        position: 'left'
      }
    }
    setFilterRefList(obj)
  }

  const setCandidateCardTutorial = (value: CandidateCardRef) => {
    const obj: RefList = {
      4: {
        ref: value.previewRef as React.MutableRefObject<HTMLElement>
      },
      5: { ref: value.detailRef as React.MutableRefObject<HTMLElement> },
      6: { ref: value.moveToRef as React.MutableRefObject<HTMLElement> }
    }
    setCardRefList(obj)
  }

  if (notAuthorized)
    return (
      <div
        className={`content-grid ${
          expandedMenu ? 'content-grid-expanded-menu' : ''
        }`}
      >
        <GoHomeButton />
      </div>
    )

  return (
    <>
      {loading ? (
        <div className='content-grid'>
          <Loading />
        </div>
      ) : (
        <div
          className={`content-grid ${
            expandedMenu ? 'content-grid-expanded-menu' : ''
          }`}
        >
          <Onboarding reflist={refList} page='candidates' />
          <CandidateFilterContainer
            onFilter={setFilter}
            getRefs={setFilterTutorial}
          />
          <CandidateHeader
            opportunity={job?.name || ''}
            stage={t(selectedStage?.name) || ''}
          />

          <CandidateActions
            stages={stages}
            stageId={selectedStage?.uuid || ''}
            moveStepId={moveStepId}
            onOrderBy={orderBy => setOrderBy(orderBy)}
            onChangeViewType={value => setViewType(value)}
            onMoveToStep={stepId => setMoveStepId(stepId)}
            getRefs={setCandidateActionsTutorial}
          />

          <HiringStages
            selected={selectedStage?.uuid || ''}
            stages={stages}
            onChange={stage => setSelectedStage(stage)}
            getRefs={setWorkflowTutorial}
          />
          {selectedStage?.uuid && (
            <CandidatesListContainer
              filter={filter}
              selectedStage={selectedStage?.uuid || ''}
              moveStepId={moveStepId}
              clearMoveStepId={() => setMoveStepId('')}
              orderBy={orderBy}
              stages={stages}
              viewType={viewType}
              getRefs={setCandidateCardTutorial}
              profiles={profiles}
              updateJobs={() => jobUuid && getJob(jobUuid)}
            />
          )}
        </div>
      )}
    </>
  )
}
