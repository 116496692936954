import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import recrudLogo from 'assets/images/logo/recrud-logo-header.png'
import { ResetPassword } from 'containers/ResetPassword/ResetPassword'
import '../login/login.scss'
import ReactGA from 'utils/analytics'

export function ResetPasswordPage(): JSX.Element {
  const { t } = useTranslation('login')

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('metatags.title')}</title>
        <meta name='description' content={t('metatags.description')} />
        <meta name='url' content={t('metatags.url')} />
        <meta property='og:title' content={t('metatags.title')} />
        <meta property='og:description' content={t('metatags.description')} />
        <meta property='og:url' content={t('metatags.url')} />
      </Helmet>

      <main>
        <div className='container h-100'>
          <div className='row h-100'>
            <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
              <div className='landing-info'>
                <div className='d-flex justify-content-center mb-4 mt-5'>
                  <img src={recrudLogo} alt='Recrud' />
                </div>

                <h2>{t('welcome')}</h2>
                <h1>recrud</h1>

                <div className='d-none d-lg-block'>
                  <p>{t('firstParagraph')}</p>
                  <p>{t('secondParagraph')}</p>
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
              <ResetPassword />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
