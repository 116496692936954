import React, { useEffect, useState, useRef } from 'react'

import { Avatar } from 'components/Avatar/Avatar'

import './DragAndDrop.scss'

type Props = {
  availables?: Array<{ text: string; el: { uuid: string } }>
  team?: Array<{ text: string; el: { uuid: string } }>
  onChange: (value: {
    availables: Array<{ text: string; el: { uuid: string } }>
    team: Array<{ text: string; el: { uuid: string } }>
  }) => void
  board1Header?: string
  board2Header?: string
  board1Tooltip?: string
  board2Tooltip?: string
  boardID: string
}

function useFirstRender() {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

export const DragAndDrop: React.FC<Props> = ({
  availables,
  team,
  onChange,
  board1Header,
  board2Header,
  board1Tooltip,
  board2Tooltip,
  boardID
}) => {
  const firstRender = useFirstRender()

  const [cards, setCards] = useState<NodeListOf<Element>>()
  const [dropzones, setDropzones] = useState<NodeListOf<Element>>()

  const [board1, setBoard1] = useState<
    Array<{ text: string; el: { uuid: string } }>
  >([])
  const [board2, setBoard2] = useState<
    Array<{ text: string; el: { uuid: string } }>
  >([])

  const setCardData = React.useCallback(() => {
    const board1El = document.querySelector(
      `[data-board='1${boardID}']#dropzone-1-${boardID}`
    )?.childNodes as NodeListOf<Element>
    const board2El = document.querySelector(
      `[data-board='2${boardID}']#dropzone-2-${boardID}`
    )?.childNodes as NodeListOf<Element>

    const board1Data: Array<{ text: string; el: { uuid: string } }> = []
    const board2Data: Array<{ text: string; el: { uuid: string } }> = []

    board1El?.forEach(card => {
      const cardData = JSON.parse(card.getAttribute('data-card') || '{}') as {
        text: string
        el: { uuid: string }
      }
      board1Data.push({
        text: cardData.text,
        el: cardData.el
      })
    })

    board2El?.forEach(card => {
      const cardData = JSON.parse(card.getAttribute('data-card') || '{}') as {
        text: string
        el: { uuid: string }
      }
      board2Data.push({
        text: cardData.text,
        el: cardData.el
      })
    })

    setBoard1(board1Data)
    setBoard2(board2Data)
  }, [])

  function dragstart(this: Element) {
    const dataType = this.getAttribute('data-type')
    if (!dataType) return
    dropzones?.forEach(dropzone => {
      if (dropzone.getAttribute('id')?.includes(dataType)) {
        dropzone.classList.add('highlight')
      }
    })
    this.classList.add('is-dragging')
  }

  function dragend(this: Element) {
    const dataType = this.getAttribute('data-type')
    if (!dataType) return
    dropzones?.forEach(dropzone => {
      if (dropzone.getAttribute('id')?.includes(dataType)) {
        dropzone.classList.remove('highlight')
      }
    })
    this.classList.remove('is-dragging')

    setCardData()
  }

  function dragover(this: HTMLDivElement) {
    const cardBeingDragged = document.querySelector('.is-dragging')
    const dataType = cardBeingDragged?.getAttribute('data-type')
    const id = this.getAttribute('id')
    if (!id || !dataType) return
    if (id.includes(dataType)) {
      this.classList.add('over')
      this.appendChild(cardBeingDragged as HTMLDivElement)
    }
  }

  function dragleave(this: Element) {
    this.classList.remove('over')
  }

  function drop(this: Element) {
    this.classList.remove('over')
  }

  useEffect(() => {
    setCards(document.querySelectorAll('.card'))
  }, [availables])

  useEffect(() => {
    cards?.forEach(card => {
      card.addEventListener('dragstart', dragstart)
      card.addEventListener('dragend', dragend)
    })

    return () => {
      cards?.forEach(card => {
        card.removeEventListener('dragstart', dragstart)
        card.removeEventListener('dragend', dragend)
      })
    }
  }, [cards])

  useEffect(() => {
    dropzones?.forEach(dropzone => {
      dropzone.addEventListener('dragover', dragover)
      dropzone.addEventListener('dragleave', dragleave)
      dropzone.addEventListener('drop', drop)
    })

    return () => {
      dropzones?.forEach(dropzone => {
        dropzone.addEventListener('dragover', dragover)
        dropzone.addEventListener('dragleave', dragleave)
        dropzone.addEventListener('drop', drop)
      })
    }
  }, [dropzones])

  useEffect(() => {
    if (!firstRender) onChange({ availables: board1, team: board2 })
  }, [board2, board1])

  useEffect(() => {
    setCardData()
  }, [])

  useEffect(() => {
    setCards(document.querySelectorAll('.card'))
    setDropzones(document.querySelectorAll('.dropzone'))
  }, [])

  return (
    <div className='boards'>
      <div className='board'>
        <div className='board-header'>
          <p className='title'>{board1Header}</p>
          {board1Tooltip && (
            <div className='tooltip-info' data-tooltip={board1Tooltip}>
              i
            </div>
          )}
        </div>
        <div
          className='dropzone'
          id={`dropzone-1-${boardID}`}
          data-board={`1${boardID}`}
        >
          {availables?.map((el, index) => {
            return (
              <div
                className='card'
                draggable
                key={index}
                data-type={boardID}
                data-card={JSON.stringify(el)}
              >
                <Avatar user={el.text} />
                <p>{el.text}</p>
              </div>
            )
          })}
        </div>
      </div>

      <div className='board'>
        <div className='board-header'>
          <p className='title'>{board2Header}</p>
          {board2Tooltip && (
            <div className='tooltip-info' data-tooltip={board2Tooltip}>
              i
            </div>
          )}
        </div>
        <div
          className='dropzone'
          id={`dropzone-2-${boardID}`}
          data-board={`2${boardID}`}
        >
          {team?.map((el, index) => {
            return (
              <div
                className='card'
                draggable
                key={index}
                data-type={boardID}
                data-card={JSON.stringify(el)}
              >
                <Avatar user={el.text} />
                <p>{el.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
