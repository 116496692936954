/* eslint-disable */

export const camelToSnakeCase = (value: string): string => {
  if (!value) return value

  return value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

export const camelKeyToSnakeCase = (object: { [key: string]: any }) => {
  try {
    const newObject = {}
    Object.keys(object).forEach((key: string) => {
      if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
        return (newObject[camelToSnakeCase(key)] = camelKeyToSnakeCase(
          object[key] as { [key: string]: unknown }
        ))
      }

      newObject[camelToSnakeCase(key)] = object[key]
    })
    return newObject
  } catch (error) {
    return object
  }
}
