/* istanbul ignore file */

import { ActionMap } from 'store/types'

export type State = {
  messages: NotificationConfig[]
}

export enum Types {
  SHOW = 'notification/SHOW',
  DISMISS = 'notification/DISMISS'
}

export type Payload = {
  [Types.SHOW]: NotificationConfig
  [Types.DISMISS]: NotificationConfig
}

export type Selector = {
  messages(): NotificationConfig[]
  hasMessage(): boolean
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>]
