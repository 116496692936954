import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'
import { Select } from 'components/Select/Select'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface CandidatesByLastSixMonthsProps {
  t: any
  loading: boolean
}

const CandidatesByLastSixMonths = ({
  t,
  loading
}: CandidatesByLastSixMonthsProps) => {
  const {
    workflowSelected,
    candidateStage,
    workflows,
    fetchLastSixMonthsCandidatesStage
  } = useDashboardContext()
  return (
    <div className='row'>
      <div className='col-12'>
        {loading && !hasContent(candidateStage) ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard
            title={t('chart11')}
            select={
              <Select
                id='workflow-select'
                label={t('filterByWorkflow')}
                value={workflowSelected || ''}
                onChange={e => {
                  fetchLastSixMonthsCandidatesStage(e.target.value)
                }}
              >
                <option value={workflows?.[0]?.uuid}>
                  {workflows?.[0]?.name}
                </option>
                {workflows?.map((workflow: WorkflowSchema, index: number) => {
                  return (
                    index >= 1 && (
                      <option value={workflow.uuid} key={workflow.uuid}>
                        {workflow.name}
                      </option>
                    )
                  )
                })}
              </Select>
            }
          >
            <DashboardChart
              id='candidate-stage'
              visible={hasContent(candidateStage)}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default CandidatesByLastSixMonths
