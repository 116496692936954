import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import badges from 'assets/images/general/badges.png'
import { JobOpportunityBaseForm } from 'components/JobOpportunityBaseForm/JobOpportunityBaseForm'
import { SectionBanner } from 'components/SectionBanner/SectionBanner'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import ReactGA from 'utils/analytics'

export function JobOpportunityFormPage(): JSX.Element {
  const { t } = useTranslation('jobOpportunity')
  const title = 'addJobTitle'
  const text = 'addJobText'

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  return (
    <>
      <div className='content-grid'>
        <SectionBanner
          title={t(title)}
          text={t(text)}
          icon={badges}
          alt={t(title)}
        />
        <div className='grid grid-12 medium-space'>
          <div className='account-hub-content'>
            <div className='grid-column'>
              <WidgetBox>
                <JobOpportunityBaseForm />
              </WidgetBox>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
