const RedirectToCallbackUrl = (): void => {
  window.localStorage.setItem('removeCallbackUrl', 'true')
  const callbackUrl =
    window.localStorage.getItem('partnerUrlCallback') &&
    window.localStorage.getItem('partnerUrlCallback') !== null
      ? window.localStorage.getItem('partnerUrlCallback')
      : null

  if (callbackUrl !== null) {
    window.location.replace(callbackUrl)
  }
}

const RedirectAndRemoveCallbackUrl = (): void => {
  const callbackUrl =
    window.localStorage.getItem('partnerUrlCallback') &&
    window.localStorage.getItem('partnerUrlCallback') !== null
      ? window.localStorage.getItem('partnerUrlCallback')
      : null

  if (callbackUrl !== null) {
    window.location.replace(callbackUrl)
    window.localStorage.removeItem('partnerUrlCallback')
    window.localStorage.removeItem('removeCallbackUrl')
  }
}

const CheckCallbackUrl = (): boolean =>
  !!(
    window.localStorage.getItem('partnerUrlCallback') &&
    window.localStorage.getItem('partnerUrlCallback') !== null
  )

export { RedirectToCallbackUrl, RedirectAndRemoveCallbackUrl, CheckCallbackUrl }
