import React, { useRef, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as Members } from 'assets/images/icons/members.svg'
import { Form } from 'components/Form'

import './UploadBox.scss'

interface UploadBoxProps {
  onChange: (file: File) => void
  src?: string
  suggestedSize?: string
  getRef: (value: { companyLogoRef }) => void
}

const WithoutIcon: React.FC = () => {
  const { t } = useTranslation('uploadBox')
  return (
    <>
      <Members className='icon' />
      <p className='title' data-testid='logo'>
        {t('logo')}
      </p>
      <p className='text' data-testid='logo-size'>
        {t('minSizeRecommended')}
      </p>
    </>
  )
}

const WithIcon: React.FC<{ src: string; name?: string }> = ({ src, name }) => {
  const { t } = useTranslation('uploadBox')
  return (
    <>
      <p className='title'>{t('logo')}</p>
      <img src={src} alt={name} title={name} />
    </>
  )
}

export const UploadBox: React.FC<UploadBoxProps> = ({
  onChange,
  src,
  suggestedSize,
  getRef
}) => {
  const inputReference = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File>()
  const [imageSource, setImageSource] = useState<string>('')
  const companyLogoRef = React.useRef<HTMLDivElement>(null)

  const { t } = useTranslation('uploadBox')

  useEffect(() => {
    getRef({ companyLogoRef })
  }, [companyLogoRef])

  useEffect(() => {
    src && setImageSource(src)
  }, [src])

  useEffect(() => {
    if (!file) return
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      setImageSource(event.target?.result as string)
    }

    reader.readAsDataURL(file)
  }, [file])

  const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event)
    event.dataTransfer.dropEffect = 'copy'
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event)

    const files = [...event.dataTransfer.files]
    if (files && files.length > 0) {
      const [file] = files
      validateFile(file)
    }
  }

  const validateFile = (file: File) => {
    if (!file.type.match(/image\//i)) return
    onChange(file)
    setFile(file)
  }

  return (
    <div ref={companyLogoRef}>
      <Form.Row>
        <Form.Item>
          <div
            className={`upload-box ${imageSource ? 'with-image' : ''}`}
            onDrop={handleDrop}
            onDragEnter={stopEvent}
            onDragLeave={stopEvent}
            onDragOver={handleDragOver}
            onClick={() => inputReference.current?.click()}
            data-testid='upload'
          >
            {imageSource ? (
              <WithIcon src={imageSource} name={file?.name} />
            ) : (
              <WithoutIcon />
            )}

            <input
              ref={inputReference}
              type='file'
              accept='image/*'
              onChange={event => {
                if (event.target.files?.length) {
                  validateFile(event.target.files[0])
                }
              }}
            />
          </div>
          {imageSource && suggestedSize && (
            <p>{`${t('suggestedSize')} ${suggestedSize}`}</p>
          )}
        </Form.Item>
      </Form.Row>
    </div>
  )
}
