import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { ColorPicker } from 'components/ColorPicker/ColorPicker'
import { Form } from 'components/Form'

import './Color.scss'

enum Colors {
  HEADER_COLOR = 'headerColor',
  FONT_COLOR = 'fontColor',
  BUTTON_COLOR = 'buttonColor',
  BUTTON_FONT_COLOR = 'buttonFontColor'
}

type ColorContainerProps = {
  setFieldValue: SetFieldValue
  careerPageSettings: {
    headerColor: string
    fontColor: string
    buttonColor: string
    buttonFontColor: string
  }
  getRef: (value: { companyColorsRef }) => void
}

export const ColorContainer: React.FC<ColorContainerProps> = ({
  setFieldValue,
  careerPageSettings,
  getRef
}) => {
  const { t } = useTranslation('companySettings')
  const companyColorsRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    getRef({ companyColorsRef })
  }, [companyColorsRef])

  const handleOptionChange = (key: Colors, value: string) => {
    setFieldValue('careerPageSettings', {
      ...careerPageSettings,
      [key]: value
    })
  }

  return (
    <div className='color-container' ref={companyColorsRef}>
      <Form.Item>
        <ColorPicker
          label={t('color.companyPageHeaderColor')}
          value={careerPageSettings[Colors.HEADER_COLOR]}
          onChange={color => handleOptionChange(Colors.HEADER_COLOR, color)}
        />
      </Form.Item>

      <Form.Item>
        <ColorPicker
          label={t('color.companyPageFontColor')}
          value={careerPageSettings[Colors.FONT_COLOR]}
          onChange={color => handleOptionChange(Colors.FONT_COLOR, color)}
        />
      </Form.Item>

      <Form.Item>
        <ColorPicker
          label={t('color.companyPageButtonColor')}
          value={careerPageSettings[Colors.BUTTON_COLOR]}
          onChange={color => handleOptionChange(Colors.BUTTON_COLOR, color)}
        />
      </Form.Item>

      <Form.Item>
        <ColorPicker
          label={t('color.companyPageButtonFontColor')}
          value={careerPageSettings[Colors.BUTTON_FONT_COLOR]}
          onChange={color =>
            handleOptionChange(Colors.BUTTON_FONT_COLOR, color)
          }
        />
      </Form.Item>
    </div>
  )
}
