/* istanbul ignore file */

import { ActionMap, EmptyPayload } from 'store/types'

export type State = {
  permissions: UserPermissions | Object
  loading: boolean
  error: string
}

export enum Types {
  ENABLE_LOADING = 'permissions/ENABLE_LOADING',
  DISABLE_LOADING = 'permissions/DISABLE_LOADING',
  SET_ERROR = 'permissions/SET_ERROR',
  SET_PERMISSIONS = 'permissions/SET_PERMISSIONS'
}

export type Payload = {
  [Types.ENABLE_LOADING]: EmptyPayload
  [Types.DISABLE_LOADING]: EmptyPayload
  [Types.SET_ERROR]: string
  [Types.SET_PERMISSIONS]: UserPermissions
}

export type Selector = {
  permissions(): UserPermissions | Object
  isLoading(): boolean
  error(): string
  getPermissionsByModuleName(name: PermissionModule): PermissionAction[]
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>]
