import React from 'react'

import { DefaultModal } from 'components/Modals/lugarh/Default/DefaultModal'
import './ListItemsModal.styles.scss'
import { Loading } from 'components/Loading/lugarh/Loading'

interface Labels<T> {
  id: keyof T
  title: keyof T
  subtitle?: keyof T
}

interface ListItemsModalProps<T> {
  open: boolean
  onClose: () => void
  items: T[]
  labels: Labels<T>
  modalTitle: string
  modalSubtitle?: string
  customClass?: string
  loading?: boolean
}

/**
 * Renders a modal component that displays a list of items.
 *
 * @template T - The type of the items in the list.
 * @param open: boolean - The state of the modal.
 * @param onClose: () => void - The function to close the modal.
 * @param items: T[] - The list of items to be displayed.
 * @param labels: Labels<T> - The labels to be used to display the items. The values must be the keys of the item object.
 * @param modalTitle: string - The title of the modal.
 * @param modalSubtitle?: string - The subtitle of the modal.
 * @param customClass?: string - The custom class to be applied to the modal.
 * @param loading?: boolean - The loading state of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
export const ListItemsModal = <T,>({
  open,
  onClose,
  items,
  labels,
  modalTitle,
  modalSubtitle,
  customClass = '',
  loading
}: ListItemsModalProps<T>): JSX.Element => {
  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      customClass={customClass}
      modalTitle={modalTitle}
      {...(modalSubtitle && { modalSubtitle })}
    >
      <DefaultModal.Content>
        {loading ? (
          <Loading />
        ) : (
          items.map((item: T) => (
            <div key={String(item[labels.id])} className='mui-modal__list-item'>
              <p className='mui-modal__list-title'>{item[labels.title]}</p>
              {labels.subtitle && item[labels.subtitle] && (
                <p className='mui-modal__list-subtitle'>
                  {item[labels.subtitle]}
                </p>
              )}
            </div>
          ))
        )}
      </DefaultModal.Content>
    </DefaultModal>
  )
}
