import { snakeKeyToCamelCase } from 'utils/snakeToCamelCase'

import api from './api'

export const list = async ({
  search
}: {
  search: string
}): Promise<[APIError, Search | null]> => {
  try {
    const response = await api.get('/v1/search', { params: { search } })
    if (response.status === 200) {
      return [null, snakeKeyToCamelCase(response.data.data)]
    }
    if (response.status === 401 || response.status === 500) {
      const data: ErrorSchema = response.data
      return [data.message, null]
    }

    return ['unmapped', null]
  } catch (error) {
    return [error.message, null]
  }
}
