import React from 'react'

import { useTranslation } from 'react-i18next'

type DashboardChartProps = {
  id: string
  visible?: boolean
}

function DashboardChart({
  id,
  visible = true
}: DashboardChartProps): React.ReactElement {
  const { t } = useTranslation('dashboard')

  return (
    <React.Fragment>
      <canvas
        id={id}
        style={{
          visibility: visible ? 'visible' : 'hidden'
        }}
      />
      <p
        style={{
          display: visible ? 'none' : 'block'
        }}
      >
        {t('errorChart')}
      </p>
    </React.Fragment>
  )
}

export default DashboardChart
