import api from './api'

interface GetPagedPayload {
  page: number
  per_page: number
  filters?: any
  allUsers?: boolean
}

export const get = async (uuid: string): Promise<[APIError, User | null]> => {
  const response = await api.get(`/v1/users/${uuid}`)

  if (response.status === 200) {
    const data: UserSchema = response.data.data
    return [
      null,
      {
        uuid: data.uuid,
        active: data.active,
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        role: data.role,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        company: {
          uuid: data.company?.uuid || '',
          fancy_name: data.company?.fancy_name || '',
          slug: data.company?.slug || '',
          data_privacy_settings: data.company?.data_privacy_settings || null
        },
        themeCustomization: data.theme_customization
          ? {
              partnerName: data.theme_customization.partner_name,
              primaryColor: data.theme_customization.primary_color,
              secondaryColor: data.theme_customization.secondary_color,
              thirdColor: data.theme_customization.third_color,
              icon: data.theme_customization.icon,
              logo: data.theme_customization.logo,
              favicon: data.theme_customization.favicon
            }
          : null,
        workspaces: response.data.workspaces,
        currentWorkspace: {
          uuid: response?.data?.workspaces?.[0]?.uuid,
          name: response?.data?.workspaces?.[0]?.name
        }
      }
    ]
  }

  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [
      {
        message: data.message,
        errors: data.errors
      },
      null
    ]
  }

  return ['unmapped', null]
}

export const read = async (
  filter: GetPagedPayload
): Promise<
  [
    APIError,
    {
      data: Array<User>
      per_page: number
      page: number
    } | null
  ]
> => {
  const response = await api.get('/v1/users', {
    params: { ...filter }
  })

  if (response?.status === 200) {
    const data: {
      data: Array<User>
      per_page: number
      page: number
    } = response.data
    return [
      null,
      {
        data: data.data,
        per_page: data.per_page,
        page: data.page
      }
    ]
  }

  if (response?.status === 401 || response?.status === 500) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}

export const edit = async (
  payload: {
    uuid: string
    user: {
      firstname?: string
      lastname?: string
      role?: string
      password?: string
      email?: string
      active?: boolean
    }
  },
  params: { params: { email_change_code: string } } | undefined = undefined
): Promise<[APIError, User | null]> => {
  const response = await api.patch(
    `/v1/users/${payload.uuid}`,
    payload.user,
    params
  )

  if (response.status === 200) {
    const data: UserSchema = response.data.data
    return [
      null,
      {
        uuid: data.uuid,
        active: data.active,
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        role: data.role,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        company: {
          uuid: data.company?.uuid || '',
          fancy_name: data.company?.fancy_name || '',
          slug: data.company?.slug || '',
          data_privacy_settings: data.company?.data_privacy_settings || null
        },
        themeCustomization: data.theme_customization
          ? {
              partnerName: data.theme_customization.partner_name,
              primaryColor: data.theme_customization.primary_color,
              secondaryColor: data.theme_customization.secondary_color,
              thirdColor: data.theme_customization.third_color,
              icon: data.theme_customization.icon,
              logo: data.theme_customization.logo,
              favicon: data.theme_customization.favicon
            }
          : null,
        workspaces: data.workspaces,
        currentWorkspace: {
          uuid: data.workspaces[0].uuid,
          name: data.workspaces[0].name
        }
      }
    ]
  }

  if (response.status === 202) {
    return [null, null]
  }

  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data

    let message = data.message
    if (Object.keys(data.errors).length > 0) {
      const key = Object.keys(data.errors)[0] || ''
      message = data.errors[key].toString()
    }

    return [message, null]
  }

  return ['unmapped', null]
}

export const create = async (payload: {
  user: {
    firstname?: string
    lastname?: string
    role?: string
    password?: string
    email?: string
  }
}): Promise<[APIError, User | null]> => {
  const response = await api.post('/v1/users/', payload.user)

  if (response.status === 201) {
    const data: UserSchema = response.data.data
    return [
      null,
      {
        uuid: data.uuid,
        active: data.active,
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        role: data.role,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        company: {
          uuid: data.company?.uuid || '',
          fancy_name: data.company?.fancy_name || '',
          slug: data.company?.slug || '',
          data_privacy_settings: data.company?.data_privacy_settings || null
        },
        themeCustomization: data.theme_customization
          ? {
              partnerName: data.theme_customization.partner_name,
              primaryColor: data.theme_customization.primary_color,
              secondaryColor: data.theme_customization.secondary_color,
              thirdColor: data.theme_customization.third_color,
              icon: data.theme_customization.icon,
              logo: data.theme_customization.logo,
              favicon: data.theme_customization.favicon
            }
          : null,
        workspaces: data.workspaces,
        currentWorkspace: {
          uuid: data.workspaces[0].uuid,
          name: data.workspaces[0].name
        }
      }
    ]
  }

  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [
      {
        message: data.message,
        errors: data.errors
      },
      null
    ]
  }

  return ['unmapped', null]
}

export const remove = async (
  uuid: string
): Promise<[APIError, boolean | null]> => {
  const response = await api.delete(`/v1/users/${uuid}`)

  if (response.status === 204) {
    return [null, true]
  }

  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 404 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [
      {
        message: data.message,
        errors: data.errors
      },
      null
    ]
  }

  return ['unmapped', null]
}
