import axios from 'axios'

import api, { apiNoInterceptors, apiWithoutAccessToken } from './api'

export interface TokenRequest {
  email: string
  password: string
  recaptcha: string
}

export interface ResetPasswordRequest {
  newPassword: string
  code: string | string[] | null
}

export const token = async (
  data: TokenRequest,
  withInterceptor = true
): Promise<[APIError, Token | null]> => {
  const payload = {
    email: data.email,
    password: data.password,
    g_recaptcha_response: data.recaptcha
  }

  const selectedApi = withInterceptor ? api : apiNoInterceptors

  let response
  try {
    response = await apiWithoutAccessToken.post('/v1/auth', payload)
  } catch (error) {
    return ['unmapped', null]
  }

  if (response.status === 200) {
    const data: AccessTokenSchema = response.data.data
    selectedApi.defaults.headers.token = data.access_token
    return [
      null,
      {
        token: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
        refreshExpiresIn: data.refresh_expires_in
      }
    ]
  }

  if (response.status === 400 || response.status === 500) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [
      {
        message: data.message,
        errors: data.errors
      },
      null
    ]
  }

  return ['unmapped', null]
}

export const refreshToken = async (
  refreshToken: string
): Promise<[APIError, Token | null]> => {
  const response = await axios({
    method: 'GET',
    url: '/v1/auth/refresh',
    baseURL: process.env.REACT_APP_API,
    headers: { refresh: refreshToken }
  })
  if (response.status === 200) {
    const data: AccessTokenSchema = response.data.data
    api.defaults.headers.token = data.access_token
    return [
      null,
      {
        token: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
        refreshExpiresIn: data.refresh_expires_in
      }
    ]
  }

  if (response.status === 400 || response.status === 500) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 401) {
    const data: ErrorSchema = response.data
    return ['EXPIRED_SESSION', null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}

export const login = async (
  token: string
): Promise<[APIError, User | null]> => {
  const response = await api.post('/v1/auth/login', null, {
    headers: { token }
  })

  if (response.status === 200) {
    const data: UserSchema = response.data.data
    const mapper: [APIError, User | null] = [
      null,
      {
        uuid: data.uuid,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        firstname: data.firstname,
        lastname: data.lastname,
        role: data.role,
        email: data.email,
        active: data.active,
        company: data.company,
        themeCustomization: data.theme_customization
          ? {
              partnerName: data.theme_customization?.partner_name,
              primaryColor: data.theme_customization?.primary_color,
              secondaryColor: data.theme_customization?.secondary_color,
              thirdColor: data.theme_customization?.third_color,
              icon: data.theme_customization?.icon,
              logo: data.theme_customization?.logo,
              favicon: data.theme_customization?.favicon
            }
          : null,
        workspaces: response.data.workspaces,
        currentWorkspace: {
          uuid: response?.data?.workspaces?.[0]?.uuid,
          name: response?.data?.workspaces?.[0]?.name
        }
      }
    ]
    return mapper
  }

  if (response.status === 401) {
    const data: ErrorSchema = response.data
    data.message = 'BLOCKED_USER'
    return [data.message, null]
  }

  if (response.status === 402) {
    const data: ErrorSchema = response.data
    data.message = 'EXPIRED_SUBSCRIPTION'
    return [data.message, null]
  }

  if (response.status === 500) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}

export const register = async (
  data: RegisterPayload
): Promise<[APIError, Token | null]> => {
  const payload: {
    firstname: string
    lastname: string
    email: string
    password: string
    repassword?: string
    g_recaptcha_response: string
  } = {
    firstname: data.firstName,
    lastname: data.lastName,
    email: data.email,
    password: data.password,
    g_recaptcha_response: data.recaptcha
  }

  const response = await api.post('/v1/auth/register', payload)

  if (response.status === 200 || response.status === 201) {
    const data: AccessTokenSchema = response.data.data

    return [
      null,
      {
        token: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
        refreshExpiresIn: data.refresh_expires_in
      }
    ]
  }

  if (
    response.status === 400 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [data, null]
  }

  return ['unmapped', null]
}

export const forgotPassword = async (
  email: string
): Promise<[APIError, null]> => {
  const response = await api.post('/v1/auth/forgot-password', { email })

  if (response.status === 200) {
    return [null, null]
  } else if (
    response.status === 400 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  } else if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [data, null]
  } else if (response.status === 404) {
    const data: ErrorSchema = response.data
    data.message = 'NOT_FOUND'
    return [data.message, null]
  } else {
    return ['unmapped', null]
  }
}

export const resetPassword = async (
  data: ResetPasswordRequest
): Promise<[APIError, null]> => {
  const response = await api.post(
    '/v1/auth/reset-password',
    { new_password: data.newPassword },
    { params: { code: data.code } }
  )

  if (response.status === 200) {
    return [null, null]
  } else if (
    response.status === 400 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  } else if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [data, null]
  } else {
    return ['unmapped', null]
  }
}
