/* eslint-disable indent */
import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as AddIcon } from 'assets/images/icons/add-circle-outline-icon.svg'
import { Button } from 'components/Button/Button'
import {
  CustomQuestions,
  Questions
} from 'components/CustomQuestions/CustomQuestions'
import { DragAndDrop } from 'components/DragAndDrop/DragAndDrop'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { Select } from 'components/Select/Select'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import { JobFormContainer } from 'containers/Jobs/JobForm/JobFormContainer'
import {
  ValidatewDateOptions,
  useDateValidation
} from 'hooks/useDateValidation'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { jobs, workflow, users } from 'services/api/'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import { dateToTimestamp } from 'utils/date'
import { isEntityError } from 'utils/errorTypeGuards'
import { convertUserToUserSchema, filterUsersByRole } from 'utils/users.utils'

import createContainerAnalytics from './createContainerAnalytics'

export type FormData = {
  name: string
  city: string | null
  deadline: string
  active: boolean
  hiring_workflow: string
  budget: number | string
  job_group_uuid: string
  job_group: string
  vacancy_allocation: string
  quantity: number
  description: string
  tags: Array<string>
  recruiters_team: Array<string>
  managers_team: Array<string>
}

type ObjectError = {
  message: string
  errors: {
    [key: string]: string[]
  }
}

export const CreateContainer: React.FC = () => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const { validateDate, errors: dateError } = useDateValidation()
  const [workflows, setWorkflows] = useState<Array<WorkflowSchema>>([])
  const [workflowPage, setWorkflowPage] = useState(1)
  const [userPage, setUserPage] = useState(1)
  const [recruiters, setRecruiters] = useState<Array<UserSchema>>([])
  const [managers, setManagers] = useState<Array<UserSchema>>([])
  const [formData, setFormData] = useState({} as FormData)
  const { dispatch } = useStore()
  const [questions, setQuestions] = useState([] as Array<Questions>)
  const [workflowSelectTouched, setWorkflowSelectTouched] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [triedToSend, setTriedToSend] = useState(false)
  const [questionsErrors, setQuestionsErrors] = useState<Array<boolean>>([])
  const [loading, setLoading] = useState(false)
  const [formValidation, setFormValidation] = useState(false)

  const navigate = useNavigate()

  const { t } = useTranslation('job')
  const { t: fieldError } = useTranslation('errors')

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  useEffect(() => {
    fetchWorkflows()
  }, [workflowPage])

  useEffect(() => {
    fetchUsers()
  }, [userPage])

  useEffect(() => {
    const hasError = questionsErrors.filter(el => el)
    if (hasError.length > 0) setDisableSubmit(true)
    else setDisableSubmit(false)
  }, [questionsErrors])

  const createJob = async () => {
    try {
      setLoading(true)

      const city =
        formData.vacancy_allocation === 'remote' ||
        formData.vacancy_allocation === 'unspecified'
          ? null
          : formData.city

      const invalidDeadline =
        formData.deadline &&
        formData.deadline.trim() !== '//' &&
        !validateDate(formData.deadline, ValidatewDateOptions.DEADLINE)

      if (invalidDeadline)
        throw new Error(
          dateError.invalidDate || t('errors:deadlineInvalidFormat')
        )

      const formDataTreated: CreateJobPayload = {
        ...formData,
        deadline:
          formData.deadline && formData.deadline.trim() !== '//'
            ? dateToTimestamp(formData.deadline)
            : 0,
        managers_team: formData.managers_team || [],
        recruiters_team: formData.recruiters_team || [],
        tags: formData.tags || [],
        city
      }

      const [error, data] = await jobs.create(formDataTreated)

      if (error && isEntityError(error)) {
        setLoading(false)
        setTriedToSend(true)
        if (
          error.errors.hiring_workflow &&
          Object.keys(error.errors).length === 1
        ) {
          return showNotification({
            message: t('missingWorkflow'),
            type: 'error'
          })
        }
        return showNotification({
          message: t('creationFailed'),
          type: 'error'
        })
      } else if (error) throw new Error(error)

      if (data) createQuestions(data?.uuid)

      showNotification({
        message: t('creationSuccess'),
        type: 'success'
      })

      setLoading(false)

      createContainerAnalytics({ ...formData, questions })
    } catch (error: unknown) {
      setTriedToSend(true)
      setLoading(false)
      const errorMessages = (error as Error).message
      if (errorMessages === 'This feature has already reached the limit.') {
        showNotification({
          message: t('errors:limitReached'),
          type: 'error'
        })
      } else {
        showNotification({
          message: dateError.invalidDate || errorMessages,
          type: 'error'
        })
      }
    }
  }

  const createQuestions = async (uuid: string) => {
    try {
      questions.map(async el => {
        const payload = { ...el }
        delete payload.key
        const [error] = await jobs.createQuestions({ uuid, data: payload })

        if (error) {
          return showNotification({
            message: (error as ObjectError).message,
            type: 'error'
          })
        }
      })

      showNotification({
        message: t('creationSuccess'),
        type: 'success'
      })
    } catch (error: unknown) {
      const errorMessages = (error as Error).message
      showNotification({ message: errorMessages, type: 'error' })
    }

    navigate('/jobs')
  }

  const fetchWorkflows = async () => {
    try {
      const [error, data] = await workflow.read({
        page: workflowPage,
        per_page: 10
      })
      if (error) {
        return showNotification({
          message: (error as ObjectError).message,
          type: 'error'
        })
      }
      if (data?.data !== undefined) {
        setWorkflows([...workflows, ...data.data])
      }
      if (data?.data.length === 10) {
        setWorkflowPage(workflowPage + 1)
      }
    } catch (error: unknown) {
      const errorMessages = (error as Error).message
      showNotification({ message: errorMessages, type: 'error' })
    }
  }

  const fetchUsers = async () => {
    try {
      const [error, data] = await users.read({ page: 1, per_page: 1000 })
      if (error) {
        return showNotification({
          message: (error as ObjectError).message,
          type: 'error'
        })
      }
      if (data?.data.length) {
        const userData = convertUserToUserSchema(data.data)
        const { filteredRecruiters, filteredManagers } = filterUsersByRole({
          data: userData,
          filterActiveUsers: true
        })

        setRecruiters([...recruiters, ...filteredRecruiters])
        setManagers([...managers, ...filteredManagers])
      }

      if (data?.data.length === 10) {
        setUserPage(userPage + 1)
      }
    } catch (error: unknown) {
      const errorMessages = (error as Error).message
      showNotification({ message: errorMessages, type: 'error' })
    }
  }

  useEffect(() => {
    fetchWorkflows()
    fetchUsers()
  }, [])

  const handleValidationChange = (value: boolean) => {
    setFormValidation(value)
  }

  return (
    <>
      {dontShowOnGeralWorkspace && (
        <>
          <WidgetBox>
            <JobFormContainer
              triedToSend={triedToSend}
              onChange={values => setFormData({ ...formData, ...values })}
              onValidationChange={value => handleValidationChange(value)}
            />
          </WidgetBox>

          <WidgetBox>
            <p className='title'>{t('linkWorkflow')}</p>
            <Select
              required={true}
              onBlur={() => setWorkflowSelectTouched(true)}
              onChange={e =>
                setFormData({ ...formData, hiring_workflow: e.target.value })
              }
              error={
                !formData.hiring_workflow &&
                (workflowSelectTouched || triedToSend)
                  ? fieldError('errors:requiredField')
                  : ''
              }
            >
              <option value='' key={1}>
                Selecione um workflow
              </option>
              {workflows.map(el => (
                <option key={el.uuid} value={el.uuid}>
                  {el.name}
                </option>
              ))}
            </Select>
          </WidgetBox>

          <WidgetBox>
            <p className='title'>{t('questions')}</p>

            {questions.map((el, index) => {
              return (
                <CustomQuestions
                  onError={e => {
                    const errors = [...questionsErrors]
                    errors[index] = e
                    setQuestionsErrors(errors)
                  }}
                  key={el.key}
                  value={questions[index]}
                  blockButtons={
                    questions.length <= 1
                      ? { up: true, down: true }
                      : index === 0
                        ? { up: true, down: false }
                        : index + 1 === questions.length
                          ? { up: false, down: true }
                          : { up: false, down: false }
                  }
                  onChange={e => {
                    const arr = [...questions]
                    arr[index] = { key: el.key, ...e }
                    setQuestions(arr)
                  }}
                  onDeleteQuestion={() => {
                    const arr = [...questions]
                    const errors = [...questionsErrors]
                    delete errors[index]
                    setQuestionsErrors(errors)
                    arr.splice(index, 1)
                    setQuestions(arr)
                  }}
                  onDuplicateQuestion={() => {
                    const arr = [...questions]
                    const item = { ...questions[index] }
                    item.key = +new Date()
                    arr.push(item)
                    setQuestions(arr)
                  }}
                  onMoveDownQuestion={() => {
                    if (index < questions.length - 1) {
                      const arr = [...questions]
                      arr[index + 1] = questions[index]
                      arr[index] = questions[index + 1]
                      setQuestions(arr)
                    }
                  }}
                  onMoveUpQuestion={() => {
                    if (index !== 0) {
                      const arr = [...questions]
                      arr[index - 1] = questions[index]
                      arr[index] = questions[index - 1]
                      setQuestions(arr)
                    }
                  }}
                />
              )
            })}

            <div className='row d-flex justify-content-end'>
              <div className='col d-flex justify-content-end'>
                <Button
                  className='primary add-button'
                  onClick={() => {
                    setQuestions(oldState => {
                      const arr = [...oldState]
                      arr.push({
                        type: 'open_ended',
                        description: '',
                        eliminate: false,
                        answers: [{ description: '', right_answer: false }],
                        key: +new Date()
                      } as Questions)
                      return arr
                    })
                  }}
                >
                  <AddIcon />
                </Button>
              </div>
            </div>
          </WidgetBox>
          {recruiters.length ? (
            <WidgetBox>
              <DragAndDrop
                boardID='recruiters'
                onChange={e => {
                  const team = e.team.map(el => {
                    return el.el.uuid
                  })

                  setFormData({ ...formData, recruiters_team: team })
                }}
                board1Header={t('recruiters')}
                board2Header={t('recruitersTeam')}
                availables={recruiters.map(el => {
                  return { text: `${el.firstname} ${el.lastname}`, el }
                })}
              />
            </WidgetBox>
          ) : null}

          {managers.length ? (
            <WidgetBox>
              <DragAndDrop
                boardID='managers'
                onChange={e => {
                  const team = e.team.map(el => {
                    return el.el.uuid
                  })

                  setFormData({ ...formData, managers_team: team })
                }}
                board1Header={t('managers')}
                board2Header={t('managersTeam')}
                availables={managers.map(el => {
                  return { text: `${el.firstname} ${el.lastname}`, el }
                })}
              />
            </WidgetBox>
          ) : null}
          <PermissionAgent
            module='job'
            actions={['create']}
            onFail='disabled'
            Component={({ disabled }) => (
              <Button
                className='secondary mt-3'
                onClick={createJob}
                disabled={
                  disabled || disableSubmit || loading || !formValidation
                }
                loading={loading}
              >
                {t('save')}
              </Button>
            )}
          />
        </>
      )}
    </>
  )
}
