import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import add from 'assets/images/avatar/new.png'
import play from 'assets/images/avatar/play-grey.png'
import stop from 'assets/images/avatar/stop-grey.png'
import { ReactComponent as ClockIcon } from 'assets/images/icons/access_time.svg'
import { ReactComponent as CandidatesIcon } from 'assets/images/icons/candidates-icon.svg'
import { ReactComponent as SettingIcon } from 'assets/images/icons/settings-icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/share-icon.svg'
import { ReactComponent as TrophyIcon } from 'assets/images/icons/trophy.svg'
import { Avatar } from 'components/Avatar/Avatar'
import { Button } from 'components/Button/Button'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { TagList } from 'components/TagList/TagList'

import './JobCard.scss'

export type JobCardRefs = {
  addButton?: React.RefObject<HTMLDivElement>
  status?: React.RefObject<HTMLDivElement>
  team?: React.RefObject<HTMLDivElement>
  candidates?: React.RefObject<HTMLDivElement>
  share?: React.RefObject<HTMLDivElement>
  edit?: React.RefObject<HTMLDivElement>
}

type Props = {
  closed?: boolean
  overdue?: boolean
  status: 'add-new-job' | 'active' | 'disabled'
  tags?: Array<string>
  team?: Array<{
    firstname: string
    lastname: string
    type: 'managers' | 'recruiter'
  }>
  hiddenTeam: boolean
  hiddenEdit: boolean
  hiddenActiveStatus: boolean
  title?: string
  location?: string
  onShare?: () => void
  onEdit?: () => void
  onCreateNewVacancy?: () => void
  onListCandidates?: () => void
  getRefs?: (value: JobCardRefs) => void
  toggleActive?: () => void
}

export const JobCard: React.FC<Props> = ({
  closed,
  overdue,
  status,
  tags,
  onShare,
  onEdit,
  onCreateNewVacancy,
  onListCandidates,
  location,
  title,
  team,
  hiddenTeam,
  hiddenEdit,
  hiddenActiveStatus,
  getRefs,
  toggleActive
}) => {
  const [image, setImage] = useState('')

  const { t } = useTranslation('jobCard')

  const addButtonRef = useRef<HTMLDivElement>(null)
  const statusRef = useRef<HTMLDivElement>(null)
  const teamRef = useRef<HTMLDivElement>(null)
  const candidatesRef = useRef<HTMLDivElement>(null)
  const shareRef = useRef<HTMLDivElement>(null)
  const editRef = useRef<HTMLDivElement>(null)

  const isAddCard = status === 'add-new-job'
  const isActive = status === 'active'

  useEffect(() => {
    setImage(isActive ? play : isAddCard ? add : stop)

    let obj: JobCardRefs = {
      status: statusRef,
      team: teamRef,
      candidates: candidatesRef,
      share: shareRef,
      edit: editRef
    }

    if (isAddCard) {
      obj = {
        addButton: addButtonRef
      }
    }

    if (getRefs !== undefined) {
      getRefs(obj)
    }
  }, [])

  return (
    status !== 'add-new-job' && (
      <div className={`job-card-wrapper`}>
        {hiddenActiveStatus ? (
          <div className={`job-card-header ${status}`}>
            <div ref={statusRef}>
              {overdue && !closed && (
                <div className='overdue-icon' data-tooltip={t('overdueJob')}>
                  <ClockIcon />
                </div>
              )}

              {closed && (
                <div className='overdue-icon' data-tooltip={t('closedJob')}>
                  <TrophyIcon />
                </div>
              )}
            </div>
            <svg
              onClick={toggleActive}
              viewBox='0 0 100 100'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              className='hexagon'
              {...{ 'data-tooltip': t('toggleJob') }}
            >
              <defs>
                <pattern
                  id={`img${status}`}
                  patternUnits='userSpaceOnUse'
                  width='100'
                  height='100'
                >
                  <image xlinkHref={image} x='-25' width='150' height='100' />
                </pattern>
              </defs>
              <polygon
                points='50 1 95 25 95 75 50 99 5 75 5 25'
                fill={`url(#img${status})`}
              />
            </svg>
          </div>
        ) : (
          <div className={`job-card-header custom ${status}`}>
            <div ref={statusRef}>
              {overdue && !closed && (
                <div className='overdue-icon' data-tooltip={t('overdueJob')}>
                  <ClockIcon />
                </div>
              )}

              {closed && (
                <div className='overdue-icon' data-tooltip={t('closedJob')}>
                  <TrophyIcon />
                </div>
              )}
            </div>
          </div>
        )}
        <>
          <div className='job-card-body'>
            <div className='job-card-content'>
              <div className='job-head'>
                <p className='text-center title mb-0'>{title}</p>
                <p className='text-center location mt-0'>{location}</p>
              </div>
              {hiddenTeam ? (
                <div>
                  <div
                    className='row d-flex justify-content-center mt-2'
                    ref={teamRef}
                  >
                    {team?.map((el, index) => {
                      return (
                        <Avatar
                          showTooltip
                          user={`${el.firstname} ${el.lastname}`}
                          tooltip={`${t(el.type)}: ${el.firstname} ${
                            el.lastname
                          }`}
                          key={index}
                        />
                      )
                    })}
                  </div>

                  <div className='row d-flex justify-content-center mb-3'>
                    {team && team.length > 0 ? (
                      <p className='text-center location'>{t('team')}</p>
                    ) : (
                      <p className='text-center location'>{t('noteam')}</p>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='row m-0 mt-3 d-flex user-stats'>
                {hiddenEdit ? (
                  <PermissionAgent
                    module='job'
                    actions={['edit']}
                    onFail='disabled'
                    Component={({ disabled }) => (
                      <div className='btn-job-action user-stat' ref={editRef}>
                        <Button
                          className='custom-color'
                          onClick={() => !disabled && onEdit?.()}
                          disabled={disabled}
                        >
                          <SettingIcon />
                        </Button>
                        <p>{t('edit')}</p>
                      </div>
                    )}
                  />
                ) : (
                  ''
                )}

                <div className='btn-job-action user-stat' ref={candidatesRef}>
                  <Button className='custom-color' onClick={onListCandidates}>
                    <CandidatesIcon />
                  </Button>
                  <p>{t('candidates')}</p>
                </div>

                {/* <div className='btn-job-action user-stat' ref={shareRef}>
                  <Button className='custom-color' onClick={onShare}>
                    <ShareIcon />
                  </Button>
                  <p>{t('share')}</p>
                </div> */}
              </div>
            </div>
          </div>

          <div className='job-card-footer'>
            <TagList tags={tags} onClick={() => null} />
          </div>
        </>
      </div>
    )
  )
}
