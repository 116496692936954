import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface AverageFinalizeTimeProps {
  t: any
  loading: boolean
}

const AverageFinalizeTime = ({ t, loading }: AverageFinalizeTimeProps) => {
  const { closingTime } = useDashboardContext()
  return (
    <div className='row'>
      <div className='col-12'>
        {loading && !hasContent(closingTime) ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard title={t('chart2')}>
            <DashboardChart
              id='avg-closing-by-recruiter-chart'
              visible={hasContent(closingTime)}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default AverageFinalizeTime
