import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { Select } from 'components/Select/Select'

import { FilterForm } from '../CandidateFilterContainer'

export interface LanguageFilter {
  name: string
  level: string
}

type Props = {
  onChange: (languageFilter: LanguageFilter) => void
  filter: FilterForm
}

export const LanguageSection: React.FC<Props> = ({ onChange, filter }) => {
  const { t } = useTranslation('candidateFilter')
  // Language
  const [language, setLanguage] = useState(filter.language?.name || '')
  const [languageLevel, setLanguageLevel] = useState(
    filter.language?.level || ''
  )

  const languageLevelOptions = ['basic', 'intermediate', 'fluent', 'native']

  useEffect(() => {
    onChange({
      name: language,
      level: languageLevel
    } as LanguageFilter)
  }, [language, languageLevel])

  useEffect(() => {
    if (!Object.keys(filter).length) {
      setLanguage('')
      setLanguageLevel('')
    }
  }, [filter])

  return (
    <>
      <p className='candidate-filter-section-title'>{t('language.title')}</p>
      <FormRow split formRowClasses='mb-3'>
        <FormItem>
          <Input
            type='text'
            label={t('language.name')}
            name='language-name'
            value={language}
            onChange={e => setLanguage(e)}
            small
          />
        </FormItem>
        <FormItem>
          <Select
            id='languageLevel-select'
            label={t('language.level')}
            value={languageLevel}
            onChange={e => setLanguageLevel(e.target.value)}
          >
            <option value=''></option>

            {languageLevelOptions.map((el, index) => {
              return (
                <option value={el} key={index}>
                  {t(`languageLevelOptions.${el}`)}
                </option>
              )
            })}
          </Select>
        </FormItem>
      </FormRow>
    </>
  )
}
