import { Dispatch } from 'react'

import { StoreTypes } from 'store/types'

import { permissions } from 'services/api'

import * as types from './types'

export const enableLoading = (): types.Actions => ({
  type: types.Types.ENABLE_LOADING,
  payload: null
})

export const disableLoading = (): types.Actions => ({
  type: types.Types.DISABLE_LOADING,
  payload: null
})

export const setError = (payload: string): types.Actions => ({
  type: types.Types.SET_ERROR,
  payload
})

export const setPermissions = (payload: UserPermissions): types.Actions => ({
  type: types.Types.SET_PERMISSIONS,
  payload
})

export const getUserPermissions =
  () =>
  async (dispatch: Dispatch<StoreTypes.ActionTypes>): Promise<void> => {
    dispatch(enableLoading())

    try {
      const [error, data] = await permissions.get()
      if (error) throw new Error(error as string)

      const permissionsData = {}
      data?.forEach(permission => {
        permissionsData[permission.rsname] = { ...permission }
      })

      dispatch(setPermissions(permissionsData as UserPermissions))
    } catch (error) {
      dispatch(setError(error.message))
    } finally {
      dispatch(disableLoading())
    }
  }
