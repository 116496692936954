import { Dispatch } from 'react'

import { StoreTypes } from 'store/types'

import * as types from './types'

export const notify =
  (payload: NotificationConfig) =>
  async (dispatch: Dispatch<StoreTypes.ActionTypes>): Promise<void> => {
    const notification = {
      message: payload.message,
      type: payload.type,
      timeout: payload.timeout ?? 5 * 1000
    }

    dispatch(show(notification))
    setTimeout(
      () => dispatch(dismiss(notification)),
      notification.timeout + 2000
    )
  }

export const show = (payload: NotificationConfig): types.Actions => ({
  type: types.Types.SHOW,
  payload
})

export const dismiss = (payload: NotificationConfig): types.Actions => ({
  type: types.Types.DISMISS,
  payload
})
