import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface JobPositionsFinalizedByRecruiterOffScheduleProps {
  t: any
  loading: boolean
}

const JobPositionsFinalizedByRecruiterOffSchedule = ({
  t,
  loading
}: JobPositionsFinalizedByRecruiterOffScheduleProps) => {
  const { closedByRecruiters } = useDashboardContext()
  return (
    <div className='row'>
      <div className='col-12'>
        {loading && !hasContent(closedByRecruiters) ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard title={t('chart10')}>
            <DashboardChart
              id='closed-jobs-by-recruiter-chart'
              visible={hasContent(closedByRecruiters)}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default JobPositionsFinalizedByRecruiterOffSchedule
