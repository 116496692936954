import React from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import starOffIMG from 'assets/images/badges/star-off.png'
import starOnIMG from 'assets/images/badges/star-on.png'
import { ReactComponent as StarIcon } from 'assets/svg/star.svg'
import { PopoverHelp } from 'components/PopoverHelp/PopoverHelp'

import './BadgeList.scss'

type BadgeListProps = {
  review: number
  title?: string
  onClick?: (review: number) => void
  viewType?: 'list' | 'grid'
}

interface ItemProps extends BadgeListProps {
  alt: string
}

const Item: React.FC<ItemProps> = ({ review, alt, onClick }) => (
  <div
    data-testid='badge-item'
    className={classNames('badge-item', { clickable: !!onClick })}
    onClick={() => onClick && onClick(Number(alt))}
  >
    <StarIcon
      stroke={review >= +alt ? '#FFB300' : '#797B96'}
      fill={review >= +alt ? '#FFB300' : 'none'}
    />
  </div>
)

const txt = title => {
  const { t } = useTranslation()
  return (
    <div className='badge'>
      <p className='badge-title mb-2'>{title}</p>
      <div className='badge m-8 font'>
        <PopoverHelp content={t('badgeList:candidateAssessment')} />
      </div>
    </div>
  )
}

export const BadgeList: React.FC<BadgeListProps> = ({
  review,
  title,
  onClick,
  viewType
}) => {
  const { t } = useTranslation()

  return (
    <>
      {title && txt(title)}
      <div className={`badge-list -${viewType}`}>
        <div className='scale'>
          <p>{t('badgeList:initialScale')}</p>
        </div>
        <div className='starts-list'>
          <Item review={review} alt='1' onClick={onClick} />
          <Item review={review} alt='2' onClick={onClick} />
          <Item review={review} alt='3' onClick={onClick} />
          <Item review={review} alt='4' onClick={onClick} />
          <Item review={review} alt='5' onClick={onClick} />
        </div>
        <div className='scale'>
          <p>{t('badgeList:finalScale')}</p>
        </div>
      </div>
    </>
  )
}
