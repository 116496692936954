import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { useDashboardContext } from '../../DashboardContainerContext'

interface CandidatesAcquisitionProps {
  t: any
  loading: boolean
}

const CandidatesAcquisition = ({ t, loading }: CandidatesAcquisitionProps) => {
  const { candidatesAcquisition } = useDashboardContext()
  return (
    <div className='row'>
      <div className='col-12'>
        {loading && candidatesAcquisition?.length < 1 ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard title={t('chart3')}>
            <DashboardChart
              id='acquisition-source-chart'
              visible={candidatesAcquisition?.length > 0}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default CandidatesAcquisition
