import React, { useState, useEffect, useRef } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import candidateDetailIcon from 'assets/images/icons/candidate-detail-icon.png'
import candidatePreviewIcon from 'assets/images/icons/candidate-preview-icon.png'
import { ReactComponent as MembersIcon } from 'assets/images/icons/members.svg'
import { BadgeList } from 'components/BadgeList/BadgeList'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { Select } from 'components/Select/Select'

import './CandidateCard.scss'

import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { getDateFormat } from 'utils/date'

export type CandidateCardRef = {
  previewRef: React.RefObject<HTMLDivElement>
  detailRef: React.RefObject<HTMLDivElement>
  moveToRef?: React.RefObject<HTMLDivElement>
  jobApplyRef?: React.RefObject<HTMLDivElement>
}

type Props = {
  name: string
  date: Date
  review?: number
  stages?: Array<WorkflowStageSchema>
  isDbCandidate?: boolean
  onJobApply?: () => void
  selected?: boolean
  onSelected?: (value: boolean) => void
  onShowPreview: () => void
  onShowDetail: () => void
  onMoveToStep?: (stepId: string) => void
  getRefs?: (value: CandidateCardRef) => void
}

const DbCandidate: React.FC<Props> = ({
  name,
  date,
  onShowDetail,
  onShowPreview,
  onJobApply,
  getRefs
}) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const { t } = useTranslation('candidateList')
  const previewRef = useRef(null)
  const detailRef = useRef(null)
  const jobApplyRef = useRef(null)

  useEffect(() => {
    if (getRefs !== undefined) getRefs({ previewRef, detailRef, jobApplyRef })
  }, [])

  return (
    <div className='demo-box candidate-box-database col-xs-12 col-sm-12 col-md-3 col-lg-2'>
      <div className='demo-box-icon-wrap'>
        <MembersIcon className='icon-add-friend demo-box-icon' />
      </div>

      <div className='name'>
        <p className='demo-box-title' data-testid='candidate-name'>
          {name}
        </p>
        <p className='demo-box-text mb-4'>
          {moment(date, getDateFormat()).format(getDateFormat())}
        </p>
      </div>

      <div>
        <PermissionAgent
          module='job-application'
          actions={['view']}
          onFail='disabled'
          Component={({ disabled }) => (
            <div className='user-stats mb-2'>
              <div className='user-stat tutorial-step-4' ref={previewRef}>
                <Button onClick={onShowPreview} disabled={disabled}>
                  <img
                    src={candidatePreviewIcon}
                    alt='Preview'
                    data-testid='preview-button'
                  />
                </Button>
                <p className='user-stat-text'>{t('preview')}</p>
              </div>
              <div className='user-stat' ref={detailRef}>
                <Button onClick={onShowDetail} disabled={disabled}>
                  <img
                    src={candidateDetailIcon}
                    alt='Detail'
                    data-testid='detail-button'
                  />
                </Button>

                <p className='user-stat-text'>{t('detail')}</p>
              </div>
            </div>
          )}
        />
        {dontShowOnGeralWorkspace && (
          <PermissionAgent
            module='job-application'
            actions={['edit']}
            onFail='disabled'
            Component={({ disabled }) => (
              <div ref={jobApplyRef}>
                <Button
                  id='job-apply-button'
                  onClick={onJobApply}
                  disabled={disabled}
                  className='button void candidate-new'
                >
                  {t('apply')}
                </Button>
              </div>
            )}
          />
        )}
      </div>
    </div>
  )
}

export const CandidateCard: React.FC<Props> = ({
  name,
  date,
  selected,
  review = 0,
  stages = [],
  isDbCandidate = false,
  onSelected,
  onMoveToStep,
  onShowDetail,
  onShowPreview,
  onJobApply,
  getRefs
}) => {
  const [stage, setStage] = useState('')
  const { t } = useTranslation('candidateList')
  const [tworkflows] = useTranslation('defaultWorkflowStages')
  const previewRef = useRef(null)
  const detailRef = useRef(null)
  const moveToRef = useRef(null)

  useEffect(() => {
    if (getRefs !== undefined && !isDbCandidate) {
      getRefs({ previewRef, detailRef, moveToRef })
    }
  }, [])

  if (isDbCandidate) {
    return (
      <DbCandidate
        name={name}
        date={date}
        onJobApply={onJobApply}
        onShowDetail={onShowDetail}
        onShowPreview={onShowPreview}
        getRefs={getRefs}
      />
    )
  }

  return (
    <div className='demo-box candidate-box'>
      <PermissionAgent
        module='job-application'
        actions={['edit']}
        onFail='hidden'
        Component={({ hidden }) =>
          !hidden &&
          onSelected && (
            <div className='select-candidate'>
              <Checkbox
                checkIcon
                name='select-candidate'
                checked={selected}
                onChange={e => onSelected(e)}
              />
            </div>
          )
        }
      />
      <div className='demo-box-icon-wrap'>
        <MembersIcon className='icon-add-friend demo-box-icon' />
      </div>
      <p className='demo-box-title' data-testid='candidate-name'>
        {name}
      </p>
      <p className='demo-box-text mb-4'>
        {moment(date, getDateFormat()).format(getDateFormat())}
      </p>

      <p className='demo-box-text mb-2'>{t('review')}</p>
      <div className='mb-4'>
        <BadgeList review={review} viewType='grid' />
      </div>

      <div className='user-stats mb-2'>
        <div className='user-stat tutorial-step-4' ref={previewRef}>
          <Button onClick={onShowPreview}>
            <img
              src={candidatePreviewIcon}
              alt='Preview'
              data-testid='preview-button'
            />
          </Button>
          <p className='user-stat-text'>{t('preview')}</p>
        </div>
        <div className='user-stat' ref={detailRef}>
          <Button onClick={onShowDetail}>
            <img
              src={candidateDetailIcon}
              alt='Detail'
              data-testid='detail-button'
            />
          </Button>
          <p className='user-stat-text'>{t('detail')}</p>
        </div>
      </div>

      <PermissionAgent
        module='job-application'
        actions={['edit']}
        onFail='hidden'
        Component={({ hidden }) =>
          !hidden && (
            <div ref={moveToRef}>
              <Select
                id='step-select'
                label={t('select')}
                value={stage}
                onChange={e => {
                  setStage(e.target.value)
                  onMoveToStep && onMoveToStep(e.target.value)
                }}
              >
                <option value=''></option>

                {stages.map(stage => {
                  return (
                    <option value={stage.uuid} key={stage.uuid}>
                      {tworkflows(stage.name)}
                    </option>
                  )
                })}
              </Select>
            </div>
          )
        }
      />
    </div>
  )
}
