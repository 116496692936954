import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import RocketIMG from 'assets/images/logo/rocket.png'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { forgotPassword } from 'services/api/auth'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import rules from 'utils/rules'

import '../Login/LoginCard/LoginCard.scss'

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const { t } = useTranslation('forgotPassword')

  const { dispatch } = useStore()

  const sendEmail = async email => {
    if (!email) {
      setEmailError(t('emailValidationError'))
      return
    }

    const [error] = await forgotPassword(email)
    const errorMessage =
      error === 'NOT_FOUND' ? t('emailNotFound') : t('sendEmailFail')

    dispatch(
      notificationActions.notify({
        message: error ? errorMessage : t('sendEmailSuccess'),
        type: error ? 'error' : 'success'
      })
    )
  }

  const handleEmailChange = value => {
    setEmail(value)
    if (!rules.isEmail(value)) {
      setEmailError(t('emailValidationError'))
    } else {
      setEmailError('')
    }
  }

  return (
    <div className='card active'>
      <h2>{t('forgotPassword')}</h2>
      <p>{t('cardParagraph')}</p>

      <form onSubmit={e => e.preventDefault()} className='form mt-5'>
        <Input
          label='Email'
          type='text'
          name='email'
          error={emailError}
          value={email}
          onChange={e => handleEmailChange(e)}
        />

        <Button
          onClick={() => sendEmail(email)}
          className='secondary'
          disabled={!!emailError}
        >
          {t('recoveryPassword')}
        </Button>
      </form>
    </div>
  )
}
