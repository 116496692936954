import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import add from 'assets/images/avatar/new.png'
import userAdmin from 'assets/images/avatar/user-admin.png'
import userManager from 'assets/images/avatar/user-manager.png'
import userRecruiter from 'assets/images/avatar/user-recruiter.png'
import { ReactComponent as BlockIcon } from 'assets/images/icons/block-icon.svg'
import { ReactComponent as PlayIcon } from 'assets/images/icons/play-icon.svg'
import { ReactComponent as SettingIcon } from 'assets/images/icons/settings-icon.svg'
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash-icon.svg'
import { Button } from 'components/Button/Button'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { usePermission } from 'hooks/usePermission'

import './TeamCard.scss'
import { ListItemsModal } from 'components/Modals/lugarh/ListItemsModal/ListItemsModal'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'

export type TeamCardRefs = {
  card?: React.RefObject<HTMLDivElement>
  status?: React.RefObject<HTMLDivElement>
  block?: React.RefObject<HTMLDivElement>
  edit?: React.RefObject<HTMLDivElement>
  delete?: React.RefObject<HTMLDivElement>
  addButton?: React.RefObject<HTMLDivElement>
}

type Props = {
  status: 'add-new-team' | 'active' | 'disabled'
  name?: string
  role?: string
  workspaceList?: WorkspaceSchema[]
  onReactivate?: () => void
  onBlock?: () => void
  onEdit?: () => void
  onDelete?: () => void
  onCreateNewUser?: () => void
  getRefs?: (value: TeamCardRefs) => void
  blockActions?: boolean
}

export const TeamCard: React.FC<Props> = ({
  status,
  name,
  role,
  workspaceList,
  onReactivate,
  onBlock,
  onEdit,
  onDelete,
  onCreateNewUser,
  getRefs,
  blockActions
}) => {
  usePermission({ moduleName: 'user', action: 'view' })

  const { dontShowOnGeralWorkspace, showOnGeralWorkspace } =
    useWorkspacePermission()
  const [image, setImage] = useState('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const cardRef = useRef<HTMLDivElement>(null)
  const statusRef = useRef<HTMLDivElement>(null)
  const blockRef = useRef<HTMLDivElement>(null)
  const editRef = useRef<HTMLDivElement>(null)
  const deleteRef = useRef<HTMLDivElement>(null)

  const addButtonRef = useRef<HTMLDivElement>(null)
  const randomId = Math.random().toString()
  const isAddCard = status === 'add-new-team'

  useEffect(() => {
    if (role === 'admin') {
      setImage(userAdmin)
    } else if (role === 'manager') {
      setImage(userManager)
    } else if (role === 'recruiter') {
      setImage(userRecruiter)
    } else if (isAddCard) {
      setImage(add)
    }
  }, [role, status])

  useEffect(() => {
    let obj: TeamCardRefs = {
      card: cardRef,
      status: statusRef,
      block: blockRef,
      edit: editRef,
      delete: deleteRef
    }

    if (isAddCard) {
      obj = {
        addButton: addButtonRef
      }
    }

    if (getRefs !== undefined) {
      getRefs(obj)
    }
  }, [])

  const { t } = useTranslation('teamCard')
  const showWorkspaces = () => setOpenModal(true)

  return (
    <div
      className={`team-card-wrapper ${isAddCard ? 'add' : ''}`}
      ref={cardRef}
    >
      <div ref={statusRef}>
        <div className={`team-card-header ${status}`} />
      </div>

      <svg
        onClick={isAddCard ? onCreateNewUser : () => null}
        viewBox='0 0 100 100'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        className={`hexagon ${isAddCard && 'clickable'}`}
      >
        <defs>
          <pattern
            id={randomId}
            patternUnits='userSpaceOnUse'
            width='100'
            height='100'
          >
            <image xlinkHref={image} x='-25' width='150' height='100' />
          </pattern>
        </defs>
        <polygon
          points='50 1 95 25 95 75 50 99 5 75 5 25'
          fill={`url(#${randomId})`}
        />
      </svg>

      {isAddCard ? (
        <div>
          <div className='p-2' ref={addButtonRef}>
            <Button className='btn-add-new-team' onClick={onCreateNewUser}>
              {t('createNewUser')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className='team-card-body'>
            <div className='team-card-content'>
              <p className='text-center title'>{name}</p>
              <p className='text-center role'>{t(`profileOptions.${role}`)}</p>
              {showOnGeralWorkspace && (
                <>
                  <p
                    className='text-center workspaces'
                    onClick={() => showWorkspaces()}
                  >
                    {workspaceList && workspaceList.length > 0
                      ? `${workspaceList.length} WORKSPACE${
                          workspaceList.length > 1 ? 'S' : ''
                        }`
                      : t('noWorkspaces')}
                  </p>
                  {workspaceList && (
                    <ListItemsModal<WorkspaceSchema>
                      open={openModal}
                      onClose={() => setOpenModal(false)}
                      items={workspaceList}
                      labels={{ id: 'uuid', title: 'name' }}
                      modalTitle={t('modalWorkspacesTitle')}
                      modalSubtitle={t('modalWorkspacesSubtitle')}
                      customClass='list-items-modal'
                    />
                  )}
                </>
              )}
              {dontShowOnGeralWorkspace && (
                <div className='row m-0 d-flex user-stats mt-5'>
                  <PermissionAgent
                    module='user'
                    actions={['edit']}
                    onFail='disabled'
                    Component={({ disabled }) => (
                      <>
                        {status === 'disabled' && (
                          <div
                            className='btn-team-action user-stat'
                            ref={blockRef}
                          >
                            <Button
                              className='green'
                              onClick={onReactivate}
                              disabled={disabled}
                            >
                              <PlayIcon />
                            </Button>
                            <p>{t('reactivate')}</p>
                          </div>
                        )}

                        {status === 'active' && (
                          <div
                            className='btn-team-action user-stat'
                            ref={blockRef}
                          >
                            <Button
                              className='orange'
                              onClick={onBlock}
                              disabled={disabled || blockActions}
                            >
                              <BlockIcon />
                            </Button>
                            <p>{t('block')}</p>
                          </div>
                        )}

                        <div
                          className='btn-team-action user-stat'
                          ref={editRef}
                        >
                          <Button
                            className='purple'
                            onClick={onEdit}
                            disabled={disabled}
                          >
                            <SettingIcon />
                          </Button>
                          <p>{t('edit')}</p>
                        </div>
                      </>
                    )}
                  />

                  <PermissionAgent
                    module='user'
                    actions={['delete']}
                    onFail='disabled'
                    Component={({ disabled }) => (
                      <div
                        className='btn-team-action user-stat'
                        ref={deleteRef}
                      >
                        <Button
                          className='red'
                          onClick={onDelete}
                          disabled={disabled || blockActions}
                        >
                          <TrashIcon />
                        </Button>
                        <p>{t('delete')}</p>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
