import { AxiosResponse } from 'axios'

import { NotificationConfigTypes } from './../notification/notification.d'

export const handleApiResponse = (
  response: AxiosResponse
): DefaultAPIResponse => {
  const { status: statusCode, data } = response
  let message, status

  if (statusCode >= 200 && statusCode < 300) {
    status = NotificationConfigTypes.SUCCESS
    message = data.message || 'Your request was successfully completed'
  } else {
    status = NotificationConfigTypes.ERROR
    message = data.message || 'An error occurred while processing your request'
  }

  return {
    status,
    statusCode,
    message,
    ...(data?.data && { data: data.data })
  }
}
