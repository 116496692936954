import React, { useCallback, useState } from 'react'

import { FormikTouched } from 'formik/dist/types'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'

import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { InputCity } from 'components/InputCity/InputCity'
import { Loading } from 'components/Loading/Loading'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'
import { company } from 'services/api'

// eslint-disable-next-line
const CNPJ_REGEX = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/

type CompanyFormContainerProps = {
  name: string
  fancyName: string
  site: string
  location: string
  description: string
  cnpj: string
  setFieldValue: SetFieldValue
  setFieldTouched: SetFieldTouched
  touched: FormikTouched<CompanyPayload>
  getRef: (value: { companyFormRef }) => void
}

export const CompanyFormContainer: React.FC<CompanyFormContainerProps> = ({
  name,
  fancyName,
  site,
  location,
  description,
  cnpj,
  setFieldValue,
  setFieldTouched,
  touched,
  getRef
}) => {
  const { t } = useTranslation('companySettings')
  const { t: errorsTranslations } = useTranslation('errors')
  const companyFormRef = React.useRef<HTMLDivElement>(null)

  const [validSlug, setValidSlug] = useState<undefined | boolean>(undefined)
  const [loading, setLoading] = useState(false)

  React.useEffect(() => {
    getRef({ companyFormRef })
  }, [companyFormRef])

  const handleErrorMessage = (field: string, value: string) =>
    touched[field] && !value ? errorsTranslations('requiredField') : ''

  const validateSlug = useCallback(
    debounce(async (value: any) => {
      setValidSlug(undefined)
      if (!value.match('[^0-9A-Za-z]')) {
        const status = await company.checkSlug(value)
        setValidSlug(status === 200)
      } else {
        setValidSlug(false)
      }
      setLoading(false)
    }, 500),
    []
  )

  return (
    <div ref={companyFormRef}>
      <FormRow>
        <FormItem formItemClasses='col-md-6'>
          <Input
            type='text'
            label={t('companyForm.name')}
            name='name'
            value={name}
            onChange={(value: string) => setFieldValue('name', value)}
            onBlur={() => setFieldTouched('name')}
            error={handleErrorMessage('name', name)}
            small
          ></Input>
        </FormItem>

        <FormItem formItemClasses='spaceUp col-md-6'>
          <Input
            type='text'
            label={t('companyForm.fancyName')}
            name='fancyName'
            value={fancyName}
            onChange={(value: string) => setFieldValue('fancyName', value)}
            onBlur={() => setFieldTouched('fancyName')}
            error={handleErrorMessage('fancyName', fancyName)}
            small
          ></Input>
        </FormItem>
      </FormRow>

      <FormRow>
        <FormItem formItemClasses='col-md-4'>
          <Input
            type='text'
            label={t('companyForm.site')}
            name='site'
            value={site}
            onChange={(value: string) => {
              setFieldValue('slug', value)
              setLoading(true)
              validateSlug(value)
            }}
            onBlur={() => setFieldTouched('slug')}
            error={handleErrorMessage('slug', site)}
            fixedValue={
              loading ? <Loading className='m-0' /> : '.recrud.bne.com.br'
            }
            small
          />

          <p className={!validSlug ? 'error' : 'error text-success'}>
            {validSlug !== undefined &&
              (!validSlug
                ? errorsTranslations('linkUnavailable')
                : errorsTranslations('linkAvailable'))}
          </p>
        </FormItem>

        <FormItem formItemClasses='spaceUp col-md-4 col-lg-4'>
          <InputCity
            type='text'
            label={t('companyForm.location')}
            name='location'
            value={location}
            onChange={value => setFieldValue('location', value[0])}
            onBlur={() => setFieldTouched('location')}
            error={handleErrorMessage('location', location)}
            small
          ></InputCity>
        </FormItem>

        <FormItem formItemClasses='spaceUp col-md-4'>
          <Input
            type='text'
            label={t('companyForm.cnpj')}
            name='cnpj'
            value={cnpj}
            onChange={(value: string) => setFieldValue('cnpj', value)}
            onBlur={() => setFieldTouched('cnpj')}
            error={handleErrorMessage('cnpj', cnpj)}
            small
          ></Input>
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem>
          <RichTextEditor
            placeholder={t('companyForm.description')}
            value={description}
            onChange={(value: string) => setFieldValue('description', value)}
            error={handleErrorMessage('description', description)}
            onBlur={() => setFieldTouched('description')}
          />
        </FormItem>
      </FormRow>
    </div>
  )
}
