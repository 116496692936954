import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import { MyProfileFormContainer } from 'containers/MyProfile/MyProfile'
import useStore from 'store'
import ReactGA from 'utils/analytics'

export function MyProfilePage(): JSX.Element {
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const { t } = useTranslation('myProfile')

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      <div className='grid grid-12 medium-space'>
        <div className='account-hub-content'>
          <div className='grid-column'>
            <WidgetBox>
              <MyProfileFormContainer />
            </WidgetBox>
          </div>
        </div>
      </div>
    </div>
  )
}
