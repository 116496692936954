import * as React from 'react'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Skeleton from '@mui/material/Skeleton'

interface GraphicSkeleton {
  isRouded?: boolean
}

function GraphicSkeleton({ isRouded }: GraphicSkeleton) {
  return (
    <Card
      sx={{
        p: 3,
        boxShadow: 4,
        margin: '10px 0'
      }}
    >
      <CardHeader
        action={null}
        title={
          <Skeleton
            animation='wave'
            width={isRouded ? '20%' : '50%'}
            sx={{ height: 35, marginBottom: 2 }}
          />
        }
        sx={{ p: 0 }}
      />
      {isRouded ? (
        <Skeleton sx={{ height: 472 }} animation='wave' variant='circular' />
      ) : (
        <Skeleton sx={{ height: 472 }} animation='wave' variant='rectangular' />
      )}
    </Card>
  )
}

export default GraphicSkeleton
