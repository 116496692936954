import React from 'react'

import { EditContainer } from 'containers/Jobs/EditContainer/EditContainer'
import useStore from 'store'

import './createJob.scss'

export function EditJobsPage(): JSX.Element {
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      <div className='grid grid-12 medium-space'>
        <div className='account-hub-content'>
          <div className='grid-column'>
            <EditContainer />
          </div>
        </div>
      </div>
    </div>
  )
}
