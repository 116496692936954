import * as AsideMenuTypes from './types'

export function Selector(state: AsideMenuTypes.State): AsideMenuTypes.Selector {
  function isExpanded(): boolean {
    return state.isExpanded
  }

  return {
    isExpanded
  }
}
