import { Dispatch } from 'react'

import { StoreTypes } from 'store/types'

import { auth as authAPI } from 'services/api'
import api, { apiNoInterceptors } from 'services/api/api'
import { RedirectAndRemoveCallbackUrl } from 'utils/partnerCallbackUrl'

import * as AuthTypes from './types'
import { getUserPermissions } from '../permissions/actions'

export const enableLoading = (): { type: AuthTypes.Types.ENABLE_LOADING } => ({
  type: AuthTypes.Types.ENABLE_LOADING
})

export const disableLoading = (): {
  type: AuthTypes.Types.DISABLE_LOADING
} => ({
  type: AuthTypes.Types.DISABLE_LOADING
})

export const setToken = (payload: Token): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_TOKEN,
  payload
})

export const setUser = (payload: User): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_USER,
  payload
})

export const setCurrentWorkspace = (
  payload: Omit<Workspace, 'slug'>
): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_CURRENT_WORKSPACE,
  payload
})

export const setCompanyId = (payload: string): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_COMPANY_ID,
  payload
})

export const setCompany = (payload: CompanyUser): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_COMPANY,
  payload
})

export const setKeepConnected = (payload: boolean): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_KEEP_CONNECTED,
  payload
})

export const logoff = (): {
  type: AuthTypes.Types.LOGOFF
} => ({
  type: AuthTypes.Types.LOGOFF
})

export const setError = (error: string): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_ERROR,
  payload: error
})

export const setLoginError = (error: string): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_LOGIN_ERROR,
  payload: { error }
})

export const setRegisterError = (error: string): AuthTypes.Actions => ({
  type: AuthTypes.Types.SET_REGISTER_ERROR,
  payload: { error }
})

export const clearErrors = (): AuthTypes.Actions => ({
  type: AuthTypes.Types.CLEAR_ERRORS,
  payload: null
})

export const register =
  (payload: RegisterPayload) =>
  async (dispatch: Dispatch<StoreTypes.ActionTypes>): Promise<void> => {
    try {
      dispatch(enableLoading())
      const [error, data] = await authAPI.register(payload)

      if (error) {
        throw new Error(error.toString())
      }

      dispatch(setToken(data as Token))
    } catch (error) {
      dispatch(setRegisterError(error.message))
    } finally {
      dispatch(disableLoading())
    }
  }

export const login =
  (payload: LoginPayload) =>
  async (dispatch: Dispatch<StoreTypes.ActionTypes>): Promise<void> => {
    try {
      dispatch(enableLoading())
      const [errorToken, tokenData] = await authAPI.token({
        email: payload.email,
        password: payload.password,
        recaptcha: payload.recaptcha
      })

      if (errorToken) {
        throw new Error(errorToken.toString())
      }

      const [loginError, loginData] = await authAPI.login(
        tokenData?.token as string
      )

      if (loginError) {
        throw new Error(loginError.toString())
      }

      dispatch(setKeepConnected(payload.keepConnected))
      dispatch(setUser(loginData as User))
      dispatch(setToken(tokenData as Token))
      window.localStorage.setItem(
        'Workspace-Tenant',
        loginData?.currentWorkspace.uuid as string
      )
      await getUserPermissions()(dispatch)
    } catch (error) {
      dispatch(setLoginError(error.message))
    } finally {
      dispatch(disableLoading())
    }
  }

export const directLogin =
  (tokenData: Token) =>
  async (dispatch: Dispatch<StoreTypes.ActionTypes>): Promise<void> => {
    try {
      dispatch(enableLoading())

      api.defaults.headers.token = tokenData.token
      apiNoInterceptors.defaults.headers.token = tokenData.token

      const [loginError, loginData] = await authAPI.login(tokenData.token)

      if (loginError) {
        throw new Error(loginError.toString())
      }

      dispatch(setKeepConnected(true))
      dispatch(setUser(loginData as User))
      dispatch(setToken(tokenData as Token))
      window.localStorage.setItem(
        'Workspace-Tenant',
        loginData?.currentWorkspace.uuid as string
      )
      await getUserPermissions()(dispatch)
    } catch (error) {
      RedirectAndRemoveCallbackUrl()
    } finally {
      dispatch(disableLoading())
    }
  }

export const refreshToken =
  (refreshToken: string) =>
  async (dispatch: Dispatch<StoreTypes.ActionTypes>): Promise<void> => {
    try {
      const [errorToken, tokenData] = await authAPI.refreshToken(refreshToken)
      if (errorToken) {
        throw new Error(errorToken.toString())
      }
      dispatch(setToken(tokenData as Token))
      await getUserPermissions()(dispatch)
    } catch (error) {
      const loginError =
        error.message === 'Request failed with status code 401'
          ? 'EXPIRED_SESSION'
          : error.message
      dispatch(logoff())
      dispatch(setError(loginError))
    }
  }
