import * as Types from './types'

export function Selector(state: Types.State): Types.Selector {
  function messages(): NotificationConfig[] {
    return state.messages
  }

  function hasMessage(): boolean {
    return !!state.messages.length
  }

  return {
    messages,
    hasMessage
  }
}
