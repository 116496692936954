import React from 'react'
import './WidgetBox.scss'

export interface WidgetBoxProps {
  title?: string
  widgetBoxClasses?: string
  widgetBoxTitleClasses?: string
  widgetBoxtContentClasses?: string
  className?: string
  children?: React.ReactNode
}

export const WidgetBox: React.FC<WidgetBoxProps> = ({
  title,
  className = '',
  widgetBoxClasses,
  widgetBoxTitleClasses,
  widgetBoxtContentClasses,
  children
}) => {
  return (
    <div className={`widget-box${widgetBoxClasses || ''} ${className}`}>
      {title && (
        <p className={`widget-box-title${widgetBoxTitleClasses || ''}`}>
          {title}
        </p>
      )}
      <div className={`widget-box-content${widgetBoxtContentClasses || ''}`}>
        {children}
      </div>
    </div>
  )
}
