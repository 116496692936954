import React, { useState } from 'react'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { CheckoutCard } from 'containers/CheckoutPlan/CheckoutPlanContainer'

import './checkoutPlan.scss'

type Kind = 'login' | 'checkout'

export function CheckoutPlanPage(): JSX.Element {
  const { t } = useTranslation('login')
  const [kind, setKind] = useState<Kind>('login')

  return (
    <>
      <Helmet>
        <title>{t('metatags.title')}</title>
        <meta name='description' content={t('metatags.description')} />
        <meta name='url' content={t('metatags.url')} />
        <meta property='og:title' content={t('metatags.title')} />
        <meta property='og:description' content={t('metatags.description')} />
        <meta property='og:url' content={t('metatags.url')} />
      </Helmet>

      <main>
        <div className='container container-checkout-plan'>
          <div className='row h-100'>
            <div className='col-12 col-lg-12 d-flex justify-content-center align-items-center'>
              <CheckoutCard
                kind={kind}
                onChangeKind={value => setKind(value as Kind)}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
