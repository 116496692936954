export const initialEditUserFormValuesStepOne = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  role: ''
}

export const initialEditUserFormValuesStepTwo = {
  workspaces: []
}
