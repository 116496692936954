import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { Select } from 'components/Select/Select'

import './HiringStepHistory.scss'

import { LogsSchemaWithDescription } from 'containers/CandidateDetail/HistoryContainer/HistoryContainer'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

import moment from 'moment'

import { getDateFormat, getFullYear, timestampToDate } from 'utils/date'

export interface StepHistoryItem {
  id: string
  date: Date
  content: string
}

export interface Stage {
  id: string
  name: string
}

interface Props {
  stepHistoryList: Array<LogsSchemaWithDescription>
  onMoveToStep: (step: string) => void
  stepList: Array<Stage>
  jobWorkspaceId: string
}

export const HiringStepHistory: React.FC<Props> = ({
  stepHistoryList,
  stepList,
  onMoveToStep,
  jobWorkspaceId
}) => {
  const { onlyAllowOnDirectJobParent } = useWorkspacePermission()
  const [moveToStep, setMoveToStep] = useState('')
  const { t } = useTranslation('candidateHeader')
  const { t: td } = useTranslation('defaultWorkflowStages')

  return (
    <div className='calendar-events-preview history tutorial-step-2'>
      <div className='calendar-event-preview-list'>
        {stepHistoryList &&
          stepHistoryList.map((history: any, index: number) => (
            <div
              className='calendar-event-preview small primary'
              key={`${history.uuid}${index}`}
            >
              <div className='calendar-event-preview-start-time'>
                <p className='calendar-event-preview-start-time-title'>
                  {moment(
                    timestampToDate(
                      history?.[index]?.startDate || history?.startDate
                    ),
                    getDateFormat()
                  ).format(getDateFormat())}
                </p>
                <p className='calendar-event-preview-start-time-text'>
                  {getFullYear(
                    moment(
                      timestampToDate(
                        history?.[index]?.startDate || history?.startDate
                      ),
                      getDateFormat()
                    ).format(getDateFormat())
                  )}
                </p>
              </div>
              <div className='calendar-event-preview-info'>
                <p className='calendar-event-preview-title'>
                  {history.description}
                </p>
              </div>
            </div>
          ))}
        {onlyAllowOnDirectJobParent(jobWorkspaceId) && (
          <PermissionAgent
            module='job-application'
            actions={['edit']}
            onFail='hidden'
            Component={({ hidden }) =>
              !hidden && (
                <div className='calendar-event-preview small primary'>
                  <div className='calendar-event-preview-info'>
                    <FormRow formRowClasses=' form candidate-list'>
                      <FormItem>
                        <Select
                          label={t('moveSelected')}
                          value={moveToStep}
                          onChange={event => {
                            setMoveToStep(event.target.value)

                            const selectedIndex =
                              event.target.options.selectedIndex
                            const selectedOption =
                              event.target.options[selectedIndex]
                            onMoveToStep(
                              selectedOption.dataset.stageUuid as string
                            )
                          }}
                        >
                          <option value=''></option>
                          {stepList.map(stage => (
                            <option
                              value={stage.name}
                              key={stage.id}
                              data-stage-uuid={stage.id}
                            >
                              {td(stage.name)}
                            </option>
                          ))}
                        </Select>
                      </FormItem>
                    </FormRow>
                  </div>
                </div>
              )
            }
          />
        )}
      </div>
    </div>
  )
}
