import React, { useState } from 'react'

import { Error } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button/Button'
import { Modal } from 'components/Modal/Modal'
import { Select } from 'components/Select/Select'

type ModalAddDocumentProps = {
  open: boolean
  onClose: (status: boolean) => void
  profiles?: Array<any>
  onSubmit: (withProfile: any) => void
  withProfile?: any
  isLoading: boolean
}

const ModalAddDocument: React.FC<ModalAddDocumentProps> = ({
  open,
  onClose,
  profiles,
  withProfile,
  onSubmit,
  isLoading
}): JSX.Element => {
  const { t } = useTranslation('candidatesListContainer')
  const [withProfileParams, setWithProfileParams] = useState<any>(
    withProfile || {}
  )

  const onSubmitWithProfile = () => {
    onSubmit(withProfileParams)
    onClose(false)
  }
  if (!withProfileParams?.profile && profiles?.length === 0) {
    return <></>
  }
  return (
    <div className='modal-add-document'>
      <Modal title={t('title')} open={open} onClose={() => onClose(false)}>
        <div className='modal-add-document-content'>
          <div className='info-content'>
            <p>{t('info')}</p>
            <p>
              <b>{t('infoBold')}</b>
            </p>
          </div>
          {profiles && profiles.length > 0 && (
            <Select
              label={t('select')}
              value={withProfileParams?.profile || ''}
              onChange={e => {
                setWithProfileParams({
                  ...withProfileParams,
                  profile: e.target.value
                })
              }}
            >
              <option value=''></option>
              {profiles.map(profile => {
                return (
                  <option value={profile.id} key={profile.id}>
                    {profile.name}
                  </option>
                )
              })}
            </Select>
          )}
          <div className='alert-content'>
            <Error
              style={{ marginRight: 8, width: '15px', color: '#1B499B' }}
            />
            <span>
              {t('alertInfo')}{' '}
              <span className='bold'>{t('alertInfoBold')}</span>?
              <br /> {t('alertInfo2')}
            </span>
          </div>
          <Button
            className='primary mt-3 mb-3'
            disabled={!withProfileParams?.profile}
            onClick={onSubmitWithProfile}
            loading={isLoading}
          >
            {t('button')}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default ModalAddDocument
