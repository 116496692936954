import React from 'react'

import { useTranslation } from 'react-i18next'

import { CardPlan } from 'components/CardPlan/CardPlan'

interface SelectedPlan {
  planSlug: string
  planId: string
}

type SelectedPlanProps = {
  planIdentifier: string
}

export const CurrentCardPlan: React.FC<SelectedPlanProps> = ({
  planIdentifier
}) => {
  const { t } = useTranslation('plans')

  let selectedPlan: SelectedPlan = {
    planSlug: '',
    planId: ''
  }
  switch (planIdentifier) {
    case process.env.REACT_APP_PLAN_BASIC:
      selectedPlan = {
        planSlug: 'plansCardBasic',
        planId: process.env.REACT_APP_PLAN_BASIC
          ? process.env.REACT_APP_PLAN_BASIC
          : ''
      }
      break

    case process.env.REACT_APP_PLAN_SMART:
      selectedPlan = {
        planSlug: 'plansCardSmart',
        planId: process.env.REACT_APP_PLAN_SMART
          ? process.env.REACT_APP_PLAN_SMART
          : ''
      }
      break

    case process.env.REACT_APP_PLAN_ADVANCED:
      selectedPlan = {
        planSlug: 'plansCardAdvanced',
        planId: process.env.REACT_APP_PLAN_ADVANCED
          ? process.env.REACT_APP_PLAN_ADVANCED
          : ''
      }
      break

    case process.env.REACT_APP_PLAN_ENTERPRISE:
      selectedPlan = {
        planSlug: 'plansCardEnterprise',
        planId: process.env.REACT_APP_PLAN_ENTERPRISE
          ? process.env.REACT_APP_PLAN_ENTERPRISE
          : ''
      }
      break
  }

  return (
    <CardPlan
      title={t(`${selectedPlan.planSlug}.title`)}
      subTitle={t(`${selectedPlan.planSlug}.subTitle`)}
      planValue={t(`${selectedPlan.planSlug}.planValue`)}
      planPeriod={t(`${selectedPlan.planSlug}.planPeriod`)}
      features={[
        t(`${selectedPlan.planSlug}.feat1`),
        t(`${selectedPlan.planSlug}.feat2`),
        t(`${selectedPlan.planSlug}.feat3`)
      ]}
      selectButton={t('changePlanButton', { ns: 'checkoutPlan' })}
      withoutButton={true}
      onChangeKind={() => alert()}
    />
  )
}

export default CurrentCardPlan
