import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { Modal } from 'components/Modal/Modal'

import styles from './CodeConfirmationModal.module.scss'

type CodeConfirmationModalProps = {
  open: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: (password) => void
}

export const CodeConfirmationModal: React.FC<CodeConfirmationModalProps> = ({
  open,
  isLoading,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation('codeConfirmationModal')
  const [code, setCode] = useState('')

  return (
    <div>
      <Modal
        width='400px'
        onClose={() => onClose()}
        open={open}
        title={t('title')}
        closeIcon='cross'
      >
        <p className='popup-box-subtitle'>
          <span className='light'>{t('subtitle')}</span>
        </p>
        <div className={styles['modal-content-wrapper']}>
          <Input
            label={t('label')}
            type='text'
            name='code'
            onChange={setCode}
            value={code}
            small
          ></Input>
          <Button
            className={'popup-box-action full secondary'}
            disabled={isLoading}
            onClick={() => {
              onConfirm(code)
              setCode('')
            }}
          >
            {t('buttonText')}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
