export type APIResponse<T> = [APIError, T | null, Profile[]?]

/**
 * Manipula a resposta da API e retorna uma APIResponse.
 *
 * @template T - O tipo de dado esperado na resposta.
 * @param {number} status - O código de status da resposta.
 * @param {any} data - Os dados da resposta.
 * @returns {APIResponse<T>} - A resposta da API.
 * returns [APIError, T | null, Profile[]?]
 */
export const handleResponse = <T>(
  status: number,
  data: any
): APIResponse<T> => {
  switch (status) {
    case 200:
    case 201:
      return [null, data.data as T, data.profile || null]

    case 400:
    case 401:
    case 409:
    case 500:
      return [data.message, null]

    case 422:
      return [
        {
          message: data.message,
          errors: data.errors
        },
        null
      ]

    default:
      return ['unmapped', null]
  }
}
