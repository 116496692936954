/**
 * Changes the colors of the theme by updating CSS variables dynamically.
 * @param colors - An object containing the color names and their corresponding values.
 * @returns void
 */
export const changeColors = (colors: ColorsTheme): void => {
  const styleSheet = document.styleSheets[0]

  Object.entries(colors).forEach(([colorName, colorValue]) => {
    const [color, shade] = colorName.split('_')
    const definedShade = shade ? `-${shade}` : ''
    const colorProperty = `--${color}-color${definedShade}`
    styleSheet.insertRule(
      `.theme-lugarh { ${colorProperty}: ${colorValue}; }`,
      styleSheet.cssRules.length
    )
  })
}
// Compare this snippet from app/src/context/ThemeContext/config.ts:
