import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { DefaultModal } from 'components/Modals/lugarh/Default/DefaultModal'
import { EditUserForm } from 'containers/TeamManagement/lugarh/EditUser/EditUserForm'

import './CreateUserModal.style.scss'

interface CreateUserModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (user: UserPayload) => void
  loading?: boolean
  userToEdit?: UserSchema | null
  customClass?: string
}

export const CreateUserModal: React.FC<CreateUserModalProps> = ({
  open,
  onClose,
  onConfirm,
  loading = false,
  userToEdit,
  customClass
}) => {
  const { t } = useTranslation(userToEdit ? 'editUserModal' : 'createUserModal')
  const [step, setStep] = useState<number>(1)

  const handleFormSubmit = async (user: UserPayload): Promise<void> => {
    onConfirm(user)
  }

  useEffect(() => {
    !open && setStep(1)
  }, [open])

  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      customClass={customClass}
      modalTitle={t(`lugarh.title`)}
      modalSubtitle={t(`lugarh.subtitle`)}
    >
      <DefaultModal.Nav>
        <DefaultModal.Steps>
          <DefaultModal.Step
            step={1}
            stepActive={step === 1}
            stepDone={step === 2}
            onStepChange={setStep}
            clickable={true}
          >
            {t(`lugarh.stepOne`)}
            {step === 2 && (
              <>
                {' '}
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12.2668 7.32496L8.69175 10.9083L7.31675 9.53329C7.24205 9.44606 7.15011 9.37521 7.04673 9.32519C6.94334 9.27517 6.83073 9.24706 6.71596 9.24263C6.6012 9.23819 6.48675 9.25753 6.37982 9.29943C6.27288 9.34132 6.17576 9.40487 6.09454 9.48608C6.01333 9.5673 5.94978 9.66442 5.90789 9.77136C5.86599 9.87829 5.84665 9.99274 5.85109 10.1075C5.85552 10.2223 5.88363 10.3349 5.93365 10.4383C5.98367 10.5417 6.05452 10.6336 6.14175 10.7083L8.10009 12.675C8.17795 12.7522 8.2703 12.8133 8.37183 12.8548C8.47336 12.8962 8.58208 12.9173 8.69175 12.9166C8.91037 12.9157 9.11987 12.8289 9.27509 12.675L13.4418 8.50829C13.5199 8.43082 13.5819 8.33866 13.6242 8.23711C13.6665 8.13556 13.6883 8.02664 13.6883 7.91663C13.6883 7.80662 13.6665 7.69769 13.6242 7.59615C13.5819 7.4946 13.5199 7.40243 13.4418 7.32496C13.2856 7.16975 13.0744 7.08263 12.8543 7.08263C12.6341 7.08263 12.4229 7.16975 12.2668 7.32496ZM10.0001 1.66663C8.35191 1.66663 6.74074 2.15537 5.37033 3.07105C3.99992 3.98672 2.93182 5.28821 2.30109 6.81093C1.67036 8.33365 1.50533 10.0092 1.82687 11.6257C2.14842 13.2422 2.94209 14.7271 4.10753 15.8925C5.27297 17.058 6.75782 17.8516 8.37433 18.1732C9.99084 18.4947 11.6664 18.3297 13.1891 17.699C14.7118 17.0682 16.0133 16.0001 16.929 14.6297C17.8447 13.2593 18.3334 11.6481 18.3334 9.99996C18.3334 8.90561 18.1179 7.82198 17.6991 6.81093C17.2803 5.79988 16.6665 4.88122 15.8926 4.1074C15.1188 3.33358 14.2002 2.71975 13.1891 2.30096C12.1781 1.88217 11.0944 1.66663 10.0001 1.66663ZM10.0001 16.6666C8.68154 16.6666 7.39261 16.2756 6.29628 15.5431C5.19996 14.8105 4.34547 13.7694 3.84089 12.5512C3.3363 11.333 3.20428 9.99256 3.46152 8.69936C3.71875 7.40615 4.35369 6.21826 5.28604 5.28591C6.21839 4.35356 7.40628 3.71863 8.69948 3.46139C9.99269 3.20416 11.3331 3.33618 12.5513 3.84076C13.7695 4.34535 14.8107 5.19983 15.5432 6.29616C16.2758 7.39249 16.6668 8.68142 16.6668 9.99996C16.6668 11.7681 15.9644 13.4638 14.7141 14.714C13.4639 15.9642 11.7682 16.6666 10.0001 16.6666Z'
                    fill='#00BF12'
                  />
                </svg>
              </>
            )}
          </DefaultModal.Step>
          <DefaultModal.Step step={2} stepActive={step === 2} stepDone={false}>
            {t(`lugarh.stepTwo`)}
          </DefaultModal.Step>
        </DefaultModal.Steps>
      </DefaultModal.Nav>
      <DefaultModal.Content>
        <EditUserForm
          onSubmit={handleFormSubmit}
          userToEdit={userToEdit}
          step={step}
          setStep={setStep}
          customClass='mui-modal__form'
          isLoading={loading}
        />
      </DefaultModal.Content>
    </DefaultModal>
  )
}
