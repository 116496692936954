import React, { useState, useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { PlansCard } from 'containers/Plans/PlansCard/PlansCard'
import ReactGA from 'utils/analytics'

import './plans.scss'

type Kind = 'login' | 'register' | 'plans'

export function PlansPage(): JSX.Element {
  const { t } = useTranslation('login')
  const [kind, setKind] = useState<Kind>('login')
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageView()
    if (location.pathname === '/plans') setKind('plans')
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('metatags.title')}</title>
        <meta name='description' content={t('metatags.description')} />
        <meta name='url' content={t('metatags.url')} />
        <meta property='og:title' content={t('metatags.title')} />
        <meta property='og:description' content={t('metatags.description')} />
        <meta property='og:url' content={t('metatags.url')} />
      </Helmet>

      <main>
        <div className='container h-100'>
          <div className='row h-100'>
            <div className='col-12 col-lg- d-flex justify-content-center align-items-center'>
              <PlansCard kind={kind} />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
