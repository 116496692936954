import isEqual from 'lodash/isEqual'

import ReactGA from 'utils/analytics'

const companySettingsAnalytics = (
  oldCompanyData: CompanyPayload | null,
  companyPayload: any
): void => {
  if (!oldCompanyData || !companyPayload) return

  if (
    oldCompanyData?.name !== companyPayload.name ||
    oldCompanyData?.fancyName !== companyPayload.fancy_name ||
    oldCompanyData?.slug !== companyPayload.slug ||
    oldCompanyData?.location !== companyPayload.location ||
    oldCompanyData?.cnpj !== companyPayload.cnpj
  ) {
    ReactGA.event('mandatory_info', {
      event_category: 'company_settings',
      event_label: 'mandatory_info'
    })
  }

  if (oldCompanyData?.description !== companyPayload.description) {
    ReactGA.event('description', {
      event_category: 'company_settings',
      event_label: 'description'
    })
  }

  const { buttonColor, buttonFontColor, fontColor, headerColor } =
    oldCompanyData?.careerPageSettings

  const { button_color, button_font_color, font_color, header_color } =
    companyPayload?.career_page_settings

  if (
    buttonColor !== button_color ||
    buttonFontColor !== button_font_color ||
    fontColor !== font_color ||
    headerColor !== header_color
  ) {
    ReactGA.event('personalization', {
      event_category: 'company_settings',
      event_label: 'personalization'
    })
  }

  if (
    !isEqual(
      oldCompanyData?.dataPrivacySettings,
      companyPayload.data_privacy_settings
    )
  ) {
    const {
      ask_telephone,
      required_telephone,
      ask_marital_status,
      required_marital_status,
      ask_sex,
      required_sex,
      ask_birthdate,
      required_birthdate,
      ask_wage_claim,
      required_wage_claim,
      ask_city_change_availability,
      required_city_change_availability,
      ask_linkedin_profile,
      required_linkedin_profile,
      ask_facebook_profile,
      required_facebook_profile,
      ask_twitter_profile,
      required_twitter_profile
    } = companyPayload.data_privacy_settings

    const {
      askTelephone,
      requiredTelephone,
      askMaritalStatus,
      requiredMaritalStatus,
      askSex,
      requiredSex,
      askBirthdate,
      requiredBirthdate,
      askWageClaim,
      requiredWageClaim,
      askCityChangeAvailability,
      requiredCityChangeAvailability,
      askLinkedinProfile,
      requiredLinkedinProfile,
      askFacebookProfile,
      requiredFacebookProfile,
      askTwitterProfile,
      requiredTwitterProfile
    } = oldCompanyData?.dataPrivacySettings

    if (ask_telephone !== askTelephone) {
      ReactGA.event('change_LGPD_ask_telephone', {
        event_category: 'company_settings',
        event_label: 'ask_telephone'
      })
    }

    if (required_telephone !== requiredTelephone) {
      ReactGA.event('change_LGPD_required_phone', {
        event_category: 'company_settings',
        event_label: 'required_phone'
      })
    }

    if (ask_marital_status !== askMaritalStatus) {
      ReactGA.event('change_LGPD_ask_marital_status', {
        event_category: 'company_settings',
        event_label: 'ask_marital_status'
      })
    }

    if (required_marital_status !== requiredMaritalStatus) {
      ReactGA.event('change_LGPD_required_marital_status', {
        event_category: 'company_settings',
        event_label: 'required_marital_status'
      })
    }

    if (ask_sex !== askSex) {
      ReactGA.event('change_LGPD_ask_sex', {
        event_category: 'company_settings',
        event_label: 'ask_sex'
      })
    }

    if (required_sex !== requiredSex) {
      ReactGA.event('change_LGPD_required_sex', {
        event_category: 'company_settings',
        event_label: 'required_sex'
      })
    }

    if (ask_birthdate !== askBirthdate) {
      ReactGA.event('change_LGPD_ask_birthdate', {
        event_category: 'company_settings',
        event_label: 'ask_birthdate'
      })
    }

    if (required_birthdate !== requiredBirthdate) {
      ReactGA.event('change_LGPD_required_birthdate', {
        event_category: 'company_settings',
        event_label: 'required_birthdate'
      })
    }

    if (ask_wage_claim !== askWageClaim) {
      ReactGA.event('change_LGPD_ask_salary_expectation', {
        event_category: 'company_settings',
        event_label: 'ask_salary_expectation'
      })
    }

    if (required_wage_claim !== requiredWageClaim) {
      ReactGA.event('change_LGPD_required_wage_claim', {
        event_category: 'company_settings',
        event_label: 'required_wage_claim'
      })
    }

    if (ask_city_change_availability !== askCityChangeAvailability) {
      ReactGA.event('change_LGPD_ask_realocation', {
        event_category: 'company_settings',
        event_label: 'ask_realocation'
      })
    }

    if (required_city_change_availability !== requiredCityChangeAvailability) {
      ReactGA.event('change_LGPD_required_city_change_availability', {
        event_category: 'company_settings',
        event_label: 'required_city_change_availability'
      })
    }

    if (ask_linkedin_profile !== askLinkedinProfile) {
      ReactGA.event('change_LGPD_ask_linkedin_profile', {
        event_category: 'company_settings',
        event_label: 'ask_linkedin_profile'
      })
    }

    if (required_linkedin_profile !== requiredLinkedinProfile) {
      ReactGA.event('change_LGPD_required_linkedin_profile', {
        event_category: 'company_settings',
        event_label: 'required_linkedin_profile'
      })
    }

    if (ask_facebook_profile !== askFacebookProfile) {
      ReactGA.event('change_LGPD_ask_facebook_profile', {
        event_category: 'company_settings',
        event_label: 'ask_facebook_profile'
      })
    }

    if (required_facebook_profile !== requiredFacebookProfile) {
      ReactGA.event('change_LGPD_required_facebook_profile', {
        event_category: 'company_settings',
        event_label: 'required_facebook_profile'
      })
    }

    if (ask_twitter_profile !== askTwitterProfile) {
      ReactGA.event('change_LGPD_ask_twitter_profile', {
        event_category: 'company_settings',
        event_label: 'ask_twitter_profile'
      })
    }

    if (required_twitter_profile !== requiredTwitterProfile) {
      ReactGA.event('change_LGPD_required_twitter_profile', {
        event_category: 'company_settings',
        event_label: 'required_twitter_profile'
      })
    }
  }
}

export default companySettingsAnalytics
