/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useState,
  useContext,
  SetStateAction,
  useCallback,
  PropsWithChildren
} from 'react'

import { FilterForm } from 'containers/CandidateFilter/CandidateFilterContainer'

// Define the initial state
export const initialFilterFormState: FilterForm = {
  general: {
    location: '',
    age: { min: '', max: '' },
    gender: '',
    civilStatus: '',
    salary: { min: '', max: '' },
    availableToMove: '',
    workspaces: ''
  },
  education: {
    name: '',
    course: '',
    level: ''
  },
  experience: {
    company: '',
    position: '',
    timeInYears: ''
  },
  language: {
    name: '',
    level: ''
  }
}

const FilterFormContext = createContext<{
  filterFormState: FilterForm
  updateFilterFormState: React.Dispatch<React.SetStateAction<FilterForm>>
  resetFilterFormState: () => void
}>({
  filterFormState: initialFilterFormState,
  updateFilterFormState: (newState: SetStateAction<FilterForm>) => {},
  resetFilterFormState: () => {}
})

interface FilterFormProviderProps {
  children: React.ReactNode
}

export const FilterFormProvider: React.FC<FilterFormProviderProps> = ({
  children
}: PropsWithChildren<FilterFormProviderProps>) => {
  const [filterFormState, setFilterFormState] = useState<FilterForm>(
    initialFilterFormState
  )

  const updateFilterFormState = useCallback(
    (newState: SetStateAction<FilterForm>) => {
      setFilterFormState(newState)
    },
    []
  )

  const resetFilterFormState = useCallback(() => {
    setFilterFormState(initialFilterFormState)
  }, [])

  return (
    <FilterFormContext.Provider
      value={{ filterFormState, updateFilterFormState, resetFilterFormState }}
    >
      {children}
    </FilterFormContext.Provider>
  )
}

// Custom hook to use the filter form state
export const useFilterForm = () => {
  const context = useContext(FilterFormContext)
  if (!context) {
    throw new Error('useFilterForm must be used within a FilterFormProvider')
  }
  return context
}
