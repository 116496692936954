import React, { useEffect, useState } from 'react'

import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import accounthub from 'assets/images/general/accounthub.png'
import overview from 'assets/images/general/overview.png'
import quests from 'assets/images/general/quests.png'
import { SectionBanner } from 'components/SectionBanner/SectionBanner'
import { recrudInfo } from 'services/api'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'

type Props = {
  section: 'whatsrecrud' | 'privacypolicy' | 'terms'
}

export const ContentContainer: React.FC<Props> = ({ section }) => {
  const { dispatch } = useStore()
  const [content, setContent] = useState<RecrudInfo | null>()
  const [icon, setIcon] = useState('')
  const [language, setLanguage] = useState('')
  const [iframeSrc, setIframeSrc] = useState('')
  const { t } = useTranslation('content')
  const [title, setTitle] = useState('')
  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    setSelectedContent()
  }, [section, language])

  const fetchContent = async (
    func: () => Promise<[APIError, RecrudInfo | null]>
  ) => {
    const [error, data] = await func()
    if (error) throw new Error(error.toString())
    setContent(data)
  }

  const setSelectedContent = async () => {
    try {
      if (section === 'whatsrecrud') {
        setIcon(overview)
        setTitle('whatsrecrud.title')
        language === 'pt-BR' && setIframeSrc('/html/OqueoRecrud.html')
        language === 'en-US' && setIframeSrc('/html/WhatisRecrud.html')
        language === 'es' && setIframeSrc('/html/QuesRecrudESP.html')
      } else if (section === 'privacypolicy') {
        setIcon(quests)
        setTitle('privacypolicy.title')
        language === 'pt-BR' &&
          setIframeSrc('/html/TERMOSDEUSOEPRIVACIDADERECRUD.html')
        language === 'en-US' &&
          setIframeSrc('/html/TERMSOFUSEANDPRIVACYROCKETMAT_RECRUD.html')
        language === 'es' &&
          setIframeSrc('/html/TRMINOSDEUSOYPRIVACIDAD_ROCKETMAT_RECRUD.html')
      } else if (section === 'terms') {
        setIcon(accounthub)
        setTitle('terms.title')
        language === 'pt-BR' &&
          setIframeSrc('/html/TERMOSDEUSOEPRIVACIDADERECRUD.html')
        language === 'en-US' &&
          setIframeSrc('/html/TERMSOFUSEANDPRIVACYROCKETMAT_RECRUD.html')
        language === 'es' &&
          setIframeSrc('/html/TRMINOSDEUSOYPRIVACIDAD_ROCKETMAT_RECRUD.html')
      }
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }
  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  return (
    <>
      <div className={'content-grid active mt-5 mt-lg-0'}>
        <SectionBanner
          title={t(title) || ''}
          text={''}
          icon={icon}
          alt={content?.title.replaceAll(' ', '') || 'section'}
        />
        <div className='grid grid-12 medium-space'>
          <div className='account-hub-content'>
            <div className='grid-column content'>
              <iframe
                src={iframeSrc}
                onLoad={e => {
                  const iframe: any = e.target
                  iframe.style.height =
                    iframe.contentWindow.document.body.scrollHeight + 'px'

                  iframe.contentWindow.document.body.style = 'margin: 0 auto'
                }}
                scrolling='no'
                seamless
                style={{ display: 'block', width: '100%', textAlign: 'center' }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
