import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'

import { FilterForm } from '../CandidateFilterContainer'

export interface ExperienceFilter {
  company: string
  position: string
  timeInYears: number
}

type Props = {
  onChange: (experienceFilter: ExperienceFilter) => void
  filter: FilterForm
}

export const ExperienceSection: React.FC<Props> = ({ onChange, filter }) => {
  const { t } = useTranslation('candidateFilter')
  // Experience
  const [company, setCompany] = useState(filter.experience?.company || '')
  const [position, setPosition] = useState(filter.experience?.position || '')
  const [duration, setDuration] = useState(filter.experience?.timeInYears || '')

  useEffect(() => {
    onChange({
      company,
      position,
      timeInYears: Number(duration) || 0
    } as ExperienceFilter)
  }, [company, position, duration])

  useEffect(() => {
    if (!Object.keys(filter).length) {
      setCompany('')
      setPosition('')
      setDuration('')
    }
  }, [filter])

  return (
    <>
      <p className='candidate-filter-section-title'>{t('experience.title')}</p>
      <FormRow>
        <FormItem>
          <Input
            type='text'
            label={t('experience.company')}
            name='experience-company'
            value={company}
            onChange={e => setCompany(e)}
            small
          />
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem formItemClasses='col-md-6 spaceUp'>
          <Input
            type='text'
            label={t('experience.position')}
            name='experience-position'
            value={position}
            onChange={e => setPosition(e)}
            small
          />
        </FormItem>
        <FormItem formItemClasses='col-md-6 spaceUp'>
          <Input
            type='number'
            label={t('experience.timeInYears')}
            name='experience-duration'
            value={duration}
            onChange={e => setDuration(e)}
            small
          />
        </FormItem>
      </FormRow>
    </>
  )
}
