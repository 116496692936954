import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import { WorkflowCardRefs } from 'components/WorkflowCard/WorkflowCard'
import { ListWorkflowsContainer } from 'containers/HiringWorkflow/ListWorkflowsContainer/ListWorkflowsContainer'
import { usePermission } from 'hooks/usePermission'
import useStore from 'store'
import ReactGA from 'utils/analytics'

export function HiringWorkflow(): JSX.Element {
  usePermission({ moduleName: 'hiring-workflow', action: 'view' })
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()

  const { t } = useTranslation('hiringWorkflow')
  const [refList, setRefList] = useState({})

  const setRefs = (value: WorkflowCardRefs) => {
    const obj: RefList = {
      1: { ref: value.editButton as React.MutableRefObject<HTMLElement> },
      2: {
        ref: value.deleteButton as React.MutableRefObject<HTMLElement>
      },
      3: {
        ref: value.createButton as React.MutableRefObject<HTMLElement>
      }
    }

    setRefList(obj)
  }

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      <Onboarding page='workflow' reflist={refList} />
      <div className='grid grid-12 medium-space'>
        <div className='account-hub-content'>
          <div className='grid-column'>
            <ListWorkflowsContainer getRefs={setRefs} />
          </div>
        </div>
      </div>
    </div>
  )
}
