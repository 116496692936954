import React, { useState } from 'react'

import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Button } from 'components/Button/Button'
import { CodeConfirmationModal } from 'components/CodeConfirmationModal/CodeConfirmationModal'
import { Form } from 'components/Form/Form'
import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { PasswordConfirmationModal } from 'components/PasswordConfirmationModal/PasswordConfirmationModal'
import { useReCAPTCHA } from 'hooks/useReCAPTCHA'
import { users, auth } from 'services/api'
import { TokenRequest } from 'services/api/auth'
import useStore from 'store'
import { setUser } from 'store/auth/actions'
import * as notificationActions from 'store/notification/actions'

interface editProfilePayload {
  uuid: string
  user: {
    firstname: string | undefined
    lastname: string | undefined
    email: string | undefined
    password?: string
  }
}

export const MyProfileFormContainer: React.FC = () => {
  const { getToken } = useReCAPTCHA()
  const { t } = useTranslation('myProfile')
  const { t: errorsTranslations } = useTranslation('errors')

  const { selectors, dispatch } = useStore()
  const user = selectors.auth.user()

  const [isLoading, setIsLoading] = useState(false)
  const [openPasswordConfirmationModal, setOpenPasswordConfirmationModal] =
    useState(false)
  const [openCodeConfirmationModal, setOpenCodeConfirmationModal] =
    useState(false)
  const [firstname, setFirstname] = useState(user?.firstname)
  const [lastname, setLastname] = useState(user?.lastname)
  const [email, setEmail] = useState(user?.email)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const editProfileSchema = Yup.object().shape({
    firstname: Yup.string().required(errorsTranslations('requiredField')),
    lastname: Yup.string().required(errorsTranslations('requiredField')),
    email: Yup.string()
      .email(errorsTranslations('emailInvalid'))
      .required(errorsTranslations('requiredField')),
    password: Yup.string().optional()
  })

  const checkPassword = async passwordConf => {
    setIsLoading(true)
    if (user?.email) {
      const token = await getToken()
      const payload: TokenRequest = {
        email: user.email,
        password: passwordConf,
        recaptcha: token
      }

      const [error] = await auth.token(payload, false)
      if (error) {
        showNotification({
          message: t('confirmationModal.wrongPassword'),
          type: 'error',
          timeout: 500
        })
      } else {
        const payload: editProfilePayload = {
          uuid: user?.uuid || '',
          user: {
            firstname,
            lastname,
            email
          }
        }
        if (password) {
          payload.user.password = password
        }

        const [errorEdit] = await users.edit(payload)
        if (errorEdit) {
          showNotification({
            message: t('confirmationModal.failToSend'),
            type: 'error'
          })
        } else {
          showNotification({
            message: t('confirmationModal.passwordConfirmationSucess'),
            type: 'success'
          })
          setOpenPasswordConfirmationModal(false)
          setTimeout(() => {
            setOpenCodeConfirmationModal(true)
          }, 400)
        }
      }
    } else {
      showNotification({
        message: t('confirmationModal.unavailable'),
        type: 'error'
      })
    }
    setIsLoading(false)
  }

  const checkEmailChangeCode = async code => {
    setIsLoading(true)
    const payload: editProfilePayload = {
      uuid: user?.uuid || '',
      user: {
        firstname,
        lastname,
        email
      }
    }
    if (password) {
      payload.user.password = password
    }

    const [error, data] = await users.edit(payload, {
      params: { email_change_code: code }
    })
    if (error) {
      showNotification({
        message: t('confirmationModal.wrongCode'),
        type: 'error',
        timeout: 500
      })
    } else {
      dispatch(setUser(data as User))
      showNotification({
        message: t('form.successEditMessage'),
        type: 'success'
      })
      setOpenCodeConfirmationModal(false)
    }
    setIsLoading(false)
  }

  return (
    <>
      <PasswordConfirmationModal
        open={openPasswordConfirmationModal}
        isLoading={isLoading}
        onClose={() => setOpenPasswordConfirmationModal(false)}
        onConfirm={checkPassword}
      />
      <CodeConfirmationModal
        open={openCodeConfirmationModal}
        isLoading={isLoading}
        onClose={() => setOpenCodeConfirmationModal(false)}
        onConfirm={checkEmailChangeCode}
      />
      <Formik
        initialValues={{
          firstname,
          lastname,
          email,
          password,
          repassword: ''
        }}
        validationSchema={editProfileSchema}
        onSubmit={async values => {
          try {
            setIsLoading(true)
            const payload: editProfilePayload = {
              uuid: user?.uuid || '',
              user: {
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email
              }
            }
            if (values.password) {
              payload.user.password = values.password
            }

            if (payload.user.email !== user?.email) {
              setOpenPasswordConfirmationModal(true)
              return
            }

            const [error, data] = await users.edit(payload)
            if (error) throw new Error(error.toString())
            if (data) {
              if (user?.currentWorkspace)
                data.currentWorkspace = user?.currentWorkspace
              dispatch(setUser(data as User))
              showNotification({
                message: t('form.successEditMessage'),
                type: 'success'
              })
            }
          } catch (error) {
            showNotification({ message: error.message, type: 'error' })
          } finally {
            setIsLoading(false)
          }
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          handleBlur,
          touched
        }) => (
          <Form id='my-profile-form' onSubmit={handleSubmit}>
            <FormRow>
              <FormItem formItemClasses='col-sm-6'>
                <Input
                  type='text'
                  label={t('form.firstname')}
                  name='firstname'
                  value={values.firstname}
                  onChange={value => {
                    setFirstname(value)
                    setFieldValue('firstname', value)
                  }}
                  onBlur={handleBlur}
                  error={touched.firstname ? errors.firstname : undefined}
                  small
                ></Input>
              </FormItem>

              <FormItem formItemClasses='col-sm-6 spaceUp'>
                <Input
                  type='text'
                  label={t('form.lastname')}
                  name='lastname'
                  value={values.lastname}
                  onChange={value => {
                    setLastname(value)
                    setFieldValue('lastname', value)
                  }}
                  onBlur={handleBlur}
                  error={touched.lastname ? errors.lastname : undefined}
                  small
                ></Input>
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem formItemClasses='spaceUp'>
                <Input
                  type='text'
                  label={t('form.email')}
                  name='email'
                  value={values.email}
                  onChange={value => {
                    setEmail(value)
                    setFieldValue('email', value)
                  }}
                  onBlur={handleBlur}
                  error={touched.email ? errors.email : undefined}
                  small
                  autoComplete={false}
                ></Input>
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem formItemClasses='col-sm-6 spaceUp'>
                <Input
                  type='password'
                  label={t('form.password')}
                  name='password'
                  value={values.password}
                  onChange={value => {
                    setPassword(value)
                    setFieldValue('password', value)
                  }}
                  onBlur={handleBlur}
                  error={touched.password ? errors.password : ''}
                  small
                  autoComplete={false}
                ></Input>
              </FormItem>

              <FormItem formItemClasses='col-sm-6 spaceUp'>
                <Input
                  type='password'
                  label={t('form.confirmPassword')}
                  name='repassword'
                  value={confirmPassword}
                  onChange={value => {
                    setConfirmPassword(value)
                    setFieldValue('repassword', value)
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.repassword && confirmPassword !== values.password
                      ? errorsTranslations('passwordConfirm')
                      : undefined
                  }
                  small
                  autoComplete={false}
                ></Input>
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem>
                <Button
                  className='primary-custom'
                  disabled={isLoading || password !== confirmPassword}
                  type='submit'
                >
                  {t('form.save')}
                </Button>
              </FormItem>
            </FormRow>
          </Form>
        )}
      </Formik>
    </>
  )
}
