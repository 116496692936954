import React, { useState, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as MenuIcon } from 'assets/images/icons/menu_24px.svg'
import { ReactComponent as Logo } from 'assets/images/logo/lugarh-new-brand.svg'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import * as notificationActions from 'store/notification/actions'
import { CheckCallbackUrl } from 'utils/partnerCallbackUrl'

import { AsideMenu } from '../AsideMenu/AsideMenu'
import { HeaderSettings } from '../HeaderSettings/HeaderSettings'
import { SearchBar } from '../SearchBar/SearchBar'

import './Header.scss'
import { Avatar } from '@mui/material'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

export const Header: React.FC = () => {
  const headerSettingsRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation('user')

  const { dispatch, selectors } = useStore()
  const user = selectors.auth.user()
  const fullName = selectors.auth.fullName()
  const firstLetter = fullName.charAt(0)
  const role = t(`role.${user?.role}`)

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const logoff = () => {
    selectors.auth.clearUser()

    dispatch(authActions.logoff())

    if (CheckCallbackUrl()) {
      window.localStorage.setItem('removeCallbackUrl', 'true')
    } else {
      document.location.reload()
    }
  }

  const CustomTheme = React.lazy(() =>
    import('../CustomTheme/CustomTheme').then(({ CustomTheme }) => ({
      default: CustomTheme
    }))
  )

  const ThemeSelector = ({ children }) => {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {user?.themeCustomization && <CustomTheme />}
        </React.Suspense>
        {children}
      </>
    )
  }

  const generateReferralLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/signup?associate=${user?.uuid}`
    )

    showNotification({
      message: t('copyLinkSuccessMessage'),
      type: 'success'
    })
  }

  return (
    <>
      <ThemeSelector>
        <header
          style={{ backgroundColor: '#fff' }}
          // style={
          //   !user?.themeCustomization?.secondaryColor
          //     ? { backgroundColor: user?.themeCustomization?.secondaryColor }
          //     : { backgroundColor: '#fff' }
          // }
          className='header'
          data-testid='recrud-header'
        >
          <div className='header-actions'>
            <div className='header-brand'>
              <div className='logo'>
                <a href='' onClick={() => navigate('/dashboard')}>
                  <Logo />
                  {/* {!user?.themeCustomization?.logo ? (
                    <img
                      style={{ maxWidth: '160px', maxHeight: '40px' }}
                      src={user?.themeCustomization?.logo}
                      alt='Recrud Logo'
                    />
                  ) : ( */}
                </a>
              </div>
            </div>
          </div>
          <div className='header-actions' onClick={() => setOpen(!open)}>
            <div className='mobilemenu-trigger navigation-widget-mobile-trigger'>
              <div className='burger-icon inverted'>
                <div className='burger-icon-bar'></div>
                <div className='burger-icon-bar'></div>
                <div className='burger-icon-bar'></div>
              </div>
            </div>
          </div>

          <SearchBar />

          {/* <!-- HEADER ACTIONS --> */}
          <div className='header-actions'>
            <section className='headers_options'>
              <PermissionAgent
                module='indicate-button'
                actions={['view']}
                onFail='hidden'
                Component={() => (
                  <div className='indicate-btn'>
                    <a onClick={() => generateReferralLink()}>
                      {t('indicationButton')}
                    </a>
                  </div>
                )}
              />
              <div className='action-list dark tutorial-step-5'>
                <div
                  className='action-list-item-wrap d-flex'
                  style={{ position: 'relative', alignItems: 'center' }}
                >
                  <div ref={headerSettingsRef}>
                    <div
                      className='header-avatar-container'
                      onClick={() => setOpen(!open)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        className={`header-avatar-container__avatar header-avatar-container__avatar--${user?.role}`}
                      >
                        <Avatar>{firstLetter}</Avatar>
                      </div>
                      <div className='header-avatar-container__info'>
                        <span className='header-avatar-container__info-name default-font'>
                          {fullName}
                        </span>
                        <span className='header-avatar-container__info-company default-font'>
                          {user?.currentWorkspace?.name}
                        </span>
                      </div>
                      <div className='header-avatar-container__icon'>
                        <KeyboardArrowDownRoundedIcon />
                      </div>
                    </div>
                    <HeaderSettings
                      logoff={logoff}
                      fullName={fullName}
                      role={role}
                      initialState={open}
                      wrapperRef={headerSettingsRef}
                      onChange={() => setOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </header>
        <span>{t('here')}</span>
        <AsideMenu
          fullName={fullName}
          role={role}
          logoff={logoff}
          open={open}
          onChange={() => setOpen(false)}
          returnIconButton={
            user?.themeCustomization ? user?.themeCustomization.icon : null
          }
        />
      </ThemeSelector>
    </>
  )
}
