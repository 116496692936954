interface FormatedDate {
  day: number
  month: string
  year: number
  format: string
}

export const getFormatedDate = (date: Date): FormatedDate => {
  const month = date
    .toLocaleString('default', { month: 'short' })
    .replace('.', '')

  const day = date.getDate()
  const year = date.getFullYear()

  return {
    day,
    month,
    year,
    format: `${day} ${month} ${year}`
  }
}
