import React from 'react'

import { Switch } from 'components/Switch/Switch'

import './CareerSettingsButton.scss'

export interface CarrerSettingsButtonProps {
  title: string
  text: string
  label: string
  initialValue: boolean
  questionKey: string
  onChange: (key: string, value: boolean) => void
}

export const CarrerSettingsButton: React.FC<CarrerSettingsButtonProps> = ({
  title,
  text,
  label,
  initialValue,
  questionKey,
  onChange
}) => {
  return (
    <div className='career-switch-option'>
      <div className='career-description-area'>
        <p className='career-title'>{title}</p>
        <p className='career-text'>{text}</p>
      </div>
      <div className='career-switch-area'>
        <div className='career-form-switch'>
          <div className='career-form-switch-area'>
            <label className='career-form-switch-label'>{label}</label>
            <div className='career-switch-button'>
              <Switch
                initialValue={initialValue}
                onChange={value => onChange(questionKey, value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
