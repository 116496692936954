import React, { createContext, useContext, useEffect, useRef } from 'react'

import { useReducer } from 'reinspect'

import { persistState } from './persistState'
import { initialState, Reducer } from './rootReducer'
import { selectors as Selectors } from './rootSelectors'
import { StoreTypes } from './types'
import withThunk from './withThunk'

const StoreContext = createContext<StoreTypes.ContextType>({
  state: initialState
} as StoreTypes.ContextType)

export const StoreProvider: React.FC<StoreTypes.Props> = ({
  children
}: StoreTypes.Props): JSX.Element => {
  const oldPersistentState = useRef({
    auth: initialState.auth
  })

  const [state, dispatch] = useReducer(
    Reducer,
    initialState,
    state => state,
    'store'
  )
  async function saveAuthState() {
    try {
      if (state && state.auth) {
        await persistState('auth', state.auth)
      } else {
        throw new Error('State or auth property is null or undefined')
      }
    } catch (error) {
      console.error('Error occurred while saving auth state:', error)
      throw error
    }
  }

  useEffect(() => {
    const thereDiff =
      JSON.stringify(oldPersistentState.current.auth) !==
      JSON.stringify(state.auth)

    if (thereDiff) {
      oldPersistentState.current = { auth: state.auth }
      saveAuthState()
    }
  }, [state])

  return (
    <StoreContext.Provider value={{ state, dispatch: withThunk(dispatch) }}>
      {children}
    </StoreContext.Provider>
  )
}

const useStore = (): StoreTypes.useStore => {
  const { state, dispatch } = useContext(StoreContext)
  const selectors = Selectors(state)

  return { state, dispatch, selectors }
}

export default useStore
