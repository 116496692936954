import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface CandidatesByGenderAndAgeProps {
  t: any
  loading: boolean
  dataPrivacySettings: any
}

const CandidatesByGenderAndAge = ({
  t,
  loading,
  dataPrivacySettings
}: CandidatesByGenderAndAgeProps) => {
  const { candidatesAge, candidatesGender } = useDashboardContext()
  return (
    <div className='row justify-content-around'>
      {dataPrivacySettings?.ask_birthdate && (
        <div className='col-12 col-md-6'>
          {loading && !hasContent(candidatesAge) ? (
            <GraphicSkeleton isRouded />
          ) : (
            <DashboadCard title={t('chart5')}>
              <DashboardChart
                id='age-chart'
                visible={hasContent(candidatesAge)}
              />
            </DashboadCard>
          )}
        </div>
      )}

      {dataPrivacySettings?.ask_sex && (
        <div className='col-12 col-md-6'>
          {loading && !hasContent(candidatesGender) ? (
            <GraphicSkeleton isRouded />
          ) : (
            <DashboadCard title={t('chart6')}>
              <DashboardChart
                id='sex-chart'
                visible={hasContent(candidatesGender)}
              />
            </DashboadCard>
          )}
        </div>
      )}
    </div>
  )
}

export default CandidatesByGenderAndAge
