import * as React from 'react'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

interface DefaultProps {
  children: React.ReactNode
  customClass?: string
}

export enum DefaultModalTemplate {
  DEFAULT = 'default',
  RIGHT = 'right'
}

interface DefaultModalProps extends DefaultProps {
  open: boolean
  onClose: () => void
  modalTitle?: string
  modalSubtitle?: string
  template?: DefaultModalTemplate
}

interface StepProps extends DefaultProps {
  step: number
  stepActive: boolean
  stepDone: boolean
  onStepChange?: (step: number) => void
  clickable?: boolean
}

const Step: React.FC<StepProps> = ({
  children,
  step,
  stepActive,
  stepDone,
  onStepChange,
  clickable = false,
  customClass = ''
}) => {
  return (
    <li
      className={`mui-modal__step ${customClass}${
        stepActive ? ' mui-modal__step--active' : ''
      }${stepDone ? ' mui-modal__step--done' : ''}${
        clickable && stepDone ? ' mui-modal__step--clickable' : ''
      }`}
      {...(clickable && onStepChange && { onClick: () => onStepChange(step) })}
    >
      {children}
    </li>
  )
}

const Steps: React.FC<DefaultProps> = ({ children, customClass = '' }) => {
  return <div className={`mui-modal__steps ${customClass}`}>{children}</div>
}

const Nav: React.FC<DefaultProps> = ({ children, customClass = '' }) => {
  return <div className={`mui-modal__nav ${customClass}`}>{children}</div>
}

const Content: React.FC<DefaultProps> = ({ children, customClass = '' }) => {
  return <div className={`mui-modal__content ${customClass}`}>{children}</div>
}

const DefaultModal: React.FC<DefaultModalProps> & {
  Nav: React.FC<DefaultProps>
  Steps: React.FC<DefaultProps>
  Step: React.FC<StepProps>
  Content: React.FC<DefaultProps>
} = ({
  open,
  onClose,
  customClass = '',
  children,
  modalTitle,
  modalSubtitle,
  template = DefaultModalTemplate.DEFAULT
}) => {
  const templateClass =
    template !== DefaultModalTemplate.DEFAULT ? `mui-modal--${template}` : ''

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={`theme-lugarh mui-modal ${customClass} ${templateClass}`}
    >
      <Box className='mui-modal__box'>
        {modalTitle && (
          <div className='mui-modal__header'>
            <h2 className='mui-modal__title default-font'>{modalTitle}</h2>
            {modalSubtitle && (
              <p className='mui-modal__subtitle'>{modalSubtitle}</p>
            )}
          </div>
        )}
        {children}
        <div className='mui-modal__close-btn' onClick={onClose}>
          <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13.2997 0.710215C12.9097 0.320215 12.2797 0.320215 11.8897 0.710215L6.99973 5.59022L2.10973 0.700215C1.71973 0.310215 1.08973 0.310215 0.699727 0.700215C0.309727 1.09021 0.309727 1.72022 0.699727 2.11022L5.58973 7.00022L0.699727 11.8902C0.309727 12.2802 0.309727 12.9102 0.699727 13.3002C1.08973 13.6902 1.71973 13.6902 2.10973 13.3002L6.99973 8.41021L11.8897 13.3002C12.2797 13.6902 12.9097 13.6902 13.2997 13.3002C13.6897 12.9102 13.6897 12.2802 13.2997 11.8902L8.40973 7.00022L13.2997 2.11022C13.6797 1.73022 13.6797 1.09022 13.2997 0.710215Z'
              fill='#52443B'
            />
          </svg>
        </div>
      </Box>
    </Modal>
  )
}

DefaultModal.Nav = Nav
DefaultModal.Steps = Steps
DefaultModal.Step = Step
DefaultModal.Content = Content

export { DefaultModal }
