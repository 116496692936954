import React, { useMemo, useRef } from 'react'

import JoditEditor from 'jodit-react'

import styles from './RichTextEditor.module.scss'
import Error from '../Error/Error'
import './RichTextEditorApplication.scss'

export interface RichTextEditorProps {
  value: string
  placeholder: string
  required?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
  error?: string
  height?: number
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  placeholder,
  required,
  onChange,
  onBlur,
  error,
  height
}) => {
  const editor = useRef(null)
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || 'Start typings...',
      language: 'pt_br',
      toolbarSticky: false,
      showCharsCounter: false,
      showWordsCounter: false,
      defaultActionOnPaste: 'insert_only_text',
      defaultActionOnPasteFromWord: 'insert_only_text',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      buttons:
        'bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,superscript,subscript,classSpan,spellcheck,cut,copy,paste,selectall'
    }),
    [placeholder]
  )
  return (
    <>
      <div className={styles.label}>
        {placeholder}
        {required ? (
          <span style={{ color: 'red', margin: '0px 3px' }}>*</span>
        ) : (
          ''
        )}
      </div>
      <div>
        <JoditEditor
          ref={editor}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          config={config}
        />
      </div>
      <div>
        <Error error={error} />
      </div>
    </>
  )
}
