import * as AuthTypes from './types'

export function Selector(state: AuthTypes.State): AuthTypes.Selector {
  function isLoading(): boolean {
    return state.loading
  }

  function hasLoginError(): string | null {
    return state.loginError
  }

  function hasRegisterError(): string | null {
    return state.registerError
  }

  function logged(): boolean {
    return !!state.token
  }

  function user(): User | null {
    return state.user
  }

  function fullName(): string {
    return `${state.user?.firstname} ${state.user?.lastname}`
  }

  function clearUser(): void {
    state.user = null
    window.localStorage.clear()
    window.localStorage.removeItem('auth')
    state.loading = false
  }

  function keepConnected(): boolean {
    return state.keepConnected
  }

  function refreshToken(): string | null {
    return state.refreshToken
  }

  function expiresIn(): number | null {
    return state.expiresIn
  }

  function companyId(): string {
    return state.user?.company?.uuid as string
  }

  function companySlug(): string {
    return state.user?.company?.slug as string
  }

  function hasCompany(): boolean {
    return !!state.user?.company?.uuid
  }

  return {
    isLoading,
    hasLoginError,
    hasRegisterError,
    logged,
    user,
    fullName,
    clearUser,
    keepConnected,
    refreshToken,
    expiresIn,
    companyId,
    hasCompany,
    companySlug
  }
}
