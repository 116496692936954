import React from 'react'
import './Loading.scss'

export const Loading: React.FC<{ className?: string }> = ({ className }) => (
  <div className={`loader-bars ${className}`}>
    <div className='bar'></div>
    <div className='bar'></div>
    <div className='bar'></div>
    <div className='bar'></div>
    <div className='bar'></div>
    <div className='bar'></div>
    <div className='bar'></div>
    <div className='bar'></div>
  </div>
)
