import React, { ReactNode } from 'react'

import './Input.scss'
import classNames from 'classnames'
import i18n from 'i18next'
import CurrencyInput from 'react-currency-input-field'
import InputMask from 'react-input-mask'

import Error from '../Error/Error'

export interface InputProps {
  label: string
  required?: boolean
  type: 'text' | 'password' | 'number' | 'money'
  mask?: string
  maskChar?: string | null
  small?: boolean
  full?: boolean
  name: string
  onChange: (value: string) => void
  onKeyDown?: (value: React.KeyboardEvent) => void
  value?: string | number | readonly string[]
  error?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  fixedValue?: string | Element | ReactNode
  inputRef?: React.RefObject<HTMLInputElement>
  autoComplete?: boolean
  step?: string
  pattern?: string
  disabled?: boolean
  withFocus?: boolean
}

export const Input: React.FC<InputProps> = ({
  label,
  required,
  small,
  full,
  name,
  onChange,
  onKeyDown,
  type,
  mask,
  maskChar,
  value,
  error,
  onBlur,
  onFocus,
  fixedValue,
  inputRef,
  autoComplete,
  step,
  disabled,
  withFocus
}) => {
  const [active, setActive] = React.useState(false)
  return (
    <div
      className={classNames('form-input', {
        active: withFocus || active || !!value,
        small,
        full,
        'with-fixed-value': !!fixedValue
      })}
      data-testid='input-container'
    >
      <label htmlFor={name} data-testid='input-label'>
        {label}
        {required ? (
          <span style={{ color: 'red', margin: '0px 3px' }}>*</span>
        ) : (
          ''
        )}
      </label>

      {type === 'money' ? (
        <CurrencyInput
          data-testid='input'
          name={name}
          value={value}
          onValueChange={(formattedValue = '') => {
            let value = formattedValue
            if (i18n.language !== 'en-US') {
              value = formattedValue.replace('.', '').replace(',', '.')
            }
            onChange(value)
          }}
          onFocus={() => setActive(true)}
          onBlur={event => {
            setActive((!event.target.value && !!value) || false)
            onBlur?.(event)
          }}
          disabled={disabled}
          onKeyDown={event => onKeyDown && onKeyDown(event)}
          intlConfig={{
            locale: i18n.language
          }}
          decimalScale={2}
          step={100}
        />
      ) : (
        <InputMask
          type={type}
          inputRef={ref => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (inputRef) inputRef.current = ref
          }}
          name={name}
          value={value}
          mask={mask || ''}
          maskChar={maskChar}
          onChange={event => onChange(event.target.value)}
          onFocus={event => {
            setActive(true)
            onFocus?.(event)
          }}
          onBlur={event => {
            setActive((!event.target.value && !!value) || false)
            onBlur?.(event)
          }}
          data-testid='input'
          onKeyDown={event => onKeyDown && onKeyDown(event)}
          autoComplete={!autoComplete ? 'new-password' : ''}
          role='new-password'
          step={step}
          disabled={disabled}
          {...(!autoComplete && {
            autoCapitalize: 'off',
            autoCorrect: 'off',
            spellCheck: 'false'
          })}
        />
      )}
      {fixedValue ? <p className='fixed-value'>{fixedValue}</p> : null}

      <Error error={error} />
    </div>
  )
}
