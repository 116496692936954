import React from 'react'

import '../Input/Input.scss'
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'

import Error from '../Error/Error'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePickerInput.scss'

export interface DatePickerProps {
  label: string
  required?: boolean
  small?: boolean
  full?: boolean
  showMonthYearPicker?: boolean
  dateFormatType?: string
  name: string
  onChange: (value: any) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  value?: string | null | undefined
  error?: string
}

export type DateType = {
  date: Date | [Date, Date] | null
}

export const DatePickerInput: React.FC<DatePickerProps> = ({
  label,
  required,
  small,
  full,
  showMonthYearPicker,
  dateFormatType,
  name,
  onChange,
  onBlur,
  value,
  error
}) => {
  const handleInputMaskChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target
    const cleanValue = value.replace(/_/g, '')
    if (cleanValue.length <= 10) {
      onChange(cleanValue)
    }
  }

  return (
    <div
      className={classNames('form-input', {
        active: true,
        small,
        full
      })}
      data-testid='input-container'
    >
      <label
        className='date-picker-label active'
        htmlFor={name}
        data-testid='input-label'
      >
        {label}
        {required ? (
          <span style={{ color: 'red', margin: '0px 3px' }}>*</span>
        ) : (
          ''
        )}
      </label>
      <ReactInputMask
        mask='99/99/9999'
        maskChar='_'
        alwaysShowMask={true}
        className='border w-100 pl-2 rounded'
        onChange={event => handleInputMaskChange(event)}
        onBlur={event => {
          onBlur?.(event)
        }}
        value={value || ''}
      />
      <Error error={error} />
    </div>
  )
}
