import React from 'react'

import { useFormik } from 'formik'
import * as queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import RocketIMG from 'assets/images/logo/rocket.png'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { resetPassword } from 'services/api/auth'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'

import '../Login/LoginCard/LoginCard.scss'

export const ResetPassword: React.FC = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const values = queryString.parse(search)
  const { t } = useTranslation('changePassword')
  const { t: terrors } = useTranslation('errors')
  const { dispatch } = useStore()

  const formik = useFormik({
    onSubmit: async () => {
      await formik.validateForm()
      if (formik.isValid) changePassword(formik.values.newPassword)
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required(terrors('requiredField'))
        .min(6, terrors('passwordMin')),
      confirmNewPassword: Yup.string()
        .required(terrors('requiredField'))
        .oneOf([Yup.ref('newPassword')], terrors('passwordConfirm'))
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    }
  })

  const changePassword = async (newPassword: string) => {
    const [error] = await resetPassword({
      newPassword,
      code: values.code
    })

    if (error) {
      dispatch(
        notificationActions.notify({
          message: t('changePasswordFail'),
          type: 'error'
        })
      )
    } else {
      navigate('/')
      dispatch(
        notificationActions.notify({
          message: t('changePasswordSuccess'),
          type: 'success'
        })
      )
    }
  }

  return (
    <div className='card active'>
      <img src={RocketIMG} alt='rocket' />
      <h2>{t('changePassword')}</h2>

      <form onSubmit={e => e.preventDefault()} className='form'>
        <Input
          label={t('password')}
          type='password'
          name='password'
          value={formik.values.newPassword}
          onChange={e => formik.setFieldValue('newPassword', e)}
          error={formik.errors.newPassword}
        />

        <Input
          label={t('passwordConfirmation')}
          type='password'
          name='passwordConfirmation'
          value={formik.values.confirmNewPassword}
          onChange={e => formik.setFieldValue('confirmNewPassword', e)}
          error={formik.errors.confirmNewPassword}
        />

        <Button
          disabled={!values.code}
          onClick={() => {
            formik.submitForm()
          }}
          className='secondary'
        >
          {t('changePassword')}
        </Button>
      </form>
    </div>
  )
}
