import React, { useEffect } from 'react'

import { DashboardContainer } from 'containers/Dashboard/lugarh/DashboardContainer'
import ReactGA from 'utils/analytics'

export function Dashboard(): JSX.Element {
  useEffect(() => {
    ReactGA.pageView()
  }, [])
  return (
    <>
      <DashboardContainer />
    </>
  )
}
