import React, { useState } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CandidateDetailIcon } from 'assets/images/icons/candidate-detail-icon.svg'
import { ReactComponent as CandidatePreviewIcon } from 'assets/images/icons/candidate-preview-icon.svg'
import { ReactComponent as MembersIcon } from 'assets/images/icons/members.svg'

import './CandidateList.scss'

import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { Select } from 'components/Select/Select'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { getDateFormat } from 'utils/date'

type Props = {
  name: string
  date: Date
  review: number
  selected?: boolean
  isDbCandidate?: boolean
  onJobApply?: () => void
  onSelected?: (value: boolean) => void
  onShowPreview: () => void
  onShowDetail: () => void
  onMoveToStep: (steps: string) => void
  stages: Array<WorkflowStageSchema>
}

export const CandidateList: React.FC<Props> = ({
  name,
  date,
  review,
  selected,
  onSelected,
  isDbCandidate,
  onMoveToStep,
  onJobApply,
  onShowDetail,
  onShowPreview,
  stages
}) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const [stage, setStage] = useState('')
  const { t } = useTranslation('candidateList')
  const [tworkflows] = useTranslation('defaultWorkflowStages')

  return (
    <div className='candidate-list-wrapper'>
      {dontShowOnGeralWorkspace && !isDbCandidate && (
        <div className='checkbox'>
          <Checkbox
            checkIcon
            name='select-candidate'
            checked={selected}
            onChange={e => onSelected && onSelected(e)}
          />
        </div>
      )}
      <div className='demo-box-icon-wrap'>
        <MembersIcon className='icon-add-friend demo-box-icon' />
      </div>
      <div className='row w-100 d-flex align-items-center'>
        <div className='col-6 col-sm-4 col-lg-7 mt-3 mb-3 d-flex'>
          <div className='candidate-list-info'>
            <p className='candidate-name'>{name}</p>
            <p className='date'>
              {moment(date, getDateFormat()).format(getDateFormat())}
            </p>
          </div>
        </div>

        <div className='col-6 col-sm-4 col-lg-2 d-flex align-items-end justify-content-end mt-3 mb-3'>
          <div className='col flex-column d-flex align-items-end justify-content-end'>
            <Button onClick={onShowPreview}>
              <CandidatePreviewIcon />
            </Button>
            <p className='action-text'>{t('preview')}</p>
          </div>

          <div className='flex-column d-flex align-items-end justify-content-end'>
            <Button onClick={onShowDetail}>
              <CandidateDetailIcon />
            </Button>
            <p className='action-text'>{t('detail')}</p>
          </div>
        </div>
        {isDbCandidate ? (
          <>
            {dontShowOnGeralWorkspace && (
              <PermissionAgent
                module='job-application'
                actions={['edit']}
                onFail='disabled'
                Component={({ disabled }) => (
                  <div className='col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-end mt-3 mb-3'>
                    <Button
                      id='job-apply-button'
                      onClick={onJobApply}
                      disabled={disabled}
                      className='button-list void candidate-new'
                    >
                      {t('apply')}
                    </Button>
                  </div>
                )}
              />
            )}
          </>
        ) : (
          <>
            {dontShowOnGeralWorkspace && (
              <PermissionAgent
                module='job-application'
                actions={['edit']}
                onFail='hidden'
                Component={({ hidden }) =>
                  !hidden && (
                    <div className='col-6 col-sm-4 col-lg-3 d-flex align-items-center mt-3 mb-3'>
                      <Select
                        label={t('select')}
                        value={stage}
                        onChange={e => {
                          setStage(e.target.value)
                          onMoveToStep(e.target.value)
                        }}
                      >
                        <option value=''></option>

                        {stages?.map(stage => {
                          return (
                            <option value={stage.uuid} key={stage.uuid}>
                              {tworkflows(stage.name)}
                            </option>
                          )
                        })}
                      </Select>
                    </div>
                  )
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
