import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface CandidatesApplicationsProps {
  t: any
  loading: boolean
}

const CandidatesApplications = ({
  t,
  loading
}: CandidatesApplicationsProps) => {
  const { lastThirtyDays } = useDashboardContext()
  return (
    <div className='row'>
      <div className='col-12'>
        {loading && !hasContent(lastThirtyDays) ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard title={t('chart4')}>
            <DashboardChart
              id='last-thirty-days-chart'
              visible={hasContent(lastThirtyDays)}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default CandidatesApplications
