import React, { HTMLAttributes, useEffect, useRef } from 'react'

import './TagList.scss'

export type Props = {
  onClick: (tag: string) => void
  tags?: Array<string>
  isDismissible?: boolean
  showCount?: boolean
}

export const TagList: React.FC<Props> = ({
  onClick,
  tags = [],
  isDismissible = false,
  showCount = true
}) => {
  const [count, setCount] = React.useState(10)
  const [showAll, setShowAll] = React.useState(false)

  const moreTagsRef = useRef<HTMLDivElement | null>(null)
  const allTagsRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (tags?.length > 4) {
      setCount(tags.length - 3)
    } else {
      setCount(0)
    }
  }, [tags])

  if (!showCount) {
    return (
      <div
        className='tag-list'
        data-testid='tag-list'
        style={{ flexWrap: 'wrap', rowGap: '0px' }}
      >
        {tags?.length > 0 &&
          tags.map((tag: string, index: number) => {
            return (
              <div key={tag} className='tag-item' data-testid='tag-item'>
                {isDismissible ? (
                  <>
                    <p className='tag-item-button' onClick={() => onClick(tag)}>
                      x
                    </p>
                    <p className='tag-item-recruiter tag-item-right'>{tag}</p>
                  </>
                ) : (
                  <>
                    <p className='tag-item-recruiter'>{tag}</p>
                  </>
                )}
              </div>
            )
          })}
      </div>
    )
  }

  return (
    <>
      <div className='tag-list' data-testid='tag-list'>
        {tags?.length > 0 &&
          tags.map((tag: string, index: number) => {
            if (index < 3) {
              return (
                <div key={tag} className='tag-item' data-testid='tag-item'>
                  {isDismissible ? (
                    <>
                      <p
                        className='tag-item-button'
                        onClick={() => onClick(tag)}
                      >
                        x
                      </p>
                      <p className='tag-item-recruiter'>{tag}</p>
                    </>
                  ) : (
                    <>
                      <p className='tag-item-recruiter'>{tag}</p>
                    </>
                  )}
                </div>
              )
            }

            return null
          })}

        {count > 0 && (
          <div
            className='tag-item'
            data-testid='tag-item'
            onMouseEnter={() => setShowAll(true)}
            onMouseLeave={() => setShowAll(false)}
            ref={moreTagsRef}
          >
            <p className='tag-item-recruiter-count'>+{count}</p>
            {showAll && (
              <div className='tag-list-hidden' ref={allTagsRef}>
                {tags.map(tag => (
                  <span key={tag}>{tag}</span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
