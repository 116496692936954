import React, { useState } from 'react'

import './Checkbox.scss'
import classNames from 'classnames'

import { ReactComponent as CheckIcon } from 'assets/images/icons/check-icon.svg'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'

export interface CheckboxProps {
  label?: string
  name: string
  small?: boolean
  checked?: boolean
  onChange: (value: boolean) => void
  checkIcon?: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label = '',
  small,
  name,
  checked,
  onChange,
  checkIcon = false
}) => {
  const [value, setValue] = useState(checked)

  return (
    <div
      className={classNames('checkbox-wrap', { small })}
      data-testid='checkbox-wrap'
      onClick={() => {
        onChange(!value)
        setValue(!value)
      }}
    >
      <input
        type='checkbox'
        name={name}
        checked={value}
        onChange={() => {
          onChange(!value)
          setValue(!value)
        }}
        data-testid='checkbox-input'
      />
      <div
        className={`checkbox-box ${checkIcon ? 'check' : ''}`}
        data-testid='checkbox-box'
      >
        {!checkIcon && (
          <CrossIcon className='icon-cross' data-testid='icon-cross' />
        )}
        {checkIcon && value && (
          <CheckIcon className='icon-check' data-testid='icon-check' />
        )}
      </div>

      <label htmlFor={name} data-testid='checkbox-label'>
        {label}
      </label>
    </div>
  )
}
