import React, { useEffect, useRef, useState } from 'react'

import { createPopper } from '@popperjs/core'
import { useTranslation } from 'react-i18next'

import './Onboarding.scss'
import { Button } from 'components/Button/Button'
import useStore from 'store'
import { OnboardingHelperActions } from 'store/onboardingHelper'
import ReactGA, { returnGACategoryByPage } from 'utils/analytics'

export type RefList = {
  [key in string]: {
    ref: React.MutableRefObject<HTMLElement>
    position?:
      | 'top'
      | 'top-start'
      | 'top-end'
      | 'bottom'
      | 'bottom-start'
      | 'bottom-end'
      | 'left'
      | 'left-start'
      | 'left-end'
      | 'right'
      | 'right-start'
      | 'right-end'
  }
}

type Props = {
  initialStep?: number
  reflist: RefList
  page:
    | 'dashboard'
    | 'jobs'
    | 'cadidatesDB'
    | 'candidateDetail'
    | 'candidates'
    | 'jobsEdit'
    | 'companySettings'
    | 'companyTeam'
    | 'workflow'
    | 'workflowEdit'
}

type onboardingContent = {
  title: string
  text: string
}

export const Onboarding: React.FC<Props> = ({ initialStep, page, reflist }) => {
  const [step, setStep] = useState(initialStep ?? 0)
  const modalRef = useRef<HTMLDivElement>(null)
  const [disable, setDisable] = useState(true)
  const { dispatch } = useStore()
  const onboardingDone = JSON.parse(localStorage.getItem('onboarding') || '{}')[
    JSON.parse(localStorage.getItem('auth') || '{}')?.user.uuid
  ]?.[page]

  const incrementStep = tutorialLength => () => {
    setStep(step + 1)

    const GACategory = returnGACategoryByPage(page)
    if (step + 1 === tutorialLength) {
      ReactGA.event(`finish_tutorial_${GACategory}`, {
        event_category: GACategory,
        event_label: `finish_tutorial_${GACategory}`
      })
    } else {
      ReactGA.event(`next_tutorial_${GACategory}`, {
        event_category: GACategory,
        event_label: `next_tutorial_${GACategory}`
      })
    }
  }
  const decrementStep = () => setStep(step > 0 ? step - 1 : step)

  const { t } = useTranslation('onboarding')
  const onboardingData = t(`${page}.content`, {
    returnObjects: true
  }) as Array<onboardingContent>

  function handleClick(e: any) {
    if (
      e.target?.className !== 'btn-default finish-onboarding-button' &&
      e.target?.className !== 'btn-default back-onboarding-button' &&
      e.target?.className !== 'btn-gray skip-onboarding-button'
    ) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  let instance

  useEffect(() => {
    if (disable) {
      window.addEventListener('click', handleClick, true)
      window.addEventListener('mousedown', handleClick, true)
      window.addEventListener('mouseup', handleClick, true)
    } else {
      window.removeEventListener('click', handleClick, true)
      window.removeEventListener('mousedown', handleClick, true)
      window.removeEventListener('mouseup', handleClick, true)
    }

    return () => {
      window.removeEventListener('click', handleClick, true)
      window.removeEventListener('mousedown', handleClick, true)
      window.removeEventListener('mouseup', handleClick, true)
    }
  }, [disable])

  useEffect(() => {
    if (onboardingDone) {
      setStep(onboardingData.length)
    }
  }, [])

  useEffect(() => {
    const body = document.querySelector('body')
    const html = document.querySelector('html')
    if (step + 1 <= onboardingData.length) {
      body?.classList.add('scroll-smooth')
      html?.classList.add('scroll-smooth')
    } else {
      body?.classList.remove('scroll-smooth')
      html?.classList.remove('scroll-smooth')
    }

    if (step === onboardingData.length) {
      const lsData = JSON.parse(localStorage.getItem('onboarding') || '{}')
      lsData[JSON.parse(localStorage.getItem('auth') || '{}')?.user.uuid] = {
        ...lsData[JSON.parse(localStorage.getItem('auth') || '{}')?.user.uuid],
        ...{ [page]: true }
      }
      localStorage.setItem('onboarding', JSON.stringify(lsData))
      setDisable(false)
    }

    Object.keys(reflist).map(key => {
      return reflist[key].ref === undefined ||
        reflist[key].ref === null ||
        reflist[key].ref.current === undefined ||
        reflist[key].ref.current === null
        ? delete reflist[key]
        : null
    })

    if (reflist[step] !== undefined) {
      instance = createPopper(
        reflist[step].ref.current,
        modalRef.current as unknown as HTMLElement,
        {
          placement: reflist[step].position || 'auto',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [10, 10]
              }
            }
          ]
        }
      )
    }

    changeZIndex()
  }, [step])

  const changeZIndex = () => {
    if (reflist[step] === undefined) {
      dispatch(OnboardingHelperActions.updateValue(false))
      modalRef.current?.classList.add('center-modal')
      document.querySelector('.without-popper')?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
      resetZIndex()
      return
    } else {
      modalRef.current?.classList.remove('center-modal')
      resetZIndex()
    }

    if (reflist[step - 1] !== undefined) {
      reflist[step - 1].ref.current.style.zIndex = ''
      reflist[step - 1].ref.current.style.background = ''
      reflist[step - 1].ref.current.style.padding = ''
      reflist[step - 1].ref.current.style.borderRadius = ''
      if (
        window
          .getComputedStyle(reflist[step - 1].ref.current)
          .getPropertyValue('position') === 'fixed'
      ) {
        dispatch(OnboardingHelperActions.updateValue(false))
      }
    }

    if (reflist[step + 1] !== undefined) {
      reflist[step + 1].ref.current.style.zIndex = ''
      reflist[step + 1].ref.current.style.position = ''
      reflist[step + 1].ref.current.style.background = ''
      reflist[step + 1].ref.current.style.padding = ''
      reflist[step + 1].ref.current.style.borderRadius = ''
      if (
        window
          .getComputedStyle(reflist[step + 1].ref.current)
          .getPropertyValue('position') === 'fixed'
      ) {
        dispatch(OnboardingHelperActions.updateValue(false))
      }
    }

    if (reflist[step] !== undefined) {
      reflist[step].ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
      reflist[step].ref.current.style.zIndex = '9999999'
      reflist[step].ref.current.style.background = '#fff'
      reflist[step].ref.current.style.padding = '15px'
      if (
        window
          .getComputedStyle(reflist[step].ref.current)
          .getPropertyValue('position') === 'fixed'
      ) {
        dispatch(OnboardingHelperActions.updateValue(true))

        setTimeout(() => {
          instance.forceUpdate()
        }, 500)
      }
      reflist[step].ref.current.style.position =
        window
          .getComputedStyle(reflist[step].ref.current)
          .getPropertyValue('position') === 'fixed'
          ? 'fixed'
          : 'relative'
      reflist[step].ref.current.style.borderRadius = '15px'
    }

    if (step === onboardingData.length) {
      resetZIndex()
    }
  }

  const resetZIndex = () => {
    Object.keys(reflist).map(key => {
      reflist[key].ref.current.style.zIndex = ''
      reflist[key].ref.current.style.position = ''
      reflist[key].ref.current.style.padding = ''
      reflist[key].ref.current.style.borderRadius = ''
      return 0
    })
  }

  const handleSkip = onboardingData => () => {
    setStep(onboardingData.length)

    const GACategory = returnGACategoryByPage(page)
    ReactGA.event(`skip_tutorial_${GACategory}`, {
      event_category: GACategory,
      event_label: `skip_tutorial_${GACategory}`
    })
  }

  const ModalRef = () => {
    return (
      <>
        {step + 1 <= onboardingData.length ? (
          <>
            <div className='onboarding-modal in' ref={modalRef}>
              <Modal />
            </div>
          </>
        ) : null}
      </>
    )
  }

  const Modal = () => {
    return (
      <>
        {step + 1 <= onboardingData.length ? (
          <>
            {' '}
            <div className='onboarding-title'>
              <h3>{onboardingData[step].title}</h3>
            </div>
            <div className='onboarding-body'>
              <p
                dangerouslySetInnerHTML={{
                  __html: onboardingData[step].text
                }}
              />
            </div>
            <div className='onboarding-actions'>
              <div className='row'>
                {step + 1 !== onboardingData.length ? (
                  <div className={`${step > 0 ? 'col-4' : 'col-6'}`}>
                    <Button
                      className='btn-gray skip-onboarding-button'
                      onClick={handleSkip(onboardingData)}
                    >
                      {t('buttons.skip')}
                    </Button>
                  </div>
                ) : null}

                {step > 0 && step + 1 !== onboardingData.length ? (
                  <div
                    className={`${
                      step > 0 && step + 1 !== onboardingData.length
                        ? 'col-4'
                        : 'col-6'
                    }`}
                  >
                    <Button
                      className='btn-default back-onboarding-button'
                      onClick={decrementStep}
                    >
                      {t('buttons.previous')}
                    </Button>
                  </div>
                ) : null}

                <div
                  className={`${
                    step > 0 && step + 1 !== onboardingData.length
                      ? 'col-4'
                      : step + 1 === onboardingData.length
                        ? 'col-12'
                        : 'col-6'
                  }`}
                >
                  <Button
                    className='btn-default finish-onboarding-button'
                    onClick={incrementStep(onboardingData.length)}
                  >
                    {step + 1 === onboardingData.length
                      ? t(`${page}.finishOnboardingButton`)
                      : t('buttons.next')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    )
  }

  if (onboardingDone) return null
  return (
    <>
      {reflist[step] !== undefined ? (
        ModalRef()
      ) : (
        <div className='onboarding-modal in without-popper'>{Modal()}</div>
      )}

      {step + 1 <= onboardingData.length && (
        <div className='onboarding-overlay' />
      )}
    </>
  )
}
