import * as Types from './types'

export const InitialState: Types.State = {
  permissions: {},
  loading: false,
  error: ''
}

export function reducer(
  state = InitialState,
  action: Types.Actions
): Types.State {
  switch (action.type) {
    case Types.Types.ENABLE_LOADING:
      return { ...state, loading: true }

    case Types.Types.DISABLE_LOADING:
      return { ...state, loading: false }

    case Types.Types.SET_ERROR:
      return { ...state, error: action.payload }

    case Types.Types.SET_PERMISSIONS:
      return { ...state, permissions: action.payload }

    default:
      return state
  }
}
