import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Button } from 'components/Button/Button'
import { Form } from 'components/Form/Form'
import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Loading } from 'components/Loading/Loading'
import { Modal } from 'components/Modal/Modal'
import { Select } from 'components/Select/Select'
import { jobs, workflow } from 'services/api'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'

export type JobSteps = {
  uuid: string
  name: string
}

type ApplyJobModalProps = {
  open: boolean
  candidate: string
  onClose: () => void
}

export const ApplyJobModal: React.FC<ApplyJobModalProps> = ({
  open,
  candidate,
  onClose
}) => {
  const { t } = useTranslation()
  const [tworkflows] = useTranslation('defaultWorkflowStages')
  const { t: te } = useTranslation('errors')
  const { t: ts } = useTranslation('success')
  const { dispatch } = useStore()
  const [modalOpen, setModalOpen] = useState(open)
  const [stepsList, setStepsList] = useState<JobSteps[]>([])
  const [selectedJob, setSelectedJob] = useState<Job>()
  const [loading, setLoading] = useState(false)

  const [jobsList, setJobsList] = useState<Job[]>([])

  const applyToJob = async values => {
    setLoading(true)
    const [error, data] = await jobs.applyToJob(values as JobApplyPayload)
    if (error)
      showNotification({ message: te('applicationError'), type: 'error' })
    if (data) {
      showNotification({
        message: ts('applicationSuccess'),
        type: 'success'
      })
      onClose()
      setModalOpen(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    formik.setFieldValue('candidate', candidate)
  }, [candidate])

  useEffect(() => {
    if (modalOpen) {
      setJobsList([])
      setStepsList([])

      fetchJobs()
    }
    formik.setFieldValue('jobUUID', '')
    formik.setFieldValue('hiringWorkflowStage', '')
  }, [modalOpen])

  const fetchJobs = async (initialPage = 1) => {
    setLoading(true)
    try {
      let hasNext = false
      let page = initialPage
      const fullJobsList: Job[] = []

      do {
        const [error, data] = await jobs.list({ page, perPage: 100 })

        if (error) throw new Error(error.toString())

        if (data) {
          fullJobsList.push(...data.data)

          hasNext = fullJobsList.length < data.total
        } else {
          hasNext = false
        }
        page += 1
      } while (hasNext)

      setJobsList(
        fullJobsList.filter(el => {
          return el.active === true
        })
      )
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedJob && selectedJob?.hiringWorkflow) {
      try {
        workflow
          .getStages({
            workflow_uuid: selectedJob?.hiringWorkflow.uuid,
            per_page: 100,
            page: 1,
            job_uuid: selectedJob.uuid
          })
          .then((response): void => {
            const [error, data] = response

            if (error) throw new Error(error.toString())
            setStepsList([...(data?.data || [])] as JobSteps[])
          })
      } catch (error) {
        showNotification({ message: error.message, type: 'error' })
      }
    }
  }, [selectedJob])

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  const formik = useFormik<JobApplyPayload>({
    initialValues: {
      candidate: candidate || '',
      jobUUID: '',
      hiringWorkflowStage: ''
    },
    validationSchema: Yup.object().shape({
      jobUUID: Yup.string().required(te('requiredField')),
      hiringWorkflowStage: Yup.string().required(te('requiredField'))
    }),
    onSubmit: async (values): Promise<void> => {
      try {
        if (formik.isValid) {
          applyToJob(values)
        }
      } catch (error) {
        showNotification({ message: error.message, type: 'error' })
      }
    }
  })

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  return (
    <div>
      <Modal
        onClose={onClose}
        open={modalOpen}
        title={'+ ' + t('jobOpportunity:applyJobOpportunity')}
        closeIcon='cross'
        width={'384px'}
      >
        <Form id='apply-to-job-form' onSubmit={formik.handleSubmit}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <FormRow>
                <FormItem>
                  <Select
                    label={t('jobOpportunity:jobNameLabel')}
                    value={formik.values.jobUUID}
                    onBlur={formik.handleBlur}
                    name='jobUUID'
                    onChange={e => {
                      formik.setFieldValue('jobUUID', e.target.value)
                      setSelectedJob(
                        jobsList?.filter(el => el.uuid === e.target.value)[0]
                      )
                    }}
                    error={
                      formik.touched.jobUUID ? formik.errors.jobUUID : undefined
                    }
                  >
                    <option value='' key='none'></option>

                    {jobsList.length > 0 &&
                      jobsList?.map((el, index) => (
                        <option value={el.uuid} key={index}>
                          {el.name}
                        </option>
                      ))}
                    {jobsList.length === 0 && (
                      <option value='' key='no-job'>
                        {t('jobOpportunity:noJobFound')}
                      </option>
                    )}
                  </Select>
                </FormItem>
              </FormRow>

              <FormRow>
                <FormItem>
                  <Select
                    label={t('jobOpportunity:applyToStep')}
                    value={formik.values.hiringWorkflowStage}
                    onBlur={formik.handleBlur}
                    name='hiringWorkflowStage'
                    onChange={e => {
                      formik.setFieldValue(
                        'hiringWorkflowStage',
                        e.target.value
                      )
                    }}
                    error={
                      formik.touched.hiringWorkflowStage
                        ? formik.errors.hiringWorkflowStage
                        : undefined
                    }
                  >
                    <option value=''></option>

                    {stepsList?.map((el, index) => (
                      <option value={el.uuid} key={index}>
                        {tworkflows(el.name)}
                      </option>
                    ))}
                  </Select>
                </FormItem>
              </FormRow>
            </>
          )}

          <div className={'popup-box-actions medium void'}>
            <Button
              className={'popup-box-action full secondary'}
              disabled={loading || !formik.isValid}
              loading={loading}
              type='submit'
            >
              {t('jobOpportunity:applyCandidate')}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
