import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { ReactComponent as LinkIcon } from 'assets/images/icons/link-icon.svg'
import { Button } from 'components/Button/Button'
import { Form } from 'components/Form/Form'
import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Modal } from 'components/Modal/Modal'
import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { UploadBox } from 'components/UploadBox/UploadBox'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import { CarrerMainPageContainer } from 'containers/CompanySettings/CareerMainPageContainer/CareerMainPageContainer'
import { ColorContainer } from 'containers/CompanySettings/ColorContainer/ColorContainer'
import { CompanyFormContainer } from 'containers/CompanySettings/CompanyForm/CompanyFormContainer'
import { LGPDContainer } from 'containers/CompanySettings/LGPDContainer/LGPDContainer'
import { usePermission } from 'hooks/usePermission'
import { company } from 'services/api'
import useStore from 'store'
import * as authAction from 'store/auth/actions'
import * as notificationActions from 'store/notification/actions'
import { camelKeyToSnakeCase } from 'utils'
import ReactGA from 'utils/analytics'

import companySettingsAnalytics from './CompanySettingsAnalytics'
import './companySettings.scss'

// eslint-disable-next-line
const CNPJ_REGEX = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/

export function CompanySettingsPage(): JSX.Element {
  usePermission({ moduleName: 'company', action: 'view' })
  const [successModal, setSuccessModal] = useState(false)
  const [companyIsCreated, setCompanyIsCreated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [oldCompanyData, setOldCompanyData] = useState<CompanyPayload | null>(
    null
  )

  const [companyFormRef, setCompanyFormRef] = useState({})
  const [companyLogoRef, setCompanyLogoRef] = useState({})
  const [companyColorsRef, setCompanyColorsRef] = useState({})
  const [companyLGPDRef, setCompanyLGPDRef] = useState({})
  const [carrerPageSettingsRef, setcarrerPageSettingsRef] = useState({})

  const [refList, setRefList] = useState<{
    [k in string]: {
      ref: React.RefObject<HTMLDivElement> | string
      position?: string
    }
  }>({
    1: { ref: '', position: '' },
    2: { ref: '', position: '' },
    3: { ref: '', position: '' },
    4: { ref: '', position: '' },
    5: { ref: '', position: '' }
  })

  const setFormRef = value => {
    const formRef = { 1: { ref: value.companyFormRef } }
    setCompanyFormRef(formRef)
  }

  const setLogoRef = value => {
    const logoRef = { 2: { ref: value.companyLogoRef } }
    setCompanyLogoRef(logoRef)
  }

  const setColorsRef = value => {
    const colorsRef = { 3: { ref: value.companyColorsRef } }
    setCompanyColorsRef(colorsRef)
  }

  const setLGPDRef = value => {
    const lgpdRef = { 4: { ref: value.companyLGPDRef, position: 'top' } }
    setCompanyLGPDRef(lgpdRef)
  }
  const setCarrerRef = value => {
    const carrerRef = { 5: { ref: value.carrerPageSettingsRef } }
    setcarrerPageSettingsRef(carrerRef)
  }

  useEffect(() => {
    setRefList({
      ...companyFormRef,
      ...companyLogoRef,
      ...companyColorsRef,
      ...companyLGPDRef,
      ...carrerPageSettingsRef
    })
  }, [
    companyFormRef,
    companyLogoRef,
    companyColorsRef,
    companyLGPDRef,
    carrerPageSettingsRef
  ])

  const { t } = useTranslation('companySettings')
  const { t: errorsTranslations } = useTranslation('errors')
  const getFullLink = () => {
    return `https://${formik.values.slug}.recrud.bne.com.br`
  }
  const { dispatch, selectors } = useStore()
  const hasCompany = selectors.auth.hasCompany()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const navigate = useNavigate()

  const getCompanyPayload = (companyData: CompanyPayload) => {
    const companyPayload = camelKeyToSnakeCase(companyData)
    companyPayload.cnpj = formik.values.cnpj
      .replace(/\./g, '')
      .replace(/-/g, '')
      .replace(/\//g, '')
    companyPayload.site = getFullLink()

    delete companyPayload.logo

    return companyPayload
  }

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  useEffect(() => {
    if (user?.company?.uuid !== null && user?.company?.uuid !== undefined) {
      try {
        getCompany()
      } catch (error) {
        showNotification({ message: error.message, type: 'error' })
      }
    }
  }, [])

  useEffect(() => {
    const unblock = history.block((route): any => {
      if ((!hasCompany || !companyIsCreated) && route.pathname !== '/') {
        return false
      }
      return true
    })

    return () => {
      unblock()
    }
  }, [companyIsCreated])

  const clickListener = (e: any) => {
    if (
      e.target.nodeName === 'A' &&
      (!hasCompany || !companyIsCreated) &&
      !e.target.classList.contains('language-link') &&
      !e.target.classList.contains('logout-link')
    ) {
      dispatch(
        notificationActions.notify({
          type: 'info',
          message: t('errors:finalizeCompanyRegistration')
        })
      )
    }
  }

  useEffect(() => {
    if (!companyIsCreated) {
      setCompanyIsCreated(hasCompany)
      if (!hasCompany) {
        document.addEventListener('click', clickListener)
      }
    }

    return () => document.removeEventListener('click', clickListener)
  }, [hasCompany])

  const getCompany = async () => {
    try {
      company.get(user?.company?.uuid).then((response): void => {
        const [error, data] = response
        if (error) throw new Error(error.toString())

        if (data !== null) {
          data.careerPageSettings.showHomeButton =
            data.careerPageSettings.showHomeButton === null
              ? true
              : data?.careerPageSettings.showHomeButton
          data.careerPageSettings.showMainPage =
            data?.careerPageSettings.showMainPage === null
              ? true
              : data?.careerPageSettings.showMainPage
        }

        setOldCompanyData(data)
        formik.setValues({ ...(data as CompanyPayload) })
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const createCompany = async (companyData: CompanyPayload) => {
    setLoading(true)
    try {
      const companyPayload = getCompanyPayload(companyData)

      delete companyPayload.site

      const [error, data] = await company.create(
        companyPayload as CompanyAPIPayload
      )
      if (error) throw new Error(error.toString())

      dispatch(authAction.setCompanyId(data?.uuid as string))

      const [imageError] = await company.logo(
        companyData.logo,
        data?.uuid as string
      )

      if (imageError) throw new Error(imageError.toString())

      delete companyPayload.data_privacy_settings.updated_at
      delete companyPayload.data_privacy_settings.created_at
      delete companyPayload.data_privacy_settings.uuid

      dispatch(
        authAction.setCompany({
          fancy_name: companyData.fancyName,
          uuid: data?.uuid,
          slug: companyData.slug,
          data_privacy_settings: companyPayload.data_privacy_settings || null
        })
      )

      window.scrollTo(0, 0)
      setSuccessModal(true)
      setCompanyIsCreated(true)
      document.removeEventListener('click', clickListener)
    } catch (error) {
      const errMessage = parseErrormessages(error.message)
      showNotification({ message: errMessage, type: 'error' })
    }
    setLoading(false)
  }

  const editCompany = async (companyData: CompanyPayload) => {
    setLoading(true)
    try {
      const companyPayload = getCompanyPayload(companyData)

      if (user?.company?.uuid) {
        delete companyPayload.uuid
        delete companyPayload.updated_at
        delete companyPayload.created_at
        delete companyPayload.data_privacy_settings.updated_at
        delete companyPayload.data_privacy_settings.created_at
        delete companyPayload.data_privacy_settings.uuid
        delete companyPayload.career_page_settings.updated_at
        delete companyPayload.career_page_settings.created_at
        delete companyPayload.career_page_settings.uuid
        delete companyPayload.site
        delete companyPayload.status

        const [error] = await company.edit(
          user?.company?.uuid,
          companyPayload as CompanyAPIPayload
        )

        if (error) throw new Error(error.toString())

        if (typeof companyData.logo !== 'string') {
          const [imageError] = await company.logo(
            companyData.logo,
            user?.company?.uuid as string
          )

          ReactGA.event('logo_upload', {
            event_category: 'company_settings',
            event_label: 'logo_upload'
          })

          if (imageError) throw new Error(imageError.toString())
        }

        dispatch(
          authAction.setCompany({
            fancy_name: companyData.fancyName,
            uuid: user?.company?.uuid,
            slug: companyData.slug,
            data_privacy_settings: companyPayload.data_privacy_settings || null
          })
        )

        companySettingsAnalytics(oldCompanyData, companyPayload)

        window.scrollTo(0, 0)
        setSuccessModal(true)
      }
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
    setLoading(false)
  }

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const parseErrormessages = (error: string) => {
    if (error === 'logo is a required field') {
      return 'Você precisa selecionar uma logo, tente novamente.'
    } else if (error === 'This record already exists in our databases.') {
      return 'O Link escolhido já está em uso.'
    } else {
      return error
    }
  }

  const formik = useFormik<CompanyPayload>({
    initialValues: {
      name: '',
      fancyName: '',
      slug: '',
      location: '',
      description: '',
      cnpj: '',
      logo: null,
      careerPageSettings: {
        headerColor: '#000000',
        fontColor: '#000000',
        buttonColor: '#000000',
        buttonFontColor: '#FFFFFF',
        showHomeButton: true,
        showMainPage: true
      },
      dataPrivacySettings: {
        askTelephone: false,
        requiredTelephone: false,
        askMaritalStatus: false,
        requiredMaritalStatus: false,
        askSex: false,
        requiredSex: false,
        askBirthdate: false,
        requiredBirthdate: false,
        askWageClaim: false,
        requiredWageClaim: false,
        askCityChangeAvailability: false,
        requiredCityChangeAvailability: false,
        askLinkedinProfile: false,
        requiredLinkedinProfile: false,
        askFacebookProfile: false,
        requiredFacebookProfile: false,
        askTwitterProfile: false,
        requiredTwitterProfile: false,
        termsOfUse: ''
      }
    },
    onSubmit: async (values): Promise<void> => {
      try {
        const formSchema = Yup.object().shape({
          name: Yup.string()
            .matches(
              /^[a-zA-Z0-9\s}]{1,80}$/,
              errorsTranslations('invalidComapanyName')
            )
            .required(errorsTranslations('requiredField')),
          fancyName: Yup.string().required(errorsTranslations('requiredField')),
          slug: Yup.string()
            .required(errorsTranslations('requiredField'))
            .matches(/^[a-zA-Z0-9]{1,80}$/, errorsTranslations('invalidSlug')),
          location: Yup.string().required(errorsTranslations('requiredField')),
          description: Yup.string().required(
            errorsTranslations('requiredField')
          ),
          cnpj: Yup.string().required(errorsTranslations('requiredField')),
          logo: Yup.mixed().required(),
          careerPageSettings: Yup.object().shape({
            headerColor: Yup.string(),
            fontColor: Yup.string(),
            buttonColor: Yup.string(),
            buttonFontColor: Yup.string()
          }),
          dataPrivacySettings: Yup.object().shape({
            askTelephone: Yup.boolean().required(),
            askMaritalStatus: Yup.boolean().required(),
            askSex: Yup.boolean().required(),
            askBirthdate: Yup.boolean().required(),
            askWageClaim: Yup.boolean().required(),
            askCityChangeAvailability: Yup.boolean().required(),
            askLinkedinProfile: Yup.boolean().required(),
            askFacebookProfile: Yup.boolean().required(),
            askTwitterProfile: Yup.boolean().required()
          })
        })

        const isValid = await formSchema.validate({
          ...values
        })

        if (values.dataPrivacySettings.termsOfUse === null) {
          delete values.dataPrivacySettings.termsOfUse
        }

        if (values.careerPageSettings.emailCodeVerification === null) {
          delete values.careerPageSettings.emailCodeVerification
        }

        if (isValid && user?.company === null) {
          createCompany(values)
        } else if (isValid && user?.company !== null) {
          editCompany(values)
        }
      } catch (error) {
        showNotification({
          message: parseErrormessages(error.message),
          type: 'error'
        })
      }
    }
  })
  const user = selectors.auth.user()

  return (
    <>
      <Onboarding reflist={refList as RefList} page='companySettings' />
      <div
        className={`content-grid ${
          expandedMenu ? 'content-grid-expanded-menu' : ''
        }`}
      >
        <div className='grid grid-12 medium-space'>
          <div className='account-hub-content'>
            <div className='grid-column'>
              <WidgetBox>
                <Form id='company-form' onSubmit={formik.handleSubmit}>
                  <CompanyFormContainer
                    name={formik.values.name}
                    fancyName={formik.values.fancyName}
                    site={formik.values.slug}
                    location={formik.values.location}
                    description={formik.values.description}
                    cnpj={formik.values.cnpj}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    touched={formik.touched}
                    getRef={setFormRef}
                  />

                  <UploadBox
                    onChange={file => formik.setFieldValue('logo', file)}
                    src={formik.values.logo}
                    suggestedSize={'400 x 50'}
                    getRef={setLogoRef}
                  />

                  <ColorContainer
                    careerPageSettings={formik.values.careerPageSettings}
                    setFieldValue={formik.setFieldValue}
                    getRef={setColorsRef}
                  />

                  <CarrerMainPageContainer
                    setFieldValue={formik.setFieldValue}
                    careerPageSettings={formik.values.careerPageSettings}
                    getRef={setCarrerRef}
                  />

                  <LGPDContainer
                    dataPrivacySettings={formik.values.dataPrivacySettings}
                    setFieldValue={formik.setFieldValue}
                    getRef={setLGPDRef}
                  />

                  <FormRow>
                    <FormItem>
                      <PermissionAgent
                        module='company'
                        actions={['create', 'edit']}
                        onFail='disabled'
                        Component={Button}
                        componentProps={{
                          className: 'primary-custom',
                          children: t('save'),
                          disabled: loading,
                          loading
                        }}
                      />
                    </FormItem>
                  </FormRow>
                </Form>
              </WidgetBox>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={t('changesSaved')}
        open={successModal}
        onClose={() => {
          setSuccessModal(!successModal)
          navigate('/dashboard')
        }}
        closeIcon={'cross'}
        width={'403px'}
      >
        <p className='success-text'>{t('messageChangesSaved')}</p>
        <div className='recrud-link-container'>
          <a
            className='recrud-link language-link'
            href={getFullLink()}
            target='_blank'
            rel='noreferrer'
          >
            {getFullLink()}
            <LinkIcon />
          </a>
        </div>
        <div className={'popup-box-actions medium void'}>
          <Button
            className={'popup-box-action full secondary'}
            onClick={() => {
              setSuccessModal(!successModal)
              navigate('/dashboard')
            }}
          >
            Ok!
          </Button>
        </div>
      </Modal>
    </>
  )
}
