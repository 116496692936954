import React from 'react'

import { useTranslation } from 'react-i18next'

type CardPlanProps = {
  status: 'paid' | 'pending' | 'expired' | 'canceled' | string
}

export const StatusBadger: React.FC<CardPlanProps> = ({ status }) => {
  const { t } = useTranslation('subscriptionSettings')

  let cssClass = ''
  let statusTranslated
  switch (status) {
    case 'paid':
      cssClass = 'success'
      statusTranslated = 'invoices.statusPaid'
      break
    case 'pending':
      cssClass = 'warning'
      statusTranslated = 'invoices.statusPending'
      break
    case 'expired':
      cssClass = 'danger'
      statusTranslated = 'invoices.statusExpired'
      break
    case 'canceled':
      cssClass = 'secondary'
      statusTranslated = 'invoices.statusCanceled'
      break
    case 'refunded':
      cssClass = 'dark'
      statusTranslated = 'invoices.statusRefunded'
      break
  }

  return (
    <div>
      <span className={`badge badge-${cssClass}`}>{t(statusTranslated)}</span>
    </div>
  )
}

export default StatusBadger
