import React from 'react'
import './Error.scss'

type ErrorProps = {
  error?: string
}

const Error: React.FC<ErrorProps> = ({ error }) =>
  error ? <p className='error'>{error}</p> : null

export default Error
