import React, { useEffect, useRef } from 'react'

import './RecruitersList.scss'
import { useTranslation } from 'react-i18next'

export type Props = {
  team?: { recruiter: string[]; managers: string[] }
}

export const RecruitersList: React.FC<Props> = ({
  team = { recruiter: [], managers: [] }
}) => {
  const [count, setCount] = React.useState(10)
  const [showAll, setShowAll] = React.useState(false)
  const { t } = useTranslation('recruiterList')
  const allRecruitersRef = useRef<HTMLDivElement | null>(null)
  const moreRecruitersRef = useRef<HTMLDivElement | null>(null)
  const generateTeamContent = (teamMembers: string[], title: any) => {
    const membersFormatted = teamMembers
      .map((recruiter: string) => {
        const fullName = recruiter.split(' ')
        const firstName = fullName[0]
        const lastName = fullName.slice(1).join(' ')

        return `${firstName} ${lastName}`
      })
      .join('\n')
    return `${title}:\n\n${membersFormatted}\n\n`
  }
  const recruitersContent =
    team.recruiter.length > 0
      ? generateTeamContent(team.recruiter, t('recruiters'))
      : ''
  const managersContent =
    team.managers.length > 0
      ? generateTeamContent(team.managers, t('managers'))
      : ''
  const combinedContent = [recruitersContent, managersContent]
    .filter(Boolean)
    .join('\n')

  const combinedTeam = [...team.recruiter, ...team.managers]

  useEffect(() => {
    const counter = team?.recruiter.length + team?.managers.length
    if (counter > 4) {
      setCount(counter - 3)
    } else {
      setCount(0)
    }
  }, [team])

  return (
    <>
      <div className='recruiter-list' data-testid='recruiter-list'>
        {team?.recruiter.length + team?.managers.length > 0 &&
          combinedTeam.map((recruiter: string, index: number) => {
            const recruiterType =
              index < team.recruiter.length ? 'recruiters' : 'managers'
            const fullName = recruiter.split(' ')
            const firstName = fullName[0]
            const lastName = fullName.slice(1).join(' ')
            if (index < 3) {
              return (
                <div
                  key={recruiter}
                  className='recruiter-item'
                  data-testid='recruiter-item'
                  data-tooltip={`${t(recruiterType)}: ${firstName} ${lastName}`}
                >
                  {recruiter.charAt(0).toUpperCase()}
                </div>
              )
            }

            return null
          })}
        {count > 0 && (
          <div
            className='recruiter-item'
            data-testid='recruiter-item'
            ref={moreRecruitersRef}
            data-tooltip={combinedContent}
            data-type='list'
          >
            + {count}
          </div>
        )}
      </div>
    </>
  )
}
