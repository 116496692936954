import { RefObject, useEffect } from 'react'

export const useOutsideAlerter = (
  ref: RefObject<HTMLElement>,
  onClick: (target: HTMLElement) => void
): void => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        onClick(event.target as HTMLElement)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClick])
}
