import React, { useState, useRef, useEffect } from 'react'

import EmailIcon from '@mui/icons-material/Email'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FacebookIcon } from 'assets/images/icons/facebook-icon.svg'
import { ReactComponent as LinkedinIcon } from 'assets/images/icons/linkedin-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/icons/twitter-icon.svg'
import WhatsappIcon from 'assets/images/icons/whatsapp-icon.png'
import { ApplyJobModal } from 'components/ApplyJobModal/ApplyJobModal'
import { Button } from 'components/Button/Button'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { Select } from 'components/Select/Select'
import createSocialMediaLink from 'utils/createSocialMediaLink'

import './CandidateDetailHeader.scss'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

type Props = {
  facebook?: string
  twitter?: string
  linkedin?: string
  whatsapp?: string
  candidateEmail?: string
  user: string
  currentStep: string
  stages?: Array<WorkflowStageSchema>
  onStageChange: (stageUUID: string) => void
  getRefs: (value: React.RefObject<HTMLDivElement>) => void
  candidateUUID: string
}

export const CandidateDetailHeader: React.FC<Props> = ({
  facebook,
  twitter,
  linkedin,
  whatsapp,
  candidateEmail,
  user,
  currentStep,
  stages,
  onStageChange,
  candidateUUID,
  getRefs
}) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const [step, setStep] = useState(currentStep)
  const [applyJobModalOpened, setApplyJobModalOpened] = useState<boolean>(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  useEffect(() => {
    getRefs(selectRef)
  }, [selectRef])

  const firstLetter = () => {
    const firstLetter = [...user][0].toUpperCase()
    return firstLetter
  }

  return (
    <>
      <ApplyJobModal
        open={applyJobModalOpened}
        candidate={candidateUUID}
        onClose={() => setApplyJobModalOpened(false)}
      />

      <div className='candidate-detail-header'>
        <div className='figure' />
        <div className='avatar'>{firstLetter()}</div>

        <div className='content'>
          <div className='row d-flex justify-content-between h-100 align-items-center'>
            <div className='col-12 col-md-4 col-xl-3 d-flex justify-content-center mb-3 order-2'>
              {dontShowOnGeralWorkspace && (
                <PermissionAgent
                  module='job-application'
                  actions={['edit', 'create']}
                  onFail='hidden'
                >
                  <div ref={selectRef} className='w-100'>
                    {stages ? (
                      <Select
                        label={t('candidateList:select')}
                        value={step}
                        onChange={event => {
                          setStep(event.target.value)

                          const selectedIndex =
                            event.target.options.selectedIndex
                          const selectedOption =
                            event.target.options[selectedIndex]
                          onStageChange(
                            selectedOption.dataset.stageUuid as string
                          )
                        }}
                      >
                        {stages?.map((stage, index) => (
                          <option
                            key={index}
                            value={stage.name}
                            data-stage-uuid={stage.uuid}
                          >
                            {t('defaultWorkflowStages:' + stage.name)}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Button
                        id='job-apply-button'
                        onClick={() => setApplyJobModalOpened(true)}
                        className='apply-to-job'
                      >
                        {t('common:applyToJob')}
                      </Button>
                    )}
                  </div>
                </PermissionAgent>
              )}
            </div>

            <div className='col-12 col-md-4 d-flex justify-content-center mb-3 order-1'>
              <p className='user-profile'>{user}</p>
            </div>

            <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-end mb-3 buttons order-3'>
              {facebook && (
                <a
                  href={createSocialMediaLink(facebook, 'facebook')}
                  target='_blank'
                  rel='noreferrer'
                  className='social-media facebook'
                >
                  <FacebookIcon />
                </a>
              )}

              {twitter && (
                <a
                  className='social-media twitter'
                  href={createSocialMediaLink(twitter, 'twitter')}
                  target='_blank'
                  rel='noreferrer'
                >
                  <TwitterIcon />
                </a>
              )}

              {linkedin && (
                <a
                  className='social-media linkedin'
                  href={createSocialMediaLink(linkedin, 'linkedin')}
                  target='_blank'
                  rel='noreferrer'
                >
                  <LinkedinIcon />
                </a>
              )}

              <a
                className='social-media email-button'
                href={`mailto:${candidateEmail}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <EmailIcon />
              </a>

              {whatsapp && (
                <a
                  className='social-media whatsapp'
                  href={`https://wa.me/${whatsapp.replace(/[+()]/g, '')}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={WhatsappIcon} alt='whatsapp icon' />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
