import React from 'react'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

import './PopoverHelp.scss'

interface Props {
  content: string
}

export const PopoverHelp: React.FC<Props> = ({ content }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={e => handleClick(e)}>
        <HelpOutlineIcon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Typography sx={{ p: 1 }} className='popoverhelp'>
          {content}
        </Typography>
      </Popover>
    </>
  )
}
