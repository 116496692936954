import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { useDashboardContext } from '../../DashboardContainerContext'

interface JobPositionsProps {
  t: any
  loading: boolean
}

const JobPositions = ({ t, loading }: JobPositionsProps) => {
  const { jobsOppened, jobsCLosed } = useDashboardContext()
  return (
    <div className='row justify-content-around'>
      <div className='col-12 col-md-6'>
        {loading && !(jobsOppened.late > 0 || jobsOppened.in_time > 0) ? (
          <GraphicSkeleton isRouded />
        ) : (
          <DashboadCard title={t('chart01')}>
            <DashboardChart
              id='open-jobs-chart'
              visible={jobsOppened.late > 0 || jobsOppened.in_time > 0}
            />
          </DashboadCard>
        )}
      </div>

      <div className='col-12 col-md-6'>
        {loading && !(jobsCLosed.late > 0 || jobsCLosed.in_time > 0) ? (
          <GraphicSkeleton isRouded />
        ) : (
          <DashboadCard title={t('chart02')}>
            <DashboardChart
              id='closed-jobs-chart'
              visible={jobsCLosed.late > 0 || jobsCLosed.in_time > 0}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default JobPositions
