import React, { useEffect } from 'react'

import { ReactComponent as SelectArrowIcon } from 'assets/images/icons/select-arrow-icon.svg'
import './Select.scss'

import Error from '../Error/Error'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  error?: string
  noPadding?: boolean
}

export const Select: React.FC<Props> = ({
  children,
  name,
  id,
  className,
  value,
  onChange,
  onBlur,
  label,
  required,
  error,
  noPadding = false
}) => {
  const [isEmpty, setIsEmpty] = React.useState(true)

  useEffect(() => {
    if (value) {
      setIsEmpty(false)
    } else {
      setIsEmpty(true)
    }
  }, [value])

  return (
    <>
      <div className='select-wrapper' data-testid='select' data-empty={isEmpty}>
        <label>
          {label}
          {required ? (
            <span style={{ color: 'red', margin: '0px 3px' }}>*</span>
          ) : (
            ''
          )}
        </label>
        <select
          name={name}
          value={value}
          id={id}
          className={className}
          onChange={onChange}
          onBlur={onBlur}
          style={!noPadding ? { paddingLeft: required ? '35px' : '20px' } : {}}
        >
          {children}
        </select>
        <SelectArrowIcon />
      </div>

      <Error error={error} />
    </>
  )
}
