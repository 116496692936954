import React from 'react'

import { useTranslation } from 'react-i18next'

import styles from './RecruiterAnswers.module.scss'

export interface Answer {
  id: string
  question: {
    uuid: string
    description: string
  }
  answers: Array<{
    uuid: string
    description: string
  }>
}

interface Props {
  answers: Array<Answer>
}

export const RecruiterAnswers: React.FC<Props> = ({ answers }) => {
  const { t } = useTranslation('recruiterAnswers')

  return (
    <section className={styles.answersWrapper}>
      <h1 className={styles.title}>{t('title')}</h1>
      {answers.map((item, index) => (
        <ul key={item.id}>
          <li>
            <h2 className={styles.question}>{`${index + 1}. ${
              item.question.description
            }`}</h2>
            <ul>
              {item.answers.map(answer => (
                <li className={styles.answer} key={answer.uuid}>
                  <p>{answer.description}</p>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      ))}
    </section>
  )
}
