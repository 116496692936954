import React from 'react'

import { MultipleSelect } from 'components/Select/lugarh/MultipleSelect'
import { Select } from 'components/Select/Select'

import { useDashboardContext } from '../../DashboardContainerContext'

interface DashboardHeaderProsp {
  t: any
}

const DashboardHeader = ({ t }: DashboardHeaderProsp) => {
  const {
    selectors,
    showOnGeralWorkspace,
    workspacesList,
    workspacesSelected,
    recruiterSelected,
    selectedRecruiter,
    handleChangeWorkspaces,
    setRecruiterSelected,
    fullName
  } = useDashboardContext()

  return (
    <header className={'section-header'}>
      {selectors.auth.user()?.role === 'admin' && (
        <>
          <div className={'section-header-dashboard'}>
            <h2 className={'section-title'}>
              {t('hello')}, {fullName}!
            </h2>
            <p className={'section-pretitle'}>{t('seeCompanyData')}</p>
          </div>
          <div className='section-header-filters'>
            {showOnGeralWorkspace && workspacesList && (
              <MultipleSelect
                items={workspacesList}
                onChange={handleChangeWorkspaces}
                defaultValue={workspacesSelected?.split(',') || []}
              />
            )}

            <Select
              id='recruter-select'
              label={t('filterByRecruiter')}
              value={recruiterSelected || ''}
              onChange={e => {
                setRecruiterSelected(e.target.value)
              }}
              noPadding={true}
            >
              <option value=''></option>

              {selectedRecruiter?.map((item: any) => {
                return (
                  <option value={item.uuid} key={item.uuid}>
                    {item.firstname} {item.lastname}
                  </option>
                )
              })}
            </Select>
          </div>
        </>
      )}
    </header>
  )
}

export default DashboardHeader
