import React, { useEffect, useState } from 'react'

import { JobCardRefs } from 'components/JobCard/JobCard'
import { JobHeader, JobHeaderRef } from 'components/JobHeader/JobHeader'
import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import { JobsContainer } from 'containers/Jobs/JobsContainer'
import useStore from 'store'
import userCheckPermission from 'utils/userCheckPermission'

export function JobsPage(): JSX.Element {
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const [viewType, setViewType] = useState<'grid' | 'list'>('list')
  const [filter, setFilter] = useState<'active' | 'disabled' | 'all' | 'late'>(
    'active'
  )
  const [search, setSearch] = useState<string>('')
  const [refList, setRefList] = useState({})
  const [headerRefList, setHeaderRefList] = useState({})
  const [cardRefList, setCardRefList] = useState({})
  const [jobs, setJobs] = useState(0)

  useEffect(() => {
    setRefList({ ...cardRefList, ...headerRefList })
  }, [headerRefList, cardRefList])

  const setCardTutorial = (value: JobCardRefs) => {
    const obj: RefList = {
      1: { ref: value.addButton as React.MutableRefObject<HTMLElement> },
      2: {
        ref: value.status as React.MutableRefObject<HTMLElement>
      },
      3: {
        ref: value.team as React.MutableRefObject<HTMLElement>
      },
      4: { ref: value.candidates as React.MutableRefObject<HTMLElement> },
      5: { ref: value.share as React.MutableRefObject<HTMLElement> },
      6: { ref: value.edit as React.MutableRefObject<HTMLElement> }
    }

    setCardRefList(obj)
  }

  const setHeaderTutorial = (value: JobHeaderRef) => {
    const obj: RefList = {
      7: { ref: value.filterRef as React.MutableRefObject<HTMLElement> },
      8: { ref: value.viewRef as React.MutableRefObject<HTMLElement> }
    }
    setHeaderRefList(obj)
  }

  return (
    <div
      className={`content-grid mt-2 ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      <Onboarding reflist={refList} page='jobs' />

      <JobHeader
        onChangeFilter={setFilter}
        onChangeViewType={setViewType}
        onSearch={setSearch}
        getRefs={setHeaderTutorial}
        hiddenDueJob={userCheckPermission({
          resource: 'job',
          scope: ['filter:past-due-jobs']
        })}
      />

      <JobsContainer
        viewType={viewType}
        getRefs={setCardTutorial}
        filter={filter}
        search={search}
        jobsTotal={setJobs}
      />
    </div>
  )
}
