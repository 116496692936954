import React, { useEffect } from 'react'

import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { Modal } from 'components/Modal/Modal'
import { TextArea } from 'components/TextArea/TextArea'

import './WorkflowCreate.scss'

type Props = {
  onSubmit: (payload: { name: string; description: string }) => void
  onReset: (boolean) => void
  modal: boolean
  setModal: (value: boolean) => void
  disableButton: boolean
  resetData?: boolean
  update?: boolean
  name?: string
  description?: string
}

export const WorkflowCreate: React.FC<Props> = ({
  modal,
  setModal,
  onSubmit,
  disableButton,
  resetData,
  update,
  name,
  description
}) => {
  const { t } = useTranslation('')

  const createWorkflowSchema = Yup.object().shape({
    workflowName: Yup.string()
      .trim()
      .required(t('errors:requiredField'))
      .matches(
        /^(?=.*[a-zA-Z0-9À-ÿ])[a-zA-Z0-9À-ÿ ]+$/,
        t('errors:specialCharacters')
      )
      .max(80, t('errors:hiringWorkflowNameMaxLength')),
    workflowDescription: Yup.string()
      .trim()
      .required(t('errors:requiredField'))
      .matches(/^\S.*\S$/, t('errors:requiredField'))
      .max(300, t('errors:hiringWorkflowDescriptionMaxLength'))
  })

  return (
    <Formik
      initialValues={{
        workflowName: name ?? '',
        workflowDescription: description ?? ''
      }}
      validationSchema={createWorkflowSchema}
      enableReinitialize
      onSubmit={values => {
        onSubmit({
          name: values.workflowName,
          description: values.workflowDescription
        })

        if (resetData) {
          values.workflowName = ''
          values.workflowDescription = ''
        }
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleSubmit,
        resetForm,
        handleBlur,
        touched
      }) => {
        return (
          <Modal
            open={modal}
            onClose={() => {
              setModal(false)
              resetForm()
            }}
            title={`+ ${
              update
                ? t('onboarding:workflow.update')
                : t('onboarding:workflow.add')
            }`}
          >
            <form
              id='create-workflow-form'
              className={'list-workflows-container-form'}
              onSubmit={handleSubmit}
            >
              <div className='mb-4'>
                <Input
                  label='Nome do Workflow'
                  required
                  name='workflowName'
                  type='text'
                  value={values.workflowName}
                  error={touched.workflowName ? errors.workflowName : undefined}
                  onChange={value => setFieldValue('workflowName', value)}
                  onBlur={handleBlur}
                  small
                />
              </div>

              <div className='mb-5'>
                <TextArea
                  label='Descrição do Workflow'
                  name='workflowDescription'
                  required
                  value={values.workflowDescription}
                  onChange={value =>
                    setFieldValue('workflowDescription', value)
                  }
                  onBlur={handleBlur}
                  error={
                    touched.workflowDescription
                      ? errors.workflowDescription
                      : undefined
                  }
                  small
                />
              </div>

              <Button
                className='secondary'
                disabled={disableButton}
                type='submit'
              >
                {update
                  ? t('createWorkflow:update')
                  : t('createWorkflow:create')}
              </Button>
            </form>
          </Modal>
        )
      }}
    </Formik>
  )
}
