import React from 'react'

import useStore from 'store'
import * as notificationActions from 'store/notification/actions'

interface UseStoreNotification {
  showNotification: (payload: NotificationConfig) => void
}

export const useStoreNotification = (): UseStoreNotification => {
  const { dispatch } = useStore()

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  return { showNotification }
}
