import React, { useState, useEffect } from 'react'

import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation, useParams } from 'react-router-dom'

import { CandidateDetailHeader } from 'components/CandidateDetailHeader/CandidateDetailHeader'
import { Loading } from 'components/Loading/Loading'
import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import { AboutContainer } from 'containers/CandidateDetail/AboutContainer/AboutContainer'
import {
  HistoryContainer,
  History
} from 'containers/CandidateDetail/HistoryContainer/HistoryContainer'
import * as candidatesAPI from 'services/api/candidates'
import * as jobsAPI from 'services/api/jobs'
import * as workflowsAPI from 'services/api/workflow'
import useStore from 'store'
import * as NotificationsActions from 'store/notification/actions'
import ReactGA from 'utils/analytics'
import { dateToTimestamp, getFullYear, timestampToDate } from 'utils/date'
import { snakeKeyToCamelCase } from 'utils/snakeToCamelCase'

i18n.init({ interpolation: { escapeValue: false } })

const workflows = {}

export const CandidateDetail: React.FC = () => {
  const location = useLocation()
  const { uuid: candidateUUID } = useParams<{ uuid: string }>()

  const jobUUID = location.state?.jobUUID
  const jobStages = location.state?.stages
  const applicationUUID = location.state?.applicationUUID

  const [historic, setHistoric] = useState<History[]>([])
  const [applicationsPage, setApplicationsPage] = useState<number>(1)
  const [hasMoreHistoric, setHasMoreHistoric] = useState<boolean>(true)
  const [candidate, setCandidate] = useState<Candidate>()

  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation('')
  const { dispatch, selectors } = useStore()
  const userName = selectors.auth.fullName()
  const expandedMenu = selectors.asideMenu.isExpanded()

  useEffect(() => {
    getCandidate(candidateUUID as string)

    ReactGA.pageView()
  }, [])

  const getCandidate = (candidateUUID: string): void => {
    setLoading(true)
    try {
      candidatesAPI.detail(candidateUUID as string).then(response => {
        const [error, data] = response
        if (error) {
          return dispatch(
            NotificationsActions.show({
              type: 'error',
              message: t('errors.onRequestFail')
            })
          )
        }

        setCandidate(snakeKeyToCamelCase(data) as Candidate)
        getApplications(applicationsPage, data as Candidate)
      })
    } catch (error) {
      dispatch(
        NotificationsActions.show({
          type: 'error',
          message: t('errors.onRequestFail')
        })
      )
    } finally {
      setLoading(false)
    }
  }

  const getApplications = (page: number, candidate: Candidate) => {
    candidatesAPI
      .applications({
        uuid: candidateUUID as string,
        page,
        perPage: 3
      })
      .then(response => {
        const [error, applications] = response
        if (error || !applications) return
        if (!applications.length) setHasMoreHistoric(false)
        getApplicationsData(applications, candidate)
      })
  }

  const getApplicationsData = async (
    applications: JobApplicationByCandidate[],
    candidate: Candidate
  ) => {
    const historicData: History[] = []

    for (let index = 0; index < applications.length; index++) {
      const application = applications[index]
      const [, notesData] = await getNotesByApplication(
        application.uuid,
        application.job.uuid
      )

      const [, logsData] = await getLogsByApplication(
        application.uuid,
        application.job.uuid
      )

      const [, questionsData] = await getAnswersByApplication(
        application.uuid,
        application.job.uuid
      )

      const [, reviewData] = await jobsAPI.reviews({
        applicationUUID: application.uuid,
        jobUUID: application.job.uuid
      })

      const [, jobData] = await jobsAPI.read(application.job.uuid, true)

      if (jobData && !workflows[jobData?.hiring_workflow.uuid]) {
        const [, workflowsStages] = await workflowsAPI.getStages({
          workflow_uuid: jobData?.hiring_workflow.uuid as string,
          per_page: 100,
          page: 1,
          job_uuid: jobData?.uuid || ''
        })

        workflows[jobData?.hiring_workflow.uuid] = workflowsStages?.data.sort(
          (a, b) => a.order - b.order
        )
      }

      const formatedLogs = logsData.length
        ? logsData.map(log => {
            const workflowStage = workflows[
              jobData?.hiring_workflow?.uuid
            ].find(
              (workflow: any) => workflow.uuid === log.hiringWorkflowStage.uuid
            )
            const stageName = workflowStage
              ? t(`defaultWorkflowStages:${workflowStage.name}`)
              : ''
            const authorName = log.author
              ? `${log.author.firstname} ${log.author.lastname}`
              : ''

            return {
              ...logsData,
              description: log.author
                ? t('candidateDetail:movedTo', {
                    stageName,
                    authorName
                  })
                : t('candidateDetail:appliedTo')
            }
          })
        : []

      historicData.push({
        candidateName: `${candidate?.firstname} ${candidate?.lastname}`,
        applicationUUID: application.uuid,
        jobUUID: application.job.uuid,
        jobName: jobData?.name as string,
        jobAppliedIn: timestampToDate(application.createdAt) as Date,
        review: reviewData?.review as number,
        stages: workflows[jobData?.hiring_workflow.uuid as string],
        logs: formatedLogs,
        notes: notesData.length
          ? notesData.map(note => ({
              id: note.uuid,
              date: timestampToDate(note.createdAt),
              name: `${note.author.firstname} ${note.author.lastname}`,
              content: note.content
            }))
          : [],
        answers: questionsData.length
          ? questionsData.map(question => ({
              id: question.uuid,
              question: question.question,
              answers: question.answers
            }))
          : [],
        workspace: jobData?.workspace
      })
    }

    setHistoric(prevState => [...prevState, ...historicData])
    setApplicationsPage(prevState => prevState + 1)
  }

  const getNotesByApplication = async (
    applicationUUID: string,
    jobUUID: string
  ): Promise<[string | undefined, JobApplicationNote[]]> =>
    new Promise(resolve => {
      jobsAPI
        .notes({ jobUUID, applicationUUID, perPage: 100 })
        .then(response => {
          const [error, data] = response
          if (error) resolve([error.toString(), []])
          resolve([
            undefined,
            (data || ([] as unknown)) as JobApplicationNote[]
          ])
        })
    })

  const getAnswersByApplication = async (
    applicationUUID: string,
    jobUUID: string
  ): Promise<[string | undefined, JobApplicationAnswers[]]> =>
    new Promise(resolve => {
      jobsAPI.answers({ jobUUID, applicationUUID }).then(response => {
        const [error, data] = response
        if (error) resolve([error.toString(), []])
        resolve([
          undefined,
          (data || ([] as unknown)) as JobApplicationAnswers[]
        ])
      })
    })

  const getLogsByApplication = async (
    applicationUUID: string,
    jobUUID: string
  ): Promise<[string | undefined, LogsSchema[]]> =>
    new Promise(resolve => {
      jobsAPI
        .logs({ jobUUID, applicationUUID, perPage: 100 })
        .then(response => {
          const [error, data] = response
          if (error) resolve([error.toString(), []])
          resolve([undefined, (data || ([] as unknown)) as LogsSchema[]])
        })
    })

  const addNote = async (
    applicationUUID: string,
    jobUUID: string,
    note: string
  ): Promise<boolean> => {
    const [error, data] = await jobsAPI.addNote({
      applicationUUID,
      jobUUID,
      note
    })

    if (!data) return false

    const newHistoric = historic.map(history => {
      if (history.applicationUUID !== applicationUUID) {
        return history
      }

      return {
        ...history,
        notes: [
          ...history.notes,
          {
            id: data.uuid,
            date: timestampToDate(data.createdAt),
            name: `${data.author.firstname} ${data.author.lastname}`,
            content: data.content
          }
        ]
      }
    })

    setHistoric(newHistoric)
    return error === null
  }

  const [refList, setRefList] = useState<{
    [k in string]: {
      ref: React.RefObject<HTMLDivElement> | string
      position?: string
    }
  }>({
    1: { ref: '', position: '' },
    2: { ref: '', position: '' },
    3: { ref: '', position: '' },
    4: { ref: '', position: '' },
    5: { ref: '', position: '' }
  })

  const setHistoryRefs = value => {
    const refs = { ...refList }

    refs[1] = { ref: value.historyRef }
    refs[3] = { ref: value.noteRef }
    refs[5] = { ref: value.noteRef }

    setRefList(refs)
  }

  const setHeaderRefs = value => {
    const refs = { ...refList }

    refs[2] = { ref: value }

    setRefList(refs)
  }

  const getYear = (timestamp: number) =>
    timestamp ? getFullYear(timestampToDate(timestamp)) : t('common.current')

  const moveCandidateToStage = (
    stageUUID: string,
    jobUUID: string,
    applicationUUID: string
  ) => {
    jobsAPI
      .updateCandidateAplication({
        job_uuid: jobUUID,
        aplication_uuid: applicationUUID,
        hiring_workflow_stage: stageUUID
      })
      .then(async response => {
        const [error, data] = response
        showNotification({
          message: t(error ? 'errors:onRequestFail' : 'success:candidateMoved'),
          type: error ? 'error' : 'success'
        })

        const [, jobData] = await jobsAPI.read(jobUUID, true)

        if (!data || !jobData) return

        const newHistoric = historic.map(history => {
          if (history.applicationUUID !== applicationUUID) {
            return history
          }

          return {
            ...history,
            logs: [
              ...history.logs,
              {
                uuid: data.uuid,
                startDate: dateToTimestamp(new Date()),
                description: t('candidateDetail:movedTo', {
                  authorName: userName,
                  stageName: t(
                    `defaultWorkflowStages:${
                      workflows[jobData.hiring_workflow.uuid].find(
                        workflow =>
                          workflow.uuid === data.hiringWorkflowStage.uuid
                      ).name
                    }`
                  )
                })
              }
            ]
          }
        })

        setHistoric(newHistoric)
      })
  }

  const showNotification = (payload: NotificationConfig) =>
    dispatch(NotificationsActions.notify(payload))

  const addReview = async (
    applicationUUID: string,
    jobUUID: string,
    review: number
  ) => {
    await jobsAPI
      .addReview({
        applicationUUID,
        jobUUID,
        review
      })
      .then(response => {
        const [error, data] = response

        dispatch(
          NotificationsActions.show({
            type: error ? 'error' : 'success',
            message: t(error ? 'errors:addReview' : 'success:addReview')
          })
        )

        if (!data) return false

        const newHistoric = historic.map(history => {
          if (history.applicationUUID !== applicationUUID) {
            return history
          }

          return { ...history, review: data.review }
        })

        setHistoric(newHistoric)
      })
  }

  const downloadAttachedFile = async (file: string) => {
    try {
      if (candidateUUID !== undefined) {
        const [error, data] = await candidatesAPI.downloadAttachedFile(
          candidateUUID,
          file
        )
        if (error) throw new Error(error.toString())
        if (data !== null) {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${file}`)
          document.body.appendChild(link)
          link.click()
        }
      }
    } catch (error) {
      showNotification({
        message: t('errors:downloadAttachedFile'),
        type: 'error'
      })
    }
  }

  const getAge = () => {
    const birthdate = timestampToDate(Number(candidate?.birthdate))

    return (
      Math.floor(
        (Date.now() / 1000 - Number(candidate?.birthdate)) / 31_557_600
      ) +
      ' ' +
      t('candidateInfo:years') +
      ' (' +
      birthdate +
      ')'
    )
  }

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Onboarding reflist={refList as RefList} page='candidateDetail' />

          <CandidateDetailHeader
            getRefs={setHeaderRefs}
            currentStep='teste'
            stages={jobStages}
            onStageChange={(stageUUID: string) =>
              moveCandidateToStage(
                stageUUID,
                jobUUID as string,
                applicationUUID as string
              )
            }
            candidateUUID={candidate?.uuid as string}
            user={`${candidate?.firstname} ${candidate?.lastname}`}
            facebook={candidate?.facebookProfile}
            twitter={candidate?.twitterProfile}
            linkedin={candidate?.linkedinProfile}
            whatsapp={candidate?.phone}
            candidateEmail={candidate?.email}
          />

          <AboutContainer
            about={candidate?.about}
            certificates={candidate?.certificates.map(certificate => ({
              title: certificate.name,
              // @ts-ignore
              subTitle: certificate.working_hours
            }))}
            education={candidate?.educationalExperiences?.map(
              educationalExperience => ({
                title: `${educationalExperience.course} - ${educationalExperience.institution}`,
                subTitle: `${getYear(
                  educationalExperience.start_date
                )} - ${getYear(educationalExperience.end_date)}`,
                content: educationalExperience.description
              })
            )}
            languages={candidate?.languages?.map(language => ({
              name: language.language,
              level: t(`languageLevelOptions:${language.fluency}`)
            }))}
            experience={candidate?.professionalExperiences.map(
              professionalExperience => ({
                title: `${professionalExperience.role} - ${professionalExperience.company}`,
                // @ts-ignore
                subTitle: `${getYear(
                  // @ts-ignore
                  professionalExperience.start_date
                )} - ${
                  // @ts-ignore
                  professionalExperience.end_date === 0
                    ? t('candidateDetail:current') // @ts-ignore
                    : getYear(professionalExperience.end_date)
                }`,
                content: professionalExperience.description
              })
            )}
            personalInfo={{
              email: candidate?.email,
              gender: candidate?.sex && t(`common:gender.${candidate?.sex}`),
              localization:
                candidate?.fullAddress || candidate?.geoLocation || '',
              birthdate: getAge(),
              maritalStatus:
                candidate?.maritalStatus &&
                t(`common:civilStatus.${candidate?.maritalStatus}`),
              phone: candidate?.phone,
              possibilityChange:
                candidate?.cityRelocation?.toString() === 'true'
                  ? t('aboutContainer:yes')
                  : t('aboutContainer:not'),
              salaryExpectation: candidate?.salaryExpectation?.toFixed(0)
            }}
            attachments={candidate?.attachments}
            downloadAttachedFile={downloadAttachedFile}
          />
          <InfiniteScroll
            dataLength={historic.length}
            next={() =>
              getApplications(applicationsPage, candidate as Candidate)
            }
            hasMore={hasMoreHistoric}
            style={{ overflow: 'hidden' }}
            loader={<Loading />}
          >
            <>
              <div className='history-header'>
                <div className='history-header-info'>
                  <h2 className='history-title'>
                    {t('historyContainer:title')}
                  </h2>
                  <p className='history-pretitle'>
                    {t('historyContainer:description')}
                  </p>
                </div>
              </div>

              {historic.map(history => (
                <React.Fragment key={history.applicationUUID}>
                  <HistoryContainer
                    getRefs={setHistoryRefs}
                    history={history}
                    onSendNote={note =>
                      addNote(history.applicationUUID, history.jobUUID, note)
                    }
                    onMoveToStep={stage =>
                      moveCandidateToStage(
                        stage,
                        history.jobUUID,
                        history.applicationUUID
                      )
                    }
                    onAddReview={review =>
                      addReview(
                        history.applicationUUID,
                        history.jobUUID,
                        review
                      )
                    }
                  />
                </React.Fragment>
              ))}
            </>
          </InfiniteScroll>
        </>
      )}
    </div>
  )
}
