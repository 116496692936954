/* eslint-disable indent */
import React, { useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import badgeGold from 'assets/images/badges/blank-gold-s.png'
import badgeGreen from 'assets/images/badges/blank-green-s.png'
import badgePlus from 'assets/images/badges/blank-s-plus.png'
import { Button } from 'components/Button/Button'

import './WorkflowStep.scss'

type Props = {
  kind: 'default' | 'custom' | 'add'
  title?: string
  description?: string
  onCreate?: () => void
  step?: number
  getRefs?: (value: WorkflowStepCardRefs) => void
}

export type WorkflowStepCardRefs = { add?: React.RefObject<HTMLDivElement> }

export const WorkflowStep: React.FC<Props> = ({
  kind,
  title,
  description,
  onCreate,
  step,
  getRefs
}) => {
  const add = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('workflowStepCard')

  useEffect(() => {
    const obj = {
      add
    }

    if (getRefs !== undefined) {
      getRefs(obj)
    }
  }, [])

  return (
    <div className='workflow-step-wrapper'>
      <div className='content'>
        {kind === 'add' ? (
          <div ref={add}>
            <Button
              className='add-workflow-step'
              style={{ padding: '0 16px' }}
              onClick={onCreate}
            >
              + {t('create')}
            </Button>
          </div>
        ) : (
          <div>
            <p className='title'>{title}</p>
            {description && <p className='description'>{description} </p>}
          </div>
        )}
      </div>
    </div>
  )
}
