import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import br from 'assets/images/flags/brazil.png'
import es from 'assets/images/flags/spain.png'
import us from 'assets/images/flags/usa.png'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { Input } from 'components/Input/Input'
import { Select } from 'components/Select/Select'
import { useReCAPTCHA } from 'hooks/useReCAPTCHA'
import i18n from 'i18n'
import { list } from 'services/api/acquisitionChannel'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import * as notificationActions from 'store/notification/actions'
import ReactGA from 'utils/analytics'
import './LoginCard.scss'

export const CardFooter: React.FC = () => {
  const { t } = useTranslation('login')

  return (
    <>
      <p className='lined-text'>{t('aboutUs')}</p>

      <div className='user-stats'>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <a
              href='https://recrud.zendesk.com/hc'
              target='_blank'
              rel='noreferrer'
            >
              {t('whatsRecrud')}
            </a>
          </p>
        </div>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <Link to={{ pathname: '/content/terms' }}>{t('terms')}</Link>
          </p>
        </div>
      </div>
    </>
  )
}

type LoginProps = {
  active: boolean
  onButtonClick: (payload: Omit<LoginPayload, 'recaptcha'>) => void
  loading: boolean
}

const Login: React.FC<LoginProps> = ({ active, onButtonClick, loading }) => {
  const { t } = useTranslation('login')
  const { t: errorsTranslations } = useTranslation('errors')

  const loginFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(errorsTranslations('emailInvalid'))
      .required(errorsTranslations('requiredField')),
    password: Yup.string()
      .min(6, errorsTranslations('passwordMin'))
      .required(errorsTranslations('requiredField'))
  })

  return (
    <div className={`card ${active ? 'active' : 'hidden'}`}>
      <h2 className='mb-3'>{t('loginTitle')}</h2>

      <p className='lined-text mt-4'>{t('language')}</p>

      <div className='row d-flex justify-content-center align-items-center'>
        <Button
          className={classNames('language-button', {
            active: i18n.language === 'en-US'
          })}
          onClick={() => i18n.changeLanguage('en-US')}
        >
          <img
            src={us}
            alt='english'
            width='35'
            style={{ position: 'relative', top: '0', left: '0' }}
          />
        </Button>

        <Button
          className={classNames('language-button', {
            active: i18n.language === 'pt-BR'
          })}
          onClick={() => i18n.changeLanguage('pt-BR')}
        >
          <img
            src={br}
            alt='portugues'
            width='35'
            style={{ position: 'relative', top: '0', left: '0' }}
          />
        </Button>

        <Button
          className={classNames('language-button', {
            active: i18n.language === 'es'
          })}
          onClick={() => i18n.changeLanguage('es')}
        >
          <img
            src={es}
            alt='español'
            width='35'
            style={{ position: 'relative', top: '0', left: '0' }}
          />
        </Button>
      </div>

      <Formik
        initialValues={{ email: '', password: '', keepConnected: true }}
        validationSchema={loginFormSchema}
        onSubmit={values => {
          onButtonClick(values)
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          setTouched,
          touched,
          setFieldTouched,
          setValues,
          setErrors
        }) => {
          useEffect(() => {
            const values = { email: '', password: '', keepConnected: true }
            setValues(values)
            setErrors({ ...values, keepConnected: '' })
            setTouched({})
          }, [active])

          return (
            <form className='form mt-5' onSubmit={handleSubmit}>
              <Input
                label={t('email')}
                type='text'
                name='email'
                value={values.email}
                onChange={value => setFieldValue('email', value)}
                onBlur={() => setFieldTouched('email', true)}
                error={touched.email ? errors.email : undefined}
                withFocus
              />

              <Input
                label={t('password')}
                type='password'
                name='password'
                value={values.password}
                onChange={value => setFieldValue('password', value)}
                onBlur={() => setFieldTouched('password', true)}
                error={touched.password ? errors.password : undefined}
                withFocus
              />

              <div className='form-row'>
                <Checkbox
                  label={t('loginCheckBox')}
                  name='remember'
                  checked={values.keepConnected}
                  onChange={value => setFieldValue('keepConnected', value)}
                />

                <Link to='/forgot-password'>{t('forgotPassword')}</Link>
              </div>

              <Button
                disabled={loading}
                loading={loading}
                className='secondary'
                type='submit'
              >
                {t('loginAccount')}
              </Button>
            </form>
          )
        }}
      </Formik>

      <CardFooter />
    </div>
  )
}

type RegisterProps = {
  active: boolean
  onButtonClick: (payload: Omit<RegisterPayload, 'recaptcha'>) => void
  loading: boolean
  acquisitionChannel: boolean
  acquisitionChannelData: AcquisitionChannel[]
}

const Register: React.FC<RegisterProps> = ({
  active,
  onButtonClick,
  loading,
  acquisitionChannel,
  acquisitionChannelData
}) => {
  const [terms, setTerms] = useState(false)
  const { t } = useTranslation('login')
  const { t: errorsTranslations } = useTranslation('errors')

  const signupFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(errorsTranslations('emailInvalid'))
      .required(errorsTranslations('requiredField')),
    firstName: Yup.string().required(errorsTranslations('requiredField')),
    lastName: Yup.string().required(errorsTranslations('requiredField')),
    password: Yup.string()
      .required(errorsTranslations('requiredField'))
      .min(6, errorsTranslations('passwordMin')),
    repassword: Yup.string()
      .required(errorsTranslations('requiredField'))
      .oneOf([Yup.ref('password')], t('passwordConfirm')),
    acquisitionChannel: acquisitionChannel
      ? Yup.string().required(errorsTranslations('requiredField'))
      : Yup.string()
  })

  return (
    <div className={`card register ${active ? 'active' : 'hidden'}`}>
      <h2>{t('registerTitle')}</h2>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          repassword: '',
          acquisitionChannel: ''
        }}
        onSubmit={onButtonClick}
        validationSchema={signupFormSchema}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          setValues,
          setErrors,
          setTouched,
          touched,
          handleBlur,
          setFieldTouched
        }) => {
          useEffect(() => {
            const values = {
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              repassword: '',
              acquisitionChannel: ''
            }
            setValues(values)
            setTouched({})
            setErrors({})
          }, [active])
          return (
            <form className='form' onSubmit={handleSubmit}>
              <Input
                label={t('firstname')}
                type='text'
                name='first-name'
                value={values.firstName}
                onChange={value => {
                  setFieldValue('firstName', value)
                }}
                onBlur={() => {
                  setFieldTouched('firstName', true)
                }}
                error={touched.firstName ? errors.firstName : ''}
              />

              <Input
                label={t('lastname')}
                type='text'
                name='last-name'
                value={values.lastName}
                onChange={value => {
                  setFieldValue('lastName', value)
                }}
                onBlur={() => {
                  setFieldTouched('lastName', true)
                }}
                error={touched.lastName ? errors.lastName : ''}
              />

              <Input
                label={t('email')}
                type='text'
                name='email'
                value={values.email}
                onChange={value => {
                  setFieldValue('email', value)
                  setFieldTouched('email', true)
                }}
                onBlur={() => {
                  setFieldTouched('email', true)
                }}
                error={touched.email ? errors.email : ''}
              />

              <Input
                label={t('password')}
                type='password'
                name='password'
                value={values.password}
                onChange={value => {
                  setFieldValue('password', value)
                }}
                onBlur={() => {
                  setFieldTouched('password', true)
                }}
                error={touched.password ? errors.password : ''}
              />

              <Input
                label={t('repeatPassword')}
                type='password'
                name='repassword'
                value={values.repassword}
                onChange={value => {
                  setFieldValue('repassword', value)
                }}
                onBlur={() => {
                  setFieldTouched('repassword', true)
                }}
                error={touched.repassword ? errors.repassword : ''}
              />

              {acquisitionChannel && (
                <div className='form-input '>
                  <Select
                    className='input'
                    label={t('login:acquisitionChannel')}
                    required={true}
                    value={values.acquisitionChannel}
                    name='acquisitionChannel'
                    onBlur={handleBlur}
                    onChange={selectedOptions => {
                      setFieldValue(
                        'acquisitionChannel',
                        selectedOptions.currentTarget.selectedOptions[0].value
                      )
                    }}
                    error={
                      touched.acquisitionChannel
                        ? errors.acquisitionChannel
                        : ''
                    }
                  >
                    <option value=''>{t('select:acquisitionChannel.0')}</option>
                    {acquisitionChannelData.map(
                      (option, index) =>
                        option.name !== 'Indication' &&
                        option.status === 'active' && (
                          <option
                            value={option.id}
                            key={`${index}-${option.name}`}
                          >
                            {option.name}
                          </option>
                        )
                    )}
                  </Select>
                </div>
              )}

              <div className='form-row'>
                <Checkbox
                  label={t('registerCheckBox')}
                  name='terms'
                  checked={terms}
                  onChange={setTerms}
                />
              </div>

              <Button
                disabled={!terms || loading}
                loading={loading}
                className='primary'
              >
                {t('registerNow')}
              </Button>
            </form>
          )
        }}
      </Formik>

      <CardFooter />
    </div>
  )
}

type LoginCardProps = {
  kind: 'login' | 'register' | 'plans'
  onChangeKind: (value: 'login' | 'register') => void
}

export const LoginCard: React.FC<LoginCardProps> = ({ kind, onChangeKind }) => {
  const { dispatch, selectors } = useStore()
  const isLoading = selectors.auth.isLoading()
  const hasLoginError = selectors.auth.hasLoginError()
  const hasRegisterError = selectors.auth.hasRegisterError()
  const userLogged = selectors.auth.logged()
  const user = selectors.auth.user()
  const navigate = useNavigate()
  const { t } = useTranslation('login')
  const { getToken } = useReCAPTCHA()
  const [acquisitionChannel, setAcquisitionChannel] = useState(true)
  const [acquisitionChannelData, setAcquisitionChannelData] = useState<
    AcquisitionChannel[]
  >([])

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  useEffect(() => {
    dispatch(authActions.disableLoading())
    if (window.localStorage.getItem('associate')) {
      setAcquisitionChannel(false)
    } else {
      listAcquisitionChannel()
    }
  }, [])

  useEffect(() => {
    if (!isLoading && hasLoginError) {
      let errorMessage
      if (hasLoginError === 'BLOCKED_USER') {
        errorMessage = t('blockedUser')
      } else if (hasLoginError === 'EXPIRED_SUBSCRIPTION') {
        errorMessage = t('expiredSubscription')
      } else if (hasLoginError === 'EXPIRED_SESSION') {
        errorMessage = t('expiredSession')
      } else {
        errorMessage = t('loginError')
      }

      showNotification({ message: errorMessage, type: 'error' })
      dispatch(authActions.clearErrors())
    }
  }, [isLoading, hasLoginError])

  useEffect(() => {
    if (!isLoading && hasRegisterError) {
      localStorage.removeItem('register')
      if (hasRegisterError === 'This record already exists in our databases.') {
        showNotification({
          message: t('registerErrorDataExists'),
          type: 'error'
        })
      }
      showNotification({ message: t('registerError'), type: 'error' })
      dispatch(authActions.clearErrors())
    }
  }, [isLoading, hasRegisterError])

  useEffect(() => {
    if (!user && localStorage.getItem('register') === 'true') {
      localStorage.removeItem('auth')
      localStorage.removeItem('register')
      dispatch(authActions.logoff())
      onChangeKind('login')
      showNotification({
        message: t('signupSuccess'),
        type: 'success'
      })
      return
    }

    if (userLogged && user?.company === null && user) {
      navigate('/plans')
      return
    }

    if (userLogged && user) {
      navigate('/dashboard')
      navigate(0)
    }
  }, [userLogged])

  const listAcquisitionChannel = async () => {
    const [, data] = await list()
    if (data) {
      setAcquisitionChannelData(data)
    }
  }

  const handleLoginClick = async (payload: Omit<LoginPayload, 'recaptcha'>) => {
    const token = await getToken()
    dispatch(authActions.login({ ...payload, recaptcha: token }))

    ReactGA.event('login', {
      event_category: 'login',
      event_label: 'login'
    })
  }

  const handleRegisterClick = async (
    payload: Omit<RegisterPayload, 'recaptcha'>
  ) => {
    const token = await getToken()
    localStorage.setItem('register', 'true')

    if (payload.acquisitionChannel) {
      window.localStorage.setItem(
        'acquisitionChannel',
        payload.acquisitionChannel
      )
    }

    dispatch(
      authActions.register({
        ...payload,
        recaptcha: token
      })
    )

    ReactGA.event('create_new_account_finish', {
      event_category: 'register',
      event_label: 'create_new_account_finish'
    })
  }

  return (
    <>
      <Login
        onButtonClick={handleLoginClick}
        active={kind === 'login'}
        loading={isLoading}
      />

      <Register
        onButtonClick={handleRegisterClick}
        active={kind === 'register'}
        loading={isLoading}
        acquisitionChannel={acquisitionChannel}
        acquisitionChannelData={acquisitionChannelData}
      />
    </>
  )
}
