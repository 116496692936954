import React, { useState, useEffect } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import br from 'assets/images/flags/brazil.png'
import es from 'assets/images/flags/spain.png'
import us from 'assets/images/flags/usa.png'
import logo from 'assets/images/logo/recrud-logo-header.png'
import { Button } from 'components/Button/Button'
import { ContentContainer } from 'containers/Content/Content'
import './content.scss'
import i18n from 'i18n'
import ReactGA from 'utils/analytics'

export function ContentPage(): JSX.Element {
  const params = useParams<{ section: string }>()
  const { t } = useTranslation('content')
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  const [section, setSection] = useState(params?.section || 'whatsrecrud')

  return (
    <>
      <header className='header header-content' data-testid='recrud-header'>
        <div className='header-actions logo'>
          <div className='header-brand'>
            <div className='logo'>
              <a href='#'>
                <img src={logo} alt='Recrud Logo' />
              </a>
            </div>
          </div>
        </div>

        {/* <!-- HEADER ACTIONS --> */}
        <div className='header-actions list'>
          <div className='action-list dark align-items-center'>
            <div
              className={`action-list-item white ${
                section === 'whatsrecrud' ? 'active' : ''
              }`}
              onClick={() => setSection('whatsrecrud')}
            >
              {t('whatsrecrud.title')}
            </div>

            <div
              className={`action-list-item white ${
                section === 'terms' ? 'active' : ''
              }`}
              onClick={() => setSection('terms')}
            >
              {t('terms.title')}
            </div>

            <div
              className={'action-list-item white'}
              onClick={() => navigate('/dashboard')}
            >
              Login
            </div>

            <Button
              className={classNames('language-button action-list-item', {
                active: i18n.language === 'en-US'
              })}
              onClick={() => i18n.changeLanguage('en-US')}
            >
              <img
                src={us}
                alt='english'
                width='35'
                style={{ position: 'relative', top: '0', left: '0' }}
              />
            </Button>

            <Button
              className={classNames('language-button action-list-item', {
                active: i18n.language === 'pt-BR'
              })}
              onClick={() => i18n.changeLanguage('pt-BR')}
            >
              <img
                src={br}
                alt='portugues'
                width='35'
                style={{ position: 'relative', top: '0', left: '0' }}
              />
            </Button>

            <Button
              className={classNames('language-button action-list-item', {
                active: i18n.language === 'es'
              })}
              onClick={() => i18n.changeLanguage('es')}
            >
              <img
                src={es}
                alt='español'
                width='35'
                style={{ position: 'relative', top: '0', left: '0' }}
              />
            </Button>
          </div>
        </div>
      </header>
      {section === 'whatsrecrud' && <ContentContainer section='whatsrecrud' />}
      {section === 'privacypolicy' && (
        <ContentContainer section='privacypolicy' />
      )}
      {section === 'terms' && <ContentContainer section='terms' />}
    </>
  )
}
