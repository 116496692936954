interface FilterUsersByRoleReturn {
  filteredRecruiters: UserSchema[]
  filteredManagers: UserSchema[]
}

interface FilterUsersByRoleParams {
  data: UserSchema[]
  filterActiveUsers?: boolean
}

interface FilterUsersByRole {
  (params: FilterUsersByRoleParams): FilterUsersByRoleReturn
}

/**
 * Filters users by role.
 *
 * @param data - The array of users to filter.
 * @param filterActiveUsers - Optional. Specifies whether to filter active users only. Defaults to false.
 * @returns An object containing two arrays: `recruiters` and `managers`.
 */
export const filterUsersByRole: FilterUsersByRole = ({
  data,
  filterActiveUsers = false
}) => {
  const filteredRecruiters = data.filter(
    (item: UserSchema) =>
      item.role === 'recruiter' && (!filterActiveUsers || item.active)
  )
  const filteredManagers = data.filter(
    (item: UserSchema) =>
      item.role === 'manager' && (!filterActiveUsers || item.active)
  )

  return { filteredRecruiters, filteredManagers }
}

/**
 * Converts an array of User objects to an array of UserSchema objects.
 * @param data - The array of User objects to be converted.
 * @returns The converted array of UserSchema objects.
 */
export const convertUserToUserSchema = (data: User[]): UserSchema[] => {
  return data.map(user => ({
    ...user,
    created_at: user.createdAt,
    updated_at: user.updatedAt,
    theme_customization: user.themeCustomization
      ? {
          ...user.themeCustomization,
          partner_name: user.themeCustomization.partnerName,
          primary_color: user.themeCustomization.primaryColor,
          secondary_color: user.themeCustomization.secondaryColor,
          third_color: user.themeCustomization.thirdColor
        }
      : null
  }))
}
