import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CoinBadge from 'assets/images/badges/completedq-s.png'
import { Button } from 'components/Button/Button'
import { TimeLine } from 'components/TimeLine/TimeLine'
import useStore from 'store'

import './AboutContainer.scss'

type PersonalInfo = {
  email?: string
  phone?: string
  localization?: string
  birthdate?: string
  maritalStatus?: string
  gender?: string
  salaryExpectation?: string
  possibilityChange?: string
}

type Languages = {
  name?: string
  level?: string
}

type Education = {
  title?: string
  subTitle?: string
  content?: string
}

type Certificates = {
  title?: string
  subTitle?: string
}

type Experience = Education

type Props = {
  personalInfo?: PersonalInfo
  attachments?: Array<{ name: string; path: string }>
  downloadAttachedFile: (file: string) => void
  languages?: Array<Languages>
  about?: string
  education?: Array<Education>
  experience?: Array<Experience>
  otherInfos?: string
  certificates?: Array<Certificates>
}

export const AboutContainer: React.FC<Props> = ({
  personalInfo,
  attachments,
  downloadAttachedFile,
  about,
  education,
  experience,
  otherInfos,
  languages,
  certificates
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('aboutContainer')
  const { selectors } = useStore()
  const user = selectors.auth.user()

  const personalInfoLgpd = key => {
    const privacy = user?.company?.data_privacy_settings
    switch (key) {
      case 'birthdate':
        return privacy?.ask_birthdate
      case 'gender':
        return privacy?.ask_sex
      case 'maritalStatus':
        return privacy?.ask_marital_status
      case 'phone':
        return privacy?.ask_telephone
      case 'salaryExpectation':
        return privacy?.ask_wage_claim
      case 'possibilityChange':
        return privacy?.ask_city_change_availability
      default:
        return true
    }
  }

  return (
    <div className='about-candidate-wrapper'>
      <div className='row'>
        <div className='col-12 col-lg-3'>
          <div className=' mb-3'>
            <div className='about-card'>
              <Button
                style={{
                  backgroundColor: '#C1C1C2'
                }}
                className='secondary'
                onClick={() => history.go(-1)}
              >
                &lt; {t('goBack')}
              </Button>
            </div>
          </div>

          {!!personalInfo && !!Object.keys(personalInfo).length && (
            <div className=' mb-3'>
              <div className='about-card'>
                <p className='title'>{t('personalInfo')}</p>
                <div className='content'>
                  {Object.keys(personalInfo).map(key => {
                    return (
                      personalInfo[key] &&
                      personalInfoLgpd(key) && (
                        <div className='d-flex mb-3' key={key}>
                          <p className='personal-info title'>{t(key)}</p>{' '}
                          <p className='personal-info'>{personalInfo[key]}</p>
                        </div>
                      )
                    )
                  })}
                </div>
              </div>
            </div>
          )}

          {!!attachments?.length && (
            <div className=' mb-3'>
              <div className='about-card'>
                <p className='title'>{t('attachedFiles')}</p>
                <div className='content'>
                  {attachments.map(attachment => {
                    return (
                      <div key={attachment.path} className='download-link'>
                        <span
                          onClick={() => downloadAttachedFile(attachment.path)}
                        >
                          {attachment.name}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}

          {!!languages?.length && (
            <div className=' mb-3'>
              <div className='about-card'>
                <p className='title'>{t('languages')}</p>
                <div className='content'>
                  <TimeLine
                    items={languages.map(el => {
                      return { title: el.name, content: el.level }
                    })}
                  />
                </div>
              </div>
            </div>
          )}

          {!!certificates?.length && (
            <div className=' mb-3'>
              <div className='about-card'>
                <p className='title'>{t('certificates')}</p>
                <div className='content'>
                  {certificates?.map((el, index) => {
                    return (
                      <div className='d-flex' key={index}>
                        <img
                          src={CoinBadge}
                          alt='Badge'
                          width='35'
                          height='35'
                          className='mr-3'
                        />
                        <div className='mb-3'>
                          <p className='title'>{el.title}</p>
                          <p>
                            {t('certificateHours', {
                              hours: el.subTitle?.toLocaleString()
                            })}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='col-12 col-lg-9'>
          {about ? (
            <div className=' mb-3'>
              <div className='about-card'>
                <p className='title'>{t('about')}</p>
                <div className='content'>
                  <p className='information'>{about}</p>
                </div>
              </div>
            </div>
          ) : null}

          {!!education?.length && (
            <div className=' mb-3'>
              <div className='about-card'>
                <p className='title'>{t('education')}</p>
                <div className='content'>
                  <TimeLine items={education} />
                </div>
              </div>
            </div>
          )}

          {!!experience?.length && (
            <div className=' mb-3'>
              <div className='about-card'>
                <p className='title'>{t('experience')}</p>
                <div className='content'>
                  <TimeLine items={experience} />
                </div>
              </div>
            </div>
          )}

          {otherInfos ? (
            <div className='mb-3'>
              <div className='about-card'>
                <p className='title'>{t('otherInfo')}</p>
                <div className='content'>
                  <p className='information'>{otherInfos}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
