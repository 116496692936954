import React from 'react'

import { useTranslation } from 'react-i18next'

import './RecruiterNotes.scss'

import { ReactComponent as SendMessageIcon } from 'assets/images/icons/send-message-icon.svg'
import { BadgeList } from 'components/BadgeList/BadgeList'
import { Button } from 'components/Button/Button'
import { FormItem } from 'components/FormItem/FormItem'
import { TextArea } from 'components/TextArea/TextArea'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

import moment from 'moment'

import useStore from 'store'
import { notify } from 'store/notification/actions'
import { getDateFormat, getFullYear } from 'utils/date'
import { getFormatedDate } from 'utils/getFormatedDate'

export interface Note {
  id: string
  date: Date
  name: string
  content: string
}

interface Props {
  notes: Array<Note>
  onSendNote: (note: string) => Promise<boolean>
  jobName: string
  jobWorkspaceId: string
  review: number
  onAddReview: (review: number) => void
}

export const RecruiterNotes: React.FC<Props> = ({
  notes,
  onSendNote,
  jobName,
  jobWorkspaceId,
  review,
  onAddReview
}) => {
  const { onlyAllowOnDirectJobParent } = useWorkspacePermission()
  const { t } = useTranslation()
  const [newNote, setNewNote] = React.useState('')
  const { dispatch } = useStore()

  const formatedDate = (date: Date) => {
    const { day, month } = getFormatedDate(date)
    return `${day} ${month}`
  }

  const isAllowed = onlyAllowOnDirectJobParent(jobWorkspaceId)

  return (
    <div className='calendar-events-preview history tutorial-step-4'>
      {isAllowed && (
        <BadgeList
          title={t('recruiterNotes:review', { jobName })}
          review={review}
          onClick={onAddReview}
        />
      )}

      <p className='calendar-events-preview-title'>
        {t('recruiterNotes:notes')}
      </p>
      <div className='calendar-event-preview-list'>
        {isAllowed ? (
          <div className='calendar-event-preview small secondary tutorial-step-5'>
            <div className='calendar-event-preview-start-time'>
              <p className='calendar-event-preview-start-time-title'>
                {t('recruiterNotes:new').toLowerCase()}
              </p>
              <p className='calendar-event-preview-start-time-text'>
                {t('recruiterNotes:note').toLowerCase()}
              </p>
            </div>
            <div className='calendar-event-preview-info'>
              <FormItem>
                <TextArea
                  label={t('recruiterNotes:newNote')}
                  name='new-note'
                  small
                  onChange={value => setNewNote(value)}
                  value={newNote}
                />
              </FormItem>
              <FormItem formItemClasses=' text-right'>
                <Button
                  className='button primary padded small'
                  style={{ width: '68px', backgroundColor: '#000000' }}
                  onClick={async () => {
                    const success = await onSendNote(newNote)
                    dispatch(
                      notify({
                        type: success ? 'success' : 'error',
                        message: t(
                          success ? 'success:noteAdded' : 'error:addNoteError'
                        )
                      })
                    )

                    if (success) setNewNote('')
                  }}
                >
                  <SendMessageIcon className='button-icon' />
                </Button>
              </FormItem>
            </div>
          </div>
        ) : (
          <div className='calendar-events-not-allowed-container'>
            <p className='calendar-events-not-allowed'>
              {t('recruiterNotes:notAllowed')}
            </p>
          </div>
        )}
        {notes &&
          notes.map(note => (
            <div
              key={note.id}
              className='calendar-event-preview small secondary'
            >
              <div className='calendar-event-preview-start-time'>
                <p className='calendar-event-preview-start-time-title'>
                  {moment(note.date, getDateFormat()).format(getDateFormat())}
                </p>
                <p className='calendar-event-preview-start-time-text'>
                  {getFullYear(
                    moment(note.date, getDateFormat()).format(getDateFormat())
                  )}
                </p>
              </div>
              <div className='calendar-event-preview-info'>
                <p className='calendar-event-preview-title'>
                  {t('recruiterNotes:by', { name: note.name })}
                </p>
                <p className='calendar-event-preview-text'>{note.content}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
