export default (link: string, type: string): string => {
  if (link.includes('http')) return link

  if (!link.includes('http') && link.includes(type)) {
    return `https://${link}`
  }

  if (type === 'linkedin') return `https://${type}.com/in/${link}`

  return `https://${type}.com/${link}`
}
