import * as AsideMenuTypes from './types'

export const InitialState: AsideMenuTypes.State = {
  isExpanded: !!(
    window.localStorage.getItem('asideMenuExpanded') !== null &&
    window.localStorage.getItem('asideMenuExpanded') === 'true'
  )
}

export function reducer(
  state = InitialState,
  action: AsideMenuTypes.Actions
): AsideMenuTypes.State {
  switch (action.type) {
    case AsideMenuTypes.Types.SET_EXPANDED:
      window.localStorage.setItem(
        'asideMenuExpanded',
        action.payload.toString()
      )
      return { ...state, isExpanded: action.payload }

    default:
      return state
  }
}
