import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  DefaultModal,
  DefaultModalTemplate
} from 'components/Modals/lugarh/Default/DefaultModal'
import {
  EducationFilter,
  EducationSection
} from 'containers/CandidateFilter/EducationSection/EducationSection'
import {
  ExperienceFilter,
  ExperienceSection
} from 'containers/CandidateFilter/ExperienceSection/ExperienceSection'
import {
  GeneralFilter,
  GeneralSection
} from 'containers/CandidateFilter/GeneralSection/GeneralSection'
import {
  LanguageFilter,
  LanguageSection
} from 'containers/CandidateFilter/LanguageSection/LanguageSection'
import {
  initialFilterFormState,
  useFilterForm
} from 'context/CandidateFilterContext'
import useStore from 'store'
import './CandidatesFilter.style.scss'

interface CandidatesFilterModalProps {
  open: boolean
  onClose: () => void
  customClass?: string
  onSubmit: () => void
}

export const CandidatesFilterModal: React.FC<CandidatesFilterModalProps> = ({
  open,
  onClose,
  customClass,
  onSubmit
}) => {
  const { t } = useTranslation('candidateFilter')
  const { selectors } = useStore()
  const { filterFormState: filter, updateFilterFormState } = useFilterForm()
  const [companySettings, setCompanySettings] =
    useState<CompanySettingsAPI | null>(null)

  const onSetFilter = (value: FilterForm | SetFilterForm) => {
    // Check if value includes a GeneralFilter and treat it
    if (value.general) {
      // Convert workspace from array to string separated by commas
      const workspacesAsString = Array.isArray(value.general.workspaces)
        ? value.general.workspaces.join(',')
        : value.general.workspaces

      const treatedGeneralFilter = {
        ...value.general,
        workspaces: workspacesAsString
      }

      // Update the value with the treated GeneralFilter
      const updatedValue = {
        ...value,
        general: treatedGeneralFilter
      }

      updateFilterFormState(updatedValue)
    } else {
      // If no General, call updateFilterFormState directly
      updateFilterFormState(value as FilterForm)
    }
  }

  const handleSubmitFilter = () => {
    onSubmit()
    onClose()
  }

  const handleClearFilters = () => {
    onSetFilter(initialFilterFormState)
    onSubmit()
    onClose()
  }

  useEffect(() => {
    const user = selectors.auth.user()
    setCompanySettings(user?.company?.data_privacy_settings || null)
  }, [])

  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      customClass={customClass}
      modalTitle={t(`modalTitle`)}
      template={DefaultModalTemplate.RIGHT}
    >
      <DefaultModal.Content>
        <div className='mui-modal__content-container'>
          <div className='candidate-filter-container'>
            <GeneralSection
              companySettings={companySettings}
              onChange={(general: GeneralFilter) =>
                onSetFilter({ ...filter, general })
              }
              filter={filter}
              clearFilters={handleClearFilters}
            />
            <EducationSection
              onChange={(education: EducationFilter) =>
                onSetFilter({ ...filter, education })
              }
              filter={filter}
            />
            <ExperienceSection
              onChange={(experience: ExperienceFilter) =>
                onSetFilter({ ...filter, experience })
              }
              filter={filter}
            />
            <LanguageSection
              onChange={(language: LanguageFilter) =>
                onSetFilter({ ...filter, language })
              }
              filter={filter}
            />
          </div>
        </div>
        <div className='mui-modal__footer'>
          <button
            type='submit'
            className='form__button btn__primary mui-modal__unique-button'
            onClick={handleSubmitFilter}
          >
            {t(`buttons.applyFilters`)}
          </button>
        </div>
      </DefaultModal.Content>
    </DefaultModal>
  )
}
