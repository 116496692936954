import React from 'react'

export interface FormProps {
  id: string
  onSubmit: (e: any) => void
  autoComplete?: boolean
  children: React.ReactNode
}

export interface RowAndItem {
  children: React.ReactNode
}

export const Form = ({
  id,
  children,
  onSubmit,
  autoComplete = false
}: FormProps) => (
  <form
    id={id}
    className='form'
    data-testid='form'
    onSubmit={onSubmit}
    autoComplete={autoComplete ? 'on' : 'off'}
  >
    {children}
  </form>
)

export const Row = ({ children }: RowAndItem) => (
  <div className='form-row'>{children}</div>
)

export const Item = ({ children }: RowAndItem) => (
  <div className='form-item'>{children}</div>
)
