import React, { lazy } from 'react'

import { Theme } from 'context/ThemeContext/ThemeContext.config'

import { RoutePaths, routesConfig } from './Router.config'

export type PagesMapping = Record<RoutePaths, PageToLoad>

export const pagesMapping = (): PagesMapping => {
  const setPageDetails: SetPageDetails = ({
    path,
    theme,
    pageFolder,
    recrudPage
  }) => {
    const pageToLoad =
      theme === Theme.RECRUD
        ? recrudPage
        : lazy(() => import(`pages/${pageFolder}/${theme}/${pageFolder}.page`))
    const themePage = { [path]: pageToLoad } as SetPageDetailsReturn
    return themePage
  }

  const pages = routesConfig.reduce((acc, route) => {
    const pageDetails = setPageDetails(route)
    return { ...acc, ...pageDetails }
  }, {} as PagesMapping)

  return pages
}
