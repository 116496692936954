import React from 'react'

type RefType = React.RefObject<HTMLElement>

type CallbackType = () => void

export const useClickOutside = (ref: RefType, callback: CallbackType): void => {
  const handleClick = (e: MouseEvent): void => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback()
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref, callback])
}
