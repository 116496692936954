import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Button/Button'
import { Loading } from 'components/Loading/Loading'
import { Modal } from 'components/Modal/Modal'
import {
  WorkflowCard,
  WorkflowCardRefs
} from 'components/WorkflowCard/WorkflowCard'
import { WorkflowCreate } from 'components/WorkflowCreate/WorkflowCreate'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { workflow } from 'services/api'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import ReactGA from 'utils/analytics'

import './ListWorkflowsContainer.scss'
import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'

export const mockWorkspaceList: WorkspaceSchema[] = [
  {
    uuid: '1',
    name: 'Workspace 1',
    slug: 'workspace-1',
    role: 'admin',
    status: 'active',
    users: ['1'],
    user_count: 1,
    id_matriz: '1',
    created_at: '2021-10-01T00:00:00Z',
    updated_at: '2021-10-01T00:00:00Z'
  },
  {
    uuid: '2',
    name: 'Workspace 2',
    slug: 'workspace-2',
    role: 'admin',
    status: 'active',
    users: ['2'],
    user_count: 1,
    id_matriz: '2',
    created_at: '2021-10-01T00:00:00',
    updated_at: '2021-10-01T00:00:00'
  },
  {
    uuid: '3',
    name: 'Workspace 3',
    slug: 'workspace-3',
    role: 'admin',
    status: 'active',
    users: ['3'],
    user_count: 1,
    id_matriz: '3',
    created_at: '2021-10-01T00:00:00',
    updated_at: '2021-10-01T00:00:00'
  }
]

type Props = {
  getRefs: (value: WorkflowCardRefs) => void
}

export const ListWorkflowsContainer: React.FC<Props> = ({ getRefs }) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const { t } = useTranslation('listWorkflowsContainer')

  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [modalUpdate, setModalUpdate] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [currentUpdate, setCurrentUpdate] = useState<{
    name: string
    description: string
    uuid: string
  }>(
    {} as {
      name: string
      description: string
      uuid: string
    }
  )
  const [currentDelete, setCurrentDelete] = useState<{
    name: string
    uuid: string
  }>({} as { name: string; uuid: string })
  const [isLoading, setIsLoading] = useState(false)
  const [reset, setReset] = useState(false)
  const { dispatch } = useStore()

  const [ref, setRefs] = useState<WorkflowCardRefs>({} as WorkflowCardRefs)
  const [addButtonRef, setAddButtonRef] = useState<WorkflowCardRefs>(
    {} as WorkflowCardRefs
  )

  const [workflows, setWorkflows] = useState<WorkflowSchema[]>()

  useEffect(() => {
    if (page === 1) fetchWorkflows()
  }, [page])

  useEffect(() => {
    let refs = {
      ...addButtonRef
    }

    if (ref.editButton) refs = { ...refs, ...ref }
    getRefs(refs)
  }, [addButtonRef, ref])

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const fetchWorkflows = async () => {
    try {
      const [error, data] = await workflow.read({ page, per_page: 10 })
      if (error) throw new Error(error.toString())
      setWorkflows([...(workflows || []), ...(data?.data || [])])
      setHasMore(data?.data.length === 10)
      setPage(page + 1)
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const submitForm = async (payload: { name: string; description: string }) => {
    try {
      setIsLoading(true)
      const [error] = await workflow.create(payload)

      if (error) throw new Error(error.toString())

      setWorkflows([])
      setHasMore(true)
      setPage(1)
      setReset(true)
      setModal(false)
      showNotification({
        message: t('successCreateMessage'),
        type: 'success'
      })

      ReactGA.event('create_new_workflow_finish', {
        event_category: 'hiring_workflows',
        event_label: 'create_new_workflow_finish'
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const submitFormUpdate = async (payload: {
    name: string
    description: string
  }) => {
    try {
      setIsLoading(true)
      const [error] = await workflow.update(payload, currentUpdate.uuid)

      if (error) throw new Error(error.toString())

      setWorkflows([])
      setHasMore(true)
      setPage(1)
      setReset(true)
      setModalUpdate(false)
      setCurrentUpdate({ uuid: '', name: '', description: '' })
      showNotification({
        message: t('successUpdateMessage'),
        type: 'success'
      })

      ReactGA.event('edit_workflow', {
        event_category: 'hiring_workflows',
        event_label: 'edit_workflow'
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const removeWorkflow = async () => {
    try {
      const [error] = await workflow.remove(currentDelete.uuid)
      if (error) throw new Error(error.toString())
      setCurrentDelete({ name: '', uuid: '' })
      setWorkflows([])
      setPage(1)
      setHasMore(true)
      showNotification({
        message: t('successDeleteMessage'),
        type: 'success'
      })

      ReactGA.event('delete_workflow', {
        event_category: 'hiring_workflows',
        event_label: 'delete_workflow'
      })
    } catch (error) {
      if (error.message === 'locked') {
        showNotification({ message: t('lockedDeletion'), type: 'error' })
      } else {
        showNotification({ message: error.message, type: 'error' })
      }
    }
  }

  const handleCreateNew = () => {
    setModal(true)

    ReactGA.event('create_new_workflow_start', {
      event_category: 'hiring_workflows',
      event_label: 'create_new_workflow_start'
    })
  }

  const duplicateWorkflow = async (
    name: string,
    description: string,
    id: string
  ) => {
    const [, createdWorkflow] = await workflow.create({
      name,
      description
    })

    const [, createdWorkflowStages] = await workflow.getStages({
      workflow_uuid: createdWorkflow?.uuid as string,
      per_page: 100,
      page: 1,
      job_uuid: ''
    })

    const [, originWorkflowStages] = await workflow.getStages({
      workflow_uuid: id,
      per_page: 100,
      page: 1,
      job_uuid: ''
    })

    if (originWorkflowStages !== null && originWorkflowStages.data !== null) {
      for await (const stage of originWorkflowStages?.data) {
        if (!stage.deletable) {
          await workflow.updateAutoSendAndEmailContent(
            {
              email_content: stage.email_content,
              email_subject: stage.email_subject,
              email_autosend: stage.email_autosend,
              uuid: createdWorkflowStages?.data.find(stg => {
                return stg.name === stage.name
              })?.uuid as string
            },
            createdWorkflow?.uuid as string
          )
        } else {
          await workflow.createHiringStage(
            {
              name: stage.name as string,
              description: stage.description as string,
              email_autosend: stage.email_autosend as boolean,
              email_content: stage.email_content as string,
              email_subject: stage.email_subject as string,
              order: stage.order as number
            },
            createdWorkflow?.uuid as string
          )
        }
      }
    }

    setWorkflows([])
    setHasMore(true)
    setPage(1)
    setReset(true)
    setModalUpdate(false)
    setCurrentUpdate({ uuid: '', name: '', description: '' })
    showNotification({
      message: t('successDuplicateMessage'),
      type: 'success'
    })

    ReactGA.event('duplicate_workflow', {
      event_category: 'hiring_workflows',
      event_label: 'duplicate_workflow'
    })
  }

  return (
    <>
      <div className='modal-confirmation'>
        <Modal
          title={t('modalTitle')}
          open={confirmation}
          onClose={() => setConfirmation(false)}
        >
          <div className='modal-confirmation-content'>
            <p className='content'>
              <span dangerouslySetInnerHTML={{ __html: t('modalText') }} />
              <strong> {currentDelete.name}</strong>
            </p>

            <div className='row'>
              <div className='col-6'>
                <Button
                  className='secondary'
                  onClick={() => {
                    removeWorkflow()
                    setConfirmation(false)
                  }}
                >
                  {t('yes')}
                </Button>
              </div>

              <div className='col-6'>
                <Button
                  className='danger'
                  onClick={() => {
                    setCurrentDelete({ name: '', uuid: '' })
                    setConfirmation(false)
                  }}
                >
                  {t('no')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <WorkflowCreate
        modal={modal}
        setModal={setModal}
        onSubmit={submitForm}
        onReset={setReset}
        disableButton={isLoading}
        resetData={reset}
      />

      <WorkflowCreate
        modal={modalUpdate}
        setModal={setModalUpdate}
        onSubmit={submitFormUpdate}
        onReset={setReset}
        disableButton={isLoading}
        resetData={reset}
        update={true}
        name={currentUpdate.name}
        description={currentUpdate.description}
      />
      <InfiniteScroll
        dataLength={workflows?.length || 0}
        next={fetchWorkflows}
        hasMore={hasMore}
        loader={<Loading />}
        className='list-workflows-content'
      >
        {dontShowOnGeralWorkspace && (
          <WorkflowCard
            status='add-new'
            onCreateNew={handleCreateNew}
            getRefs={setAddButtonRef}
          />
        )}

        {workflows?.map((el, index) => {
          return (
            <WorkflowCard
              status='active'
              key={index}
              name={el.name}
              description={el.description}
              workspaceList={el.workspaces}
              uuid={el.uuid}
              onView={() =>
                navigate(`/hiring-workflow-edit/${el.name}/${el.uuid}`)
              }
              onEdit={() => {
                setCurrentUpdate({
                  name: el.name,
                  description: el.description,
                  uuid: el.uuid
                })
                setModalUpdate(true)
              }}
              onDelete={() => {
                setConfirmation(true)
                setCurrentDelete(el)
              }}
              onDuplicate={() => {
                duplicateWorkflow(
                  el.name + ' ' + t('copy'),
                  el.description,
                  el.uuid
                )
              }}
              {...(!index && { getRefs: setRefs })}
            />
          )
        })}
      </InfiniteScroll>
    </>
  )
}
