import React from 'react'

import './DashboardCard.scss'

type Props = {
  title: string
  center?: boolean
  select?: any
  children?: React.ReactNode
}

export const DashboadCard: React.FC<Props> = ({
  title,
  children,
  select,
  center
}) => {
  return (
    <div className='dashboard-card-wrapper'>
      <div className='dashboard-card-select'>
        <p>{title}</p>
        {select}
      </div>
      <div className={`mt-3 w-100 h-100 ${center ? 'center' : ''}`}>
        {children}
      </div>
    </div>
  )
}
