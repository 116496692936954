import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button/Button'
import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import {
  EditWorkflowsContainer,
  EditWorkflowsRefs
} from 'containers/HiringWorkflow/EditWorkflowsContainer/EditWorkflowsContainer'
import { usePermission } from 'hooks/usePermission'

import './hiringWorkflowEdit.scss'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

import { useParams, useNavigate } from 'react-router-dom'

import useStore from 'store'
import ReactGA from 'utils/analytics'

export function HiringWorkflowEdit(): JSX.Element {
  usePermission({ moduleName: 'hiring-workflow', action: 'edit' })
  const { selectors } = useStore()
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const expandedMenu = selectors.asideMenu.isExpanded()

  const { t } = useTranslation('hiringWorkflowEdit')
  const [refList, setRefList] = useState({})

  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  const setRefs = (value: EditWorkflowsRefs) => {
    const obj: RefList = {
      1: { ref: value.defaultStage as React.MutableRefObject<HTMLElement> },
      2: {
        ref: value.createStage as React.MutableRefObject<HTMLElement>
      },
      3: {
        ref: value.customStage as React.MutableRefObject<HTMLElement>
      },
      4: {
        ref: value.deleteStage as React.MutableRefObject<HTMLElement>
      },
      5: {
        ref: value.sendEmail as React.MutableRefObject<HTMLElement>
      },
      6: {
        ref: value.editEmail as React.MutableRefObject<HTMLElement>
      }
    }

    setRefList(obj)
  }

  const help = () => {
    const lsData = JSON.parse(localStorage.getItem('onboarding') || '{}')
    lsData[JSON.parse(localStorage.getItem('auth') || '{}')?.user.uuid] = {
      ...lsData[JSON.parse(localStorage.getItem('auth') || '{}')?.user.uuid],
      ...{ workflowEdit: false }
    }
    localStorage.setItem('onboarding', JSON.stringify(lsData))
    history.go(0)
  }

  return (
    <div
      className={`content-grid ${
        expandedMenu ? 'content-grid-expanded-menu' : ''
      }`}
    >
      {dontShowOnGeralWorkspace && (
        <>
          <Onboarding page='workflowEdit' reflist={refList} />
          <div className='grid grid-12'>
            <div className='account-hub-content'>
              <div className='grid-column'>
                <div className='card card-message w-100'>
                  <p>
                    {t('firstP')}
                    <Button className='btn-link' onClick={help}>
                      {t('button')}
                    </Button>
                  </p>
                </div>

                <EditWorkflowsContainer getRefs={setRefs} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
