import React from 'react'

import './SectionBanner.scss'

interface Props {
  title: string
  text: string
  icon: string
  alt: string
}

export const SectionBanner: React.FC<Props> = ({ title, text, icon, alt }) => {
  return (
    <div className='section-banner'>
      <img className='section-banner-icon' src={icon} alt={alt + '-icon'} />
      <p className='section-banner-title' data-testid='banner-title'>
        {title}
      </p>
      <p className='section-banner-text' data-testid='banner-text'>
        {text}
      </p>
    </div>
  )
}
