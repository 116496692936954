import React, { useState, useEffect, useCallback } from 'react'

import { Input } from 'components/Input/Input'
import { TagList } from 'components/TagList/TagList'

export type InputTagProps = {
  label: string
  name: string
  onChange: (tags: string[]) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  initialTags?: string[]
  small?: boolean
  full?: boolean
  error?: string
}

export const InputTag: React.FC<InputTagProps> = ({
  label,
  name,
  onChange,
  onBlur,
  initialTags,
  small,
  full,
  error
}) => {
  const [value, setValue] = useState('')
  const [tags, setTags] = useState(initialTags || [])

  useEffect(() => {
    initialTags && setTags(initialTags)
  }, [initialTags])

  const handleRemoveTag = (selectedTag: string): void => {
    const newTags = tags.filter(tag => tag !== selectedTag)
    setTags(newTags)
    onChange([...newTags])
  }

  const handleSetTag = (text: string): void => {
    const isOnlySpaces = text.trim().length === 0
    const isInvalidText = [';', ',', ''].includes(text)
    const isLongerThanAllowed = text.length > 34

    if (!isOnlySpaces && !isInvalidText && !isLongerThanAllowed) {
      const newTags = new Set([...tags, text])
      setTags([...newTags])
      onChange([...newTags])
      setValue('')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    event.key === 'Enter' && handleSetTag(value)
  }

  const handleOnInputChange = (text: string): void => {
    const lastChar = text.slice(-1)
    const isDelimiter = [',', ';'].includes(lastChar)
    const trimmedText = text.slice(0, -1)

    if (isDelimiter && trimmedText) {
      handleSetTag(trimmedText)
      setValue('')
    } else if (!isDelimiter) {
      setValue(text)
    }
  }

  return (
    <>
      <Input
        label={label}
        type='text'
        name={name}
        value={value}
        onChange={text => handleOnInputChange(text)}
        onKeyDown={event => handleKeyDown(event)}
        small={small}
        full={full}
        onBlur={event => handleSetTag(event.target.value)}
        error={error}
      />

      <TagList
        tags={tags}
        onClick={tag => handleRemoveTag(tag)}
        isDismissible
        showCount={false}
      />
    </>
  )
}
