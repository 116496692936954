import React from 'react'
import './Loading.style.scss'

interface LoadingProps {
  className?: string
}

export const Loading: React.FC<LoadingProps> = ({ className = '' }) => (
  <div className={`loader ${className}`}>
    <div className='loader__bars'>
      <div className='loader__bar'></div>
      <div className='loader__bar'></div>
      <div className='loader__bar'></div>
      <div className='loader__bar'></div>
      <div className='loader__bar'></div>
      <div className='loader__bar'></div>
      <div className='loader__bar'></div>
      <div className='loader__bar'></div>
    </div>
  </div>
)
