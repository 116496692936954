import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { Select } from 'components/Select/Select'

import { FilterForm } from '../CandidateFilterContainer'

export interface EducationFilter {
  name: string
  course: string
  level: string
}

type Props = {
  onChange: (educationFilter: EducationFilter) => void
  filter: FilterForm
}

export const EducationSection: React.FC<Props> = ({ onChange, filter }) => {
  const { t } = useTranslation('candidateFilter')
  // Education
  const [schoolName, setSchoolName] = useState(filter.education?.name || '')
  const [course, setCourse] = useState(filter.education?.course || '')
  const [courseLevel, setCourseLevel] = useState(filter.education?.level || '')

  const educationLevelOptions = [
    'incomplete_middleschool',
    'middleschool',
    'incomplete_highschool',
    'highschool',
    'undergraduate',
    'postgraduate',
    'masters',
    'doctorate'
  ]

  useEffect(() => {
    onChange({
      name: schoolName,
      course,
      level: courseLevel
    } as EducationFilter)
  }, [schoolName, course, courseLevel])

  useEffect(() => {
    if (!Object.keys(filter).length) {
      setSchoolName('')
      setCourse('')
      setCourseLevel('')
    }
  }, [filter])

  return (
    <>
      <p className='candidate-filter-section-title'>{t('education.title')}</p>
      <FormRow>
        <FormItem>
          <Input
            type='text'
            label={t('education.name')}
            name='education-name'
            value={schoolName}
            onChange={e => setSchoolName(e)}
            small
          />
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem formItemClasses='col-md-6 spaceUp'>
          <Input
            type='text'
            label={t('education.course')}
            name='education-course'
            value={course}
            onChange={e => setCourse(e)}
            small
          />
        </FormItem>
        <FormItem formItemClasses='col-md-6 spaceUp'>
          <Select
            id='courseLevel-select'
            label={t('education.level')}
            value={courseLevel}
            onChange={e => setCourseLevel(e.target.value)}
          >
            <option value=''></option>

            {educationLevelOptions.map((el, index) => {
              return (
                <option value={el} key={index}>
                  {t(`educationLevelOptions.${el}`)}
                </option>
              )
            })}
          </Select>
        </FormItem>
      </FormRow>
    </>
  )
}
