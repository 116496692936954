import React from 'react'

import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

import { Button } from 'components/Button/Button'

import 'components/EmailButton/EmailButton.scss'

type Props = {
  helpButtonSubject: string
  helpButtonDescription: string
}

export const EmailButton: React.FC<Props> = ({
  helpButtonDescription,
  helpButtonSubject
}) => {
  return (
    <div>
      <Button
        className='emailButton'
        onClick={() => {
          window.open(`mailto:askme@hirekast.com?subject=${helpButtonSubject}`)
        }}
      >
        <div className='emailButtonIconArea'>
          <QuestionMarkIcon className='emailButtonIcon' />
          <span>{helpButtonDescription}</span>
        </div>
      </Button>
    </div>
  )
}
