import React, { ButtonHTMLAttributes } from 'react'

import { Loading } from 'components/Loading/Loading'
import './Button.scss'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
}

export const Button: React.FC<Props> = ({
  children,
  className,
  loading,
  ...props
}) => {
  return (
    <button {...props} className={className} data-testid='button'>
      {loading ? <Loading className='m-0 p-0' /> : children}
    </button>
  )
}
