import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Button/Button'

import './404.scss'

export const NotFound = (): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation('notFound')
  return (
    <div className='page-wrapper'>
      <div className='content'>
        <div>
          <h1>{t('title')}</h1>
          <p>{t('content')}</p>
          <Button className='primary' onClick={() => history.go(-1)}>
            {t('button')}
          </Button>
        </div>
      </div>
    </div>
  )
}
