import axios from 'axios'

export const getCities = async (city: string): Promise<Array<string>> => {
  try {
    if (city.length >= 3) {
      const response = await axios.get(
        `https://www.bne.com.br/vagas-de-emprego/api/v1/City/Suggest?CityName=${city}&LimitSuggestion=5&null`
      )
      const { data } = response.data

      const arr: Array<string> = []

      data.map(city => {
        return arr.push(`${city.FormatedName}`)
      })

      return arr
    } else return []
  } catch (err) {
    throw new Error(err)
  }
}
