import includes from 'lodash/includes'

import { Questions } from 'components/CustomQuestions/CustomQuestions'
import ReactGA from 'utils/analytics'

import { FormData, NewQuestions } from './EditContainer'

interface NewData extends FormData {
  questions: Array<NewQuestions>
}

interface OldData extends Job {
  questions: Array<Questions>
}

const editContainerAnalytics = (newData: NewData, oldData: OldData): void => {
  if (!newData || !oldData) return

  if (
    newData.name !== oldData.name ||
    newData.city !== oldData.city ||
    newData.deadline !== oldData.deadline ||
    newData.budget !== oldData.budget ||
    newData.quantity !== oldData.quantity ||
    newData.job_group !== oldData.job_group ||
    newData.tags !== oldData.tags
  ) {
    ReactGA.event('mandatory_info', {
      event_category: 'edit_job_listing',
      event_label: 'mandatory_info'
    })
  }

  if (newData.description !== oldData.description) {
    ReactGA.event('description', {
      event_category: 'edit_job_listing',
      event_label: 'description'
    })
  }

  if (newData.recruiters_team.length || oldData.recruiters_team.length) {
    const hasChanges =
      newData.recruiters_team.some(
        recruiter => !includes(oldData.recruiters_team, recruiter)
      ) &&
      oldData.recruiters_team.some(
        recruiter => !includes(newData.recruiters_team, recruiter.uuid)
      )

    if (hasChanges) {
      ReactGA.event('recruiters_team', {
        event_category: 'edit_job_listing',
        event_label: 'recruiters_team'
      })
    }
  }

  if (newData.managers_team.length || oldData.managers_team.length) {
    const hasChanges =
      newData.managers_team.some(
        manager => !includes(oldData.managers_team, manager)
      ) &&
      oldData.managers_team.some(
        manager => !includes(newData.managers_team, manager.uuid)
      )

    if (hasChanges) {
      ReactGA.event('managers_team', {
        event_category: 'edit_job_listing',
        event_label: 'managers_team'
      })
    }
  }

  if (newData.questions?.length) {
    const newQuestions = newData.questions.filter(
      question => !includes(oldData.questions, question.uuid)
    )

    newQuestions.forEach((question: NewQuestions): void => {
      if (question.type === 'multiple_choice') {
        ReactGA.event('create_question_single_answer', {
          event_category: 'edit_job_listing',
          event_label: 'create_question_single_answer'
        })
      }

      if (question.type === 'selectable_multiple_choice') {
        ReactGA.event('create_question_multiple_choice', {
          event_category: 'edit_job_listing',
          event_label: 'create_question_multiple_choice'
        })
      }

      if (question.type === 'open_ended') {
        ReactGA.event('create_question_single_text', {
          event_category: 'edit_job_listing',
          event_label: 'create_question_single_text'
        })
      }
    })
  }

  ReactGA.event('edit_job', {
    event_category: 'edit_job_listing',
    event_label: 'edit_job'
  })
}

export default editContainerAnalytics
