import React from 'react'

import { AsideMenuReducer } from './asideMenu'
import { AuthReducer } from './auth'
import { NotificationReducer } from './notification'
import { OnboardingHelperReducer } from './onboardingHelper'
import { PermissionsReducer } from './permissions'
import { getInitialState } from './persistState'
import { StoreTypes } from './types'

export const initialState: StoreTypes.StateType = {
  asideMenu: AsideMenuReducer.InitialState,
  auth: (await getInitialState('auth')) ?? AuthReducer.InitialState,
  notification: NotificationReducer.InitialState,
  permissions: PermissionsReducer.InitialState,
  onboardingHelper: OnboardingHelperReducer.InitialState
}

export const Reducer: React.Reducer<
  StoreTypes.StateType,
  StoreTypes.ActionTypes
> = (state: StoreTypes.StateType, action: StoreTypes.ActionTypes) => ({
  asideMenu: AsideMenuReducer.reducer(state.asideMenu, action),
  auth: AuthReducer.reducer(state.auth, action),
  notification: NotificationReducer.reducer(state.notification, action),
  permissions: PermissionsReducer.reducer(state.permissions, action),
  onboardingHelper: OnboardingHelperReducer.reducer(
    state.onboardingHelper,
    action
  )
})
