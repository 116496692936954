import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

// import { ReactComponent as GridIcon } from 'assets/images/icons/grid-icon.svg'
// import { ReactComponent as ListIcon } from 'assets/images/icons/list-icon.svg'
// import { ReactComponent as SearchIcon } from 'assets/images/icons/magnifying-glass-icon.svg'
// import { Button } from 'components/Button/Button'
// import { Input } from 'components/Input/Input'
// import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import usePermissionAgent from 'components/PermissionAgent/usePermissionAgent'

import './JobHeader.scss'
import { MultipleSelect } from 'components/Select/lugarh/MultipleSelect'
import { Select } from 'components/Select/Select'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'
import useStore from 'store'
import ReactGA from 'utils/analytics'

export type JobHeaderRef = {
  filterRef: React.RefObject<HTMLDivElement>
  viewRef: React.RefObject<HTMLDivElement>
}

type Props = {
  onChangeViewType: (viewType: 'list' | 'grid') => void
  onChangeFilter: (filter: 'all' | 'active' | 'disabled' | 'late') => void
  onSearch: (value: string) => void
  getRefs?: (value: JobHeaderRef) => void
  hiddenDueJob: boolean
}

export const JobHeader: React.FC<Props> = ({
  onChangeViewType,
  onChangeFilter,
  onSearch,
  getRefs,
  hiddenDueJob
}) => {
  const { showOnGeralWorkspace } = useWorkspacePermission()
  const { selectors } = useStore()
  const [workspaces, setWorkspaces] = useState<WorkspaceSchema[]>([])
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<string[]>([])
  // const [viewType, setViewType] = useState<'list' | 'grid'>('list')
  const [filter, setFilter] = useState<'all' | 'active' | 'disabled' | 'late'>(
    'active'
  )
  // const [filterSearch, setFilterSearch] = useState('')
  const filterRef = useRef(null)
  const viewRef = useRef(null)
  const { t } = useTranslation('jobHeader')

  const { shouldRender } = usePermissionAgent({
    module: 'job',
    actions: ['filter:search'],
    onFail: 'hidden'
  })

  useEffect(() => {
    if (getRefs !== undefined) getRefs({ filterRef, viewRef })
  }, [])

  const getUsersWorkspaces = () => {
    const workspaces = selectors.auth.user()?.workspace
    // remove workspace with name Geral from the list
    const noGeralWorkspaces = workspaces?.filter(
      workspace => workspace.name !== 'Geral'
    )
    noGeralWorkspaces && setWorkspaces(noGeralWorkspaces)
  }

  const filterAll = () => {
    setFilter('all')
    onChangeFilter('all')

    ReactGA.event('filter_all_jobs', {
      event_category: 'job_listing',
      event_label: 'filter_all_jobs'
    })
  }

  const filterActive = () => {
    setFilter('active')
    onChangeFilter('active')

    ReactGA.event('filter_active_jobs', {
      event_category: 'job_listing',
      event_label: 'filter_active_jobs'
    })
  }

  const filterDisabled = () => {
    setFilter('disabled')
    onChangeFilter('disabled')

    ReactGA.event('filter_paused_jobs', {
      event_category: 'job_listing',
      event_label: 'filter_paused_jobs'
    })
  }

  const filterLate = () => {
    setFilter('late')
    onChangeFilter('late')

    ReactGA.event('filter_late_jobs', {
      event_category: 'job_listing',
      event_label: 'filter_late_jobs'
    })
  }

  // const filterSubmit = e => {
  //   e.preventDefault()
  //   onSearch(filterSearch)
  //   ReactGA.event('search_jobs', {
  //     event_category: 'job_listing',
  //     event_label: 'search_jobs'
  //   })
  // }

  const handleWorkspaceFilter = (workspaces: string[]) => {
    setSelectedWorkspaces(workspaces)
    const workspacesString = workspaces.join(',')
    onSearch(workspacesString)
  }

  useEffect(() => {
    getUsersWorkspaces()
  }, [])

  return (
    <div className='job-header-wrapper'>
      {shouldRender && (
        <div className='input-with-button'>
          {showOnGeralWorkspace && (
            <MultipleSelect
              items={workspaces}
              onChange={handleWorkspaceFilter}
              defaultValue={selectedWorkspaces}
              label={t('searchLabel')}
            />
          )}
          {/* <form onSubmit={filterSubmit}>
              <Input
                onChange={e => setFilterSearch(e)}
                label={t('searchLabel')}
                name='vancancy_filter'
                type='text'
                value={filterSearch}
                small
              />

              <Button className='primary vacancy-filter-button'>
                <SearchIcon />
              </Button>
            </form> */}
        </div>
      )}
      <div className='w-100' ref={filterRef}>
        <div className='filter-select'>
          <Select
            label={t('selectLabel')}
            onChange={e => {
              setFilter(
                e.target.value as 'all' | 'active' | 'disabled' | 'late'
              )
              onChangeFilter(
                e.target.value as 'all' | 'active' | 'disabled' | 'late'
              )
            }}
            value={filter}
          >
            <option value='all'>{t('fourthOption')}</option>
            <option value='active'>{t('secondOption')}</option>
            <option value='disabled'>{t('thirdOption')}</option>
            <option value='late'>{t('firstOption')}</option>
          </Select>
        </div>

        <div className='filter-tabs'>
          {hiddenDueJob ? (
            <div
              className={`filter-tab ${filter === 'late' ? 'active' : ''}`}
              onClick={filterLate}
            >
              <p className='filter-tab-text'>{t('firstOption')}</p>
            </div>
          ) : (
            ''
          )}
          <div
            className={`filter-tab ${filter === 'active' ? 'active' : ''}`}
            onClick={filterActive}
          >
            <p className='filter-tab-text'>{t('secondOption')}</p>
          </div>
          <div
            className={`filter-tab ${filter === 'disabled' ? 'active' : ''}`}
            onClick={filterDisabled}
          >
            <p className='filter-tab-text'>{t('thirdOption')}</p>
          </div>
          <div
            className={`filter-tab ${filter === 'all' ? 'active' : ''}`}
            onClick={filterAll}
          >
            <p className='filter-tab-text'>{t('fourthOption')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
