import * as Types from './types'

export const InitialState: Types.State = {
  onboardingHelper: false
}

export function reducer(
  state = InitialState,
  action: Types.Actions
): Types.State {
  switch (action.type) {
    case Types.Types.UPDATE:
      return {
        ...state,
        onboardingHelper: action.payload
      }

    default:
      return state
  }
}
