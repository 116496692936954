import React from 'react'

import { useTranslation } from 'react-i18next'

import './CandidateHeader.scss'

type Props = {
  opportunity: string
  stage: string
}

export const CandidateHeader: React.FC<Props> = ({ opportunity, stage }) => {
  const { t } = useTranslation('candidateHeader')

  return (
    <div className='list section-header-info'>
      <div className='section-header-info__container'>
        <h2 className='section-title'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='25'
            viewBox='0 0 27 27'
            fill='none'
          >
            <path
              d='M20.7874 14.1792C20.8305 13.8559 20.8521 13.522 20.8521 13.1664C20.8521 12.8217 20.8305 12.4769 20.7767 12.1537L22.9637 10.4514C23.1577 10.3006 23.2115 10.0097 23.093 9.79423L21.0245 6.21734C20.8952 5.98032 20.6258 5.9049 20.3888 5.98032L17.8139 7.0146C17.2752 6.6052 16.7042 6.26044 16.0685 6.00187L15.6807 3.26533C15.6376 3.00676 15.4221 2.82361 15.1635 2.82361H11.0264C10.7678 2.82361 10.5631 3.00676 10.52 3.26533L10.1322 6.00187C9.49653 6.26044 8.91474 6.61597 8.38683 7.0146L5.8119 5.98032C5.57488 5.89413 5.30553 5.98032 5.17625 6.21734L3.11846 9.79423C2.98917 10.0205 3.03227 10.3006 3.24774 10.4514L5.43482 12.1537C5.38095 12.4769 5.33785 12.8324 5.33785 13.1664C5.33785 13.5004 5.3594 13.8559 5.41327 14.1792L3.2262 15.8814C3.03227 16.0322 2.9784 16.3231 3.09691 16.5386L5.16547 20.1155C5.29476 20.3525 5.5641 20.4279 5.80113 20.3525L8.37605 19.3182C8.91474 19.7276 9.48575 20.0724 10.1214 20.331L10.5093 23.0675C10.5631 23.3261 10.7678 23.5092 11.0264 23.5092H15.1635C15.4221 23.5092 15.6376 23.3261 15.6699 23.0675L16.0577 20.331C16.6934 20.0724 17.2752 19.7276 17.8031 19.3182L20.378 20.3525C20.615 20.4387 20.8844 20.3525 21.0137 20.1155L23.0822 16.5386C23.2115 16.3016 23.1577 16.0322 22.953 15.8814L20.7874 14.1792ZM13.095 17.045C10.9618 17.045 9.21641 15.2996 9.21641 13.1664C9.21641 11.0332 10.9618 9.28786 13.095 9.28786C15.2282 9.28786 16.9735 11.0332 16.9735 13.1664C16.9735 15.2996 15.2282 17.045 13.095 17.045Z'
              fill='currentColor'
            />
          </svg>
          {t('title', { opportunity, stage })}
        </h2>
      </div>
    </div>
  )
}
