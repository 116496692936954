import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { RoutePaths } from 'router/Router.config'

interface GoHomeButtonProps {
  customClass?: string
}

export const GoHomeButton: React.FC<GoHomeButtonProps> = ({
  customClass = ''
}) => {
  const { t } = useTranslation('goHomeButton')
  const navigate = useNavigate()

  return (
    <button
      className={`btn__prinmary ${customClass}`}
      onClick={() => navigate('/dashboard')}
    >
      {t('lugarh.goHome')}
    </button>
  )
}
