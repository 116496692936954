import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { CarrerSettingsButton } from 'components/CareerSettingsButton/CareerSettingsButton'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'

import './CareerMainPageContainer.scss'

enum Questions {
  TITLE = 'title',
  SHOW_HOME_BUTTON = 'showHomeButton',
  SHOW_MAIN_PAGE = 'showMainPage',
  SHOW_HOME_BUTTON_TITLE = 'showHomeButtonTitle',
  SHOW_HOME_BUTTON_TEXT = 'showHomeButtonText',
  SHOW_MAIN_PAGE_TITLE = 'showMainPageTitle',
  SHOW_MAIN_PAGE_TEXT = 'showMainPageText',
  LABEL = 'carreerPageLabel'
}

type CarrerMainPageContainerProps = {
  setFieldValue: SetFieldValue
  careerPageSettings: {
    showHomeButton: boolean
    showMainPage: boolean
  }
  getRef: (value: { carrerSettingsRef }) => void
}

export const CarrerMainPageContainer: React.FC<
  CarrerMainPageContainerProps
> = ({ setFieldValue, careerPageSettings, getRef }) => {
  const { t } = useTranslation('companySettings')
  const carrerSettingsRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    getRef({ carrerSettingsRef })
  }, [carrerSettingsRef])

  const handleOptionChange = (key: string, value: boolean) => {
    setFieldValue('careerPageSettings', {
      ...careerPageSettings,
      [key]: value
    })
  }

  return (
    <div ref={carrerSettingsRef}>
      <WidgetBox
        title={t('careerPageSetting.title')}
        className='careerMainPageContainer'
      >
        <CarrerSettingsButton
          title={t('careerPageSetting.showHomeButtonTitle')}
          text={t('careerPageSetting.showHomeButtonText')}
          label={t('careerPageSetting.careerPageLabel')}
          initialValue={careerPageSettings[Questions.SHOW_HOME_BUTTON]}
          questionKey={Questions.SHOW_HOME_BUTTON}
          onChange={handleOptionChange}
        />

        <CarrerSettingsButton
          title={t('careerPageSetting.showMainPageTitle')}
          text={t('careerPageSetting.showMainPageText')}
          label={t('careerPageSetting.careerPageLabel')}
          initialValue={careerPageSettings[Questions.SHOW_MAIN_PAGE]}
          questionKey={Questions.SHOW_MAIN_PAGE}
          onChange={handleOptionChange}
        />
      </WidgetBox>
    </div>
  )
}
