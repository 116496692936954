import * as Types from './types'

export function Selector(state: Types.State): Types.Selector {
  function permissions(): UserPermissions | Object {
    return state.permissions
  }

  function isLoading(): boolean {
    return state.loading
  }

  function error(): string {
    return state.error
  }

  function getPermissionsByModuleName(
    name: PermissionModule
  ): PermissionAction[] {
    const permission = state.permissions[name]
    return permission
      ? permission.scopes.map(scope => scope.replace(`${name}:`, ''))
      : {}
  }

  return {
    permissions,
    isLoading,
    error,
    getPermissionsByModuleName
  }
}
