import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import ReactGA from 'utils/analytics'
import './LoginSwitch.scss'

type Props = {
  onChange: (value: string) => void
  kind: 'login' | 'register' | 'forgotPassword'
}

export const LoginSwitch: React.FC<Props> = ({ onChange, kind }) => {
  const [value, setValue] = useState('login')
  const { t } = useTranslation('login')
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/signup') setValue('register')
  }, [])

  useEffect(() => {
    setValue(kind)
  }, [kind])

  const handleClick = (kind: string) => {
    if (kind === 'login') {
      navigate('/')
    } else {
      navigate('/signup')

      ReactGA.event('create_new_account_start', {
        event_category: 'register',
        event_label: 'create_new_account_start'
      })
    }

    onChange(kind)
    setValue(kind)
  }

  return (
    <div className='tab-switch'>
      <button
        className={value === 'login' ? 'active' : ''}
        onClick={() => handleClick('login')}
        data-testid='switch-login'
      >
        {t('login')}
      </button>

      <button
        className={value === 'register' ? 'active' : ''}
        onClick={() => handleClick('register')}
        data-testid='switch-register'
      >
        {t('register')}
      </button>
    </div>
  )
}
