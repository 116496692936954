import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { Modal } from 'components/Modal/Modal'

import styles from './PasswordConfirmationModal.module.scss'

type PasswordConfirmationModalProps = {
  open: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: (password) => void
}

export const PasswordConfirmationModal: React.FC<
  PasswordConfirmationModalProps
> = ({ open, isLoading, onClose, onConfirm }) => {
  const { t } = useTranslation('passwordConfirmationModal')
  const [password, setPassword] = useState('')

  return (
    <div>
      <Modal
        width='400px'
        onClose={() => onClose()}
        open={open}
        title={t('title')}
        closeIcon='cross'
      >
        <p className='popup-box-subtitle'>
          <span className='light'>{t('subtitle')}</span>
        </p>
        <div className={styles['modal-content-wrapper']}>
          <Input
            label={t('label')}
            type='password'
            name='password'
            onChange={setPassword}
            value={password}
            small
          ></Input>
          <Button
            className={'popup-box-action full secondary'}
            disabled={isLoading}
            onClick={() => {
              onConfirm(password)
              setPassword('')
            }}
          >
            {t('buttonText')}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
