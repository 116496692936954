import React from 'react'

import './TextArea.scss'
import classNames from 'classnames'

import Error from '../Error/Error'

export interface TextAreaProps {
  label: string
  required?: boolean
  small?: boolean
  full?: boolean
  name: string
  onChange: (value: string) => void
  value?: string | readonly string[]
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  error?: string
  disabled?: boolean
}

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  required,
  small,
  full,
  name,
  onChange,
  value,
  onBlur,
  disabled,
  error
}) => {
  const [active, setActive] = React.useState(false)
  return (
    <div
      className={classNames('form-input', {
        active: active || !!value,
        small,
        full
      })}
      data-testid='textarea-container'
    >
      <label htmlFor={name} data-testid='textarea-label'>
        {label}
        {required ? (
          <span style={{ color: 'red', margin: '0px 3px' }}>*</span>
        ) : (
          ''
        )}
      </label>
      <textarea
        name={name}
        value={value}
        disabled={disabled}
        onChange={event => onChange(event.target.value)}
        onFocus={() => setActive(true)}
        onBlur={event => {
          setActive((!event.target.value && !!value) || false)
          onBlur?.(event)
        }}
        data-testid='textarea'
      />

      <Error error={error} />
    </div>
  )
}
