import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { CardPlan } from 'components/CardPlan/CardPlan'
import { CardPlanEnterprise } from 'components/CardPlan/CardPlanEnterprise'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import './PlansCard.scss'

export const CardFooter: React.FC = () => {
  const { t } = useTranslation('login')

  return (
    <>
      <p className='lined-text'>{t('aboutUs')}</p>
      <div className='user-stats'>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <Link to={{ pathname: '/content/whatsrecrud' }}>
              {t('whatsRecrud')}
            </Link>
          </p>
        </div>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <Link to={{ pathname: '/content/terms' }}>{t('terms')}</Link>
          </p>
        </div>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <Link to={{ pathname: '/content/privacypolicy' }}>
              {t('privacy')}
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

type CardProps = {
  active: boolean
  logoff: () => void
}

const Plans: React.FC<CardProps> = ({ active, logoff }) => {
  const { selectors } = useStore()
  const navigate = useNavigate()
  const { t } = useTranslation(['plans', 'headerSettings'])

  const handleClick = (plan: string) => {
    navigate(`/checkout/${plan}`)
  }

  return (
    <div className={`plans-card ${active ? 'active' : 'hidden'}`}>
      <div className='plans-card-logout-button'>
        <a onClick={logoff}>
          {t('leave', {
            ns: 'headerSettings'
          })}
        </a>
      </div>
      <h2 className='mb-3'>{t('selectPlanTitle')}</h2>
      <div className='card-plan-area'>
        <CardPlan
          title={t('plansCardBasic.title')}
          subTitle={t('plansCardBasic.subTitle')}
          planValue={t('plansCardBasic.planValue')}
          planPeriod={t('plansCardBasic.planPeriod')}
          features={[
            t('plansCardBasic.feat1'),
            t('plansCardBasic.feat2'),
            t('plansCardBasic.feat3')
          ]}
          selectButton={t('selectButton')}
          onChangeKind={() => handleClick('plan-basic')}
        />
        <CardPlan
          title={t('plansCardSmart.title')}
          subTitle={t('plansCardSmart.subTitle')}
          planValue={t('plansCardSmart.planValue')}
          planPeriod={t('plansCardSmart.planPeriod')}
          features={[
            t('plansCardSmart.feat1'),
            t('plansCardSmart.feat2'),
            t('plansCardSmart.feat3')
          ]}
          selectButton={t('selectButton')}
          onChangeKind={() => handleClick('plan-smart')}
        />
        <CardPlan
          title={t('plansCardAdvanced.title')}
          subTitle={t('plansCardAdvanced.subTitle')}
          planValue={t('plansCardAdvanced.planValue')}
          planPeriod={t('plansCardAdvanced.planPeriod')}
          features={[
            t('plansCardAdvanced.feat1'),
            t('plansCardAdvanced.feat2'),
            t('plansCardAdvanced.feat3')
          ]}
          selectButton={t('selectButton')}
          onChangeKind={() => handleClick('plan-advanced')}
        />
        <CardPlanEnterprise
          title={t('plansCardEnterprise.title')}
          subTitle={t('plansCardEnterprise.subTitle')}
          planValue={t('plansCardEnterprise.planValue')}
          planPeriod={t('plansCardEnterprise.planPeriod')}
          features={[
            t('plansCardEnterprise.feat1'),
            t('plansCardEnterprise.feat2'),
            t('plansCardEnterprise.feat3')
          ]}
          selectButton={t('plansCardEnterprise.selectButton')}
        />
      </div>
      <CardFooter />
    </div>
  )
}

type PlansCardProps = {
  kind: 'login' | 'register' | 'plans'
}

export const PlansCard: React.FC<PlansCardProps> = ({ kind }) => {
  const { dispatch } = useStore()

  const logoff = () => {
    dispatch(authActions.logoff())
    document.location.reload()
  }

  return (
    <>
      <Plans active={kind === 'plans'} logoff={logoff} />
    </>
  )
}
