import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import './WorkflowCard.scss'

import add from 'assets/images/avatar/new.png'
import play from 'assets/images/avatar/workflow.png'
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy-icon.svg'
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash-icon.svg'

import classNames from 'classnames'

import { Button } from 'components/Button/Button'
import { ListItemsModal } from 'components/Modals/lugarh/ListItemsModal/ListItemsModal'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { WorkspaceSchema } from 'services/api/workspaces/workspaces.d'

export type WorkflowCardRefs = {
  editButton?: React.RefObject<HTMLDivElement>
  deleteButton?: React.RefObject<HTMLDivElement>
  createButton?: React.RefObject<HTMLDivElement>
}

type Props = {
  status: 'add-new' | 'active'
  name?: string
  description?: string
  workspaceList?: WorkspaceSchema[]
  onView?: () => void
  onEdit?: () => void
  onDelete?: () => void
  onCreateNew?: () => void
  onDuplicate?: () => void
  getRefs?: (value: WorkflowCardRefs) => void
  disableDelete?: boolean
  uuid?: string
}

export const WorkflowCard: React.FC<Props> = ({
  uuid,
  status,
  onView,
  onDelete,
  onCreateNew,
  name,
  description,
  workspaceList,
  disableDelete,
  getRefs,
  onEdit,
  onDuplicate
}) => {
  const { t } = useTranslation('workflowCard')
  const { dontShowOnGeralWorkspace, showOnGeralWorkspace } =
    useWorkspacePermission()
  const editButtonRef = useRef<HTMLDivElement>(null)
  const deleteButtonRef = useRef<HTMLDivElement>(null)
  const createButtonRef = useRef<HTMLDivElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const isAddCard = status === 'add-new'

  useEffect(() => {
    let obj: WorkflowCardRefs = {
      editButton: editButtonRef,
      deleteButton: deleteButtonRef
    }

    if (isAddCard) {
      obj = {
        createButton: createButtonRef
      }
    }

    if (getRefs !== undefined) {
      getRefs(obj)
    }
  }, [])

  const showWorkspaces = () => setOpenModal(true)

  return (
    <div className={`workflow-card-wrapper ${isAddCard ? 'add' : ''}`}>
      <div>
        <div className={`workflow-card-header ${status}`}>
          {isAddCard ? (
            ''
          ) : (
            <>
              {dontShowOnGeralWorkspace && (
                <Button
                  className='btn-duplicate header-btn'
                  onClick={onDuplicate}
                >
                  <CopyIcon />
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      <svg
        onClick={isAddCard ? onCreateNew : () => null}
        viewBox='0 0 100 100'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        className={`hexagon ${isAddCard && 'clickable'}`}
      >
        <defs>
          <pattern
            id={`img${uuid}`}
            patternUnits='userSpaceOnUse'
            width='100'
            height='100'
          >
            {status === 'active' ? (
              <image xlinkHref={play} x='-25' width='150' height='100' />
            ) : (
              <image xlinkHref={add} x='-25' width='150' height='100' />
            )}
          </pattern>
        </defs>
        <polygon
          points='50 1 95 25 95 75 50 99 5 75 5 25'
          fill={`url(#img${uuid})`}
        />
      </svg>

      {isAddCard ? (
        <div>
          <div className='p-2 footer' ref={createButtonRef}>
            <Button className='btn-add-new' onClick={onCreateNew}>
              {t('create')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className='workflow-card-body'>
            <div className='workflow-card-content'>
              <p className='text-center title'>{name}</p>
              <p className='text-center location'>{description}</p>
              {showOnGeralWorkspace && (
                <>
                  <p
                    className='text-center workspaces'
                    onClick={() => showWorkspaces()}
                  >
                    {workspaceList && workspaceList.length > 0
                      ? `${workspaceList.length} WORKSPACE${
                          workspaceList.length > 1 ? 'S' : ''
                        }`
                      : t('noWorkspaces')}
                  </p>
                  {workspaceList && (
                    <ListItemsModal<WorkspaceSchema>
                      open={openModal}
                      onClose={() => setOpenModal(false)}
                      items={workspaceList}
                      labels={{ id: 'uuid', title: 'name' }}
                      modalTitle={t('modalWorkspacesTitle')}
                      modalSubtitle={t('modalWorkspacesSubtitle')}
                      customClass='list-items-modal'
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {dontShowOnGeralWorkspace && (
            <div
              className={classNames('p-2 footer', {
                grid: status === 'active'
              })}
            >
              <div ref={editButtonRef}>
                <Button className='btn-edit col-5' onClick={onView}>
                  {t('view')}
                </Button>

                <Button className='btn-edit col-7' onClick={onEdit}>
                  {t('edit')}
                </Button>
              </div>
              <div ref={deleteButtonRef}>
                <Button
                  className={classNames('btn-delete', {
                    disabled: disableDelete
                  })}
                  onClick={onDelete}
                  {...(disableDelete ? { 'data-tooltip': t('tooltip') } : null)}
                >
                  <TrashIcon />
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
