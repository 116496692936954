type Options = {
  event_category: string
  event_label: string
}

declare global {
  interface Window {
    gtag: Gtag.Gtag
  }
}

const ReactGA = {
  event: (name: string, options: Options): void => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}')

    const eventOption = {
      ...options,
      time_stamp: Date.now()
    }

    if (auth && auth.user) {
      Object.assign(
        eventOption,
        {
          user_id: auth.user.uuid
        },
        auth.user.company && {
          tenant_id: auth.user.company.uuid,
          company_name: auth.user.company.fancy_name
        }
      )
    }
    window && window.gtag && window.gtag('event', name, eventOption)
  },
  pageView: (): void => {
    window &&
      window.gtag &&
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname
      })
  }
}

export default ReactGA

type Page =
  | 'dashboard'
  | 'jobs'
  | 'cadidatesDB'
  | 'candidateDetail'
  | 'candidates'
  | 'jobsEdit'
  | 'companySettings'
  | 'companyTeam'
  | 'workflow'
  | 'workflowEdit'

export const returnGACategoryByPage = (page: Page): string => {
  const categories = {
    dashboard: 'dashboard',
    jobs: 'job_listing',
    cadidatesDB: 'candidate_base',
    candidateDetail: 'candidate_detail',
    candidates: 'job_listing_candidates',
    jobsEdit: 'edit_job_listing',
    companySettings: 'company_settings',
    companyTeam: 'team_management',
    workflow: 'hiring_workflows',
    workflowEdit: 'workflow'
  }

  return categories[page]
}
