import api from './api'

export const update = async (
  payload: {
    name: string
    description: string
  },
  uuid: string
): Promise<[APIError, WorkflowSchema | null]> => {
  const response = await api.patch(`/v1/hiring-workflows/${uuid}`, payload)

  if (response.status === 200) {
    const data: WorkflowSchema = response.data.data
    return [
      null,
      {
        uuid: data.uuid,
        created_at: data.created_at,
        updated_at: data.updated_at,
        name: data.name,
        description: data.description,
        company: {
          uuid: data.company.uuid
        }
      }
    ]
  }

  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [
      {
        message: data.message,
        errors: data.errors
      },
      null
    ]
  }

  return ['unmapped', null]
}

export const create = async (payload: {
  name: string
  description: string
}): Promise<[APIError, WorkflowSchema | null]> => {
  const response = await api.post('/v1/hiring-workflows', payload)

  if (response.status === 201) {
    const data: WorkflowSchema = response.data.data
    return [
      null,
      {
        uuid: data.uuid,
        created_at: data.created_at,
        updated_at: data.updated_at,
        name: data.name,
        description: data.description,
        company: {
          uuid: data.company.uuid
        }
      }
    ]
  }

  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [
      {
        message: data.message,
        errors: data.errors
      },
      null
    ]
  }

  return ['unmapped', null]
}

export const read = async ({
  page = 1,
  per_page = 10
}: {
  page: number
  per_page: number
}): Promise<
  [
    APIError,
    {
      data: Array<WorkflowSchema>
      per_page: number
      page: number
    } | null
  ]
> => {
  const response = await api.get(
    `/v1/hiring-workflows?page=${page}&per_page=${per_page}&allWorkflows=true`
  )

  if (response.status === 200) {
    const data: {
      data: Array<WorkflowSchema>
      per_page: number
      page: number
    } = response.data
    return [
      null,
      {
        data: data.data,
        per_page: data.per_page,
        page: data.page
      }
    ]
  }

  if (response.status === 401 || response.status === 500) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}

export const remove = async (
  uuid: string
): Promise<[APIError, boolean | null]> => {
  const response = await api.delete(`/v1/hiring-workflows/${uuid}`)

  if (response.status === 204) {
    return [null, true]
  }

  if (
    response.status === 401 ||
    response.status === 404 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }
  if (response.status === 423) {
    return ['locked', null]
  }

  return ['unmapped', null]
}

export const getStages = async (payload: {
  workflow_uuid: string
  page: number
  per_page: number
  job_uuid: string
}): Promise<
  [
    APIError,
    {
      data: Array<WorkflowStageSchema>
      per_page: number
      page: number
    } | null
  ]
> => {
  const response = await api.get(
    `/v1/hiring-workflows/${payload.workflow_uuid}/stages`,
    {
      params: { ...payload },
      headers: { job: payload.job_uuid }
    }
  )

  if (response.status === 200) {
    const data: {
      data: Array<WorkflowStageSchema>
      per_page: number
      page: number
    } = response.data
    return [
      null,
      {
        data: data.data,
        per_page: data.per_page,
        page: data.page
      }
    ]
  }

  if (response.status === 401 || response.status === 500) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}

export const removeStage = async (
  workflow_uuid: string,
  stages_uuid: string
): Promise<[APIError, boolean | null]> => {
  const response = await api.delete(
    `/v1/hiring-workflows/${workflow_uuid}/stages/${stages_uuid}`
  )

  if (response.status === 204) {
    return [null, true]
  }

  if (
    response.status === 401 ||
    response.status === 404 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  return ['unmapped', null]
}

export const createHiringStage = async (
  payload: CreateNewHiringStatePayload,
  workflowUUID: string
): Promise<CreateHiringStageResponse> => {
  const body = { ...payload }
  const { data: responseData, status } = await api.post(
    `/v1/hiring-workflows/${workflowUUID}/stages`,
    body
  )

  switch (status) {
    case 201:
      return [null, responseData.data]

    case 400:
    case 401:
    case 409:
    case 500:
      return [responseData.message, null]

    case 422:
      return [
        {
          message: responseData.message,
          errors: responseData.errors
        },
        null
      ]

    default:
      return ['unmapped', null]
  }
}

export const updateAutoSendAndEmailContent = async (
  payload: {
    uuid: string
    email_autosend?: boolean
    email_content?: string
    email_subject?: string
  },
  workflowUUID: string
): Promise<[APIError, WorkflowStageSchema | null]> => {
  const response = await api.patch(
    `/v1/hiring-workflows/${workflowUUID}/stages/${payload.uuid}`,
    {
      email_autosend: payload.email_autosend,
      email_content: payload.email_content,
      email_subject: payload.email_subject
    }
  )

  if (response.status === 200) {
    const data: WorkflowStageSchema = response.data.data
    return [
      null,
      {
        uuid: data.uuid,
        created_at: data.created_at,
        updated_at: data.updated_at,
        name: data.name,
        description: data.description,
        deletable: data.deletable,
        email_autosend: data.email_autosend,
        email_content: data.email_content,
        hiring_workflow: data.hiring_workflow,
        order: data.order,
        email_subject: data.email_subject
      }
    ]
  }

  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 404 ||
    response.status === 409 ||
    response.status === 500
  ) {
    const data: ErrorSchema = response.data
    return [data.message, null]
  }

  if (response.status === 422) {
    const data: ErrorEntitySchema = response.data
    return [
      {
        message: data.message,
        errors: data.errors
      },
      null
    ]
  }

  return ['unmapped', null]
}
