import * as Types from './types'

export function Selector(state: Types.State): Types.Selector {
  function currentValue(): boolean {
    return state.onboardingHelper
  }

  return {
    currentValue
  }
}
