import React, { useCallback, useEffect, useRef, useState } from 'react'

import debounce from 'lodash.debounce'

import { ReactComponent as MagnifyingGlassIcon } from 'assets/images/icons/magnifying-glass-icon.svg'
import { Input } from 'components/Input/Input'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'

import './SearchBox.scss'
import { useTranslation } from 'react-i18next'

export interface ValeuOption {
  name: string
  uuid: string
}

type Props = {
  label: string
  required?: boolean
  name: string
  onChange: (value: ValeuOption) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  value?: ValeuOption
  type: 'text'
  dataSource: (val: string) => Promise<Array<any>>
  indexKey: string
  valueKey: string
  small?: boolean
  full?: boolean
  error?: string
}

export const SearchBox: React.FC<Props> = ({
  label,
  required,
  name,
  onChange,
  value,
  type,
  small,
  full,
  onBlur,
  onFocus,
  dataSource,
  indexKey,
  valueKey,
  error
}) => {
  const { t } = useTranslation('searchBox')
  const [isLoading, setIsLoading] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [searchValue, setSearchValue] = useState(value?.name ?? '')
  const inputReference = useRef<HTMLInputElement>(null)
  const [options, setOptions] = useState<Array<Record<string, string>>>([])
  const [isSelectedOnDropdown, setIsSelectedOnDropdown] = useState(false)
  const inputRef = useRef(null)

  const fetchData = useCallback(
    debounce(async (value: any) => {
      const hasFocus = document.activeElement === inputReference.current
      if (hasFocus) {
        setIsLoading(true)
        const [, searchData] = await dataSource(value)
        setOptions(searchData)
        setIsLoading(false)
      }
    }, 250),
    []
  )

  useOutsideAlerter(inputRef, () => {
    setOpenDropdown(false)
  })

  const handleOnChangeInput = useCallback(
    (keyword: string): void => {
      const isOnlySpaces = keyword.trim().length === 0
      const trimmedKeyword = isOnlySpaces ? '' : keyword
      setSearchValue(trimmedKeyword)
      onChange({ name: trimmedKeyword, uuid: '' })
      setOpenDropdown(true)
    },
    [onChange]
  )

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>): void => {
      onBlur?.(event)
    },
    [onBlur]
  )

  useEffect(() => {
    // The initial value is coming from the parent component
    if (value && value.name && value.uuid && !searchValue) {
      setSearchValue(value.name)
    }

    // User selected an option from the dropdown
    if (isSelectedOnDropdown) {
      setOpenDropdown(false)
      setIsSelectedOnDropdown(false)
    }

    // User typed something in the input and the searchValue is long enough
    const isSearchValueLongEnough = searchValue.toString().length >= 3
    if (!isSelectedOnDropdown && isSearchValueLongEnough) {
      fetchData(searchValue)
    }
  }, [value, searchValue])

  return (
    <div className='input-search-container' ref={inputRef}>
      <div className='input-search-wrapper'>
        <Input
          label={label}
          required={required}
          type={type}
          name={name}
          value={searchValue}
          inputRef={inputReference}
          onChange={keyword => handleOnChangeInput(keyword)}
          small={small}
          full={full}
          onBlur={event => handleOnBlur(event)}
          onFocus={onFocus}
          error={error}
          autoComplete={false}
        />

        <MagnifyingGlassIcon className='input-icon' />
      </div>

      <div className={`results ${openDropdown ? 'active' : ''}`}>
        <ul>
          {isLoading ? (
            <li>{t('loading')}</li>
          ) : options.length === 0 ? (
            <li>{t('notFound')}</li>
          ) : (
            options?.map(option => (
              <li
                key={option[indexKey]}
                onClick={() => {
                  setSearchValue(option[valueKey])
                  onChange({
                    name: option.name,
                    uuid: option.uuid
                  })
                  setIsSelectedOnDropdown(true)
                }}
              >
                <p>{option[valueKey]}</p>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  )
}
