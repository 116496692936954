import React, { ReactNode } from 'react'

export interface FormItemProps {
  formItemClasses?: string
  children: ReactNode
}

export const FormItem: React.FC<FormItemProps> = ({
  formItemClasses,
  children
}) => {
  return (
    <div className={`form-item col-xs-12 ${formItemClasses || ''}`}>
      {children}
    </div>
  )
}
