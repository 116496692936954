import React, { useEffect, useState, useCallback } from 'react'

import { cpf, cnpj } from 'cpf-cnpj-validator'
import { Formik } from 'formik'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import CreditCardLogos from 'assets/images/general/credit-card-logos.png'
import { Button } from 'components/Button/Button'
import { CardPlan } from 'components/CardPlan/CardPlan'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { FormItem } from 'components/FormItem/FormItem'
import { Input } from 'components/Input/Input'
import { InputCity } from 'components/InputCity/InputCity'
import { Loading } from 'components/Loading/Loading'
import { useReCAPTCHA } from 'hooks/useReCAPTCHA'
import { company } from 'services/api'
import LoadIuguTokenCard from 'services/iugu-card-token-generator'
import { getCitiesViacep } from 'services/viaCep'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import * as notificationActions from 'store/notification/actions'
import './CheckoutPlanContainer.scss'

export const CardFooter: React.FC = () => {
  const { t } = useTranslation('login')

  return (
    <>
      <p className='lined-text'>{t('aboutUs')}</p>

      <div className='user-stats'>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <Link to={{ pathname: '/content/whatsrecrud' }}>
              {t('whatsRecrud')}
            </Link>
          </p>
        </div>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <Link to={{ pathname: '/content/terms' }}>{t('terms')}</Link>
          </p>
        </div>
        <div className='user-stat'>
          <p className='user-stat-text'>
            <Link to={{ pathname: '/content/privacypolicy' }}>
              {t('privacy')}
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

type CreditCardData = {
  cardNumber: string
  verificationValue: string
  cardHolderName: string
  expirationMonth: string
  expirationYear: string
}

type SelectedPlan = {
  planSlug: string | undefined
  planId: string | undefined
}

type FormData = {
  company: {
    name: string
    fancy_name: string
    slug: string
    location: string
    cnpj: string
  }
  customer: {
    phone: string
    cpfCnpj: string
    zipcode: string
    number: string
    street: string
    city: string
    state: string
    district: string
    complement: string
  }
  planIdentifier: string | undefined
  payment_token: string
}

type CompanyFormProps = {
  active: boolean
  onButtonClick: (formData: FormData) => void
  showNotification: (payload: NotificationConfig) => void
  logoff: () => void
  setLoading: (status: boolean) => void
  loading: boolean
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  active,
  onButtonClick,
  showNotification,
  logoff,
  setLoading,
  loading
}) => {
  const [terms, setTerms] = useState(false)
  const { t } = useTranslation([
    'plans',
    'companySettings',
    'login',
    'checkoutPlan',
    'headerSettings'
  ])
  const { t: errorsTranslations } = useTranslation('errors')
  const navigate = useNavigate()
  const { plan } = useParams<{ plan: string }>()

  const [creditCardData, setCreditCardData] = useState({} as CreditCardData)
  const [validSlug, setValidSlug] = useState<undefined | boolean>(undefined)
  const [loadingSlug, setLoadingSlug] = useState(false)
  const [cpfCnpjMask, setCpfCnpjMask] = useState('')
  const [isValidCpfCnpj, setIsValidCpfCnpj] = useState(true)

  let selectedPlan: SelectedPlan = {
    planSlug: '',
    planId: ''
  }

  switch (plan) {
    case 'plan-basic':
      selectedPlan = {
        planSlug: 'plansCardBasic',
        planId: process.env.REACT_APP_PLAN_BASIC
      }
      break

    case 'plan-smart':
      selectedPlan = {
        planSlug: 'plansCardSmart',
        planId: process.env.REACT_APP_PLAN_SMART
      }
      break

    case 'plan-advanced':
      selectedPlan = {
        planSlug: 'plansCardAdvanced',
        planId: process.env.REACT_APP_PLAN_ADVANCED
      }
      break

    case 'plan-enterprise':
      selectedPlan = {
        planSlug: 'plansCardEnterprise',
        planId: process.env.REACT_APP_PLAN_ENTERPRISE
      }
      break
  }

  const signupFormSchema = Yup.object().shape({
    name: Yup.string().required(errorsTranslations('requiredField')),
    fancyName: Yup.string().required(errorsTranslations('requiredField')),
    slug: Yup.string().required(errorsTranslations('requiredField')),
    location: Yup.string().required(errorsTranslations('requiredField')),
    cnpj: Yup.string()
      .max(18, errorsTranslations('cnpjMaxLength'))
      .required(errorsTranslations('requiredField')),
    cardNumber: Yup.string()
      .min(19, errorsTranslations('creditCardNumberInvalid'))
      .required(errorsTranslations('requiredField')),
    verificationValue: Yup.string()
      .min(3, errorsTranslations('creditCardCvvInvalid'))
      .required(errorsTranslations('requiredField')),
    cardHolderName: Yup.string().required(errorsTranslations('requiredField')),
    expirationMonth: Yup.number()
      .min(1, errorsTranslations('creditCardExpirationMonthInvalid'))
      .max(12, errorsTranslations('creditCardExpirationMonthInvalid'))
      .required(errorsTranslations('requiredField')),
    expirationYear: Yup.number()
      .min(
        new Date().getFullYear() - 2000,
        errorsTranslations('creditCardExpirationYearInvalid')
      )
      .required(errorsTranslations('requiredField')),
    phone: Yup.string().required(errorsTranslations('requiredField')),
    cpfCnpj: Yup.string()
      .min(11, errorsTranslations('cpfCnpjInvalid'))
      .max(14, errorsTranslations('cpfCnpjInvalid'))
      .required(errorsTranslations('requiredField')),
    zipcode: Yup.string().required(errorsTranslations('requiredField')),
    district: Yup.string().required(errorsTranslations('requiredField')),
    street: Yup.string().required(errorsTranslations('requiredField')),
    number: Yup.string().required(errorsTranslations('requiredField')),
    city: Yup.string().required(errorsTranslations('requiredField')),
    state: Yup.string().required(errorsTranslations('requiredField'))
  })

  const handleChangePlan = () => {
    navigate(`/plans`)
  }

  const validateSlug = useCallback(
    debounce(async (value: any) => {
      setValidSlug(undefined)
      if (!value.match('[^0-9A-Za-z]')) {
        const status = await company.checkSlug(value)
        setValidSlug(status === 200)
      } else {
        setValidSlug(false)
      }
      setLoadingSlug(false)
    }, 500),
    []
  )

  const handleCpfCnpjError = (isTouched, yupErros) => {
    if (isTouched) {
      if (yupErros) {
        return yupErros
      }
      if (!isValidCpfCnpj) {
        return errorsTranslations('cpfCnpjInvalid')
      }
    }
  }

  return (
    <div className={`checkout-card register ${active ? 'active' : 'hidden'}`}>
      <div className='logout-button'>
        <a onClick={logoff}>
          {t('leave', {
            ns: 'headerSettings'
          })}
        </a>
      </div>
      <div className='card-register-area'>
        <Formik
          initialValues={{
            name: '',
            fancyName: '',
            slug: '',
            location: '',
            cnpj: '',
            cardNumber: '',
            verificationValue: '',
            cardHolderName: '',
            expirationMonth: '',
            expirationYear: '',
            phone: '',
            cpfCnpj: '',
            zipcode: '',
            number: '',
            street: '',
            city: '',
            state: '',
            district: '',
            complement: ''
          }}
          onSubmit={async values => {
            if (validSlug) {
              setLoading(true)
              try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await Iugu.setTestMode(process.env.REACT_APP_IUGU_TEST_MODE)

                const fullName = creditCardData.cardHolderName.split(' ')
                const firstName = fullName[0]
                const lastName = fullName[fullName.length - 1]

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const cc = Iugu.CreditCard(
                  creditCardData.cardNumber,
                  creditCardData.expirationMonth,
                  creditCardData.expirationYear,
                  firstName,
                  lastName,
                  creditCardData.verificationValue
                )

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await Iugu.createPaymentToken(cc, response => {
                  if (response.errors) {
                    showNotification({
                      message: t('cardTokenizationError', {
                        ns: 'checkoutPlan'
                      }),
                      type: 'error'
                    })
                    setLoading(false)
                  } else {
                    onButtonClick({
                      company: {
                        name: values.name,
                        fancy_name: values.fancyName,
                        slug: values.slug,
                        location: values.location,
                        cnpj: values.cnpj
                      },
                      customer: {
                        phone: values.phone,
                        cpfCnpj: values.cpfCnpj,
                        zipcode: values.zipcode,
                        number: values.number,
                        street: values.street,
                        city: values.city,
                        state: values.state,
                        district: values.district,
                        complement: values.complement
                      },
                      planIdentifier: selectedPlan.planId,
                      payment_token: response.id
                    })
                  }
                })
              } catch (error) {
                setLoading(false)
                showNotification({
                  message: t('iugoCardTokenizationFailure', {
                    ns: 'checkoutPlan'
                  }),
                  type: 'error'
                })
              }
            }
          }}
          validationSchema={signupFormSchema}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleSubmit,
            setValues,
            setErrors,
            setTouched,
            touched,
            setFieldTouched
          }) => {
            useEffect(() => {
              const values = {
                name: '',
                fancyName: '',
                slug: '',
                location: '',
                cnpj: '',
                cardNumber: '',
                verificationValue: '',
                cardHolderName: '',
                expirationMonth: '',
                expirationYear: '',
                phone: '',
                cpfCnpj: '',
                zipcode: '',
                number: '',
                street: '',
                city: '',
                state: '',
                district: '',
                complement: ''
              }
              setValues(values)
              setTouched({})
              setErrors({})
            }, [active])
            return (
              <>
                <form className='form-register' onSubmit={handleSubmit}>
                  <h2 className='company-data-title'>
                    {t('companyFormTitle', { ns: 'checkoutPlan' })}
                  </h2>
                  <div className='row'>
                    <FormItem formItemClasses='col-md-12'>
                      <Input
                        label={t('companyForm.name', { ns: 'companySettings' })}
                        type='text'
                        name='name'
                        value={values.name}
                        onChange={value => {
                          setFieldValue('name', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('name', true)
                        }}
                        error={touched.name ? errors.name : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='col-md-12'>
                      <Input
                        label={t('companyForm.fancyName', {
                          ns: 'companySettings'
                        })}
                        type='text'
                        name='fancyName'
                        value={values.fancyName}
                        onChange={value => {
                          setFieldValue('fancyName', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('fancyName', true)
                        }}
                        error={touched.fancyName ? errors.fancyName : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='col-md-12'>
                      <Input
                        label={t('companyForm.cnpj', {
                          ns: 'companySettings'
                        })}
                        type='text'
                        name='cnpj'
                        value={values.cnpj}
                        onChange={value => {
                          setFieldValue('cnpj', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('cnpj', true)
                        }}
                        error={touched.cnpj ? errors.cnpj : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='col-md-12'>
                      <Input
                        type='text'
                        label={t('companyForm.site', { ns: 'companySettings' })}
                        name='slug'
                        value={values.slug}
                        onChange={(value: string) => {
                          setFieldValue('slug', value)
                          setLoadingSlug(true)
                          validateSlug(value)
                        }}
                        onBlur={() => {
                          setFieldTouched('slug', true)
                        }}
                        error={touched.slug ? errors.slug : ''}
                        fixedValue={
                          loadingSlug ? (
                            <Loading className='m-0' />
                          ) : (
                            '.recrud.bne.com.br'
                          )
                        }
                        small
                      />
                      <p
                        className={!validSlug ? 'error' : 'error text-success'}
                      >
                        {validSlug !== undefined &&
                          (!validSlug
                            ? errorsTranslations('linkUnavailable')
                            : errorsTranslations('linkAvailable'))}
                      </p>
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-12'>
                      <InputCity
                        type='text'
                        label={t('companyForm.location', {
                          ns: 'companySettings'
                        })}
                        name='location'
                        value={values.location}
                        onChange={value => setFieldValue('location', value[0])}
                        onBlur={() => setFieldTouched('location')}
                        error={touched.location ? errors.location : ''}
                        small
                      ></InputCity>
                    </FormItem>
                  </div>
                  <h2>{t('paymentFormTitle', { ns: 'checkoutPlan' })}</h2>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-12'>
                      <div className='credit-card-logos'>
                        <h3 className='payment-method-title'>
                          {t('creditCardLabel', { ns: 'checkoutPlan' })}
                        </h3>
                        <img src={CreditCardLogos} alt='Credit card logos' />
                      </div>
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-8'>
                      <Input
                        label={t('cardNumber', { ns: 'checkoutPlan' })}
                        type='text'
                        name='cardNumber'
                        mask='9999 9999 9999 9999'
                        maskChar={null}
                        value={values.cardNumber}
                        onChange={value => {
                          setFieldValue('cardNumber', value)
                          setCreditCardData({
                            ...creditCardData,
                            cardNumber: value
                          })
                        }}
                        onBlur={() => {
                          setFieldTouched('cardNumber', true)
                        }}
                        error={touched.cardNumber ? errors.cardNumber : ''}
                        small
                      />
                    </FormItem>
                    <FormItem formItemClasses='spaceUp col-4'>
                      <Input
                        label={t('cvv', { ns: 'checkoutPlan' })}
                        type='text'
                        name='verificationValue'
                        mask='9999'
                        maskChar={null}
                        value={values.verificationValue}
                        onChange={value => {
                          setFieldValue('verificationValue', value)
                          setCreditCardData({
                            ...creditCardData,
                            verificationValue: value
                          })
                        }}
                        onBlur={() => {
                          setFieldTouched('verificationValue', true)
                        }}
                        error={
                          touched.verificationValue
                            ? errors.verificationValue
                            : ''
                        }
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('expirationMonth', { ns: 'checkoutPlan' })}
                        type='text'
                        name='expirationMonth'
                        mask='99'
                        maskChar={null}
                        value={values.expirationMonth}
                        onChange={value => {
                          setFieldValue('expirationMonth', value)
                          setCreditCardData({
                            ...creditCardData,
                            expirationMonth: value
                          })
                        }}
                        onBlur={() => {
                          setFieldTouched('expirationMonth', true)
                        }}
                        error={
                          touched.expirationMonth ? errors.expirationMonth : ''
                        }
                        small
                      />
                    </FormItem>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('expirationYear', { ns: 'checkoutPlan' })}
                        type='text'
                        name='expirationYear'
                        mask='99'
                        maskChar={null}
                        value={values.expirationYear}
                        onChange={value => {
                          setFieldValue('expirationYear', value)
                          setCreditCardData({
                            ...creditCardData,
                            expirationYear: value
                          })
                        }}
                        onBlur={() => {
                          setFieldTouched('expirationYear', true)
                        }}
                        error={
                          touched.expirationYear ? errors.expirationYear : ''
                        }
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-12'>
                      <Input
                        label={t('cardHolderName', { ns: 'checkoutPlan' })}
                        type='text'
                        name='cardHolderName'
                        value={values.cardHolderName}
                        onChange={value => {
                          setFieldValue('cardHolderName', value.toUpperCase())
                          setCreditCardData({
                            ...creditCardData,
                            cardHolderName: value
                          })
                        }}
                        onBlur={() => {
                          setFieldTouched('cardHolderName', true)
                        }}
                        error={
                          touched.cardHolderName ? errors.cardHolderName : ''
                        }
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('cpfCnpj', { ns: 'checkoutPlan' })}
                        type='text'
                        name='cpfCnpj'
                        value={values.cpfCnpj}
                        mask={cpfCnpjMask}
                        maskChar={null}
                        onChange={value => {
                          const onlyNumbersValue = value.replace(/\D/g, '')
                          setFieldValue('cpfCnpj', onlyNumbersValue)
                          if (values.cpfCnpj.length <= 11) {
                            setCpfCnpjMask('')
                            setIsValidCpfCnpj(true)
                          }
                        }}
                        onBlur={() => {
                          setFieldTouched('cpfCnpj', true)
                          let isValid = false

                          if (values.cpfCnpj.length > 11) {
                            setCpfCnpjMask('99.999.999/9999-99')
                            isValid = cnpj.isValid(values.cpfCnpj)
                          } else {
                            setCpfCnpjMask('999.999.999-99')
                            isValid = cpf.isValid(values.cpfCnpj)
                          }
                          setIsValidCpfCnpj(isValid)
                        }}
                        error={handleCpfCnpjError(
                          touched.cpfCnpj,
                          errors.cpfCnpj
                        )}
                        small
                      />
                    </FormItem>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('phone', { ns: 'checkoutPlan' })}
                        type='text'
                        name='phone'
                        mask='+99 99999999999'
                        maskChar={null}
                        value={values.phone}
                        onChange={value => {
                          setFieldValue('phone', value?.toString())
                        }}
                        onBlur={() => {
                          setFieldTouched('phone', true)
                        }}
                        error={touched.phone ? errors.phone : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <h2>
                    {t('billingAddressFromTitle', { ns: 'checkoutPlan' })}
                  </h2>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('zipcode', { ns: 'checkoutPlan' })}
                        type='text'
                        name='zipcode'
                        value={values.zipcode}
                        mask='99.999-999'
                        maskChar={null}
                        onChange={value => {
                          setFieldValue('zipcode', value)
                        }}
                        onBlur={async () => {
                          setFieldTouched('zipcode', true)
                          const zipcodeData = await getCitiesViacep(
                            values.zipcode
                          )
                          if (zipcodeData !== null) {
                            setValues({
                              ...values,
                              district: zipcodeData?.bairro,
                              street: zipcodeData?.logradouro,
                              city: zipcodeData?.localidade,
                              state: zipcodeData?.uf
                            })
                          } else {
                            setValues({
                              ...values,
                              district: '',
                              street: '',
                              city: '',
                              state: ''
                            })
                          }
                        }}
                        error={touched.zipcode ? errors.zipcode : ''}
                        small
                      />
                    </FormItem>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('district', { ns: 'checkoutPlan' })}
                        type='text'
                        name='district'
                        value={values.district}
                        onChange={value => {
                          setFieldValue('district', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('district', true)
                        }}
                        error={touched.district ? errors.district : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-12'>
                      <Input
                        label={t('street', { ns: 'checkoutPlan' })}
                        type='text'
                        name='street'
                        value={values.street}
                        onChange={value => {
                          setFieldValue('street', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('street', true)
                        }}
                        error={touched.street ? errors.street : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('number', { ns: 'checkoutPlan' })}
                        type='text'
                        name='number'
                        value={values.number}
                        mask='999999999'
                        maskChar={null}
                        onChange={value => {
                          setFieldValue('number', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('number', true)
                        }}
                        error={touched.number ? errors.number : ''}
                        small
                      />
                    </FormItem>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('complement', { ns: 'checkoutPlan' })}
                        type='text'
                        name='complement'
                        value={values.complement}
                        onChange={value => {
                          setFieldValue('complement', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('complement', true)
                        }}
                        error={touched.complement ? errors.complement : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('city', { ns: 'checkoutPlan' })}
                        type='text'
                        name='city'
                        value={values.city}
                        onChange={value => {
                          setFieldValue('city', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('city', true)
                        }}
                        error={touched.city ? errors.city : ''}
                        small
                      />
                    </FormItem>
                    <FormItem formItemClasses='spaceUp col-6'>
                      <Input
                        label={t('state', { ns: 'checkoutPlan' })}
                        type='text'
                        name='state'
                        value={values.state}
                        mask='aa'
                        maskChar={null}
                        onChange={value => {
                          setFieldValue('state', value)
                        }}
                        onBlur={() => {
                          setFieldTouched('state', true)
                        }}
                        error={touched.state ? errors.state : ''}
                        small
                      />
                    </FormItem>
                  </div>
                  <div className='row'>
                    <FormItem formItemClasses='spaceUp col-12'>
                      <Checkbox
                        label={t('registerCheckBox', { ns: 'login' })}
                        name='terms'
                        checked={terms}
                        onChange={setTerms}
                      />
                    </FormItem>
                  </div>

                  <Button
                    disabled={!terms || loading}
                    loading={loading}
                    className='primary'
                    type='submit'
                  >
                    {t('paymentButton', { ns: 'checkoutPlan' })}
                  </Button>
                </form>
              </>
            )
          }}
        </Formik>
        <div className='selected-plan'>
          <CardPlan
            title={t(`${selectedPlan.planSlug}.title`)}
            subTitle={t(`${selectedPlan.planSlug}.subTitle`)}
            planValue={t(`${selectedPlan.planSlug}.planValue`)}
            planPeriod={t(`${selectedPlan.planSlug}.planPeriod`)}
            features={[
              t(`${selectedPlan.planSlug}.feat1`),
              t(`${selectedPlan.planSlug}.feat2`),
              t(`${selectedPlan.planSlug}.feat3`)
            ]}
            selectButton={t('changePlanButton', { ns: 'checkoutPlan' })}
            onChangeKind={() => handleChangePlan()}
          />
        </div>
      </div>
      <CardFooter />
    </div>
  )
}

type CheckoutCardProps = {
  kind: 'login' | 'checkout'
  onChangeKind: (value: 'login' | 'checkout') => void
}

export const CheckoutCard: React.FC<CheckoutCardProps> = ({
  kind,
  onChangeKind
}) => {
  const { selectors, dispatch } = useStore()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { getToken } = useReCAPTCHA()

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const logoff = () => {
    dispatch(authActions.logoff())
    document.location.reload()
  }

  useEffect(() => {
    if (selectors.auth.hasCompany()) {
      navigate('/dashboard')
    }
    onChangeKind('checkout')
  }, [])

  const handlePayment = async (formData: FormData) => {
    try {
      const token = await getToken()
      const payload: CompanyIuguAPIPayload = {
        company: {
          name: formData.company.name,
          fancy_name: formData.company.fancy_name,
          slug: formData.company.slug,
          location: formData.company.location,
          cnpj: formData.company.cnpj
        },
        billing_address: {
          phone: formData.customer.phone,
          cpf_cnpj: formData.customer.cpfCnpj,
          zip_code: formData.customer.zipcode,
          district: formData.customer.district,
          street: formData.customer.street,
          number: formData.customer.number,
          complement: formData.customer.complement,
          city: formData.customer.city,
          state: formData.customer.state
        },
        plan_identifier: formData.planIdentifier,
        payment_token: formData.payment_token,
        g_recaptcha_response: token
      }

      const associate = window.localStorage.getItem('associate')
      if (associate !== undefined && associate !== null) {
        payload.associate = associate
      }

      const acquisitionChannel =
        window.localStorage.getItem('acquisitionChannel')
      if (acquisitionChannel !== undefined && acquisitionChannel !== null) {
        payload.acquisition_channel = acquisitionChannel
      }

      const [error, data] = await company.createIugu(payload)

      if (error) {
        throw new Error(error as string)
      }
      dispatch(authActions.setCompany(data as Company))
      window.localStorage.removeItem('associate')
      window.localStorage.removeItem('acquisitionChannel')
      setIsLoading(false)
      navigate('/company-settings')
    } catch (error) {
      setIsLoading(false)
      showNotification({ message: error.message, type: 'error' })
    }
  }

  useEffect(() => {
    LoadIuguTokenCard()
  }, [])

  return (
    <>
      <CompanyForm
        onButtonClick={handlePayment}
        active={kind === 'checkout'}
        loading={isLoading}
        showNotification={showNotification}
        logoff={logoff}
        setLoading={setIsLoading}
      />
    </>
  )
}
