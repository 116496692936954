import React from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button/Button'
import { Modal } from 'components/Modal/Modal'

type ConfirmOperationModalProps = {
  open: boolean
  isLoading: boolean
  selectedOperation: 'blockModal' | 'unblockModal' | 'deleteModal' | ''
  onClose: () => void
  onConfirm: () => void
  user: string
}

export const ConfirmOperationModal: React.FC<ConfirmOperationModalProps> = ({
  open,
  isLoading,
  selectedOperation,
  onClose,
  onConfirm,
  user
}) => {
  const { t } = useTranslation('teamManagement')

  if (!selectedOperation) return <></>

  return (
    <div>
      <Modal
        width='350px'
        onClose={() => onClose()}
        open={open}
        title={t(`${selectedOperation}.title`)}
        closeIcon='cross'
      >
        <p className='popup-box-subtitle'>
          <span
            className='light'
            dangerouslySetInnerHTML={{
              __html: t(`${selectedOperation}.description`)
            }}
          />

          <span className='light'>
            <strong>{user}</strong>?
          </span>
        </p>

        <div className={'popup-box-actions medium void pl-4 pr-4 pt-0 pb-4'}>
          <Button
            className={'popup-box-action full secondary'}
            onClick={() => onConfirm()}
            disabled={isLoading}
            loading={isLoading}
          >
            {t(`${selectedOperation}.confirm`)}
          </Button>

          <Button
            className={'popup-box-action full'}
            style={{ backgroundColor: '#fd434f' }}
            onClick={() => onClose()}
          >
            {t(`${selectedOperation}.undo`)}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
