import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface CandidatesByStateProps {
  t: any
  loading: boolean
}

const CandidatesByState = ({ t, loading }: CandidatesByStateProps) => {
  const { candidatesByLocation } = useDashboardContext()
  return (
    <div className='row'>
      <div className='col-12'>
        {loading && !hasContent(candidatesByLocation) ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard title={t('chart9')}>
            <DashboardChart
              id='location-chart'
              visible={hasContent(candidatesByLocation)}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default CandidatesByState
