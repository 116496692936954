import { StoreTypes } from './types'

export const persistState = async (
  storageKey: string,
  state: StoreTypes.StateType
): Promise<void> => {
  await window.localStorage.setItem(storageKey, JSON.stringify(state))
}

export const getInitialState = async (storageKey: string) => {
  try {
    const savedState = await window.localStorage.getItem(storageKey)
    if (!savedState) {
      return {}
    }
    return JSON.parse(savedState)
  } catch (error) {
    console.error(`Error loading state: ${storageKey}`)
    return null
  }
}
