import * as Types from './types'

export const InitialState: Types.State = {
  messages: []
}

export function reducer(
  state = InitialState,
  action: Types.Actions
): Types.State {
  switch (action.type) {
    case Types.Types.SHOW:
      return {
        ...state,
        messages: [...state.messages, { ...action.payload }]
      }

    case Types.Types.DISMISS:
      return {
        ...state,
        messages: state.messages.filter(
          message => JSON.stringify(message) !== JSON.stringify(action.payload)
        )
      }

    default:
      return state
  }
}
