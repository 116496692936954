const loadIuguTokenCard = callback => {
  const existingScript = document.getElementById('iuguCardToken')

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = 'https://js.iugu.com/v2'
    script.id = 'iuguCardToken'

    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }

  if (existingScript && callback) callback()
}
export default loadIuguTokenCard
