export const isDefaultStage = (stage: WorkflowStageSchema): boolean => {
  return (
    stage.name === 'Available' ||
    stage.name === 'Rejected in Triage' ||
    stage.name === 'Approved in Triage' ||
    stage.name === 'Denied Offer' ||
    stage.name === 'Hired' ||
    stage.name === 'Rejected during the process'
  )
}

export const convertStagesSchemaToWorkflowStageSchema = (
  stages: StageSchema[]
): WorkflowStageSchema[] =>
  stages.map(stage => ({
    uuid: stage.uuid,
    created_at: stage.created_at,
    updated_at: stage.updated_at,
    name: stage.name,
    description: stage.description,
    email_autosend: stage.email_autosend,
    email_content: stage.email_content,
    email_subject: stage.email_subject,
    hiring_workflow: stage.hiring_workflow,
    order: stage.order,
    deletable: stage.deletable,
    candidate_count: stage.total_candidates
  }))

export const returnDefaultStages = (
  stages: Array<WorkflowStageSchema>
): Array<WorkflowStageSchema> => {
  return stages.filter(isDefaultStage)
}
