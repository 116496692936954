import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { CurrentCardPlan } from 'components/CurrentCardPlan/CurrentCardPlan'
import { Error403 } from 'components/Error403/Error403'
import { Loading } from 'components/Loading/Loading'
import { StatusBadger } from 'components/StatusBadger/StatusBadger'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import i18n from 'i18n'
import { SubscriptionSettings } from 'services/api/'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import './SubscriptionSettings.scss'

export type ObjectError = {
  message: string
  errors: {
    [key: string]: string[]
  }
}

export const SubscriptionSettingsContainer: React.FC = () => {
  const { t } = useTranslation(['plans', 'subscriptionSettings'])

  const { dispatch } = useStore()

  const [isLoading, setIsLoading] = useState(true)
  const [resourceAuthorized, setResourceAuthorized] = useState(true)
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionSettings>({} as SubscriptionSettings)

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const getSubscriptionSettings = async () => {
    try {
      const [error, data] = await SubscriptionSettings.get()

      if (error === 'The credentials are not authorized to access resource.') {
        return setResourceAuthorized(false)
      }

      if (error) {
        return showNotification({
          message: (error as ObjectError).message,
          type: 'error'
        })
      }

      if (data) {
        setSubscriptionData(data)
      }
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const formatDate = (date: string) => {
    if (i18n.language === 'en-US') {
      return date
    } else {
      const splitedDate = date.split('-')
      return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`
    }
  }

  useEffect(() => {
    getSubscriptionSettings()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  if (!resourceAuthorized) {
    return <Error403 />
  }

  if (
    !isLoading &&
    Object.keys(subscriptionData).length > 0 &&
    resourceAuthorized
  ) {
    return (
      <>
        <WidgetBox>
          <div className='subscription-info-area'>
            <div className='plan-info-area'>
              <CurrentCardPlan
                planIdentifier={subscriptionData.plan_identifier}
              />
              <a
                className='btn btn-cancel-subscription'
                href={`mailto:suporte@zendesk.recrud.bne.com.br?subject=${t(
                  'unsubscribeEmail.subject',
                  {
                    ns: 'subscriptionSettings'
                  }
                )}&body=${t('unsubscribeEmail.body', {
                  ns: 'subscriptionSettings'
                })}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('unsubscribeEmail.buttonLabel', {
                  ns: 'subscriptionSettings'
                })}
              </a>
            </div>
            <div className='plan-payment-area'>
              <h1>
                {t('nextChargeTitle', {
                  ns: 'subscriptionSettings'
                })}
              </h1>
              <div className='plan-text-area-next-charge'>
                <p>
                  {t('nextChargeDescriptionDate', {
                    ns: 'subscriptionSettings'
                  })}
                  <strong>
                    {subscriptionData.expires_at !== null
                      ? formatDate(subscriptionData.expires_at)
                      : formatDate(
                          subscriptionData.recent_invoices[0].due_date
                        )}
                  </strong>
                  ,{' '}
                  {t('nextChargeDescriptionValue', {
                    ns: 'subscriptionSettings'
                  })}
                  <strong>
                    {subscriptionData.currency === 'BRL' ? 'R$' : ''}{' '}
                    {subscriptionData.price.toFixed(2)}
                  </strong>
                </p>
              </div>

              <h1>
                {t('paymentMethodTitle', {
                  ns: 'subscriptionSettings'
                })}
              </h1>
              <div className='plan-text-area'>
                {subscriptionData.payment_method !== null ? (
                  <div>
                    <p>
                      {t('paymentMethodType', {
                        ns: 'subscriptionSettings'
                      })}{' '}
                      <strong>{subscriptionData.payment_method.brand}</strong>{' '}
                      {t('paymentMethodCardNumber', {
                        ns: 'subscriptionSettings'
                      })}{' '}
                      <strong>
                        {subscriptionData.payment_method.display_number}
                      </strong>
                    </p>
                    <p>
                      {t('paymentMethodCardNumberExpiresAt', {
                        ns: 'subscriptionSettings'
                      })}{' '}
                      <strong>{`${subscriptionData.payment_method.expiration_month}/${subscriptionData.payment_method.expiration_year}`}</strong>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {t('warningWithoutPaymentMethod', {
                        ns: 'subscriptionSettings'
                      })}
                    </p>
                    <p>
                      {t('descriptionWithoutPaymentMethod', {
                        ns: 'subscriptionSettings'
                      })}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </WidgetBox>
        <br></br>
        <WidgetBox>
          <p className='title'>
            {t('invoices.invoicesTitle', { ns: 'subscriptionSettings' })}
          </p>
          <div className='table-area'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>
                    {t('invoices.expiresAt', {
                      ns: 'subscriptionSettings'
                    })}
                  </th>
                  <th>
                    {t('invoices.value', {
                      ns: 'subscriptionSettings'
                    })}
                  </th>
                  <th>
                    {t('invoices.status', {
                      ns: 'subscriptionSettings'
                    })}
                  </th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionData.recent_invoices.map((invoice, i) => {
                  return (
                    <>
                      <tr key={`invoice_${i}`}>
                        <td>{formatDate(invoice.due_date)}</td>
                        <td>{invoice.total}</td>
                        <td>
                          <StatusBadger status={invoice.status} />
                        </td>
                        <td>
                          <a
                            href={invoice.secure_url}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {t('invoices.link', {
                              ns: 'subscriptionSettings'
                            })}
                          </a>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </WidgetBox>
      </>
    )
  } else {
    return <div></div>
  }
}
