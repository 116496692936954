import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface CandidatesByLevelProps {
  t: any
  loading: boolean
}

const CandidatesByLevel = ({ t, loading }: CandidatesByLevelProps) => {
  const { candidatesEducationLevel } = useDashboardContext()
  return (
    <div className='row'>
      <div className='col-12'>
        {loading && !hasContent(candidatesEducationLevel) ? (
          <GraphicSkeleton />
        ) : (
          <DashboadCard title={t('chart7')}>
            <DashboardChart
              id='education-level-chart'
              visible={hasContent(candidatesEducationLevel)}
            />
          </DashboadCard>
        )}
      </div>
    </div>
  )
}

export default CandidatesByLevel
