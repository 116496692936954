import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Input } from 'components/Input/Input'
import { LGPDOption } from 'components/LGPDOption/LGPDOption'
import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import './LGPD.scss'

enum Questions {
  ASK_TELEPHONE = 'askTelephone',
  REQUIRED_TELEPHONE = 'requiredTelephone',
  ASK_MARITAL_STATUS = 'askMaritalStatus',
  REQUIRED_MARITAL_STATUS = 'requiredMaritalStatus',
  ASK_GENDER = 'askSex',
  REQUIRED_SEX = 'requiredSex',
  ASK_BIRTHDATE = 'askBirthdate',
  REQUIRED_BIRTHDATE = 'requiredBirthdate',
  ASK_WAGE_PRETENSION = 'askWageClaim',
  REQUIRED_WAGE_CLAIM = 'requiredWageClaim',
  ASK_MOVING_AVAILABILITY = 'askCityChangeAvailability',
  REQUIRED_MOVING_AVAILABILITY = 'requiredCityChangeAvailability',
  ASK_LINKEDIN_PROFILE = 'askLinkedinProfile',
  REQUIRED_LINKEDIN_PROFILE = 'requiredLinkedinProfile',
  ASK_FACEBOOK_PROFILE = 'askFacebookProfile',
  REQUIRED_FACEBOOK_PROFILE = 'requiredFacebookProfile',
  ASK_TWITTER_PROFILE = 'askTwitterProfile',
  REQUIRED_TWITTER_PROFILE = 'requiredTwitterProfile'
}

type LGPDContainerProps = {
  setFieldValue: SetFieldValue
  dataPrivacySettings: {
    askTelephone: boolean
    requiredTelephone: boolean
    askMaritalStatus: boolean
    requiredMaritalStatus: boolean
    askSex: boolean
    requiredSex: boolean
    askBirthdate: boolean
    requiredBirthdate: boolean
    askWageClaim: boolean
    requiredWageClaim: boolean
    askCityChangeAvailability: boolean
    requiredCityChangeAvailability: boolean
    askLinkedinProfile: boolean
    requiredLinkedinProfile: boolean
    askFacebookProfile: boolean
    requiredFacebookProfile: boolean
    askTwitterProfile: boolean
    requiredTwitterProfile: boolean
    termsOfUse?: string
  }
  getRef: (value: { companyLGPDRef }) => void
}

export const LGPDContainer: React.FC<LGPDContainerProps> = ({
  dataPrivacySettings,
  setFieldValue,
  getRef
}) => {
  const { t } = useTranslation('companySettings')
  const companyLGPDRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    getRef({ companyLGPDRef })
  }, [companyLGPDRef])

  const handleOptionChange = (key: string, value: boolean | string) => {
    setFieldValue('dataPrivacySettings', {
      ...dataPrivacySettings,
      [key]: value
    })
  }

  return (
    <div ref={companyLGPDRef}>
      <WidgetBox title={t('lgpd.title')} className='lgpd-container'>
        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_TELEPHONE],
            required: dataPrivacySettings[Questions.REQUIRED_TELEPHONE]
          }}
          title={t('lgpd.askPhoneTitle')}
          text={t('lgpd.askPhoneText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_TELEPHONE,
            required: Questions.REQUIRED_TELEPHONE
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_MARITAL_STATUS],
            required: dataPrivacySettings[Questions.REQUIRED_MARITAL_STATUS]
          }}
          title={t('lgpd.civilStatusTitle')}
          text={t('lgpd.civilStatusText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_MARITAL_STATUS,
            required: Questions.REQUIRED_MARITAL_STATUS
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_GENDER],
            required: dataPrivacySettings[Questions.REQUIRED_SEX]
          }}
          title={t('lgpd.askGenderTitle')}
          text={t('lgpd.askGenderText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_GENDER,
            required: Questions.REQUIRED_SEX
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_BIRTHDATE],
            required: dataPrivacySettings[Questions.REQUIRED_BIRTHDATE]
          }}
          title={t('lgpd.askBirthdateTitle')}
          text={t('lgpd.askBirthdateText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_BIRTHDATE,
            required: Questions.REQUIRED_BIRTHDATE
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_WAGE_PRETENSION],
            required: dataPrivacySettings[Questions.REQUIRED_WAGE_CLAIM]
          }}
          title={t('lgpd.askWagePretensionTitle')}
          text={t('lgpd.askWagePretensionText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_WAGE_PRETENSION,
            required: Questions.REQUIRED_WAGE_CLAIM
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_MOVING_AVAILABILITY],
            required:
              dataPrivacySettings[Questions.REQUIRED_MOVING_AVAILABILITY]
          }}
          title={t('lgpd.askMovingAvailabilityTitle')}
          text={t('lgpd.askMovingAvailabilityText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_MOVING_AVAILABILITY,
            required: Questions.REQUIRED_MOVING_AVAILABILITY
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_LINKEDIN_PROFILE],
            required: dataPrivacySettings[Questions.REQUIRED_LINKEDIN_PROFILE]
          }}
          title={t('lgpd.askAboutLinkedinProfileTitle')}
          text={t('lgpd.askAboutLinkedinProfileText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_LINKEDIN_PROFILE,
            required: Questions.REQUIRED_LINKEDIN_PROFILE
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_FACEBOOK_PROFILE],
            required: dataPrivacySettings[Questions.REQUIRED_FACEBOOK_PROFILE]
          }}
          title={t('lgpd.askAboutFacebookProfileTitle')}
          text={t('lgpd.askAboutFacebookProfileText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_FACEBOOK_PROFILE,
            required: Questions.REQUIRED_FACEBOOK_PROFILE
          }}
          onChange={handleOptionChange}
        />

        <LGPDOption
          initialValue={{
            question: dataPrivacySettings[Questions.ASK_TWITTER_PROFILE],
            required: dataPrivacySettings[Questions.REQUIRED_TWITTER_PROFILE]
          }}
          title={t('lgpd.askAboutTwitterProfileTitle')}
          text={t('lgpd.askAboutTwitterProfileText')}
          label={{
            question: t('lgpd.label.question'),
            required: t('lgpd.label.required')
          }}
          questionKey={{
            question: Questions.ASK_TWITTER_PROFILE,
            required: Questions.REQUIRED_TWITTER_PROFILE
          }}
          onChange={handleOptionChange}
        />
        <div className='terms-of-use-option'>
          <div className='description-area-terms-of-use'>
            <p className='title' data-testid='lgpd-title'>
              {t('lgpd.termsOfUseTitle')}
            </p>
            <p className='text' data-testid='lgpd-text'>
              {t('lgpd.termsOfUseText')}
            </p>
          </div>
          <div className='terms-of-use-input-area'>
            <Input
              type='text'
              label={'Link'}
              name='name'
              value={dataPrivacySettings.termsOfUse}
              onChange={value => handleOptionChange('termsOfUse', value)}
              small
            ></Input>
          </div>
        </div>
      </WidgetBox>
    </div>
  )
}
