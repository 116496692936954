import React, { useMemo } from 'react'

import useStore from 'store'
import { State } from 'store/permissions/types'

type UsePermissionAgentProps = {
  module: string
  actions: string[]
  onFail: 'hidden' | 'disabled' | string
}

const usePermissionAgent = ({
  module: permissionModule,
  actions,
  onFail
}: UsePermissionAgentProps) => {
  const { selectors } = useStore()
  const permissions = selectors.permissions.permissions() as {
    [key: string]: State['permissions']
  }
  const permission = permissions[
    permissionModule
  ] as UserPermissions['permissions']

  // eslint-disable-next-line
  const anyDenied = useMemo(() => {
    // eslint-disable-next-line
    return actions
      .map(action => {
        // eslint-disable-next-line
        return permission?.scopes.includes(`${permissionModule}:${action}`)
      })
      .filter(action => !action)
  }, [actions, permission])

  const injectProp = useMemo(() => {
    if (onFail === 'hidden' && !!anyDenied.length) {
      return { hidden: true }
    } else if (onFail === 'disabled' && !!anyDenied.length) {
      return { disabled: true }
    }
    return {}
  }, [anyDenied, onFail])

  const shouldRender = !(onFail === 'hidden' && injectProp.hidden)

  return { shouldRender, injectProp }
}

export default usePermissionAgent
