import React from 'react'

import classNames from 'classnames'

import './MenuItem.scss'

type MenuItemProps = {
  label: string
  onClick: () => void
  index: number
  current: number
}

export const MenuItem: React.FC<MenuItemProps> = ({
  label,
  onClick,
  index,
  current
}) => {
  return (
    <p
      className={classNames('sidebar-menu-link', {
        active: current === index
      })}
      onClick={onClick}
    >
      {label}
    </p>
  )
}
