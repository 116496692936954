import { handleApiResponse } from 'services/api/default/response/response.util'
import { WorkspacesApiPath } from 'services/api/workspaces/workspaces.constants'

import api from './../api'
import {
  CreateWorkspacePayload,
  UpdateWorkspacePayload,
  WorkspaceSchema
} from './workspaces.d'

/**
 * Creates a new workspace.
 *
 * @param payload - The payload containing the data for creating the workspace.
 * @type CreateWorkspacePayload
 *
 * @returns A promise that resolves to the default API response.
 * @type DefaultAPIResponse<WorkspaceSchema>
 * @example { status: 'success', statusCode: 200, message: 'Workspace created successfully', data: {...} }
 */
export const create = async (
  payload: CreateWorkspacePayload
): Promise<DefaultAPIResponse<WorkspaceSchema>> => {
  const finalPayload = {
    ...payload,
    status: 'active',
    id_matriz: '664ba1156e77e39286817ee0'
  }
  const response = await api.post(WorkspacesApiPath.create, finalPayload)

  return handleApiResponse(response)
}

/**
 * Update a workspace.
 *
 * @returns A Promise that resolves to a default API response.
 * @type DefaultAPIResponse<WorkspaceSchema>
 * @example { status: 'success', statusCode: 204, message: 'Exclusão bem-sucedida' }
 * @example { status: 'error', statusCode: 401, message: 'Falha na autenticação ou credencias inválidas' }
 * @example { status: 'success', statusCode: 404, message: 'Espaço de trabalho não encontrado' }
 * @example { status: 'success', statusCode: 500, message: 'Erro interno de servidor' }
 */
export const update = async (
  id: string,
  payload: UpdateWorkspacePayload
): Promise<any> => {
  const finalPayload = {
    ...payload
  }

  const response = await api.patch(
    `${WorkspacesApiPath.update}/${id}`,
    finalPayload
  )

  return handleApiResponse(response)
}

/**
 * Retrieves a list of workspaces.
 *
 * @returns A Promise that resolves to a default API response.
 * @type DefaultAPIResponse<WorkspaceSchema>
 * @example { status: 'success', statusCode: 200, message: 'Workspace created successfully', data: {...} }
 */
export const list = async (): Promise<
  DefaultAPIResponse<WorkspaceSchema[]>
> => {
  const response = await api.get(WorkspacesApiPath.read, {
    params: {
      page: 1,
      per_page: 100000
    }
  })
  return handleApiResponse(response)
}

/**
 * Delete a workspace.
 *
 * @returns A Promise that resolves to a default API response.
 * @type DefaultAPIResponse<WorkspaceSchema>
 * @example { status: 'success', statusCode: 204, message: 'Exclusão bem-sucedida' }
 * @example { status: 'error', statusCode: 401, message: 'Falha na autenticação ou credencias inválidas' }
 * @example { status: 'success', statusCode: 404, message: 'Espaço de trabalho não encontrado' }
 * @example { status: 'success', statusCode: 500, message: 'Erro interno de servidor' }
 */
export const remove = async (
  id: string
): Promise<DefaultAPIResponse<WorkspaceSchema>> => {
  const response = await api.delete(`${WorkspacesApiPath.delete}/${id}`)

  return handleApiResponse(response)
}
