import React from 'react'

import { createRoot } from 'react-dom/client'

import 'assets/styles/globals.scss'
// import 'assets/styles/layout.scss'
import { FilterFormProvider } from 'context/CandidateFilterContext'
import { ThemeController } from 'context/ThemeContext/ThemeContext'
import { isMobile } from 'utils/bowser'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { StoreProvider } from './store'
import StateInspectorComponent from './store/StateInspector'

import './i18n'

if (!isMobile()) {
  require('assets/styles/tooltip.scss')
}
const container = document.getElementById('root')

const root = createRoot(container!)
root.render(
  <StateInspectorComponent>
    <StoreProvider>
      <ThemeController>
        <FilterFormProvider>
          <App />
        </FilterFormProvider>
      </ThemeController>
    </StoreProvider>
  </StateInspectorComponent>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
