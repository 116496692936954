import React, { Suspense, useEffect, useMemo } from 'react'

import { match } from 'path-to-regexp'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Loading } from 'components/Loading/Loading'
import { useTheme } from 'context/ThemeContext/ThemeContext'
import { RoutePaths, routesConfig } from 'router/Router.config'
import { pagesMapping } from 'router/routeThemeMapping'

export const DynamicPageLoader: React.FC = () => {
  const { t } = useTranslation('router')
  const location = useLocation()
  const { setTheme } = useTheme()

  const currentPathKey = useMemo(() => {
    const path = Object.keys(pagesMapping()).find(key => {
      const m = match(key)
      return m?.(location.pathname)
    }) as RoutePaths
    return path
  }, [location.pathname])

  const showPageComponent = useMemo(() => {
    const pages = pagesMapping()
    const PageComponent = pages[currentPathKey]
    if (!PageComponent) {
      return <div>{t('pageNotFound')}</div>
    }
    return <PageComponent />
  }, [currentPathKey])

  const currentRouteConfig = routesConfig.find(({ path }) =>
    match(path)(currentPathKey)
  )

  useEffect(() => {
    if (currentRouteConfig?.theme) {
      setTheme(currentRouteConfig.theme)
    }
  }, [currentRouteConfig?.theme, setTheme])

  return <Suspense fallback={<Loading />}>{showPageComponent}</Suspense>
}
