import { useNavigate } from 'react-router-dom'

import useStore from 'store'

type UsePermission = {
  moduleName: PermissionModule
  action: PermissionAction
}

export const usePermission = ({
  moduleName,
  action
}: UsePermission): PermissionAction[] => {
  const navigate = useNavigate()

  const { selectors } = useStore()
  const permissionsObject =
    selectors.permissions.getPermissionsByModuleName(moduleName)
  const permissions = Object.values(permissionsObject) as PermissionAction[]

  const isPermissionsEmpty = !Object.keys(permissions).length
  const isActionNotPermitted = !permissions.includes(action)

  if (isPermissionsEmpty) return []
  if (isActionNotPermitted) navigate('/not-allowed')

  return permissions
}
