import React, { useState, useEffect } from 'react'

import { SketchPicker, RGBColor } from 'react-color'
import './ColorPicker.scss'

type Props = {
  onChange: (value: string) => void
  value?: string | RGBColor
  label?: string
}

export const ColorPicker: React.FC<Props> = ({ onChange, value, label }) => {
  const [colorCode, setColorCode] = useState(value || ({} as RGBColor))

  useEffect(() => {
    if (value && value !== colorCode.toString()) {
      setColorCode(value)
    }
  }, [value])

  return (
    <div className='color-picker-wrapper'>
      <p className='color-picker-label'>{label}</p>

      <SketchPicker
        color={colorCode}
        presetColors={[]}
        onChange={e => {
          setColorCode(e.hex)
          onChange(e.hex)
        }}
      />
    </div>
  )
}
