import React, { useCallback, useEffect, useRef, useState } from 'react'

import debounce from 'lodash.debounce'

import { ReactComponent as MapPinIcon } from 'assets/svg/mapPinIcon.svg'
import { Input } from 'components/Input/Input'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'
import { getCities } from 'services/getCities'

import './InputCity.scss'

type Props = {
  label: string
  required?: boolean
  name: string
  onChange: (e: string[]) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  value?: string | number | readonly string[]
  type: 'text' | 'password'
  small?: boolean
  full?: boolean
  error?: string
}

export const InputCity: React.FC<Props> = ({
  label,
  required,
  name,
  onChange,
  value,
  type,
  small,
  full,
  onBlur,
  error
}) => {
  const [active, setActive] = useState(false)
  const [val, setVal] = useState(value ?? '')
  const inputReference = useRef<HTMLInputElement>(null)
  const [result, setResult] = useState(false)
  const [results, setResults] = useState<Array<string>>([])
  const inputRef = useRef(null)

  useEffect(() => {
    const noSpaces = typeof val === 'string' && val.trim() !== ''
    const notUndefined = val !== undefined

    if (notUndefined && noSpaces) {
      if (val.toString().length >= 3) {
        fetchCities(val)
      }
      if (val.toString().length < 3 || result) setActive(false)
      else setActive(true)
      setResult(false)
    }
  }, [val])

  useEffect(() => {
    if (result || !val) {
      const locations: Array<string> = []
      if (val) {
        const splitAddress = val.toString().split(',')
        locations.push(val as string)
        if (splitAddress.length === 4) {
          const simpleAddress =
            splitAddress[1].trim() +
            ',' +
            splitAddress[2] +
            ',' +
            splitAddress[3]
          locations.push(simpleAddress)
        } else {
          locations.push(val as string)
        }
      }
      onChange(locations)
    }
  }, [result, val])

  useEffect(() => {
    if (value !== undefined) {
      setVal(value?.toString())
    }
  }, [value])

  useEffect(() => {
    if (value) setVal(value?.toString())
    else setVal('')
  }, [value])

  const fetchCities = useCallback(
    debounce(async (value: string) => {
      const hasFocus = document.activeElement === inputReference.current
      if (hasFocus) {
        const cities = await getCities(value)
        setResults(cities)
      }
    }, 300),
    []
  )

  useEffect(() => {
    if (value && value !== val) {
      setVal(value)
    }
  }, [value])

  useOutsideAlerter(inputRef, () => {
    setActive(false)
  })

  return (
    <div className='input-city-container' ref={inputRef}>
      <div className='input-city-wrapper'>
        <Input
          label={label}
          required={required}
          type={type}
          name={name}
          value={val}
          inputRef={inputReference}
          onChange={e => {
            setVal(e)
          }}
          small={small}
          full={full}
          onBlur={onBlur}
          error={error}
          autoComplete={false}
        />

        <MapPinIcon className='input-icon' />
      </div>

      <div
        className={`results ${
          active && results?.[0] !== undefined ? 'active' : ''
        }`}
      >
        <ul>
          {results?.map((el, index) => (
            <li
              key={index}
              onClick={() => {
                setVal(el)
                setResult(true)
              }}
            >
              <p>{el}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
