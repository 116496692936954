import React from 'react'
import './LGPDOption.scss'

import { Switch } from 'components/Switch/Switch'

interface InitialObject {
  question: boolean
  required: boolean
}

interface QuestionObject {
  question: string
  required: string
}

interface LabelObject {
  question: string
  required: string
}

export interface LGPDOptionProps {
  title: string
  text: string
  label: LabelObject
  initialValue: InitialObject
  questionKey: QuestionObject
  onChange: (key: string, value: boolean) => void
}

export const LGPDOption: React.FC<LGPDOptionProps> = ({
  title,
  text,
  label,
  initialValue,
  questionKey,
  onChange
}) => {
  return (
    <div className='switch-option'>
      <div className='description-area'>
        <p className='title' data-testid='lgpd-title'>
          {title}
        </p>
        <p className='text' data-testid='lgpd-text'>
          {text}
        </p>
      </div>
      <div className='switch-area'>
        <div className='form-switch' data-testid='lgpd-switch'>
          <div className='form-switch-area'>
            <label className='form-switch-label'>{label.question}</label>
            <Switch
              initialValue={initialValue.question}
              onChange={value => onChange(questionKey.question, value)}
            />
          </div>
        </div>
        <div className='form-switch-required' data-testid='lgpd-switch'>
          <div className='form-switch-area'>
            <label className='form-switch-label'>{label.required}</label>
            <Switch
              initialValue={initialValue.required}
              onChange={value => onChange(questionKey.required, value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
