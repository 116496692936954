import * as AsideMenu from './asideMenu/selectors'
import * as Auth from './auth/selectors'
import * as Notification from './notification/selectors'
import * as OnboardingHelper from './onboardingHelper/selectors'
import * as Permissions from './permissions/selectors'
import { initialState } from './rootReducer'
import { StoreTypes } from './types'

export function selectors(state = initialState): StoreTypes.Selector {
  const asideMenu = AsideMenu.Selector(state.asideMenu)
  const auth = Auth.Selector(state.auth)
  const notification = Notification.Selector(state.notification)
  const permissions = Permissions.Selector(state.permissions)
  const onboardingHelper = OnboardingHelper.Selector(state.onboardingHelper)

  return {
    asideMenu,
    auth,
    notification,
    permissions,
    onboardingHelper
  }
}
