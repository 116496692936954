/* eslint-disable dot-notation */
import React, { useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { useFilterForm } from 'context/CandidateFilterContext'
import { useClickOutside } from 'hooks/useClickOutside'
import useStore from 'store'

import {
  EducationSection,
  EducationFilter
} from './EducationSection/EducationSection'
import {
  ExperienceSection,
  ExperienceFilter
} from './ExperienceSection/ExperienceSection'
import { GeneralSection, GeneralFilter } from './GeneralSection/GeneralSection'
import {
  LanguageSection,
  LanguageFilter
} from './LanguageSection/LanguageSection'

import './CandidateFilterContainer.scss'

export type FilterForm = {
  general: {
    location: string
    age: {
      min: number | string
      max: number | string
    }
    gender: string
    civilStatus: string
    salary: {
      min: number | string
      max: number | string
    }
    availableToMove: string
    workspaces: string
  }
  education: {
    name: string
    course: string
    level: string
  }
  experience: {
    company: string
    position: string
    timeInYears: number | string
  }
  language: {
    name: string
    level: string
  }
}

type SetFilterForm = Omit<FilterForm, 'general'> & {
  general: GeneralFilter
}

export type CandidateFilterRef = {
  filterRef: React.RefObject<HTMLDivElement>
}

type Props = {
  onFilter: () => void
  getRefs?: (value: CandidateFilterRef) => void
  openFilter?: boolean
  handleOpenFilter?: (value: boolean) => void
}

export const CandidateFilterContainer: React.FC<Props> = ({
  onFilter,
  getRefs,
  openFilter,
  handleOpenFilter
}) => {
  const { t } = useTranslation('candidateFilter')
  const [visible, setVisible] = useState<boolean>(false)
  const { filterFormState: filter, updateFilterFormState } = useFilterForm()
  const [companySettings, setCompanySettings] =
    useState<CompanySettingsAPI | null>(null)
  const filterRef = useRef(null)
  const { selectors } = useStore()

  const onCloseFilter = () => {
    setVisible(false)
    handleOpenFilter && handleOpenFilter(false)
  }

  useClickOutside(filterRef, () => {
    onCloseFilter()
  })

  useEffect(() => {
    if (getRefs !== undefined) getRefs({ filterRef })
  }, [])

  useEffect(() => {
    setVisible(selectors.onboardingHelper.currentValue())
  }, [selectors.onboardingHelper.currentValue()])

  useEffect(() => {
    if (openFilter) setVisible(openFilter)
  }, [openFilter])

  useEffect(() => {
    const user = selectors.auth.user()
    setCompanySettings(user?.company?.data_privacy_settings || null)
  }, [])

  const handleFilterClick = () => {
    const newVisibility = !visible
    if (newVisibility === false) {
      onFilter()
    }

    setVisible(newVisibility)
  }

  const onSetFilter = (value: FilterForm | SetFilterForm) => {
    // Check if value includes a GeneralFilter and treat it
    if (value.general) {
      // Convert workspace from array to string separated by commas
      const workspacesAsString = Array.isArray(value.general.workspaces)
        ? value.general.workspaces.join(',')
        : value.general.workspaces

      const treatedGeneralFilter = {
        ...value.general,
        workspaces: workspacesAsString
      }

      // Update the value with the treated GeneralFilter
      const updatedValue = {
        ...value,
        general: treatedGeneralFilter
      }

      updateFilterFormState(updatedValue)
    } else {
      // If no General, call updateFilterFormState directly
      updateFilterFormState(value as FilterForm)
    }
  }

  return (
    <aside
      className={`candidate-filter ${visible ? 'filter-active' : ''}`}
      ref={filterRef}
    >
      <div className='candidate-filter-container'>
        <GeneralSection
          companySettings={companySettings}
          onChange={(general: GeneralFilter) =>
            onSetFilter({ ...filter, general })
          }
          filter={filter}
          clearFilters={onSetFilter}
        />
        <EducationSection
          onChange={(education: EducationFilter) =>
            onSetFilter({ ...filter, education })
          }
          filter={filter}
        />
        <ExperienceSection
          onChange={(experience: ExperienceFilter) =>
            onSetFilter({ ...filter, experience })
          }
          filter={filter}
        />
        <LanguageSection
          onChange={(language: LanguageFilter) =>
            onSetFilter({ ...filter, language })
          }
          filter={filter}
        />
      </div>
      <button
        className='candidate-filter-button'
        onClick={() => handleFilterClick()}
      >
        {visible ? t('buttons.applyFilters') : t('buttons.filter')}
      </button>
    </aside>
  )
}
