import React from 'react'

import { DashboadCard } from 'components/DashboadCard/DashboadCard'
import DashboardChart from 'components/DashboardChart/DashboardChart'

import GraphicSkeleton from '../../components/Skeleton'
import { hasContent } from '../../DashboardContainer.util'
import { useDashboardContext } from '../../DashboardContainerContext'

interface CandidatesByMaritalStatusProps {
  t: any
  loading: boolean
  dataPrivacySettings: any
}

const CandidatesByMaritalStatus = ({
  t,
  loading,
  dataPrivacySettings
}: CandidatesByMaritalStatusProps) => {
  const { candidatesMaritalStatus } = useDashboardContext()
  return (
    <div className='row'>
      {dataPrivacySettings?.ask_marital_status && (
        <div className='col-12'>
          {loading && !hasContent(candidatesMaritalStatus) ? (
            <GraphicSkeleton />
          ) : (
            <DashboadCard title={t('chart8')}>
              <DashboardChart
                id='maritial-status-chart'
                visible={hasContent(candidatesMaritalStatus)}
              />
            </DashboadCard>
          )}
        </div>
      )}
    </div>
  )
}

export default CandidatesByMaritalStatus
