import React, { useState, useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import people from 'assets/images/background/people.png'
import splashBackground from 'assets/images/background/splash-background.png'
import recrudLogo from 'assets/images/logo/recrud-title-logo.png'
import { LoginSwitch } from 'components/LoginSwitch/LoginSwitch'
import { LoginCard } from 'containers/Login/LoginCard/LoginCard'
import useStore from 'store'
import ReactGA from 'utils/analytics'
import {
  RedirectAndRemoveCallbackUrl,
  CheckCallbackUrl
} from 'utils/partnerCallbackUrl'

import './login.scss'

type Kind = 'login' | 'register'
type LoginType = 'default' | 'direct'

export function LoginPage(): JSX.Element {
  const { t } = useTranslation('login')
  const location = useLocation()
  const navigate = useNavigate()
  const { selectors } = useStore()
  const userLogged = selectors.auth.logged()
  const userInfo = selectors.auth.user()
  const [kind, setKind] = useState<Kind>('login')
  const [loginType, setLoginType] = useState<LoginType>('direct')

  const removeCallbackUrl = window.localStorage.getItem('removeCallbackUrl')

  useEffect(() => {
    if (CheckCallbackUrl() === false) {
      setLoginType('default')
    }
    ReactGA.pageView()
    if (
      location.pathname === '/signup' &&
      process.env.REACT_APP_ALLOW_USER_REGISTRATION === 'true'
    )
      setKind('register')
  }, [])

  useEffect(() => {
    if (userLogged && CheckCallbackUrl()) {
      if (userInfo?.role === 'recruiter_webtactics') {
        navigate('/jobs')
      } else {
        navigate('/dashboard')
        navigate(0)
      }
    }
    if (!userLogged && removeCallbackUrl === 'true') {
      RedirectAndRemoveCallbackUrl()
    }
  }, [userLogged])

  if (loginType === 'default') {
    return (
      <>
        <Helmet>
          <title>{t('metatags.title')}</title>
          <meta name='description' content={t('metatags.description')} />
          <meta name='url' content={t('metatags.url')} />
          <meta property='og:title' content={t('metatags.title')} />
          <meta property='og:description' content={t('metatags.description')} />
          <meta property='og:url' content={t('metatags.url')} />
        </Helmet>

        <main>
          <div className='container h-100'>
            <div className='row h-100'>
              <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
                <div className='splash-background-area'>
                  <img
                    className='splash-background'
                    src={splashBackground}
                    alt='splash-background'
                  />
                  <img
                    className='splash-background-people'
                    src={people}
                    alt='people'
                  />

                  <div className='landing-info'>
                    <h2>{t('welcome')}</h2>
                    <div className='d-flex justify-content-center mb-4'>
                      <img
                        className='recrud-logo'
                        src={recrudLogo}
                        alt='Recrud'
                      />
                    </div>
                    <div className='paragraph-area'>
                      <p className='first-paragraph'>{t('firstParagraph')}</p>
                      <p className='second-paragraph'>{t('secondParagraph')}</p>
                    </div>
                    <div className='mb-5'>
                      {process.env.REACT_APP_ALLOW_USER_REGISTRATION ===
                        'true' && (
                        <LoginSwitch
                          kind={kind}
                          onChange={value => setKind(value as Kind)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
                <LoginCard
                  kind={kind}
                  onChangeKind={value => setKind(value as Kind)}
                />
              </div>
            </div>
          </div>
        </main>
      </>
    )
  } else {
    return <div></div>
  }
}
