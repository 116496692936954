import React, { useEffect } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-icon.svg'
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg'

import './Modal.scss'

type ModalProps = {
  open: boolean
  title?: string
  closeIcon?: 'cross' | 'arrow'
  width?: string
  onClose: () => void
  customClass?: string
}

export const Modal: React.FC<ModalProps> = ({
  open,
  title,
  closeIcon,
  children,
  width,
  onClose,
  customClass = ''
}) => {
  useEffect(() => {
    if (open) {
      document.querySelector('.popup-box')?.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'start'
      })
    }
  }, [open])
  return (
    <div className={customClass}>
      <div
        className={`popup-overlay ${open ? 'visible' : ''}`}
        data-testid='modal-overlay'
        onClick={() => onClose()}
      />
      <div
        className={`popup-box ${open ? 'visible' : ''}`}
        data-testid='modal-container'
        style={{ width: width || undefined }}
      >
        <button
          className='popup-close-button'
          onClick={() => onClose()}
          data-testid='modal-close-button'
        >
          {closeIcon === 'arrow' ? (
            <ArrowIcon className='icon-cross' />
          ) : (
            <CrossIcon className='icon-cross' />
          )}
        </button>
        <div className='modal-content-wrapper'>
          {title ? (
            <p className='popup-box-title' data-testid='modal-title'>
              {title}
            </p>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  )
}
