import React from 'react'

import './TimeLine.scss'

export type TimeLineItems = {
  title?: string
  subTitle?: string
  content?: string
}

type Props = {
  items: Array<TimeLineItems>
}

export const TimeLine: React.FC<Props> = ({ items }) => {
  return (
    <div className='timeline-wrapper'>
      <ul>
        {items.map((el, index) => {
          return (
            <li key={index}>
              <p className='timeline-title m-0'>{el.title}</p>
              <p className='timeline-subtitle m-0'>{el.subTitle}</p>
              <p className='timeline-content m-0'>{el.content}</p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
