import React, { createRef, useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import { ReactComponent as SmallArrowIcon } from 'assets/images/icons/small-arrow-icon.svg'
import ReactGA from 'utils/analytics'
import { returnDefaultStages } from 'utils/defaultStage'

import './HiringStages.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export type Stage = {
  name: string
  candidatesCount: number
}

export type HiringStagesRef = {
  workflowRef: React.RefObject<HTMLDivElement>
}

type Props = {
  stages: Array<WorkflowStageSchema>
  selected: string
  onChange: (stage: WorkflowStageSchema) => void
  getRefs?: (value: HiringStagesRef) => void
  applicationsTotal?: [k: string]
}

export const HiringStages: React.FC<Props> = ({
  stages,
  selected,
  onChange,
  getRefs,
  applicationsTotal
}) => {
  const sliderRef = createRef<Slider>()
  const workflowRef = useRef(null)
  const { t } = useTranslation('defaultWorkflowStages')

  const settings = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0
  }

  const handleOnClick = (stage: WorkflowStageSchema) => {
    onChange(stage)

    if (
      returnDefaultStages(stages).find(
        defaultStage => defaultStage.uuid === stage.uuid
      )
    ) {
      ReactGA.event(`filter_candidates_by_${stage.name.toLowerCase()}`, {
        event_category: 'job_listing_candidates',
        event_label: `filter_candidates_by_${stage.name.toLowerCase()}`
      })
    }
  }

  useEffect(() => {
    if (getRefs !== undefined) getRefs({ workflowRef })
  }, [])

  return (
    <div className='slider-line medium'>
      <div id='reaction-stats-slider-controls' className='slider-controls'>
        <div
          className='slider-control left'
          onClick={() => sliderRef.current?.slickPrev()}
        >
          <SmallArrowIcon className='slider-control-icon icon-small-arrow' />
        </div>
        <div
          className='slider-control right'
          onClick={() => sliderRef.current?.slickNext()}
        >
          <SmallArrowIcon className='slider-control-icon icon-small-arrow' />
        </div>
      </div>
      <div
        id='reaction-stats-slider'
        className='slider-slides with-separator'
        ref={workflowRef}
      >
        <Slider {...settings} ref={sliderRef}>
          {stages &&
            stages
              .sort((a, b) => a.order - b.order)
              .map(stage => {
                return (
                  <div
                    className='slider-slide'
                    key={stage.uuid}
                    onClick={() => handleOnClick(stage)}
                  >
                    <div
                      data-testid='hiring-stage'
                      className={`reaction-stat ${
                        selected === stage.uuid ? 'active' : ''
                      }`}
                    >
                      <p className='reaction-stat-title'>
                        {stage.candidate_count}
                      </p>
                      <p className='reaction-stat-text'>{t(stage.name)}</p>
                    </div>
                  </div>
                )
              })}
        </Slider>
      </div>
    </div>
  )
}
