import { useEffect, useCallback } from 'react'

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY

declare global {
  interface Window {
    grecaptcha: any // eslint-disable-line
  }
}

export const useReCAPTCHA = (): { getToken: () => Promise<string> } => {
  const getToken = useCallback((): Promise<string> => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(reCaptchaKey)
          resolve(token)
        } catch (error) {
          reject(error)
        }
      })
    })
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`
    document.body.appendChild(script)
  }, [])

  return { getToken }
}
