import { snakeKeyToCamelCase } from 'utils'

import api from './api'

export const create = async (
  payload: CompanyAPIPayload
): Promise<[APIError, Company | null]> => {
  try {
    const response = await api.post('/v1/company', payload)

    if (response.status === 201) {
      const data: Company = response.data.data
      return [null, data]
    }
    if (response.status >= 400 && response.status <= 500) {
      throw new Error(response.data.message as string)
    }
    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const createIugu = async (
  payload: CompanyIuguAPIPayload
): Promise<[APIError, Company | null]> => {
  try {
    const response = await api.post('/v1/rocketmat/company', payload)

    if (response.status === 201) {
      const data: Company = response.data.data
      return [null, data]
    }
    if (response.status >= 400 && response.status <= 500) {
      throw new Error(response.data.message as string)
    }
    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const checkSlug = async (payload: string): Promise<number> => {
  const response: { status: number } = await api.post(
    `/v1/company/check-slug?slug=${payload}`
  )

  return response.status
}

export const edit = async (
  uuid: string,
  payload: CompanyAPIPayload
): Promise<[APIError, Company | null]> => {
  try {
    const response = await api.patch(`/v1/company/${uuid}`, payload)

    if (response.status === 200) {
      const data: Company = response.data.data
      return [null, data]
    }
    if (response.status >= 400 && response.status <= 500) {
      throw new Error(response.data.message as string)
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const get = async (
  uuid: string | undefined
): Promise<[APIError, CompanyPayload | null]> => {
  try {
    const response = await api.get(`/v1/company/${uuid}`)

    if (response.status === 200) {
      return [null, snakeKeyToCamelCase(response.data.data) as CompanyPayload]
    }

    if (response.status >= 400 && response.status <= 500) {
      throw new Error(response.data.message as string)
    }
    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}

export const logo = async (
  file: File,
  uuid: string
): Promise<[APIError, CompanyPayload | null]> => {
  const formData = new FormData()
  formData.append('logo', file)

  const response = await api.post(`/v1/company/${uuid}/logo`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  if (response.status === 200) {
    return [null, response.data]
  }

  if (response.status >= 400 && response.status <= 500) {
    throw new Error(response.data.message as string)
  }

  return [response.data?.message as string, null]
}
