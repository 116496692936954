import api from './api'

export const list = async (): Promise<
  [APIError, AcquisitionChannel[] | null]
> => {
  try {
    const response = await api.get('/v1/acquisition-channel')

    if (response.status === 200) {
      return [null, response.data.data]
    }

    return [response.data?.message as string, null]
  } catch (error) {
    return [error.message, null]
  }
}
