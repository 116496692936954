import React, { useEffect, useState } from 'react'

import { Onboarding, RefList } from 'components/Onboarding/Onboarding'
import { TeamCardRefs } from 'components/TeamCard/TeamCard'
import { TeamManagementContainer } from 'containers/TeamManagement/TeamManagementContainer'
import { usePermission } from 'hooks/usePermission'
import useStore from 'store'
import ReactGA from 'utils/analytics'

export function TeamManagementPage(): JSX.Element {
  usePermission({ moduleName: 'user', action: 'list' })
  const { selectors } = useStore()
  const expandedMenu = selectors.asideMenu.isExpanded()

  const [refList, setRefList] = useState({})
  const [cardRefList, setCardRefList] = useState({})

  useEffect(() => {
    setRefList({ ...cardRefList })
  }, [cardRefList])

  useEffect(() => {
    ReactGA.pageView()
  }, [])

  const setCardTutorial = (value: TeamCardRefs) => {
    const obj: RefList = {
      1: { ref: value.card as React.MutableRefObject<HTMLElement> },
      2: {
        ref: value.status as React.MutableRefObject<HTMLElement>
      },
      3: {
        ref: value.block as React.MutableRefObject<HTMLElement>
      },
      4: { ref: value.edit as React.MutableRefObject<HTMLElement> },
      5: { ref: value.delete as React.MutableRefObject<HTMLElement> },
      6: { ref: value.addButton as React.MutableRefObject<HTMLElement> }
    }

    setCardRefList(obj)
  }

  return (
    <>
      <div
        className={`content-grid mt-2 ${
          expandedMenu ? 'content-grid-expanded-menu' : ''
        }`}
      >
        <Onboarding reflist={refList} page='companyTeam' />
        <TeamManagementContainer getRefs={setCardTutorial} />
      </div>
    </>
  )
}
