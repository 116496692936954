/* istanbul ignore file */

import { ActionMap } from 'store/types'

export type State = {
  onboardingHelper: boolean
}

export enum Types {
  UPDATE = 'onboardingHelper/UPDATE'
}

export type Payload = {
  [Types.UPDATE]: boolean
}

export type Selector = {
  currentValue(): boolean
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>]
