import React from 'react'

import { useTranslation } from 'react-i18next'

import { WidgetBox } from 'components/WidgetBox/WidgetBox'
import './Error403.scss'

export const Error403: React.FC = () => {
  const { t } = useTranslation('errors')
  return (
    <>
      <WidgetBox>
        <div className='error-area'>
          <h1>{t('errorTitle')} 403</h1>
          <p>{t('forbbidenError')}</p>
        </div>
      </WidgetBox>
    </>
  )
}
