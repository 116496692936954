/* eslint-disable */

export const snakeToCamelCase = (value: string): string => {
  if (!value) return value

  return value.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )
}

export const snakeKeyToCamelCase = object => {
  try {
    const newObject = {}
    Object.keys(object).forEach((key: string) => {
      if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
        return (newObject[snakeToCamelCase(key)] = snakeKeyToCamelCase(
          object[key] as { [key: string]: unknown }
        ))
      }

      newObject[snakeToCamelCase(key)] = object[key]
    })
    return newObject
  } catch (error) {
    return object
  }
}
